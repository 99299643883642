@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";

.searchContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  border: 1px solid #008392;
  border-radius: 10px;
  margin: 0px 40px;
}

.searchIcon {
  margin-left: 16px;
  margin-right: 16px;
}

.searchInputContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 55px;
}

.searchInput {
  // width: -webkit-fill-available;
  width: 100%;
  height: 54px;
  outline: none;
  border: none;
  line-height: 22px;
  @include medium-font();
  font-size: 1.4rem;
}

.microPhone {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.placeHolderColor {
  composes: searchInput;
  &::placeholder {
    color: $textLink;
  }
}

.border {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  border-left: 1px solid $borderDefault;
  height: 20px;
}

.clearIcon {
  cursor: pointer;
  display: flex;
  align-items: center;
}

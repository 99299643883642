@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 16px;
  background-color: $white;
  width: 600px;
  max-width: 600px;
  border-radius: 16px;
}

.createdByContainer {
  display: flex;
  flex-direction: row;
  padding: 24px;
  justify-content: space-between;
  flex-shrink: 0;
}

.createdByLeftContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.createdByRightContainer {
  display: flex;
  flex-direction: row;
  padding-left: 16px;
}
.attachmentContainer {
  display: flex;
  cursor: pointer;
}

.audioAttachmentContainer {
  display: flex;
  padding: 0 24px;
  margin-bottom: 12px;
}

.noAttachment {
  display: flex;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray31;
  padding: 0 24px;
}

.taggedTextContainer {
  display: flex;
  margin-left: 12px;
  align-items: center;
  max-width: 424px;
}

.taggedCreatedByText {
  display: flex;
  flex-direction: column;
}

.taggedCreatedByTopContainer {
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $textDefault;
}

.assesmentLinkContainer{
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $textDefault;
  text-decoration: underline;
}

.taggedAssesmentMessageText{
  @include multiLineEllipsis(2.4rem, 2);
   font-size: 1.6rem;
   padding: 0;
   margin: 0;
}

.taggedCreatedByBottomContainer {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: $textSubtle;
  @include medium-font;
  margin-top: 4px;
  line-height: 1.6rem;
}

.tagSvgIcon {
  display: flex;
  &:not(:last-child) {
    margin-right: 10px;
  }
}
.titleTextContainer {
  display: flex;
  white-space: pre-line;
  width: 100%;
  padding: 0 24px;
  margin-bottom: 24px;
}

.titleText {
  @include medium-font;
  font-size: 1.6rem;
  color: $gray13;
  width: 100%;
  word-break: break-word;
}

.evidenceTimeText {
  display: flex;
}

.rejectTextShow {
  border-top: 0px;
}

.rejectTextContainer {
  background-color: $interactiveOneDefault;
  border-radius: 16px 16px 0px 0px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rejectText {
  color: $white;
  font-size: 1.4rem;
  @include demi-bold-font;
  margin-left: 8px;
}

.rejectCommentContainer {
  background-color: $toddleBG2;
  padding: 16px 32px;
  display: flex;
  flex-direction: row;
}

.rejectCommentText {
  color: $gray13;
  font-size: 1.4rem;
  @include medium-font;
}

.rejectCommentIcon {
  margin-right: 16px;
}

.rejectedPost {
  opacity: 0.33;
}

.leInnerContainer {
  display: flex;
  align-items: center;
  padding: 20px 0 16px;
  border-bottom: 1px solid $borderSubtle;
  margin: 0 24px;
}

.assessmentContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.leIconContainer {
  margin-right: 8px;
  display: flex;
}

.assessmentTitle {
  font-size: 1.4rem;
  @include medium-font;
  @include multiLineEllipsis(1.9rem, 1);
  color: $textDefault;

  &:hover {
    border-bottom: 1px solid $textDefault;
  }
}

.dotContainer {
  display: flex;
  align-items: center;
  margin: 0 6px;
}

.dot {
  display: flex;
  align-items: center;
  @include dot(2px, 2px, $textDisabled);
}

.evidenceContainer {
  display: flex;
  align-items: center;
}

.iconContainer {
  display: flex;
  margin-right: 4px;
}

.icon {
  display: flex;
  align-items: center;
}

.taggedStudentsContainer {
  display: flex;
  align-items: center;
}

.taggedStudentsCountContainer {
  color: $textSubtle;
}

.taggedText {
  @include multiLineEllipsis(1.6rem, 1);
}

.timeText {
  white-space: nowrap;
}

.studentResponseContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.studentResponseContainerWithAudio {
  display: flex;
  flex-direction: column;
}
.createdByWrapper {
  cursor: pointer;
}

.approvalContainer {
  background-color: $yellow50;
  border-radius: 16px 16px 0px 0px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $white;
  padding:8px 16px;
}
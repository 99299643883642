@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-wrap: wrap;
}

.tagContainer {
  display: flex;
  height: min-content;
  background-color: $white;
  border: 1px solid $strokeTwo;
  border-radius: 24px;
  padding: 8px 16px;
  justify-content: space-between;
  margin: 4px 4px 2px 0;
  cursor: pointer;
}

.label {
  display: flex;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray31;
  justify-content: center;
  align-items: center;
}

.removeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  padding: 4px;
  cursor: pointer;
  fill: $gray72;
  &:hover {
    fill: $gray48;
  }
}

.addIcon {
  fill: $blue29;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 8px;
}

.addButton {
  cursor: pointer;
  border-color: $blue29;
}

.salmon {
  background: $salmon60;
  border-color: $toddleBG1;
  .label {
    color: $toddleBG1;
  }
}

.blue29 {
  border-color: $blue29;
  .label {
    color: $blue29 !important;
  }
  .removeButton {
    fill: $blue29 !important;
  }
}

.black {
  border-color: $strokeTwo;
  .label {
    color: $gray31 !important;
  }
  .removeButton {
    fill: $gray72 !important;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: $white;
  padding: 16px 40px;
  border-bottom: 1px solid $strokeOne;
}

.leftContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.chevronIconContainer {
  display: flex;
  margin-right: 8px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.rightContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.previewButton {
  display: flex;
  @include demi-bold-font;
  color: $blue29;
  font-size: 1.6rem;
  cursor: pointer;
}

.separatorline {
  display: flex;
  border-right: 1px solid $strokeTwo;
  width: 1px;
  height: 100%;
  margin: 0 12px;
}

.titleText {
  display: flex;
  @include bold-font;
  font-size: 2.2rem;
  color: $gray13;
  margin-left: 16px;
}

.closeButton {
  display: flex;
  width: 24px;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.backContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 16px;
}

.backBtnText {
  display: flex;
  @include medium-font;
  color: $salmon60;
  font-size: 1.4rem;
  margin-left: 8px;
  margin-top: 3px;
}

.leContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.leIconContainer {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.assessmentTitle {
  font-size: 1.4rem;
  @include medium-font;
  @include multiLineEllipsis(1.9rem, 1);
  color: $textDefault;

  &:hover {
    border-bottom: 1px solid $textDefault;
  }
}

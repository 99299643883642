@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.curriculumProgramRow {
  height: 64px;
  border-bottom: 1px solid $borderSubtle;
  display: flex;
  justify-content: space-between;
}

.labelContainer {
  @include demi-bold-font;
  display: flex;
  justify-content: flex-start;
  font-size: 1.6rem;
  padding-top: 20px;
  width: 50%;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.actionButtonsContainer {
  width: 120px;
  display: flex;
  justify-content: center;
}
.selectButtonContainer {
  width: 180px;
}
.selectButtonContainerWithPadding {
  padding-left: 16px;
}

.curriculumProgramRow :global {
  .Select-value-label {
    @include medium-font;
    color: $textDefault;
    font-size: 1.4rem;
  }
}

.attendanceRecordModeViewText{
  @include medium-font;
  font-size: 1.6rem;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0;
  right: 0;
  height: 100%;
  z-index: 1000;
  background: rgba(55, 55, 55, 0.7);
}

.topPanel {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  justify-content: center;
  flex-basis: 10%;
}

.crossSvg {
  background: black;
  border-radius: 0 0 0 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
}
.closeIcon {
  margin-left: 14px;
  margin-top: 8px;
}

.videoViwerContainer {
  display: flex;
  width: 80vw;
  height: 80vh;
}

.pipIconContainer {
  background: $black;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
}

.modalHeaderContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modalHeader {
  @include bold-font;
  font-size: 2rem;
  color: $bar;
}

.modalSubtext {
  @include medium-font;
  font-size: 1.4rem;
  color: $textSubtle;
}

.modalBody {
  display: flex;
  flex-direction: column;
  padding-top: 28px;
  gap: 8px;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  padding-top: 24px;
}

.errorModalFooter {
  padding-top: 40px;
}

.integrationModalFooter {
  justify-content: flex-start;
  padding-top: 28px;
}

.noPadding {
  padding: 0px;
}

.errorContainer {
  display: flex;
  background-color: $surfaceError;
  border-left: 2px solid $borderCritical;
  padding: 16px;
}

.errorText {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  @include medium-font;
  color: $error50;
  margin-left: 12px;
}

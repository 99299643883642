@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: grid;
  grid-auto-flow: row;
  width: 100%;
}

.viewEmptyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 288px;
  height: 64px;
  color: $textDisabled;
}

.disabledViewEmptyContainer {
  @include medium-font;
  background-color: $surfaceDisabled;
}

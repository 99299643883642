@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.similarityContainer {
  --active-color: none;
  --active-background-color: none;
  display: flex;
  width: 100%;
  &:hover {
    .recheckButton {
      visibility: visible;
    }
  }
}

.similarityPercentage {
  @include demi-bold-font();
  font-size: 1.2rem;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--active-background-color);
  border: 1px solid var(--active-background-color);
  color: var(--active-color);
  width: 52px;
  text-align: center;
  &:hover {
    border: 1px solid var(--active-color);
  }
  margin-right: 12px;
}

.highIndex {
  --active-background-color: #{$decorativeSurfaceFourL};
  --active-color: #{$red52};
}

.mediumIndex {
  --active-background-color: #{$decorativeSurfaceThreeL};
  --active-color: #{$yellow38};
}

.lowIndex {
  --active-background-color: #{$decorativeSurfaceOneL};
  --active-color: #{$blue29};
}

.titleBarIndex {
  color: $white;
  text-decoration: underline;
  cursor: pointer;
}

.checkForSimilarity {
  display: flex;
  color: $textSubtle;
  font-size: 1.4rem;
  @include medium-font();
  cursor: pointer;
  text-decoration: underline;
}

.checkingForSimilarity {
  composes: checkForSimilarity;
  text-decoration: none;
  cursor: default;
}

.checkForSimilarityHeader {
  color: $white;
  text-decoration: none;
}

.checkingForSimilarityHeader {
  composes: checkForSimilarity;
  border-right: none;
  margin-right: 0px;
  color: $white;
  padding-right: 20px;
}

.recheckButton {
  display: flex;
  align-items: center;
  color: $textSubtle;
  font-size: 1.4rem;
  cursor: pointer;
  @include medium-font();
  visibility: hidden;
  padding-left: 12px;
  border-left: 1px solid $borderSubtle;
  margin-left: 12px;
}

.recheckButtonHeader {
  visibility: visible;
  border: none;
  margin: 0px;
  padding: 0px;
}

.similarityDiv {
  display: flex;
  align-items: center;
}

.similarityDivHeader {
  justify-content: space-evenly;
}

.similarityDivInAttachmentItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.percentageDiv {
  composes: similarityDiv;
}

.percentageDivHeader {
  composes: similarityDiv;
  justify-content: space-evenly;
}

.percentageInnerDiv {
  display: flex;
  align-items: center;
}

.percentageInnerDivHeader {
  border-right: none;
  margin-right: 0px;
  padding-right: 20px;
}

.cancelButton {
  composes: recheckButton;
  visibility: visible;
}

.cancelButtonHeader {
  composes: cancelButton;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
}

.cardSimilarityPercentage {
  @include medium-font();
  font-size: 1.4rem;
  background-color: unset;
  text-decoration: underline;
  color: var(--active-color);
  cursor: pointer;
}

.attachmentItemSimilarityPercentage {
  text-decoration: none;
}

.errorDiv {
  display: flex;
  align-items: center;
  @include medium-font();
  color: $red52;
  font-size: 1.4rem;
  cursor: default;
  margin-left: 8px;
}

.noBorderStyle {
  border: none;
  padding: 0px;
  margin: 0px;
}

.similarityDivInColumn {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0px;
}

.errorDivOnHeader {
  color: $white;
}

.errorContainerDiv {
  display: flex;
}

.errorContainerDivInColumn {
  flex-direction: column;
}

.recheckVisible {
  visibility: visible;
}

.warningIcon {
  display: flex;
  align-items: center;
  height: 24px;
}

.attachmentItemFont {
  font-size: 1.2rem;
}

.errorContainer {
  composes: attachmentItemFont;
  display: flex;
  align-items: center;
  column-gap: 4px;
  @include demi-bold-font();
}

.errorDivAttachmentItem {
  margin: 0px;
}

.errorContainerDivAttachmentItem {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.percentageDivAttachmentItem {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.similarityIndex {
  color: $textSubtle;
  text-decoration: underline;
}

.similarityContainerInAttachment {
  display: flex;
  column-gap: 4px;
  cursor: pointer;
}

.errorInfoIcon {
  cursor: pointer;
}

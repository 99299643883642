@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.keyNameContainer {
  background: $surfacePressed;
  border-radius: 4px;
  height: 16px;
  padding: 3px 6px;
  width: fit-content;
  color: $textSubtle;
  @include medium-font();
}

.textBody {
  font-size: 12px;
  color: $textSubtle;
  @include medium-font();
}

.quickSearchIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconColor {
  color: $interactiveThreeDefault;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.folderContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: 48px;
  cursor: pointer;
  padding: 16px 24px 16px 32px;
  &:hover {
    .folderText {
      color: $textDefault;
    }
    background-color: $surfaceHover;
  }
}

.selectedFolderContainer {
  @extend .folderContainer;
  background-color: $surfaceHover;
  .folderText {
    color: $textDefault;
  }
}

.leftContainer {
  display: flex;
  align-items: center;
}

.folderIcon {
  display: flex;
}

.folderText {
  display: flex;
  @include medium-font;
  font-size: 1.4rem;
  color: $textSubtle;
  &:not(:first-child) {
    margin-left: 12px;
    margin-top: 4px;
  }
  @include multiLineEllipsis(1.4rem, 1);
}

.selectedBar {
  position: absolute;
  background-color: $salmon60;
  width: 4px;
  top: 0;
  bottom: 0;
  left: -1px;
}

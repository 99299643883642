@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  position: relative;
}

.disabledContainer {
  composes: container;
  opacity: 0.4;
  border: 1px solid $strokeTwo;
  border-radius: 4px;
  cursor: pointer;
}

.wrapper {
  padding: 24px;
  display: flex;
  align-items: center;
  border: 1px solid $strokeTwo;
  border-radius: 4px;
}

.attachmentIconContainer {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid $strokeOne;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
}

.attachmentInfo {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding: 0 12px;
  flex-direction: column;
}

.attachmentInfo .attachmentName {
  @include medium-font;
  color: $gray13;
  @include multiLineEllipsis(1.2);
}

.attachmentInfo .attachmentType {
  color: $gray31;
  font-size: 1.2rem;
  margin-top: 4px;
}

.downloadButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.useTemplateButton {
  padding: 12px 32px;
  color: $white;
  background: $blue29;
  cursor: pointer;
  margin-left: 20px;
}

.notificationCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: $yellow50;
  position: absolute;
  top: -14px;
  right: -14px;
  z-index: 1;
}

.templateButtonsContainer {
  display: flex;
  margin-right: 8px;
}

.templateActionsContainer {
  display: flex;
}

.templateActionBtn {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

// Common

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  left: 0px;
  top: 0px;
  height: 100vh;
  width: 100vw;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.countDownBox {
  position: absolute;
  padding: 32px 42px;
  color: $white;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.7);
}

.recordingInText {
  font-size: 2rem;
  @include medium-font;
  color: inherit;
}

.countText {
  font-size: 6.4rem;
  @include demi-bold-font;
  text-align: center;
  color: inherit;
}

.secondsText {
  font-size: 2rem;
  @include demi-bold-font;
  text-align: center;
  color: inherit;
}

.modalContent {
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

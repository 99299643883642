@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
}
.optionContainer {
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  flex-wrap: wrap;
  // padding-top: 8px;
  // padding-bottom: 14px;
}

.option {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 16px;
  flex-basis: 50%;
  color: black;
}

.optionWithBorder {
  border: 1px solid $borderSubtle;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    border-color: $borderHover;
  }
  padding: 16px;
}

.viewText {
  display: flex;
  @include medium-font;
  font-size: 1.6rem;
  color: $gray31;
  margin-top: 4px;
  flex-direction: column;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.BFlexDivCol {
  display: flex;
  flex-direction: column;
}

.BFlexDivRow {
  display: flex;
  align-items: center;
}

.container {
  height: 100%;
  overflow-y: auto;
}

.wrapper {
  composes: BFlexDivCol;
  width: 100%;
  padding: 48px 40px;
}

.upperCon {
  composes: BFlexDivRow;
  flex-shrink: 0;
  justify-content: space-between;
  padding-bottom: 28px;
  width: 100%;
  border-bottom: 1px solid $strokeTwo;
}

.previewLabel {
  composes: BFlexDivCol;
  @include demi-bold-font;
  font-size: 1.4rem;
  line-height: 1.43rem;
  color: $violet63;
  margin-bottom: 4px;
}

.leTitle {
  composes: BFlexDivCol;
  @include bold-font;
  font-size: 2rem;
  color: $gray13;
  margin-bottom: 8px;
}

.createdByText {
  composes: BFlexDivCol;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray48;
}

.imageContainer {
  width: 88px;
  height: 78px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 2px;
  background-color: $toddleBG1;
  flex-shrink: 0;
}

.lowerCon {
  composes: BFlexDivCol;
  width: 100%;
  flex: 1;
  flex-shrink: 0;
}

.stepContainer {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 24px;
  width: 100%;
  padding: 24px 0;
  &:not(:last-child) {
    border-bottom: 1px solid $strokeTwo;
  }
}

.stepLabel {
  font-size: 1.4rem;
  @include bold-font;
  color: $gray31;
}

.stepSubText {
  composes: BFlexDivRow;
  margin-top: 4px;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray48;
}

.formContainer {
  composes: BFlexDivRow;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  flex-shrink: 0;
  word-break: break-word;
}

.item {
  composes: BFlexDivCol;
  width: 100%;
  flex-shrink: 0;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.lePreviewHeader {
  display: flex;
  align-items: center;
  margin-left: 32px;
  margin-top: 24px;
}

.innerHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.backIcon {
  display: flex;
  margin-right: 8px;
}

.backText {
  font-size: 1.4rem;
  color: $textSubtle;
  @include medium-font;
  @include multiLineEllipsis(1.9rem, 1);
}

.assessmentToolTypeContainer {
  display: flex;
}

.assessmentToolTypeValue {
  margin-left: 8px;
  @include demi-bold-font;
}

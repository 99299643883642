@import "~UIComponents/CommonStyles/colors";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.title {
  margin: 0;
  line-height: 3.2rem;
  color: $textDefault;
}

.description {
  margin: 0;
  line-height: 2.2rem;
  color: $textSubtle;
}

.adminContainer {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.adminOptionsHeader {
  display: flex;
  justify-content: space-between;
}

.adminOptions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.adminLabel {
  line-height: 2.4rem;
  color: $textDefault;
  margin: 0;
}

.headerContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.sidebarTableContainer {
  display: flex;
  flex: 1;
  height: calc(100% - 72px);
}

.tableActionsContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 16px;
  left: 88px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 999998;
  overflow: hidden;
  background-color: $white;
  width: 320px;
}

.scrollContainer {
  display: flex;
  overflow-y: auto;
  max-height: 240px;
  width: 100%;
  flex-direction: column;
}

.headerContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid $strokeTwo;
  align-items: center;
  cursor: pointer;
}

.headerText {
  display: flex;
  @include demi-bold-font;
  color: $gray13;
  font-size: 1.2rem;
}

.arrowContainer {
  display: flex;
}

.openArrow {
  transform: rotate(0deg);
  transition: transform 0.3s;
}

.closeArrow {
  transform: rotate(-180deg);
  transition: transform 0.3s;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.modalContent {
  width: 600px !important;
  height: 480px !important;
}

.container {
  display: flex;
  width: 100%;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.title {
  width: 100%;
  margin-top: 0px;
  font-size: 2rem;
  color: $gray13;
  @include bold-font;
}

.inputsContainer {
  flex: 1;
  width: 100%;
}

.urlContainer {
  display: flex;
  margin-top: 36px;
}

.titleInputContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 36px;
}

.buttonsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.radioButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.radioContainer {
  margin-right: 44px;
}

.fileLink {
  font-size: 1.2rem;
  @include multiLineEllipsis(1.3rem, 1);
  color: $link29;
  word-break: break-all;
}

.uploadButtonContainer {
  width: 100%;
}

.uploadError {
  padding-top: 8px;
  color: $salmon60;
  @include demi-bold-font;
  font-size: 1.2rem;
}

.errorText {
  display: flex;
  margin-top: 8px;
  color: $salmon60;
  @include demi-bold-font;
  font-size: 1.2rem;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  width: 700px !important;
  height: 570px !important;
}

.editStep {
  display: flex;
  flex-direction: column;
  padding: 32px 40px 30px;
}

.editStep2 {
  display: flex;
  flex-direction: column;
  padding: 0px 40px 0px;
  margin-bottom: 10px;
  overflow: auto;
}

.editStep3 {
  display: flex;
  flex-direction: column;
  padding: 0px 40px 0px;
  margin: 20px 0px;
  overflow: auto;
}

.modalHeader {
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  flex-shrink: 0;
  font-size: 2rem;
  @include demi-bold-font;
}

.modalInnerHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 1.6rem;
  margin-left: 20px;
  @include demi-bold-font;
}

.modalFooter {
  display: flex;
  height: 64px;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 0px 40px;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: $white;
}

.lastButton {
  margin-left: 24px;
}

.horizontalRowInput {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  flex-shrink: 0;
  &:last-child {
    margin-bottom: 72px;
  }
}

.horizontalFrameworkElement {
  display: flex;
  width: 45%;
}

.labelText {
  width: 100%;
  display: flex;
  padding-left: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
  @include medium-font;
  font-size: 1.5rem;
  color: $gray13;
  line-height: 4rem;
}

.horizontalCheckBoxElement {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 1rem;
  cursor: pointer;
}

.renderClass {
  margin: 1rem 2rem 6.5rem;
}

.exitButton {
  width: fit-content;
  font-size: 16px;
  @include demi-bold-font;
  color: $salmon60;
  margin-right: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.backIcon {
  margin-right: 8px;
}

.horizontalUploadElement {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  align-items: flex-start;
}

.modalInnerHeaderUpload {
  display: flex;
  height: 31px;
  width: 50vw;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 5px;
  font-size: 1.6rem;
  @include demi-bold-font;
}

.fileComponent {
  width: 100%;
}

.filterLabelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  &:hover {
    .filterLabelText,
    .filterText {
      color: $black;
    }
  }
}

.filterBox {
  display: flex;
  fill: $gray72;
  @include medium-font();
  justify-content: space-between;
  height: 100%;
  align-items: center;
}
.bottomArrow {
  height: 24px;
  width: 16px;
  text-align: right;
}

.dropDownLabel {
  @include bold-font;
  display: inline-block;
  width: 100%;
}

@media (max-width: 720px) {
  .modalContent {
    width: 95vw !important;
    height: 90vh !important;
  }

  .horizontalRowInput {
    flex-direction: column;
  }

  .horizontalFrameworkElement {
    width: 100%;
  }

  .horizontalUploadElement {
    margin-left: 0px;
  }
}

@media (max-height: 700px) {
  .editStep2 {
    margin-bottom: 100px;
  }
}

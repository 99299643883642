@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";

.mainContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  //margin-bottom:-32px;
}

.label {
  @include medium-font;
  font-size: 1.6rem;
  color: $gray13;
}

.subjectBenchmarkContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

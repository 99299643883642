@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.text {
  display: flex;
  flex-direction: column;
  padding: 4px 0;
  @include regular-font;
  font-size: 1.4rem;
  color: $gray31;

  .boldText {
    @include bold-font;
  }
}

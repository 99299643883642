@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent :global {
  .ms-Viewport {
    height: 65vh;
    overflow-y: scroll;
  }
  width: 70vw !important;
  height: 90vh !important;
  padding: 4px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

$right_pane_width: 400px;
$borderColor: $strokeTwo;
$rightPaneAnimationTime: 0.3s;

@keyframes openRightPane {
  to {
    -webkit-transform: translateX(-$right_pane_width);
  }
}

@keyframes closeRightPane {
  to {
    -webkit-transform: translateX($right_pane_width);
  }
}

.container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, minmax(auto, auto));
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  pointer-events: auto;
}

.rightPane {
  display: flex;
  height: calc(100% - 72px);
  border-left: 1px $borderColor solid;
  position: absolute;
  right: -$right_pane_width;
  top: 72px;
  width: $right_pane_width;
  background-color: $white;
  z-index: 1110;
}

.visible {
  visibility: visible;
  animation: openRightPane $rightPaneAnimationTime;
  animation-fill-mode: forwards;
}

.hidden {
  right: 0;
  animation: closeRightPane $rightPaneAnimationTime;
  animation-fill-mode: forwards;
}

.tab {
  cursor: pointer;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  @include medium-font;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.5;
  color: $gray48;
  padding: 0 16px;
  height: 100%;
  display: flex;
  align-items: center;
  &:hover {
    color: $black;
  }
}

.activeTab {
  display: flex;
  @include demi-bold-font;
  color: $black;
  border-color: $salmon60;
  border-top: 4px solid transparent;
  font-size: 1.6rem;
  padding: 0 16px;
  height: 100%;
  align-items: center;
}

.headerButtonContainer {
  display: flex;
  column-gap: 16px;
}

.insightButtonContainer {
  &:hover {
    .insightIcon {
      fill: $white;
    }
  }
}

.insightText {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 1.6rem;
}

.insightIcon {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  fill: $gray13;
}

.pendingApprovalContainer {
  display: flex;
  column-gap: 8px;
  align-items: center;
}

.projectApprovalCount {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.projectApprovalCountContainer {
  background-color: $salmon60;
  border-radius: 50%;
  color: $white;
  height: 20px;
  width: 20px;
  position: relative;
}

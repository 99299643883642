@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.detailsHeaderText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $textDefault;
  align-self: center;
}

.detailSubHeaderText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $textDefault;
}

.detailContainer {
  display: flex;
  flex-direction: column;

  width: 100%;
  flex-shrink: 0;
}

.detailInnerContainer {
  display: flex;
}

.iconContainer {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.detailDataContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.subDetailContainer {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  padding: 24px 0;
  width: 100%;
  justify-content: space-between;
}

.detailText {
  display: flex;
  @include medium-font;
  font-size: 1.6rem;
  color: $gray31;
  margin-top: 4px;
}

.subDetailLeftContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex: 1;
  margin-right: 8px;
}

.subDetailRightContainer {
  display: flex;
  flex-shrink: 0;
  width: 56px;
  height: 56px;
}

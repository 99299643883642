@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  &:first-child {
    .textAreaContainer {
      padding-top: 0px;
    }
  }
}

.containerBg {
  background-color: rgba($yellow50, 0.1);
  padding: 0 24px;
}

.numberContainer {
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $teal20;
  justify-content: center;
  align-items: center;
  color: white;
  @include regular-font;
  flex-shrink: 0;
  font-size: 1rem;
  margin-right: 16px;
}

.viewNumberCon {
  margin-right: 4px;
  margin-top: 2px;
}

.inputContainer {
  display: flex;
  flex: 1;
  line-height: 2.4rem;
  flex-direction: column;
}

.textAreaContainer {
  display: flex;
  column-gap: 16px;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid $strokeOne;
}

.editTextArea {
  border: 1px solid $yellow50;
  border-radius: 4px;
  padding: 16px;
  background: white;
}

.listItemSvgContainer {
  margin-right: 16px;
  display: flex;
  padding: 24px 0;
}

.moreIcon {
  visibility: hidden;
}

.textAreaContainer:hover > .moreIcon {
  visibility: visible;
  cursor: pointer;
  // display: none;
}

.buttonsCotainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  margin-top: 16px;
}

.cancelButton {
  display: flex;
  color: $gray48;
  &:hover {
    color: $gray31;
  }
  font-size: 1.2rem;
  @include medium-font;
  margin-right: 24px;
  cursor: pointer;
}

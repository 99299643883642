@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.overlayContainer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  cursor: default;
  background-color: rgba(0, 0, 0, 0.7);
}

//Do Not change
.noFullscreenOverlaycontainer {
  z-index: 1100;
}

.modalContent {
  display: flex;
  flex-grow: 0;
  margin: auto;
  background-color: #fff;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  &:focus {
    outline: none;
  }
  width: 680px;
  height: 640px;
  border-radius: 4px;
  overflow: hidden;
}

.fullScreenModalContent {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.alertBanner {
  background-color: $yellow70;
  color: $black;
  font-size: 1.4rem;
  @include medium-font;
  width: 100%;
  text-align: center;
  padding: 6px 0px;
}

.childrenContainer {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.modalChildren {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.modalChildrenResize {
  height: calc(100% - 32px);
}

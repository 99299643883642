@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 44px;
  width: 100%;
}

.rowContainer {
  grid-auto-flow: row;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $white;
}

.header {
  display: flex;
  border-bottom: 1px solid $borderSubtle;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.headerLeftSection {
  display: flex;
}

.clickableHeader {
  cursor: pointer;
}

.searchContainer {
  display: flex;
  width: 600px;
}

.emptyStateContainer {
  display: flex;
  margin-top: 100px;
}

.title {
  display: flex;
  @include bold-font;
  font-size: 2.4rem;
  line-height: 3.278rem;
  margin-bottom: 24px;
  margin-top: 40px;
}

.scrollContainer {
  display: flex;
  overflow-y: auto;
  flex-grow: 1;
  flex-direction: column;
  margin: 16px 0;
}

.gridContainer {
  display: grid;
  grid-template-columns: 600px;
  grid-auto-rows: 80px;
  grid-gap: 16px;
}

.logoContainer {
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 48px;
  fill: $salmon60;
}
.toddleLogo {
  display: flex;
}
.toddleLogoText {
  display: flex;
  margin-left: 20px;
  align-items: center;
}

.loaderContainer {
  display: flex;
  width: 600px;
  justify-content: center;
  align-items: center;
  flex: 1;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container,
.innerContainer {
  width: 100%;
  background-color: $surfaceSubtle;
  scroll-behavior: smooth;
}

.innerContainerPreview {
  
}

.profileContainer {
  display: flex;
  align-items: center;
}

.name {
  @include demi-bold-font;
  color: $textDefault;
  font-size: 1.6rem;
  margin-left: 16px;
}

.response {
  @include medium-font;
  color: $textDefault;
  font-size: 1.6rem;
  margin-top: 16px;
  padding: 0 16px;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.wrapperPreview {
  display: flex;
  padding-left: 16px;
  scroll-behavior: smooth;
  transition: transform 0.5s ease 0s;
}

.evaluationContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 2;
}

.evaluationItemContainer{
  min-width: 300px;
  min-height: 284px;
  padding-right: 16px;
}

.evaluationText {
  @include demi-bold-font;
  color: $textDefault;
  font-size: 1.4rem;
  margin-top: 24px;
}

.evaluationContentContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.evaluationContentContainerPreview {
  width: 100%;
  display: flex;
  height: 100%;
}

.evaluationContent {
  margin-top: 16px;
  display: flex;
  align-items: center;
  background-color: $white;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
}

.evaluationContentPreview {
  flex: 0 0 452px;
  height: 284px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.evaluationIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.evaluationTitle {
  display: flex;
  align-items: center;
  margin-top: 8px;
  @include medium-font;
  font-size: 1.6rem;
  @include multiLineEllipsis(2rem, 1);
}

.attachmentListContainer {
  margin-top: 16px;
}

.modalContent {
  width: 86vw !important;
  height: 90vh !important;
}

.modalHeader {
  display: flex;
  color: $gray13;
  @include bold-font();
  font-size: 2.4rem;
  padding: 24px;
  flex-shrink: 0;
  justify-content: space-between;
}

.modalBody {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  padding: 0px 24px;
  height: 100%;
}

.innerWrapper {
  width: 100%;
}

.actionButtons {
  display: flex;
}

.scrollButtonsContainer {
  position: absolute;
  width:100%;
  height: 318px;
  display: flex;
  justify-content: space-between;
  align-items: center;    
  padding: 0 16px;
}

.leftScrollButton {
  width: 32px;
  height: 32px;  
  border-radius: 50%;
  background-color: $white;
  border:1px solid $borderSubtle;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 2px rgba(0, 46, 57, 0.15);
  z-index: 5;
  margin-right: auto;
}

.responseScrollButton {
  &:hover {
    fill: $gray31;
    background-color: transparent;
  }
}

.rightScrollButton {
  width: 32px;
  height: 32px;  
  border-radius: 50%;
  background-color: $white;
  border:0.5px solid $borderSubtle;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 2px rgba(0, 46, 57, 0.15);
  z-index: 5;
  margin-left: auto;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  padding: 32px;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}

.emptyText {
  display: flex;
  @include regular-font;
  font-size: 1.7rem;
  color: $gray13;
  text-align: center;
  line-height: 2.4rem;
  white-space: normal;
  margin-top: 8px;
  margin-bottom: 32px;
}

.headerText {
  display: flex;
  @include bold-font;
  font-size: 1.8rem;
  color: $gray13;
}

.colorDrop {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  cursor: pointer;
}
.noColor {
  cursor: pointer;
}

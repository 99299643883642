@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
$borderColor: $strokeTwo;

.container {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-shrink: 0;
  padding: 16px 32px;
}
.normalcontainer {
  composes: container;
}

.editCotainer {
  composes: container;
  background-color: rgba($yellow50, 0.1);
}

.headerContainer {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
}

.rightContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 12px;
  flex: 1;
}
.hidden {
  display: none;
  transition: opacity 0.3s;
}

.moreContainer {
  // composes: hidden;
  display: flex;
  margin-left: 12px;
}
.dot {
  display: flex;
  @include regular-font;
  color: $gray72;
  font-size: 1rem;
  padding: 0 4px;
}

.visible {
  display: flex;
  opacity: 1;
  transition: opacity 0.3s;
}

.profileImage {
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-size: contain;
  background-color: $toddleBG1;
  flex-shrink: 0;
}

.headerContentWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.nameText {
  max-width: 200px;
  @include demi-bold-font;
  color: $gray13;
  font-size: 1.4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.underlineOnHover {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.schoolName {
  font-size: 1.2rem;
  @include medium-font;
  color: $gray48;
  height: 22px;
}

.timeOrMoreContainer {
  display: flex;
  width: 100px;
  justify-content: flex-end;
}
.timeText {
  display: flex;
  @include regular-font;
  color: $gray48;
  font-size: 1.2rem;
  align-items: center;
}

.messageText {
  display: flex;
  @include regular-font;
  color: $gray31;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 0;
  border: 0px;
  resize: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  overflow: hidden;
}

.editMessageText {
  padding: 16px;
  border: 1px solid $yellow50;
  border-radius: 4px;
  margin-top: 8px;
}

.buttonsCotainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  margin-top: 16px;
}

.cancelButton {
  display: flex;
  color: $gray48;
  &:hover {
    color: $gray31;
  }
  font-size: 1.2rem;
  @include medium-font;
  margin-right: 24px;
  cursor: pointer;
}

.textAttachmentsCon {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.textContainer {
  margin-top: 4px;
  @include regular-font;
  word-break: break-word;
}

.textContainer b {
  @include bold-font;
}

.commentBoxOuterWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.commentBoxInnerWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $white;
  border: 1px solid $strokeTwo;
  border-radius: 2px;
  z-index: 8;
  max-height: 176px;
  overflow-y: auto;
  margin-top: 12px;
}

.commentAudioBtnWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.audioBtnWrapper {
  display: flex;
  margin: 16px 16px 0 0;
  flex-shrink: 0;
  cursor: pointer;
}

.loadingAnimationContainer {
  display: flex;
  margin-bottom: 24px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}
.loadingAnimation {
  @include dotAnimation($width: 5px, $height: 5px);
}

.replyButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
}

.replyButtonText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.4rem;
  color: $gray48;
  margin-left: 8px;
  &:hover {
    text-decoration: underline;
  }
}

.exceedCharacterCountContainer {
  position: absolute;
  bottom: 12px;
  right: 12px;
  color: $textCritical;
  z-index: 10;
  font-size: 1.4rem;
  @include demi-bold-font;
}

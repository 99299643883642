@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  height: 680px !important;
  max-height: 90vh !important;
  width: 1160px !important;
  @media (max-width: 1200px) {
    width: 90% !important;
  }
}

.modalContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 64px 52px 84px;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.cancelIcon {
  position: absolute;
  top: 30px;
  right: 36px;
  cursor: pointer;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 636px;
  margin-bottom: 40px;
}

.headerText {
  font-size: 3.2rem;
  @include bold-font;
  color: #04363d;
  margin-bottom: 8px;
}

.headerSubText {
  font-size: 1.6rem;
  color: $gray48;
  @include medium-font;
}

.steps {
  display: grid;
  grid-template-columns: repeat(4, 240px);
  align-items: center;
  column-gap: 32px;
  row-gap: 32px;
  margin-bottom: 48px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 240px);
  }
}

.stepItem {
  height: 316px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 4px;
  border: solid 1px $strokeOne;
}

.stepImage {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 104px;
  img {
    width: 40%;
    overflow: hidden;
  }
}

.stepCount {
  font-size: 1.6rem;
  color: $gray13;
  @include bold-font;
  margin-bottom: 8px;
  text-align: center;
}

.step {
  font-size: 1.6rem;
  color: $gray13;
  @include bold-font;
  line-height: 2.8rem;
  @include medium-font;
  text-align: center;
}

.buttonsContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;
}

.buttonGroup {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 16px;
}

.cancelIcon {
  display: flex;
  cursor: pointer;
  position: absolute;
  right: 32px;
  top: 20px;
  fill: $gray48;
  z-index: 1;
  &:hover {
    fill: $gray31;
  }
}

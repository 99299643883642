@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectedValue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
  border-radius: 2px;
  height: 40px;
  border: 1px solid $blue29;
  margin-left: -16px;
}

.selectedText {
  display: flex;
  @include medium-font;
  font-size: 1.4rem;
  color: $blue29;
  flex-shrink: 0;
}

.arrowContainer {
  display: flex;
  align-content: center;
  margin-left: 8px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 44px;
  width: 100%;
}

.rowContainer {
  grid-auto-flow: row;
}

.extraTextContainer {
  display: flex;
  margin-bottom: 16px;
  width: 100%;
  padding: 24px;
  color: $black;
  background-color: rgba($yellow50, 0.1);
  @include medium-font;
  border-radius: 4px;
  font-size: 1.4rem;
  flex-direction: column;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  border: 0.5px solid #cdcdd1;
  border-radius: 4px;
  width: 100%;
  flex-shrink: 0;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $toddleBG1;
  // overflow: hidden;
  @include aspect-ratio(1, 1);
}

.singleCardCon {
  @include aspect-ratio(16, 9);
}

.overlayBackground {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: 0.5s ease;
  background-color: rgba($black, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.clubCardPlusText {
  @include medium-font;
  color: white;
  font-size: 3.2rem;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #080812;
  border-radius: 4px;
  border: 0.5px solid #080812;
}

.container:hover .overlay {
  opacity: 0.4;
  display: block;
}

.bottomDiv {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 10;
  height: 48px;
  display: flex;
  align-items: flex-end;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.title {
  @include multiLineEllipsis(1.6rem, 2);
  margin: 0 0 4px 16px;
  max-width: 80%;
  font-size: 12px;
  @include medium-font;
  color: $white;
}
.typeRow {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  align-items: center;
}
.typeSvgIcon {
  display: flex;
  margin-right: 6px;
}
.pdfSvg {
  width: 13px;
  height: 16px;
}
.fileSvg {
  width: 13px;
  height: 17px;
}

.typeName {
  display: flex;
  font-size: 10px;
  @include demi-bold-font;
  color: #9b9ba5;
}
.svgContainer {
  display: none;
  transition: opacity 0.35s ease;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: row;
}

.container:hover .svgContainer {
  opacity: 1;
  display: flex;
}
.actionSvgIcon {
  display: flex;
  fill: #fff;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.actionButton {
  display: flex;
  border-radius: 50%;
  // border: 1px solid #fff;
  align-self: center;
  fill: #fff;
  margin: 0px 12px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  &:hover {
    background-color: $gray31;
  }
}

.centralButton {
  composes: actionButton;
}

.actionButton:hover .actionSvgIcon {
  fill: $white;
}

.play {
  width: 16px;
  height: 16px;
  border-style: solid;
  border-width: 8px;
  border-color: #202020;
  border-width: 8px 0px 8px 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border-color: transparent transparent transparent $white;
}

.videoSvg {
  margin-left: 12px;
  margin-top: 12px;
}

.centerIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 10;
  display: flex;
}

.uploadingContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  background-color: #080812;
  border-radius: 4px;
  border: 0.5px solid #080812;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.progressBar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.tick-container {
  position: absolute;
  top: 8px;
  right: 8px;
}

.is-selected {
  border: 1px solid $blue29;
}

.notificationCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: $yellow50;
  position: absolute;
  top: -14px;
  right: -14px;
  z-index: 1;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
}

.logoContainer {
  display: flex;
  margin-bottom: 80px;
}

.nextStepContainer {
  @include bold-font;
  font-size: 2.2rem;
  margin-bottom: 8px;
  color: $salmon60;
}

.headerText {
  display: flex;
  color: $gray13;
  @include bold-font;
  font-size: 2.4rem;
  margin-bottom: 16px;
}

.detailText {
  display: flex;
  color: $gray31;
  @include medium-font;
  font-size: 1.6rem;
  margin-bottom: 48px;
  line-height: 1.6;
  flex-direction: column;
}

.bottomContainer {
  align-items: center;
  width: 100%;
}

.detailBoldText {
  @include bold-font;
  font-size: 1.6rem;
  color: $black;
}

.calenderIcon {
  margin-right: 12px;
  display: flex;
  fill: $white;
}

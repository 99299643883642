@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  border: 1px solid $strokeTwo;
  width: 100%;
  height: 224px;
  border-radius: 4px;
  position: relative;
  &:hover {
    border: 1px solid $gray72;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  cursor: pointer;
}

.containerSelected {
  border: 1px solid $blue29;
  &:hover {
    border: 1px solid $blue29;
  }
}

.containerDisable {
  opacity: 0.5;
  pointer-events: none;
}

.tickIcon {
  position: absolute;
  top: 8px;
  right: 8px;
}

.imageContainer {
  display: flex;
  height: 144px;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.textContainer {
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
}

.titleContainer {
  @include bold-font;
  font-size: 16px;
  color: $gray13;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitleContainer {
  color: $gray48;
  @include medium-font;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.planDetailTextContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.planDetailText {
  font-size: 1.2rem;
  @include medium-font;
  color: $teal20;
  &:after {
  }
}

.dot {
  display: flex;
  margin: 0px 6px;
  width: 4px;
  height: 4px;
  border: 1px solid #18605a;
  border-radius: 50%;
  background-color: #18605a;
  flex-shrink: 0;
}

.viewOverlay {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: 8px 8px 0 0;
  .viewIcon {
    margin-right: 12px;
    height: min-content;
  }
  .viewIcon,
  .tickOffIcon {
    visibility: hidden;
  }
}

.container:hover {
  .viewOverlay {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    .viewIcon,
    .tickOffIcon {
      visibility: visible;
    }
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 48px;
  overflow-y: scroll;
  // padding: 48px 136px;
}

.header {
  display: grid;
  justify-self: right;
  font-size: 2.4rem;
  @include bold-font;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
}

.searchContainer {
  width: 324px;
  margin-bottom: 24px;
}

.gridHeaderContainer {
  margin-top: 28px;
  display: grid;
  grid-template-columns: 25% 20% 25% 25% 5%;
  // grid-column-gap: 16px;
  flex-shrink: 0;
  padding: 16px 0px 16px 0px;
  border-top: 1px solid $strokeOne;
  border-bottom: 1px solid $strokeOne;
}

.gridHeaderLabel {
  display: flex;
  @include bold-font;
  font-size: 1.4rem;
  line-height: 1.9;
  color: $gray13;
  padding-right: 24px;
}

.gridHeaderLabel :last-of-type {
  padding-right: 0px;
}

.gridContainer {
  display: grid;
  grid-template-columns: 25% 20% 25% 25% 5%;
  // grid-column-gap: 16px;
  flex-shrink: 0;
  //   padding: 16px 0px 16px 0px;
}

.girdItemContainer {
  display: flex;
  padding: 16px 0px 16px 0px;
  border-bottom: 1px solid $strokeOne;
  align-items: center;
  min-height: 70px;
}

.modalContent {
  width: 640px !important;
  height: 640px !important;
}

.modalBody {
  width: 100%;
  flex: 1;
  background-color: white;
  margin-bottom: 72px;
  overflow: hidden;
}

.loadingContainer {
  width: 100%;
  margin-top: 24px;
}

.loadingContainerFull {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  z-index: 1200;
  background: rgba(0, 0, 0, 0.5);
}

.linkColumn {
  display: flex;
  word-break: break-all;
  cursor: pointer;
  color: $blue29;
}

.backContainer {
  display: flex;
  cursor: pointer;
  padding-right: 16px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.modalContent {
  min-width: 680px !important;
  max-height: 640px !important;
  height: 90vh !important;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 48px 40px 40px;
}

.headerText {
  display: flex;
  width: 100%;
  @include bold-font;
  color: $gray13;
  font-size: 2.4rem;
  margin-bottom: 8px;
}

.headerSubtext {
  display: flex;
  width: 100%;
  font-size: 1.6rem;
  @include medium-font;
  color: $gray48;
  line-height: 1.5;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fileCountContainer {
  display: flex;
  border-bottom: 1px solid $strokeOne;
  padding-bottom: 16px;
  margin: 0px 40px;
}

.scrollContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 0px 40px;
}

.fileContainer {
  display: flex;
  border-bottom: 1px solid $strokeOne;
  padding: 24px 0px;
  justify-content: space-between;
  &:last-child {
    border-bottom: none;
  }
}

.leftFileContainer {
  display: flex;
  align-items: center;
  flex: 1;
}

.rightFileContainer {
  display: flex;
  align-items: center;
  // width: max-content;
}

.fileDetails {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  flex: 1;
  max-width: 280px;
}

.fileIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid $strokeTwo;
  min-width: 40px;
  min-height: 40px;
}

.fileName {
  @include demi-bold-font;
  font-size: 1.4rem;
  color: $gray31;
  line-height: 1.43;
  margin-bottom: 4px;
  padding-right: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  @include multiLineEllipsis(1.4rem, 1);
}

.fileType {
  @include medium-font;
  font-size: 1.2rem;
  color: $gray48;
}

.fileCountText {
  @include demi-bold-font;
  font-size: 1.8rem;
  color: $gray13;
}

.dropdownContainer {
  display: flex;
  width: 100%;
}

.dropdown :global {
  .Select--single > .Select-control {
    height: 40px;
  }

  .Select--single .Select-input {
    display: none !important;
  }

  .has-value.Select--single
    > .Select-control
    .Select-value
    .Select-value-label {
    @include medium-font;
    color: $gray31;
    font-size: 1.4rem;
  }
}

.cancelButton {
  cursor: pointer;
  fill: $gray72;
  display: flex;
  align-items: center;
  width: 30px;
  height: 40px;
  justify-content: flex-end;
}

.footerContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 72px;
  padding: 0 40px;
  border-top: 1px solid $strokeTwo;
  background-color: $white;
}

.rightButtonContainer {
  display: grid;
  justify-content: flex-end;
  grid-auto-flow: column;
  column-gap: 16px;
  width: 100%;
}

.filterBox {
  display: flex;
  fill: $gray72;
  @include medium-font();
  align-items: center;
  border: 1px solid $strokeOne;
  height: 40px;
  padding: 12px;
  justify-content: center;
  border-radius: 4px;
}

.filterText {
  display: flex;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray31;
  @include multiLineEllipsis(1.4rem, 1);
  // margin-right: 8px;
  text-align: left;
  flex: 1;
}

.filterLabelText {
  display: flex;
  @include medium-font;
  color: $gray31;
  margin-right: 8px;
  font-size: 1.4rem;
}

.bottomArrow {
  height: 24px;
  width: 16px;
  text-align: right;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  width: 100%;
  outline: none;
  padding: 4px 0;
  cursor: text;
  min-height: 24px;
}

.whiteSpace {
  white-space: pre-wrap;
}

[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  // position: absolute;
  color: $gray72;
  background-color: transparent;
}

.inputText {
  display: flex;
  @include medium-font;
  font-size: 1.6rem;
  color: $gray31;
  flex-direction: column;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.error {
  border-bottom: 1px solid $salmon60 !important;
}

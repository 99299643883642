@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  width: 100%;
  min-height: 72px;
  background-color: $white;
  position: relative;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $strokeOne;
}

.leftContainer {
  display: flex;
  position: absolute;
  flex-direction: row;
  padding: 0 24px;
  left: 0;
}

.buttonsContainer {
  display: flex;
  grid-column-gap: 16px;
  position: absolute;
  flex-direction: row;
  padding: 0 40px;
  right: 0;
  grid-auto-flow: column;
  align-items: center;
}

.editButton {
  display: flex;
  // justify-content: "space-between";
  align-items: center;
}
.lockIcon {
  display: flex;
  margin-left: 4px;
  margin-top: -4px;
}

.editText {
  display: flex;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-right: 16px;
}

.chevronIconContainer {
  display: flex;
  margin-right: 8px;
}

.textAndAttributionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.textContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rightContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleText {
  display: flex;
  font-size: 1.6rem;
  color: $gray31;
  @include demi-bold-font;
}

.highlightText {
  cursor: pointer;
  &:hover {
    color: $blue29;
  }
}

.slashIcon {
  display: flex;
  padding: 0 4px;
  font-size: 1.4rem;
  color: $gray31;
}

.subTitleText {
  display: flex;
  font-size: 1.6rem;
  color: $gray31;
  @include demi-bold-font;
  @include multiLineEllipsis(1.8rem, 1, 24);
}

.descriptionText {
  display: flex;
  font-size: 1.4rem;
  color: $gray48;
  @include medium-font;
  @include multiLineEllipsis(1.8rem, 1, 40);
}

.filterBox {
  display: flex;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid $strokeTwo;
  padding: 0px 16px;
  font-size: 1.4rem;

  fill: $gray48;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  width: max-content;
}

.filterText {
  display: flex;
  margin-right: 8px;
  color: $blue29;
  @include demi-bold-font();
}

.gradeNames {
  display: flex;
  @include medium-font();
  color: $gray13;
  margin-left: 4px;
}

.bottomArrow {
  height: 24px;
  width: 16px;
  text-align: right;
}

.checkBoxContainer {
  display: flex;
  align-self: center;
}

.buttonContainer {
  display: grid;
  align-items: center;
  grid-column-gap: 8px;
  grid-auto-flow: column;
}

.dialogBoxContent {
  min-height: 400px !important;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
//@import "@toddle-design/theme/dist/assets/fontStyles.scss";

.evidenceFeedCount {
  @include demi-bold-font;

  display: flex;
  width: 100%;
  margin-bottom: 12px;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: $gray31;
}

.filterCountText {
  text-decoration: underline;
  margin-left: 8px;
  cursor: pointer;
}

.showMoreText {
  margin-top: 8px;
}

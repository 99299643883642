@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-direction: column;
}

.leList {
  margin-top: 8px;
}

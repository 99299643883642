@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
$borderColor: $strokeOne;

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 40px 48px;
  overflow-y: auto;
  flex-shrink: 0;
}
.tableContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
  border: 1px solid $borderColor;
  border-bottom-width: 0px;
}
.countText {
  display: flex;
  @include bold-font;
  color: $gray13;
  font-size: 1.6rem;
  margin-bottom: 24px;
  flex-shrink: 0;
}

.addButton {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  cursor: pointer;
  width: max-content;
  flex-shrink: 0;
  align-items: center;
}

.addButtonText {
  display: flex;
  @include demi-bold-font;
  color: $blue29;
  font-size: 1.6rem;
  margin-left: 8px;
}

.emptyState {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 64px;
}

.emptyText {
  @include regular-font;
  display: flex;
  color: $gray48;
  font-size: 1.6rem;
  margin-top: 48px;
  text-align: center;
  justify-content: center;
  width: 100%;
}

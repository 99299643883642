@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
  padding: 40px 64px;
}
.deleteStandardLabel {
  @include bold-font;
}
.innerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1040px;

  margin: 0 auto;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
}

.header {
  display: flex;
  margin: 32px 0;
  justify-content: space-between;
  align-items: center;
}
.leftHeader {
  display: flex;
  width: 320px;
}
.filterBox {
  display: flex;
  fill: $gray72;
  @include medium-font();
  justify-content: space-between;
  height: 100%;
  align-items: center;
  max-width: 220px;
}
.filterLabelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 10px 0px 20px;

  &:hover {
    .filterLabelText,
    .filterText {
      color: $black;
    }
    .filterBox {
      fill: $gray31;
    }
  }
}
.dropDownLabel {
  @include bold-font;
}

.filterText {
  display: flex;
  @include bold-font;
  font-size: 1.4rem;
  color: $gray31;
  margin-right: 30px;
  text-align: right;
}

.bottomArrow {
  height: 24px;
  width: 16px;
  text-align: right;
}
.rightHeader {
  font-size: 1.4rem;
  padding: 12px 0px 12px 0px;
  @include medium-font();
}
.standardCountContainer {
  padding: 0px 0px 24px 0px;
  @include bold-font;
}
.loadingContainer {
  width: 100%;
  margin-top: 24px;
}

.option {
  display: flex;
  width: 100%;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  width: 100%;
  padding: 0;
}

.searchContainer {
  display: flex;
  width: 100%;
  padding: 24px 0px 40px 0px;
}

.footerButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.footerText {
  display: flex;
  font-size: 1.6rem;
  color: $gray48;
}

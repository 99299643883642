@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UnitPlans/components/LibraryCommonComponents/common.scss";
@import "~UIComponents/CommonStyles/mixins";
@import "../../../common.scss";

.container {
  display: flex;
  background: #f9f9fb;
  padding: 72px 0;
  position: relative;
  z-index: 2;
  width: 100%;
  background: #f9f9fb;
  margin-top: 80px;
}
.innerContainer {
  width: 100%;
  height: max-content;
  margin: 0 auto;
  max-width: 1304px;
  padding: 0 32px;
  flex-direction: row;
  composes: assessmentGridLayout;
}

.leftContainer {
  font-size: 2.8rem;
  @include bold-font;
  color: $gray48;
}

.userInfoCard {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
}

.name {
  font-size: 1.8rem;
  @include demi-bold-font;
  color: $gray13;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.schoolName {
  font-size: 1.6rem;
  @include medium-font;
  color: $gray48;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~Evidence/common.scss";

.modalContent {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.headerText {
  @include demi-bold-font;
  font-size: 2rem;
  margin-top: 32px;
}

.text {
  composes: headerText;
  //   @include demi-bold-font;
  font-size: 1.8rem;
  margin-top: 4px;
}

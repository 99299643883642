@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.headerContainer {
  display: flex;
  flex-direction: column;
}

.heading {
  @include bold-font;
  font-size: 2.4rem;
  color: $gray13;
}

.headerSubText {
  @include medium-font;
  font-size: 1.6rem;
  color: $gray48;
}
.footer {
  display: flex;
  align-items: center;

  justify-content: space-between;
}

.addMoreContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.addIconContainer {
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid $blue29;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}

.addMoreText {
  display: flex;
  font-size: 1.6rem;
  color: $blue29;
  @include demi-bold-font;
}

.dropzoneContainer {
  display: flex;
}

.imagesContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 156px);
  grid-auto-flow: row;
  grid-gap: 24px;
  width: 100%;
  flex-shrink: 0;
  margin-top: 56px;
}

.cancelIcon {
  margin-right: 16px;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
  cursor: pointer;
}

.itemContainer {
  display: flex;
  width: 156px;
  height: 156px;
  border: 1px solid $strokeTwo;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  cursor: grab;
  position: relative;
  &:hover {
    .overlayContainer {
      visibility: visible;
    }
  }
}

.overlayContainer {
  display: flex;
  visibility: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background-color: rgba($bar, 0.4);
  align-items: center;
  justify-content: center;
}

.itemCloseIcon {
  display: flex;
  position: absolute;
  right: 8px;
  top: 8px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: rgba($bar, 0.9);
  fill: $white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sortableHelper {
  z-index: 10000 !important;
  cursor: grab;
}

.dragIconContainer {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba($white, 0.8);
  align-items: center;
  justify-content: center;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.resourceFeedContainerClass {
  display: flex;
  flex-direction: column;
}

.resourceFeedItemDiv {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
}
.error {
  display: flex;
  flex-grow: 0;
  font-size: 1.3rem;
  color: $error50;
  margin-top: 6px;
  height: 16px;
  margin-left: 40px;
}

.emptyText {
  display: flex;
  @include regular-font;
  color: $gray48;
  font-size: 1.2rem;
}

.chevron {
  & svg {
    transform: rotate(180deg);
  }
}

.noDataContainer {
  display: flex;
  flex: 1;
  color: $gray31;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 64px;
}

.createText {
  margin-top: 40px;
  display: flex;
  @include bold-font;
  font-size: 2.1rem;
  color: $gray13;
}

.createSubText {
  margin: 16px 0 24px 0;
  display: flex;
  @include regular-font;
  font-size: 1.6rem;
  color: $gray48;
  text-align: center;
  flex-shrink: 0;
  line-height: 2.8rem;
}

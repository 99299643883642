@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.topContainer {
  display: flex;
}

.headerText {
  display: flex;
  @include bold-font();
  font-size: 1.4rem;
  color: $black;
  margin-top: 48px;
  margin-left: 40px;
  margin-bottom: 20px;
}

.ironHeaderText {
  composes: headerText;
  color: $gray48;
  margin-bottom: 8px;
}

.infoText {
  display: flex;
  margin: 0 40px;
  font-size: 1.4rem;
  line-height: 1.18;
  color: $gray48;
  @include regular-font;
}

.folderContainer {
  display: flex;
  position: relative;
  padding: 16px 0;
  width: 100%;
  height: 56px;
  cursor: pointer;
}

.selectedFolderContainer {
  @extend .folderContainer;
  background-color: $white;
}

.leftContainer {
  display: flex;
  align-items: center;
  margin-left: 48px;
}

.folderIcon {
  display: flex;
}

.folderText {
  display: flex;
  margin-left: 8px;
  margin-top: 4px;
  @include medium-font;
  font-size: 1.4rem;
  color: $black;
}

.selectedBar {
  position: absolute;
  background-color: $blue29;
  width: 4px;
  top: 0;
  bottom: 0;
  right: 0;
}

@import "~UIComponents/CommonStyles/colors";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.moreButton {
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: flex-end;
  align-items: center;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;

  @media (min-width: 1550px) {
    margin-top: 76px !important;
  }
}

.innerContainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 24px;
}

.inputContainer {
  display: flex;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.bottomContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.nestedFilterContainer {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  width: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  padding: 24px;
}

.nestedFilterLabelWithOptionContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $strokeOne;

  &:first-child .nestedFilterPrimaryOptionLabelContainer {
    padding-top: 0;
  }
}

.nestedFilterLabelContainer {
  display: flex;
  padding: 16px 0;

  justify-content: space-between;
  align-items: center;
}

.nestedFilterLabelContainerWithOption {
  padding-bottom: 10px;
}

.nestedFilterLabel {
  display: flex;
  @include bold-font;
  margin-right: auto;
  font-size: 1.4rem;
  line-height: 2rem;
  color: $gray13;
}

.selectedOptionsCount {
  display: flex;
  margin-right: 12px;
  font-size: 1.4rem;
  line-height: 2rem;
  @include bold-font;
  color: $blue29;
}

.selectedCount {
  display: flex;
  margin-left: 8px;
  @include demi-bold-font;
}

.label {
  display: flex;
  @include medium-font;
  white-space: pre;
  font-size: 1.4rem;
  line-height: 2rem;
  padding-left: 16px;
  color: $gray48;
}

.matchedPart {
  display: flex;
  @include demi-bold-font;
  color: $gray13;
}

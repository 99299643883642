@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.headerContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.headerText {
  font-size: 1.6rem;
  @include demi-bold-font;
  color: $textDefault;
  margin-left: 8px;
}

.assessmentDetailText {
  font-size: 1.4rem;
  @include medium-font;
  color: $textDefault;
  margin-left: 28px;
}

.assessmentToolContainer {
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 8px;
}

.assessmentToolText {
  font-size: 1.6rem;
  @include demi-bold-font;
  color: $textDefault;
  margin-left: 8px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  //   background-color: $toddleBG1;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
}

.notificationsContainer {
  padding-bottom: 0px;
}

.emptyState {
  font-size: 1.4rem;
  @include medium-font;
  display: flex;
  justify-content: center;
  color: $gray72;
  padding: 32px 0;
  flex: 1;
  align-items: center;
}

.loadingContainer {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

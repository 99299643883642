.dropZone {
  cursor: pointer;
  text-align: center;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  input {
    left: -24px !important;
    max-width: none;
  }
}
.renderCompChild {
  all: inherit;
}

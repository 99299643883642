@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
}
.body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  //width:280px;
  margin: 8px 64px 8px 0;
  align-items: center;
}
.childContainer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 400;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
}
.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //width:280px;
  margin: 8px 64px 8px 0;
  align-items: center;
  cursor: pointer;
}
.componentIcon {
  display: flex;
  background-position: center;
  background-size: cover;
  height: 80px;
  width: 80px;
  margin: 12px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  // position: absolute;
  // bottom: 0;
  width: 100%;
  height: 72px;
  align-items: center;
  box-shadow: -2px 0 8px 0 rgba($black, 0.1);
  background-color: $white;
  //z-index: 10;
}

.footerInnerContainer {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  padding: 0 40px;
  width: 100%;
  margin: 0 auto;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: grid;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  padding: 0 40px;
}

.scrollContainer {
  display: grid;
  height: max-content;

  width: 100%;
  grid-row-gap: 40px;
  align-content: flex-start;
  padding: 40px 0px;
}

.header {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  align-items: center;
  justify-content: space-between;
}

.heading {
  @include bold-font;
  font-size: 2.4rem;
  color: $gray31;
}
.cancelIcon {
  display: grid;
  cursor: pointer;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.list {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
}

.item {
  display: grid;
  height: 76px;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $strokeOne;
  border-left: 8px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  padding: 0 24px;
  &:hover {
    border: 1px solid $gray72;
    border-left: 8px solid transparent;
  }
  &:hover .icon {
    background-color: $gray48;
    border: 1px solid $gray48;
    svg {
      fill: $white;
    }
  }
}

.itemTitle {
  @include bold-font;
  font-size: 1.6rem;
  color: $gray13;
}

.icon {
  display: grid;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-content: center;
  border-radius: 50%;
  border: 1px solid $strokeOne;
  fill: $gray72;
  svg {
    fill: $gray48;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  flex-shrink: 0;
}

.resourceTitle {
  width: 100%;
  font-size: 1.6rem;
  @include demi-bold-font;
  color: $gray31;
  word-break: break-word;
  @include multiLineEllipsis(1.6rem, 2);
}

.resourceCentralIdea {
  width: 100%;
  height: 20px;
  font-size: 1.4rem;
  @include regular-font;
  color: $gray48;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resourceResourceCount {
  width: 100%;
  height: 16px;
  font-size: 1.2rem;
  @include medium-font;
  color: $teal20;
}

.noDataText {
  display: flex;
  text-align: center;
  width: 100%;
  font-size: 1.4rem;
  color: $gray48;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.listContent {
  display: grid;
  grid-template-columns: 100%;
  row-gap: 16px;
  width: 100%;
  flex-direction: column;
  padding: 16px 0;
}

.unitContainer {
  width: 100%;
  display: flex;
  border: 1px solid $strokeOne;
  border-radius: 4px;
  padding: 12px 16px;
  flex-direction: row;
  cursor: pointer;
  height: max-content;
  position: relative;
  justify-content: space-between;
  align-items: center;
  &:hover {
    border: 1px solid $strokeTwo;
  }
}

.unitContainerSelected {
  border: 1px solid $blue29 !important;
}

.tickIconContainer {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
}

.unitContentContainer {
  display: flex;
  width: 77%;
  flex-direction: column;
  padding-left: 12px;
  justify-content: space-between;
}

.emptyTick {
  width: 20px;
  height: 20px;
  border-radius: 12px;
  border: 1px solid $gray31;
}

.orContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.orText {
  width: "auto";
  //   height: 16px;
  font-size: 1.6rem;
  @include demi-bold-font;
  color: $blue40;
}

.orContentLine {
  display: flex;
  width: 40%;
  height: 1px;
  background-color: $strokeOne;
}

.goalsSvgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 64px;
  background-color: $pink30;
  border-radius: 4px;
}

.unitTitle {
  width: 80%;
  font-size: 1.6rem;
  @include demi-bold-font;
  color: $gray13;
}

.selectUnitTitle {
  font-size: 1.6rem;
  @include bold-font;
  color: $gray13;
  margin-bottom: 4px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.countDownContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 40px;
  .message {
    color: $bar;
    font-size: 2.4rem;
    @include medium-font;
  }
  .counter {
    color: $salmon60;
    font-size: 6.4rem;
    @include regular-font;
  }
}

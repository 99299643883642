@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.bodyClass {
  overflow: hidden;
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
}

.questionComp {
  //margin-bottom:24px;
}

.error {
  display: flex;
  flex-grow: 0;
  font-size: 1.3rem;
  color: $error50;
  margin-top: 6px;
  height: 16px;
}

.modalContent {
  width: 540px !important;
  height: 660px !important;
}

.extraTextContainer {
  display: flex;
  margin-bottom: 16px;
  width: 100%;
  padding: 24px;
  color: $black;
  background-color: rgba($yellow50, 0.1);
  @include medium-font;
  border-radius: 4px;
  font-size: 1.4rem;
  flex-direction: column;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
}

$paddingHorizontal: 24px;
.container {
  display: flex;
  //   height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 0 $paddingHorizontal;
}

.container:not(:first-of-type) {
  margin-top: 24px;
}

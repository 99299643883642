@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;

  .leftFooter {
    display: flex;
    align-items: center;

    .profile {
      display: flex;
      align-items: center;

      .nameContainer {
        display: flex;
        flex-direction: column;
        padding-left: 12px;

        .assignedBy {
          @include demi-bold-font();
          font-size: 1.4rem;
        }

        .name {
          @include regular-font();
          font-size: 1.4rem;
        }
      }
    }
  }

  .rightFooter {
    display: flex;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  padding: 140px 80px 300px;
  width: 100%;
  position: relative;
  background-color: $toddleBG2;
  margin-bottom: 140px;
}

.strips {
  margin-top: 24px;
}
.mobileButton {
  display: none;
}

.detailsContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.detailsLeftContainer {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  margin-right: 76px;
  //flex-shrink: 0;
}
.detailsRightContainer {
  display: flex;
  flex: 1;
  height: 100%;
  min-width: 50%;
}
.video {
  width: 100%;
}

.title {
  display: flex;
  @include bold-font;
  font-size: 4.4rem;

  color: $salmon60;
}

.subtitleContainer {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 16px;
  margin-top: 4px;
}

.dash {
  width: 12px;
  height: 1px;
  background-color: $gray48;
}
.subtitle {
  @include demi-bold-font;
  font-size: 1.8rem;
  line-height: 1.56;
  letter-spacing: 1px;
  color: $gray13;
}

.description {
  display: flex;
  @include medium-font;
  font-size: 2rem;
  margin-top: 32px;
  color: $gray31;
  flex-direction: column;
  line-height: 2;
  b {
    @include bold-font;
  }
}

.duration {
  display: flex;
  @include bold-font;
  font-size: 2.4rem;
  margin-top: 24px;
  color: $gray31;
  line-height: 1.5;
}

.button {
  margin-top: 32px;
  width: 278px;
}

.heroImageWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: -112px;
  flex-direction: column;
  left: 0;
}

.heroDesktopImage {
  display: flex;
  width: 1024px;
  margin: 0 auto;
}

.heroMobileImage {
  display: none;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .heroDesktopImage {
    width: calc(100% - 64px);
  }
  .container {
    padding: 112px 64px 172px;
  }

  .detailsContainer {
    flex-direction: column;
  }

  .detailsLeftContainer {
    max-width: unset;
    align-items: center;
    margin-right: unset;
  }

  .description {
    text-align: center;
  }

  .detailsRightContainer {
    height: 420px;
    flex: unset;
    margin-top: 40px;
    width: calc(100% + 128px);
    margin-left: -64px;
  }

  .title {
    font-size: 3.6rem;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .heroImageWrapper {
    bottom: -48px;
  }
  .mobileButton {
    display: block;
    width: calc(100% - 32px);
    margin: 0 auto;
    margin-top: 40px;
  }
  .container {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 224px;
    margin-bottom: 64px;
  }
  .detailsRightContainer {
    height: 240px;
    flex: unset;
    margin-top: 40px;
    width: calc(100% + 64px);
    margin-left: -32px;
  }
  .title {
    font-size: 2.8rem;
  }

  .subtitleContainer {
    grid-column-gap: 8px;
  }
  .subtitle {
    font-size: 1.4rem;
  }
  .dash {
    width: 6px;
  }
  .description {
    font-size: 1.6rem;
    margin-top: 24px;
  }
  .duration {
    font-size: 2rem;
  }
  .button {
    margin-top: 32px;
    width: 100%;
  }
  .heroDesktopImage {
    display: none;
    width: 1024px;
    margin: 0 auto;
  }

  .heroMobileImage {
    display: flex;
    width: calc(100% - 32px);
    max-width: 540px;
  }
}

@media (max-width: 767px) and (min-width: 460px) {
  .container {
    margin-bottom: 200px;
  }
  .heroImageWrapper {
    bottom: -140px;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.treeContainer {
  display: grid;
  margin: 0;
  padding: 0;
}

.nodeBody {
  // display: grid;
  margin: 0;
  padding: 0;
}

.spanStyle {
  flex: 1;
}

.h0TreeContainer {
  composes: treeContainer;
  grid-row-gap: 32px;
}

.h0RCTreeContainer {
  composes: treeContainer;
  grid-row-gap: 8px;
}

.h1TreeContainer {
  composes: treeContainer;
  grid-row-gap: 24px;
  margin-top: 16px;
}

.h1RCTreeContainer {
  composes: treeContainer;
  grid-row-gap: 4px;
}

.h2TreeContainer {
  composes: treeContainer;
  grid-row-gap: 16px;
  margin-top: 16px;
}

.h2RCTreeContainer {
  composes: treeContainer;
  grid-row-gap: 4px;
  margin-top: 8px;
}

.h3TreeContainer {
  composes: treeContainer;
  grid-row-gap: 8px;
  margin-top: 8px;
  padding-left: 16px;
}

.nodeLabel {
  display: flex;
  word-break: break-word;
}

.h0Label {
  composes: nodeLabel;
  font-size: 1.6rem;
  @include bold-font;
  color: $teal20;
}

.h1Label {
  composes: nodeLabel;
  font-size: 1.4rem;
  @include demi-bold-font;
  color: $gray31;
}

.h1RCLabel {
  composes: h1Label;
  color: $blue29;
  margin-top: 4px;
}

.h2Label {
  composes: nodeLabel;
  font-size: 1.4rem;
  @include demi-bold-font;
  color: $gray31;
}

.h2RCLabel {
  composes: h2Label;
  @include regular-font;
  color: $gray31;
}

.h3Label {
  composes: nodeLabel;
  font-size: 1.4rem;
  color: $gray31;
}

.bullet {
  display: flex;
  margin-right: 8px;
}

.bulletNoMargin {
  display: flex;
}

.codeLabel {
  @include italic-font;
}

.tagsContainer {
  display: inline-flex;
  vertical-align: top;
  margin-left: 4px;
  flex-wrap: wrap;
  gap: 4px;
}

.tagContainer {
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  height: 20px;
  background-color: $surfaceSubtleHover;
  color: $textSubtle;
  font-size: 10px;
  padding: 1px 8px 1px 1px;
  margin-right: 4px;
  @include regular-font;
  flex-shrink: 0;
}

.tagImage {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 2px;
}

.paddingLeft {
  padding-left: 8px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
}

.feedContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  align-items: center;
}

.noDataContainer {
  display: flex;
  flex: 1;
  min-height: 0;
  color: $gray31;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 64px;
}

.createText {
  margin-top: 40px;
  display: flex;
  @include bold-font;
  font-size: 2.1rem;
  color: $gray13;
}

.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.titleText {
  @include bold-font;
  font-size: 1.8rem;
  color: $blue29;
  margin-right: 4px;
}

.countText {
  font-size: 1.8rem;
  @include medium-font;
  color: $blue29;
}

.loadingContainer {
  width: 100%;
  margin: 24px 0;
}

.searchFilterContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 28px;
}

.filtersContainer {
  display: flex;
}

.searchInputContainer {
  width: 100%;
  max-width: 664px;
}

.alertContainer {
  width: 100%;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  width: 84vw !important;
  height: 90vh !important;
  max-width: 1240px;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mainContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
}

.footer {
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 8px 40px;
  align-items: center;
  border-top: 1px solid $strokeTwo;
}

.leftPane {
  display: flex;
  width: 760px;
  height: 100%;
  flex-direction: column;
}

.rightPane {
  display: flex;
  width: 480px;
  height: 100%;
  background-color: $toddleBG1;
}

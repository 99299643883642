@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
$border-radius: 4px;

.tabcontainer {
  display: grid;
  //flex-direction: row;
  // justify-content: space-between;
  // height: 48px;
  border-radius: $border-radius;
  overflow: hidden;
  border: 1px solid transparent;
  // grid-auto-flow: column;
  grid-template-columns: repeat(2, auto auto);
  grid-row-gap: 2px;
}

.rectangularOvalTabCon {
  border: 1px solid $strokeOne;
  border-radius: 12px;
  padding: 4px;
}

.tab {
  display: flex;
  cursor: pointer;
  background-color: $strokeOne;
  align-items: center;
  justify-content: center;
  padding: 10px;
  &:not(:last-child) {
    border-right: 1px solid transparent;
  }
}

.rectangularOvalTab {
  background-color: $white;
  min-width: 156px;
  border: none;
  border-radius: 8px;
  &:hover {
    background-color: $toddleBG1;
  }
}

.tabSelected {
  display: flex;
  background-color: $blue29;
}

.rectangularOvalTabSelected {
  border-radius: 8px;
  background-color: $blue29;
  border-color: $blue29;
  &:hover {
    background-color: lighten($blue29, 2%);
  }
}

.numberTextWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  border-right: 1px solid $strokeOne;
  padding: 0 16px;
}

.recOvalNumberTextWrapper {
  border-color: transparent;
}

.tabText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.4rem;
  color: $black;
  text-align: center;
}

.rectangularOvalTabText {
  color: $gray48;
}

.tabSelectedText {
  color: $white;
}

.rectangularOvalTabSelectedText {
  color: $white;
}

.numberText {
  composes: tabText;
  @include demi-bold-font;
  margin-left: 4px;
}

.error {
  display: flex;
  flex-grow: 0;
  font-size: 1.3rem;
  color: $error50;
  margin-top: 6px;
  height: 16px;
}

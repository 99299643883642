@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex: 1;
}

.listContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.studentItem {
  display: flex;
  height: 64px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  &:first-child {
    border-top: 1px solid $strokeTwo;
  }
  &:not(:last-child) {
    border-bottom: 1px solid $strokeTwo;
  }
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.itemLeftContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 8px;
}

.itemRightContainer {
  display: flex;
}

.statusWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  flex-direction: column;
  margin-left: 12px;
}

.nameText {
  display: flex;
  font-size: 1.6rem;
  color: $gray13;
  @include multiLineEllipsis(1.8rem, 1);
  @include regular-font;
}

.allIconContainer {
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: $strokeTwo;
}

.noDataText {
  display: flex;
  text-align: center;
  width: 100%;
  font-size: 1.4rem;
  color: $gray48;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.avatarContainer {
  flex-shrink: 0;
}

.emptyStateContainer {
  height: 100%;
  position: relative;
  bottom: 50px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  width: 480px !important;
  height: 100%;
  z-index: 1000;
  border-radius: 8px !important;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.headerConatiner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 24px;
  border-bottom: 1px solid $borderSubtle;
}

.headerText {
  @include bold-font;
  font-size: 2.2rem;
  color: $textDefault;
}

.headerSubText {
  @include regular-font;
  font-size: 1.8rem;
  color: $textDefault;
}

.accordianContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.labelContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  margin-left: 36px;
}

.moduleLabel,
.stepLabel {
  @include medium-font;
  font-size: 1.6rem;
  color: $textSubtle;
}

.dragDotsOutlinedIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 12px;
}

.checkboxContainer {
  margin-right: 16px;
}

.accordianListContainer {
  margin: 12px 2px 80px 2px;
}

.accordianBorder {
  border-bottom: 1px solid $borderSubtle !important;
}

.accordianTitleContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.singalCheckboxContainer {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 24px 0px;
}

.footerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 24px;
}

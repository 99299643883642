@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  // border-bottom: 1px solid $strokeTwo;
}
.stepsContainer {
  display: grid;
  margin-top: 56px;
  grid-auto-flow: column;
  grid-gap: 36px;
}

.step {
  display: grid;
  justify-items: center;
  align-content: space-between;
  width: 316px;
  height: 452px;
  border: 1px solid $strokeOne;
  border-radius: 16px;
  padding: 48px 40px 40px;
}

.topContainer {
  display: grid;
  justify-items: center;
}
.stepImage {
  display: flex;
  align-items: flex-end;
  height: 56px;
}

.stepTitle {
  margin-top: 40px;
  @include bold-font;
  font-size: 2rem;
  line-height: 1.4;
  text-align: center;
}

.stepSubTitle {
  @include medium-font;
  font-size: 1.6rem;
  color: $gray13;
  line-height: 1.75;
  margin-top: 8px;
  text-align: center;
}

.strips {
  //margin-top: 64px;
}

.othersText {
  margin-top: 24px;
  @include medium-font;
  font-size: 1.6rem;
  line-height: 1.75;
  color: $gray13;
}

.button {
  margin-top: 24px;
  width: max-content;
}

@media (max-width: 991px) {
  .stepsContainer {
    grid-template-rows: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .stepsContainer {
    margin-top: 56px;
    grid-auto-flow: row;
    grid-template-columns: unset;
  }
  .step {
    text-align: center;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  min-height: 104px;
  padding: 0px 28px;
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: $white;
  border-bottom: 1px solid $gray87;
}

.leftContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.leftIcon {
  display: inline-flex;
  align-items: center;
  fill: $gray72;
  gap: 10px;
  cursor: pointer;
}

.rightContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  justify-content: flex-end;
}

.title {
  @include bold-font;
  font-size: 2.4rem;
  color: $gray13;
  margin-left: 16px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.row {
  display: grid;
  border-bottom: solid 1px $strokeTwo;
  align-items: center;
  padding: 16px 0px;
  flex-shrink: 0;
  background-color: white;
  @include border-class(bottom, $radius: 0);
  &:hover {
    .hideCell {
      display: grid;
    }
    background-color: $surfaceHover;
  }
}

.cell {
  display: grid;
  color: $gray13;
  @include medium-font;
  font-size: 1.4rem;
  justify-items: left;
  width: 100%;
}

.hideCell {
  display: none;
}

.profileCell {
  composes: cell;
  justify-content: flex-start;
}

.actionCell {
  composes: cell;
  justify-content: center;
  width: 100%;
}

.subText {
  color: $textSubtle;
}

.textCell {
  composes: cell;
  width: 100%;
  @include multiLineEllipsis(1.8rem, 1);
}

.intCell {
  composes: cell;
  text-align: center;
  width: 100%;
  @include multiLineEllipsis(1.8rem, 1);
}

.dashStyle {
  color:$textDefault
}
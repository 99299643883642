@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, minmax(auto, 128px));
  justify-content: flex-start;
  align-items: center;
}

.tab {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-bottom: 4px solid transparent;
  @include medium-font;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.5;
  color: $gray48;
  padding: 0 8px 12px 8px;
}

.activeTab {
  @include demi-bold-font;
  color: $salmon60;
  border-color: $salmon60;
}

.updatedActiveTab {
  @include demi-bold-font;
  color: $textDefault;
  border-color: $borderActive;
}
.nonActiveTab {
  &:hover {
    // border-color: $borderSubtle;
    color: $textDefault;
  }
}

.notSubTextTab {
  justify-content: center;
}

.tabLabel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: $yellow50;
  border-radius: 50%;
  margin-left: 8px;
  color: $white;
  font-size: 1.2rem;
  @include medium-font;
}

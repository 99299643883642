@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.modalContent {
  width: 848px !important;
  height: 640px !important;
}

.modalBody {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.footer {
  padding: 12px 40px;
  display: flex;
  justify-content: space-between;
  height: 64px;
  box-shadow: 5px 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.activityClass {
  font-size: 16px;
  display: flex;
  align-items: center;
  @include demi-bold-font;
}

.activityCount {
  color: $blue29;
  composes: activityClass;
}

.noActivityCount {
  color: $gray48;
  composes: activityClass;
}

.rightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.closeButton {
  margin-right: 16px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 28px;
}

.modalHeading {
  font-size: 2rem;
  color: $textDefault;
  @include bold-font;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container :global {
  input[type="number"],
  input[type="text"] {
    border-radius: 0;
    padding-left: 12px;
    width: 100%;
    border: none;
    color: $gray13;
    font-size: 1.6rem;
    line-height: 2rem;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  .rc-time-picker {
    display: flex;
    width: 100%;
  }

  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

body :global {
  .rc-time-picker-panel {
    // width: 230px;
  }
  .rc-time-picker-panel-select {
    overflow-x: hidden;
    flex-basis: 50%;
  }
  .rc-time-picker-panel-inner {
    display: block;
    box-shadow: none;
    // border: none;
  }
  .rc-time-picker-panel-narrow {
    max-width: inherit;
  }
  .rc-time-picker-panel-combobox {
    display: flex;
  }
  .rc-time-picker-panel-select > ul > li {
    box-sizing: border-box;
    padding: 0 16px;
    height: 36px;
    font-size: 14px;
    line-height: 36px;
    &:first-child {
      margin-top: 8px;
    }
    &:last-child {
      margin-bottom: 8px;
    }
  }
}
.borderElem {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid $strokeTwo;
  border-radius: 4px;
}

.timeComponent {
  padding: 4px 16px; //added Since date and time are not same heighted
  display: flex;
  height: 56px;
  flex-direction: row;
  align-items: center;
}

.timeComponentSelected {
  border: 1px solid $gray13;
}

.timeSvg {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.resetButton {
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 0;
  transform: translateY(-50%);
  margin-right: 16px;
  cursor: pointer;
}

@import "~UIComponents/CommonStyles/colors";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  border-radius: 8px;
  border: 1px solid $borderDefault;
  overflow: hidden;
  flex: 1;
}

.cellHeader {
  display: flex;
  padding: 12px 32px;
  background-color: $surfaceDisabled;
  color: $textSubtle;
  line-height: 2rem;
  width: 100%;
}

.fieldGroup {
  display: flex;
  flex-direction: column;
}
.inquiryQuestionsContainer {
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1;
}

.dragAndDropListContainer {
  display: flex;
  padding: 0 48px 32px 48px;
  overflow: auto;
  flex: 1;
}

.levelsWrapper {
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1;
}

.levelsContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
}

.tableGroupsContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.headerBaseContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.inputListContainer {
  padding: 32px 48px 0;
}

.learningStandardHeaderContainer {
  padding: 24px 48px;
  border-bottom: 1px solid $borderSubtle;
}

.objectivesHeaderContainer {
  padding: 24px 48px;
  border-bottom: 1px solid $borderSubtle;
}

.headerLabel {
  display: flex;
  line-height: 2.8rem;
  color: $textDefault;
  margin: 0;
}

.addButtonContainer {
  padding: 32px;
  display: flex;
  width: 100%;
}

.tableRow {
  display: flex;
  flex-direction: column;
}

.tableRowHeader {
  display: flex;
  width: 100%;
}

.addFieldSection {
  display: flex;
  flex: 1;
}

.fieldContainer {
  display: flex;
  gap: 14px;
  border-bottom: 1px solid $borderDefault;
  padding: 32px;
  gap: 12px;
  width: 100%;
}

.tableRowContent {
  display: flex;
  width: 100%;
}

.tableWrapper {
  display: flex;
  gap: 8px;
}

.fieldEmptyTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  width: 100%;
  padding: 32px 48px;
  border: 1px solid $strokeTwo;
  border-radius: 4px;
  margin-top: 18px;
  transition: 0.4s;
}

.dropContainer {
  border: 1px dotted $blue29;
  background-color: rgba($strokeOne, 0.2);
  position: relative;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  font-size: 1.6rem;
  color: $blue29;
  @include regular-font;
  text-align: center;
}

.header {
  font-size: 1.6rem;
  color: $bar;
  @include bold-font;
  margin-bottom: 32px;
  text-align: center;
}

.subText {
  text-align: center;
  @include demi-bold-font;
  font-size: 1.4rem;
  margin-bottom: 16px;
  color: $teal20;
}

.previewContainer {
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
  margin-bottom: 24px;
}

.previewItem {
  height: 58px;
  width: 58px;
  border-radius: 2px;
}

.divider {
  width: 100%;
  text-align: center;
  position: relative;
  margin: 40px 0;
  span {
    padding: 0 12px;
    background: white;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    font-size: 1.4rem;
    color: $gray48;
    @include demi-bold-font;
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background: $gray72;
    left: 0;
  }
}

.buttonGroup {
  display: grid;
  grid-auto-flow: column;
  column-gap: 16px;
}

// Dropdown styles

.createTextContainer {
  width: 100%;
  height: 160px;
  display: flex;
  margin: 20px 0px 0px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: white;
  border: solid 1px $strokeTwo;
  cursor: pointer;
}

.middleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.createIcon {
  display: flex;
}

.addSvg {
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createTextSmall {
  display: flex;
  @include medium-font;
  font-size: 1.6rem;
  color: $blue29;
  //   color: var(--black-three);
}

.createText {
  @extend .createTextSmall;
  margin-top: 12px;
}

.createTextContainerSmall {
  width: 100%;
  height: 60px;
  display: flex;
  margin: 20px 0px 0px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: white;
  border: solid 1px $strokeTwo;
  cursor: pointer;
}

.titleText {
  display: flex;
  // @include bold-font;
  // font-size: 1.4rem;
  color: $gray31;
}

.addButton {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  margin-left: auto;
}

.headerContainer {
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  align-items: center;
}

@media screen and (max-width: 1294px) {
  .buttonGroup {
    grid-auto-flow: dense;
    row-gap: 20px;
  }
  .previewItem:nth-child(n + 5) {
    display: none;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  align-items: center;
  width: 100%;
  &:hover {
    .dragDropIconContainer {
      visibility: visible;
    }
    .dragDropIconContainerDisabled {
      fill: $strokeOne;
      visibility: visible;
    }

    .addIcon {
      visibility: visible;
    }
  }
}
.textContainer {
  outline: 0px solid transparent;
  flex: 1;
}

.textContainer[contentEditable="true"] {
  cursor: text;

  &:empty {
    &:before {
      content: attr(placeholdertext);
      color: $strokeTwo;
    }
  }
}

/* action styles */
.actionButtonContainer {
  display: flex;
  justify-content: flex-start;
  margin-right: 12px;
  align-items: center;
  position: relative;
}
.iconCotainer {
  fill: $gray48;
  cursor: pointer;
  &:hover {
    fill: $black;
  }
}

.leftArrowContainer {
  composes: iconCotainer;
  margin-right: 8px;
  height: 16px;
}
.rightArrowContainer {
  composes: iconCotainer;
  padding: 0px 8px 0px 0px;
  height: 16px;
}

.dragDropIcon {
  display: flex;
}

.dragDropIconContainer {
  visibility: hidden;
}

.dragDropIconContainerDisabled {
  visibility: hidden;
}
.addIconContainer {
  position: absolute;
  left: -45px;
  padding-right: 45px;
  z-index: -10;

  &:hover {
    .addIcon {
      visibility: visible;
    }
  }
}

.addIcon {
  display: flex;
  align-items: center;
  visibility: hidden;
  composes: iconCotainer;
}

.warningIconContainer {
  position: absolute;
  left: -45px;
  padding-right: 45px;
  z-index: -10;
}

.warningIcon {
  display: flex;
  align-items: center;
  composes: iconCotainer;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-right: 8px;
}
.arrowsContainer {
  display: flex;
  align-items: center;
  margin: 0px 6px 0px;
}

.selectionBox {
  display: inline-flex;
  gap: 12px;
  align-items: center;
}

.iconDisabled {
  color: $gray87;
}
.cursorPointer {
  cursor: pointer;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: grid;
  padding: 6px 0px;
}

.editContainer {
  background-color: $toddleBG1;
  border-top: 1px solid $strokeOne;
}

.headerLabel {
  display: flex;
  font-size: 1.2rem;
  @include demi-bold-font;
  color: $gray48;
  &:first-child {
    padding-left: 24px;
  }
}

.headerLabelView {
  margin-left: -24px;
}

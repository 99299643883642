@import "~UIComponents/CommonStyles/mixins";

.container {
  @include scrollContainer;

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.feedContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  gap: 24px;
}

.emptyStateContainer {
  display: flex;
  padding: 40px 0;
}

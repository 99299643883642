@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.containerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $white;
  width: 100%;
  height: max-content;
}

.container {
  display: flex;
  width: calc(100% - 88px);
  justify-content: center;
  padding: 80px 0;
  max-width: 1240px;
}

.carouselItem {
  padding: 0 66px;
  display: grid !important;
  grid-template-columns: 300px 1fr;
  column-gap: 140px;
  justify-content: center;
}

.imageContainer {
  width: 100%;
  img {
    width: 100%;
  }
}

.content {
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.leftQuote,
.rightQuote {
  display: none;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 48px;
  padding-right: 30px;
}

.comment {
  display: flex;
  font-size: 2.4rem;
  line-height: 4.8rem;
  color: $bar;
  @include medium-font;
}

.userInfo {
  display: flex;
  flex-direction: column;
}

.user {
  display: flex;
  flex-wrap: wrap;
}

.name,
.role,
.school {
  font-size: 2rem;
  line-height: 4rem;
  color: $blue29;
  @include bold-font;
}

.name,
.role {
  &:after {
    content: ",";
  }
}

.role {
  @include medium-font;
}

.school {
  color: $gray48;
  @include medium-font;
}

@include mediaQuery("mobile-and-tablet") {
  .container {
    padding: 52px 0px;
    max-width: 600px;
  }
  .carouselItem {
    grid-template-columns: auto;
    grid-row-gap: 40px;
    padding: 0;
  }

  .imageContainer {
    width: 154px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }

  .content {
    padding-top: 0;
  }

  .detailContainer {
    margin-bottom: 24px;
    padding-right: 0;
  }
  .comment {
    font-size: 1.6rem;
    line-height: 3.2rem;
    text-align: center;
  }

  .user,
  .school {
    display: flex;
    justify-content: center;
  }

  .name,
  .role,
  .school {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
  .userInfo {
    margin-bottom: 32px;
  }
  .leftQuote {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
  .rightQuote {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    .rightQuoteWrapper {
      transform: rotateY(180deg);
    }
  }
}

@include mediaQuery("mobile") {
  .imageContainer {
    width: 172px;
  }
}

@include mediaQuery("tablate") {
  .comment {
    font-size: 1.8rem;
  }
}

@import "~UIComponents/CommonStyles/colors";

.imageContainer {
  display: flex;
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: $toddleBG1;
  overflow: hidden;
  padding: 14px 1px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.wrapperContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.customHeaderElement {
  width: 100%;
  display: flex;
  font-size: 1.6rem;
  @include demi-bold-font;
  color: $textDefault;
  margin: 16px 0 40px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

// .bodyContainer {
//   display: flex;
//   flex: 1;
//   min-height: 0;
//   width: 100%;
//   margin-bottom: 72px;
//   overflow-x: hidden;
// }

.headerContainerStyle {
  display: flex;
  width: 100%;
  max-width: 100%;
  padding: 0 40px;
  margin: 0 auto;
}

.scrollContainer {
  display: flex;
  flex: 1;
  min-height: 0;
  overflow-y: auto;
}

.innerScrollContainer {
  display: flex;
  min-height: 100%;
  height: max-content;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding: 64px 40px 0px;
  margin: 0 auto;
}

.modalInnerScrollContainer {
  padding: 0 40px;
}

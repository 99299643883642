@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.label {
  display: block;
  @include medium-font;
  font-size: 1.4rem;
  line-height: 2rem;
  color: $gray48;
  margin: 0;
}

.matchedPart {
  display: inline-block;
  white-space: break-spaces;
  @include demi-bold-font;
  color: $gray13;
  margin: 0;
}

.firstNonMatchingPart {
  display: flex;
  margin: 0;
}

.secondNonMatchingPart {
  display: flex;
  margin: 0;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  // flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.footer {
  padding: 12px 40px;
  display: flex;
  justify-content: space-between;
  height: 64px;
  box-shadow: 5px 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.activityCount {
  color: $blue29;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.noActivityCount {
  color: $gray48;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.resourceFeedParentDiv {
  display: flex;
  flex: 1;
  min-height: 0;
  flex-direction: column;
}

.resourceFeedHeader {
  @include bold-font;
  font-size: 24px;
  color: $gray13;
}

.resourceFeed {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 24px;
}

.exitButton {
  width: fit-content;
  font-size: 16px;
  @include demi-bold-font;
  color: $salmon60;
  margin-right: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
}

.backIcon {
  margin-right: 8px;
}

.leftContainer {
  display: flex;
  flex-direction: column;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  padding: 24px;
}

.heading {
  @include bold-font;
  font-size: 24px;
  color: $black;
}

.noDataContainer {
  display: flex;
  flex: 1;
  color: $gray31;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 64px;
}

.createText {
  margin-top: 40px;
  display: flex;
  @include bold-font;
  font-size: 2.1rem;
  color: $gray13;
}

.createSubText {
  margin: 16px 0 24px 0;
  display: flex;
  @include regular-font;
  font-size: 1.6rem;
  color: $gray48;
  text-align: center;
  flex-shrink: 0;
  line-height: 2.8rem;
}

.cancelIcon {
  cursor: pointer;
  fill: $gray48;
  z-index: 99;
  &:hover {
    fill: $gray31;
  }
}

.loadingContainer {
  width: 100%;
  margin: 24px 0;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.formFieldsParentContainer {
  display: flex;
  overflow-y: auto;
  flex: 1;
  min-height: 0;
  margin-bottom: 72px;
  width: 100%;
}
.formFields {
  display: flex;
  flex-direction: column;
  padding: 0px 32px 32px;
  width: 100%;
}

.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 96px;
  font-size: 2.4rem;
  @include bold-font;
  color: $gray31;
  padding: 24px 32px 16px;
}

.buttonContainer {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  flex-direction: row;
  flex-grow: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 11;
  //height: 64px;
  position: absolute;
  border-top: 1px solid #cdcdd2;
  padding: 16px 32px;
  align-items: center;
  background-color: $white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 5px 2px 10px 0 rgba(0, 0, 0, 0.2);
}
.cancelDiv {
  display: flex;
  margin-right: 16px;
}

.error {
  display: flex;
  flex-grow: 0;
  font-size: 1.3rem;
  color: $error50;
  margin-top: 6px;
  height: 16px;
}

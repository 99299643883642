@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.innerContainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
}
.innerContainerNodeEditor {
  align-items: normal;
}

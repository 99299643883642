@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.dropzoneInputContainer {
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  flex-shrink: 0;
  border: 1px solid $strokeOne;
  border-radius: 4px;
  width: 100%;
}

.dropzoneInstructionContainer {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
}

.dropzoneHeaderText {
  display: flex;
  color: $gray13;
  font-size: 1.6rem;
  @include demi-bold-font;
  margin-bottom: 24px;
}

.dropzoneSubText {
  display: flex;
  color: $gray48;
  font-size: 1.6rem;
  @include medium-font;
}

.instructionsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.instructionHeaderText {
  display: flex;
  @include medium-font;
  color: $gray48;
  margin-bottom: 24px;
  width: 100%;
  font-size: 1.6rem;
}

.instructionContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
}

.dot {
  display: flex;
  @include medium-font;
  color: $gray48;
  margin-right: 8px;
  font-size: 1.6rem;
}

.instructionText {
  display: flex;
  @include medium-font;
  color: $gray48;
  width: 100%;
  font-size: 1.6rem;
}

.contactUs {
  @include medium-font;
  color: $gray48;
  width: 100%;
  font-size: 1.6rem;
  text-align: center;
  margin-top: 16px;
}

.contactUsButton {
  color: $blue29;
  text-decoration: underline;
  cursor: pointer;
}

.uploadDocumentButton {
  display: flex;
  flex-direction: column;
  padding: 12px;
  flex-shrink: 0;
  border: 1px solid $strokeOne;
  border-radius: 4px;
  width: 100%;
  @include demi-bold-font;
  align-items: center;
  justify-content: center;
  color: $salmon60;
  font-size: 1.6rem;
}

.attachmentContainer {
  display: flex;
  width: 80%;
}

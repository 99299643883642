@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
}

.container :global {
  .Select-menu-outer {
    max-height: unset;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  }
  .Select-menu {
    max-height: unset;
  }
}

.innerContainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 32px;
  width: 100%;
}

.groupsContainer {
  display: flex;
  flex-direction: column;
}
.groupContainer {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  width: 100%;
}
.groupHeaderContainer {
  display: flex;
  padding-bottom: 12px;
  margin-bottom: 40px;
  @include bold-font;
  font-size: 1.8rem;
  color: $salmon60;
  border-bottom: 1px solid $strokeOne;
  width: 100%;
}
.inputRowContainer {
  display: grid;
  grid-column-gap: 32px;
  grid-auto-flow: column;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.inputContainer {
  display: flex;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.nameContainer {
  display: flex;
  width: 45%;
}

.inputRadioList {
  display: flex;
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.bottomContainer {
  align-items: center;
  width: 100%;
}

.privacyText {
  padding-right: 16px;
  @include medium-font;
  color: $gray31;
}

.privacyButton {
  text-decoration: underline;
  cursor: pointer;
  color: $gray31;
}

.noWorkEmail {
  display: flex;
  position: absolute;
  right: 0;
  font-size: 1.2rem;
  @include medium-font;
  color: $blue29;
  cursor: pointer;
}

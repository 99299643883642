@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  flex-direction: column;
}

.practice {
  font-size: 1.6rem;
  @include medium-font;
  color: $gray31;
  margin-bottom: 20px;
}

.code {
  color: $blue29;
  @include bold-font;
  margin-right: 2px;
}

@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
}

.useKeyboard [data-elm="search-item"] {
  pointer-events: none;
}

.useKeyboard [data-elm="module-filter-item"] {
  pointer-events: none;
}

.modalContent {
  width: 792px !important;
  height: 520px !important;
  border-radius: 16px !important;
}

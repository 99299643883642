@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid $strokeOne;
}

.header {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10px;
}

.groupName {
  @include bold-font;
  font-size: 1.6rem;
  color: $bar;
}

.noMatchFound {
  text-align: center;
  font-size: 1.8rem;
  color: $gray31;
  padding: 12px 0;
  @include bold-font;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.modalContent {
  width: 604px !important;
  max-height: 612px !important;
  height: unset !important;
}

.headerText {
  display: flex;
  @include bold-font;
  color: $gray13;
  font-size: 2.4rem;
  margin-bottom: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 32px 40px 0px 40px;
}

.labelText {
  @include demi-bold-font;
  font-size: 2rem;
  color: $gray13;
  word-break: break-word;
}

.messageText {
  display: flex;
  @include regular-font;
  font-size: 2rem;
  color: $gray13;
  margin-bottom: 40px;
  word-break: break-word;
  line-height: 3.2rem;
}

.warningMessage {
  display: flex;
  @include demi-bold-font;
  font-size: 2rem;
  color: $salmon60;
  margin-bottom: 40px;
  word-break: break-word;
}

.body {
  display: flex;
  overflow-y: auto;
  padding: 0 40px;
  flex: 1;
  margin-bottom: 80px;
}
.bodyInnerContainer {
  display: flex;
  flex-direction: column;
  height: max-content;
}

.footer {
  display: flex;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: flex-end;
  height: 80px;
  padding: 0 40px;
  width: 100%;
}

.buttonContainer {
  display: grid;
  grid-column-gap: 8px;
  grid-auto-flow: column;
}

.checkboxesHeaderText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $gray13;
  width: 100%;
  margin-bottom: 24px;
  word-break: break-word;
}

.checkBoxContainer {
  display: flex;
  margin-bottom: 24px;
}

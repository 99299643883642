@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

$borderRadius: 8px;
.audioContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  position: relative;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 72px;
  overflow: hidden;
  padding: 12px;
  cursor: pointer;
  position: relative;
  &:hover {
    .deleteSvgIcon {
      visibility: visible;
    }
    .downloadIconContainer {
      display: flex;
    }
  }
  background-color: $white;
  border-radius: $borderRadius;
  border: 1px solid $strokeOne;
}

.disabledContainer {
  background-color: $surfaceSubtle;
  border: none;
}

.attachmentImageContainer {
  display: flex;
  width: 88px;
  height: 100%;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $gray72;
  overflow: hidden;
  position: relative;
  border-right: 1px solid $strokeTwo;
}

.overlay {
  display: flex;
  position: absolute;
  background-color: rgba($black, 0.4);
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.iconContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.actionSvgIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteSvgIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  fill: $black;
  visibility: hidden;
  margin-left: 12px;
  @include mediaQuery("touchable") {
    visibility: visible;
  }
}

.rightContainer {
  display: flex;
  padding: 8px 8px 8px 16px;
  flex: 1;
  border-left-width: 0;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.nameText {
  display: flex;
  font-size: 1.4rem;
  line-height: 2rem;
  @include medium-font;
  color: $gray31;
  @include multiLineEllipsis(2rem, 1);
  word-break: break-word;
}

.singleCardThemeNameText {
  font-size: 1.4rem;
  line-height: 2rem;
  @include demi-bold-font;
  color: $gray31;
  @include multiLineEllipsis(2rem, 1);
}

.typeText {
  display: flex;
  font-size: 1.2rem;
  line-height: 1.6rem;
  @include regular-font;
  color: $gray48;
}

.singleCardThemeTypeText {
  @include medium-font;
}

.downloadIconContainer {
  display: none;
  margin-left: 12px;
  fill: $black;
  align-items: center;
}

.tickIconContainer {
  display: flex;
  margin-left: 12px;
}

.audioControlIconsCon {
  display: flex;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.assessmentTypeIcon {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

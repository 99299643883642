@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  width: 100%;
  background-color: $white;
  flex-direction: row;
  padding: 16px 40px;
  border-bottom: 1px solid $strokeOne;
  position: absolute;
  z-index: 1000;
  top: 0;
}

.darkContainer {
  composes: container;
  background-color: $gray13;
  border-bottom: 1px solid $gray13;
}

.leftContainer {
  display: flex;
  align-items: center;
  height: 40px;
  flex-grow: 1;
}

.centerContainer {
  composes: leftContainer;
  justify-content: center;
}

.rightContainer {
  composes: leftContainer;
  justify-content: flex-end;
}

// Back button

.backContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: $gray48;
  fill: $gray48;
}

.chevronIconContainer {
  display: flex;
  margin-right: 16px;
}

.backButtonText {
  display: flex;
  @include bold-font;
  font-size: 2rem;
  color: inherit;
}

// Timer

.timerContainer {
  display: flex;
  align-items: center;
  color: $gray13;
}

.darkTimerContainer {
  composes: timerContainer;
  color: $white;
}

.redDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $salmon60;
  margin-right: 8px;
}

.timerText {
  font-size: 1.6rem;
  @include medium-font;
}

// Done button

.tickIconContainer {
  display: flex;
  margin-right: 8px;
  fill: $white;
}

.buttonText {
  font-size: 1.6rem;
  @include demi-bold-font;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
  background-color: rgba(255, 255, 255, 0.6);
  background-image: repeating-linear-gradient(
    315deg,
    white 0px,
    white 8px,
    $strokeTwo 9px
  );
  z-index: 10;
}

.profile {
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 20;
}

.lock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: $salmon60;
  position: absolute;
  z-index: 30;
  bottom: -4px;
  right: -2px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  padding: 40px 48px;
  height: 100%;
  position: relative;
  overflow-y: auto;
  align-items: center;
}

.containerWithAction {
  padding-bottom: 100px;
}

.centerContainer {
  display: flex;
  flex-direction: column;
  width: 600px;
  flex-shrink: 0;
}

.loadingContainer {
  width: 478px;
  height: 32px;
}

.emptyState {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 64px;
}

.emptyText {
  @include regular-font;
  display: flex;
  color: $gray48;
  font-size: 1.6rem;
  margin-top: 48px;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
}
.modalContent {
  height: 640px !important;
  width: 560px !important;
}

.adjustedHeight {
  height: calc(100% - 32px);
}

.fullHeight {
  height: 100%;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  width: 100%;
  height: 100%;
}

.listContainer {
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  overflow-y: auto;
}

.listContent {
  display: grid;
  grid-template-columns: 100%;
  row-gap: 16px;
  width: 100%;
  flex-direction: column;
  padding: 16px 0;
}

.unitContainer {
  width: 100%;
  display: flex;
  border: 1px solid $strokeOne;
  border-radius: 4px;
  padding: 12px 16px;
  flex-direction: row;
  cursor: pointer;
  height: max-content;
  position: relative;
  justify-content: space-between;
  align-items: center;
  &:hover {
    border: 1px solid $strokeTwo;
  }
}

.unitContainerSelected {
  border: 1px solid $blue29 !important;
}

.unitImage {
  display: flex;
  height: 64px;
  width: 80px;
  border-radius: 4px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $toddleBG1;
}

.unitContentContainer {
  display: flex;
  width: 77%;
  flex-direction: column;
  padding-left: 12px;
  justify-content: space-between;
}

.selectUnitTitle {
  font-size: 1.6rem;
  @include bold-font;
  color: $gray13;
  margin-bottom: 4px;
}

.unitTitle {
  width: 90%;
  font-size: 1.6rem;
  @include demi-bold-font;
  color: $gray13;
}

.themeOrSubject {
  width: 100%;
  font-size: 1.4rem;
  @include medium-font;
  color: $gray48;
  @include multiLineEllipsis(2rem, 1);
}

.unitResouceCountContainer {
  display: flex;
  flex-direction: row;
}
.unitResourceCount {
  width: 100%;
  font-size: 1.2rem;
  @include medium-font;
  color: $teal20;
  @include multiLineEllipsis(1.6rem, 1);
}

.footerButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}

.footerText {
  display: flex;
  font-size: 1.6rem;
  color: $gray48;
}

.noDataContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.tickIconContainer {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
}

.emptyTick {
  width: 20px;
  height: 20px;
  border-radius: 12px;
  border: 1px solid $gray31;
}

.topOptionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 16px;
}

.topText {
  width: 100%;
  //   height: 25px;
  font-size: 1.8rem;
  @include demi-bold-font;
  color: $gray31;
}

.orContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.orText {
  width: "auto";
  //   height: 16px;
  font-size: 1.6rem;
  @include demi-bold-font;
  color: $blue40;
}

.orContentLine {
  display: flex;
  width: 40%;
  height: 1px;
  background-color: $strokeOne;
}

.rightIcon {
  margin-left: auto;
}

.planDetailTextContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.planDetailText {
  font-size: 1.2rem;
  @include medium-font;
  color: $pink48;
  line-height: 24px;
  flex-shrink: 0;
}

.dot {
  display: flex;
  margin: 0px 6px;
  width: 4px;
  height: 4px;
  border: 1px solid #18605a;
  border-radius: 50%;
  background-color: #18605a;
  flex-shrink: 0;
}

.goalsSvgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 64px;
  background-color: $pink30;
  border-radius: 4px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.searchContainer {
  display: flex;
  width: 100%;
}

.resultContainer {
  display: flex;
  width: 100%;
}

.tagList {
  width: 100%;
  display: flex;
}

.noResultContainer {
  display: flex;
  width: 100%;
  @include medium-font;
  color: $gray48;
  font-size: 1.6rem;
}

.addNewLabel {
  display: flex;
  width: 100%;
  padding: 16px;
  font-size: 1.8rem;
  cursor: pointer;
  transition: background 0.3s;
  &:hover {
    background: $toddleBG1;
  }
}

.dropdownList {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 240px;
  overflow-y: auto;
}

.dropdownListItem {
  display: flex;
  align-items: center;
  min-height: 48px;
  padding: 0 24px;
  color: $gray48;
  font-size: 1.6rem;
  @include medium-font;
  transition: 0.4s;
  &:hover {
    background: #fafafd;
  }
}

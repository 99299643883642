@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
$borderColor: $strokeOne;

.modalHeader {
  display: flex;
  min-height: 90px;
  padding: 32px 40px;
  justify-content: space-between;
  align-items: center;
}

.modalBody {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  flex: 1;
  margin-bottom: 72px;
  padding: 0 40px;
}

.headerTitle {
  display: flex;
  @include bold-font;
  font-size: 2.4rem;
}

.relatedConceptContainer {
  display: flex;
  width: 100%;

  flex-shrink: 0;
  flex-direction: column;
  flex-shrink: 0;
}

.fieldComp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 32px;
}

.cancelIcon {
  cursor: pointer;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.tickAndHelperComp {
  position: absolute;
  top: 8px;
  right: 8px;
}

.modalFooter {
  display: flex;
  padding: 0px 40px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 72px;
  align-items: center;
  justify-content: flex-end;
  // border-top: 1px solid $gray72;
}

.modalFooterShadow {
  box-shadow: 0 -2px 16px 0 rgba($black, 0.08);
}

.cancelButton {
  display: flex;
  margin-right: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.groupLabel {
  display: flex;
  @include bold-font();
  color: $gray13;
  font-size: 1.8rem;

  margin-bottom: 40px;
}

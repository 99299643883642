@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  width: 216px;
  height: 220px;
  margin: 16px;
  border: 1px solid $strokeOne;
  border-radius: 4px;
  position: relative;
}

.containerSelected {
  border: 1px solid $blue29;
}

.containerDisable {
  opacity: 0.5;
  pointer-events: none;
}

.tickIcon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.imageContainer {
  display: flex;
  width: 100%;
  height: 104px;
  cursor: pointer;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 16px;
}

.elementText {
  @include regular-font;
  font-size: 1.4rem;
  margin-bottom: 16px;
}

.titleText {
  @include medium-font;
  @include multiLineEllipsis(1.4rem, 1);
  display: block;
  font-size: 1.4rem;
  color: $textDefault;
}

.createByContainer {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  margin-right: 4px;
  flex: 1;
}

.createdByText {
  @include medium-font;
  font-size: 1.2rem;
  color: $textSubtle;
  margin-left: 8px;
  @include multiLineEllipsis(1.8rem, 1);
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
}

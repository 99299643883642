@import "~UIComponents/CommonStyles/colors";

.container {
  display: grid;
  gap: 48px;
  width: 100%;
}

.header {
  margin: 0;
  line-height: 3.2rem;
  color: $textDefault;
}

.subHeader {
  margin: 0;
  line-height: 2.4rem;
  color: $textDefault;
}

.footer {
  display: flex;
  flex-direction: column;
}

.error {
  display: flex;
  margin-top: 8px;
  color: $textCritical;
}

.timezoneContainer {
  display: flex;
  width: 400px;
  margin-top: 32px;
  margin-bottom: 16px;
}

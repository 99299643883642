@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

$component_bottom_margin: 64px;
$component_top_margin: 56px;
$step_component_width: 640px;

.componentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  --active-border-color: #{$blue29};
}

.fieldCompFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-between;
  height: 100%;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: -20px;

  .listItemComp {
    margin: 0px 16px 16px 0px;
  }
}

.fieldCompGrid {
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-columns: repeat(2, 1fr);

  .listItemComp {
    margin: 0;
  }
}

.listItemComp {
  display: flex;
  position: relative;
  width: 284px;
  min-height: 128px;
  flex-shrink: 0;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid $strokeTwo;
  flex-direction: column;

  &:hover {
    cursor: pointer;
    border: 1px solid $strokeOne;
  }

  padding: 24px 36px 24px 24px;
}
.listItemCompActive {
  composes: listItemComp;
  border: 1px solid var(--active-border-color) !important;
  &:hover {
    border: 1px solid var(--active-border-color) !important;
  }
}

.titleTextComp {
  display: flex;
  font-size: 1.4rem;
  @include medium-font;
  color: $gray48;
  // margin-bottom: 4px;
  word-break: break-word;
}

.titleTextBold {
  display: flex;
  font-size: 1.4rem;
  @include demi-bold-font;
  color: $gray13;
}

.titleTextDemiBold {
  display: flex;
  font-size: 1.4rem;
  @include medium-font;
  color: $gray31;
}

.subText {
  display: flex;
  font-size: 1.4rem;
  @include regular-font;
  color: $gray31;
  margin-top: 4px;
}

.tickAndHelperComp {
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
  justify-content: flex-end;
  // flex-basis: 24px;
  // width: 100%;
  width: 24px;
  height: 24px;
  align-items: center;
}

.tickTop {
  top: 12px;
  right: 12px;
}

.tickCenter {
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
}

.tickTopV2 {
  right: 16px;
  top: 16px;
}

.groupedOptionsContainer {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 56px;
}

.groupedOptionsContainerV2 {
  grid-row-gap: 48px;
}

.groupedOption {
  display: flex;
  flex-direction: column;
}

.groupedLabel {
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $gray31;
}

.groupedHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  justify-content: space-between;
}

.groupedHeaderV2 {
  margin-bottom: 24px;
}

.infoIcon {
  display: flex;
  cursor: pointer;
  margin-left: 8px;
  // margin-top: -4px;
}

.listContainerV2 {
  grid-column-gap: 32px;
  grid-row-gap: 16px;
  flex: 1;
}

.listItemContainerStyleV2 {
  align-items: center;
  flex-direction: row;
  min-height: 56px;
  border-color: $borderSubtle;
  padding: 16px 48px 16px 16px;
  border-radius: 10px;
  &:hover {
    border-color: $borderHover;
  }
}

.error {
  display: flex;
  border-color: $borderCritical;
  &:hover {
    border-color: $borderCritical;
  }
}

.unSelectedComp {
  border-radius: 50%;
  border: 1px solid $gray72;
  height: 16px;
  width: 16px;
}

.selectedTickCompV2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleTextV2 {
  @include medium-font;
  line-height: 2.4rem;
}

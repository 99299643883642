@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container,
.innerContainer {
  width: 100%;
}

.innerContainerPreview {
  pointer-events: none;
}

.profileContainer {
  display: flex;
  align-items: center;
}

.name {
  @include demi-bold-font;
  color: $textDefault;
  font-size: 1.6rem;
  margin-left: 16px;
}

.response {
  @include medium-font;
  color: $textDefault;
  font-size: 1.4rem;
  margin-top: 24px;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.wrapperPreview {
  display: flex;
}

.evaluationContainer {
  display: flex;
  flex-direction: column;
}

.evaluationText {
  @include demi-bold-font;
  color: $textDefault;
  font-size: 1.4rem;
  margin-top: 24px;
}

.evaluationContentContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.evaluationContentContainerPreview {
  width: 100%;
  display: flex;
}

.evaluationContent {
  margin-top: 16px;
  display: flex;
  align-items: center;
  background-color: $surfaceDefault;
  border-radius: 4px;
  padding: 8px 12px;
  &:hover {
    background-color: $surfacePressed;
  }
  cursor: pointer;
}

.evaluationContentPreview {
  flex: 0 0 192px;
}

.evaluationIcon {
  display: flex;
  align-items: center;
}

.evaluationTitle {
  display: flex;
  align-items: center;
  margin-left: 8px;
  @include medium-font;
  font-size: 1.2rem;
  @include multiLineEllipsis(1.63rem, 1);
}

.attachmentListContainer {
  margin-top: 16px;
}

.modalContent {
  width: 86vw !important;
  height: 90vh !important;
}

.modalHeader {
  display: flex;
  color: $gray13;
  @include bold-font();
  font-size: 2.4rem;
  padding: 24px;
  flex-shrink: 0;
  justify-content: space-between;
}

.modalBody {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  padding: 0px 24px;
  height: 100%;
}

.innerWrapper {
  width: 100%;
}

.actionButtons {
  display: flex;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.attachmentHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 24px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
}
.iconSvgContainer {
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 1px solid $strokeTwo;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

$borderColor: $strokeTwo;

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $toddleBG1;

  flex-direction: column;
  min-height: 120px;
  border-radius: 4px;
  padding: 16px;
}

.containerButton {
  border: 1px solid $strokeOne;
  display: flex;
  width: 100%;
  min-height: 120px;
  border-radius: 4px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid $strokeTwo;
    .title {
      color: $gray31;
    }
    .renderIcon {
      fill: $gray31;
    }
  }
}

.disableButton {
  &:hover {
    border: 1px solid $strokeOne;
    .title {
      color: $gray48;
    }
    .renderIcon {
      fill: $gray72;
    }
  }
}

.title {
  @include regular-font;
  font-size: 1.6rem;
  color: $gray48;
  text-align: center;
}

.subText {
  @include regular-font;
  font-size: 1.4rem;
  color: $gray48;
  text-align: center;
}

.renderIcon {
  display: flex;
  margin-bottom: 16px;
  fill: $gray72;
}

.renderBlueTheme {
  .title {
    color: $blue29;
  }
  .renderIcon {
    fill: $blue29;
  }
  &:hover {
    .title {
      color: $blue29;
    }
    .renderIcon {
      fill: $blue29;
    }
  }
}

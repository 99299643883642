@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logoText {
  display: flex;
  margin-left: 12px;
  color: $gray48;
  font-size: 2rem;
  @include demi-bold-font;
}

.imageContainer {
  margin-bottom: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  span {
    font-size: 2.4rem;
    @include bold-font;
    color: $grass;
    margin-left: 8px;
  }
}

.description {
  font-size: 1.6rem;
  color: $gray48;
  text-align: center;
  margin-bottom: 32px;
}

.revert {
  font-size: 1.6rem;
  color: $gray48;
  text-align: center;
  margin-bottom: 8px;
}

.revertLink {
  text-decoration: underline;
  cursor: pointer;
  font-size: 1.6rem;
  color: $blue29;
  text-align: center;
}

@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";

.container {
  &:focus {
    background-color: $gray95;
    outline: none;
  }
}

.selected {
  background-color: $gray95;
}

.title {
  color: $gray13;
}

.subtitle {
  color: $gray48;
}

.imageContainer {
  display: flex;
  align-items: center;
  flex: 1;
}

.profileImageContainer {
  border-radius: 8px;
}

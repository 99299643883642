@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.footerButtonContainer {
  display: flex;
  height: 96px;
  align-items: center;
  padding: 0 24px;
  justify-content: space-between;
}

.footerButtonLeftContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.footerButtonRightContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-end;
}
.footerButton {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
}

.countText {
  display: flex;
  @include regular-font;
  font-size: 1.6rem;
}

.svgIcon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.svgPinkIcon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  svg{
    fill: $red52;
  }
}

.svgBlueIcon {
  cursor: pointer;
  display: flex;
  fill: $blue29;
  &:hover {
    fill: #248a94;
  }
}

.svgLikeIcon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  stroke: $gray48;
  &:hover {
    stroke: $gray31;
  }
}

.disabledLikeIcon {
  display: flex;
  cursor: not-allowed;
  stroke: $iconDisabled;
  justify-content: center;
}

.approveText {
  padding-left: 8px;
}

.iconButton {
  margin-right:8px;
}
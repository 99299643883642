@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";

.container {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;
}

.headerContainer {
  margin-bottom: 12px;
}

.skillsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
}

.skillDetailContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.skillText {
  display: flex;
  width: 100%;
  @include demi-bold-font;
  font-size: 1.4rem;
  color: $gray13;
}

.subSkillsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 16px;
}

.subSkillItem {
  display: flex;
  padding: 0 16px;
  height: 40px;
  border: 1px solid $strokeOne;
  margin: 0 16px 16px 0;
  flex-shrink: 0;
  @include regular-font;
  font-size: 1.4rem;
  color: $gray48;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}

.editButtonContainer {
  display: flex;
  width: 100%;
  cursor: pointer;
  border: solid 1px #dbdbdb;
  height: 60px;
  color: $blue29;
  height: 48px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  @include medium-font;
  font-size: 1.6rem;
}

.subSkillText {
  display: flex;
  font-size: 1.4rem;
  color: $gray48;
  margin-top: 4px;
  @include medium-font;
}

.itemStyle {
  &:not(:last-child) {
    margin-bottom: 48px;
  }
  display: flex;
  flex-direction: column;
}

.itemHeader {
  display: flex;
  margin-bottom: 12px;
}

.itemContent {
  display: flex;
}
.subjectBenchmarkHeaderLabel {
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $gray13;
}

.overlayContainer {
  z-index: 1005 !important;
}

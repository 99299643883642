@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.body {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-bottom: 2px solid $strokeOne;
  padding: 24px;
  max-height: 70vh;
  overflow-y: auto;

  .headerTitle {
    display: flex;
    align-items: center;

    .eventImageContainer {
      display: flex;

      .eventImage {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: $strokeOne;

        .eventIcon {
          display: flex;
          width: 28px;
          height: 28px;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .eventTitleContainer {
      display: flex;
      flex-direction: column;
      padding-left: 12px;

      .title {
        @include demi-bold-font();
        font-size: 1.8rem;
        @include multiLineEllipsis(2.4rem, 2);
      }

      .publishedTime {
        display: flex;
        align-items: center;
        @include demi-bold-font();
        font-size: 1.2rem;
        color: $gray48;
        margin-top: 4px;
      }

      .publishedDate {
        display: flex;
        margin-right: 16px;
      }

      .timeBetweenText {
        display: flex;
        margin: 0 8px;
      }
    }
  }

  .descriptionText {
    @include regular-font();
    font-size: 1.4rem;
    margin-top: 28px;
    word-break: break-all;

    a {
      color: $blue29;
    }
  }

  .meeting {
    display: flex;
    align-items: center;
    margin-top: 28px;

    .meetingLinkIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
    }

    .meetingLink {
      display: flex;
      @include regular-font();
      font-size: 1.4rem;
      padding-left: 8px;
      text-decoration-line: underline;
      color: $gray48;
      word-break: break-all;
      cursor: pointer;

      a {
        color: $gray48;

        &:hover {
          color: $blue29;
        }
      }
    }
  }
}

.courseDetails {
  display: flex;
  flex-direction: column;
  margin-top: 28px;

  .courseDetailsHeader {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .left {
      display: flex;
      flex-direction: column;

      .assignedToLabel {
        @include demi-bold-font();
        font-size: 1.4rem;
      }

      .courseSummary {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include regular-font();
        font-size: 1.4rem;
      }
    }

    .right {
      display: flex;

      .arrowIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 16px;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      .arrowIconReversed {
        transform: rotate(180deg);
      }
    }
  }

  .courseDetailsBody {
    display: flex;
    flex-direction: column;
    flex: 1;
    // max-height: 300px;
    overflow-y: auto;

    .assignedCalUsersContainer {
      display: flex;
      flex-direction: column;
      &:not(:last-child) {
        margin-bottom: 24px;
      }

      .assignedCalendarLabel {
        display: flex;
        margin-bottom: 4px;
      }

      .studentListContainer {
        display: flex;
        min-height: max-content;
        flex-direction: column;
      }

      .studentProfile {
        display: flex;
        align-items: center;
        margin-top: 8px;

        .name {
          @include regular-font();
          font-size: 1.4rem;
          padding-left: 12px;
        }
      }
    }
  }
}

.calendarLabel {
  display: flex;

  .title {
    @include medium-font();
    font-size: 1.4rem;
  }

  .userCount {
    @include regular-font();
    font-size: 1.4rem;
  }
}

.body :global {
  .SingleDatePickerInput_calendarIcon {
    display: none;
  }

  .DateInput {
    width: unset;
  }

  .DateSelector__container___2D2VL input[type="number"],
  .DateSelector__container___2D2VL input[type="text"] {
    padding: 0;
    text-align: center;
    height: 22px;
    width: 112px;
    font-size: 1.2rem;
    @include medium-font;
    color: $gray48;
    background: $toddleBG1;
  }

  .DateInput_fang {
    top: 22px !important;
  }

  .SingleDatePicker_picker {
    top: 32px !important;
  }

  .TimeSelector__container___1Xur1 {
    &:hover {
      .TimeSelector__borderElem___17SFO {
        border-color: $blue29;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .TimeSelector__container___1Xur1 input[type="number"],
  .TimeSelector__container___1Xur1 input[type="text"] {
    width: 68px;
    height: 22px;
    padding: 0;
    text-align: center;
    border-radius: 2px;
    @include medium-font;
    font-size: 1.2rem;
    background: $toddleBG1;
    color: $gray48;
  }

  .rc-time-picker-panel {
    top: 23px !important;
    left: 0 !important;
  }

  .TimeSelector__borderElem___17SFO {
    border: none;
    border-bottom: 1px solid transparent;
  }

  .TimeSelector__timeComponentSelected___d07xj {
    border-color: $blue29;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

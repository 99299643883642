@import "~UIComponents/CommonStyles/mixins";
@import "~UIComponents/CommonStyles/colors";

.container {
  display: flex;
  position: relative;
  width: 100%;
  cursor: pointer;

  &:hover {
    .iconWithOverlay {
      fill: $white;
    }
    .hoverOnlyIconsContainer {
      visibility: visible;
    }
    .overlayContainer {
      visibility: visible;
    }
  }
}

.overlayContainer {
  display: flex;
  visibility: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba($black, 0.5), rgba($black, 0));
}

.iconsContainer {
  display: flex;
  height: min-content;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: flex-end;
  padding: 8px 0 0 0;
}

.icon {
  cursor: pointer;
  display: flex;
  margin-right: 12px;
  fill: $gray48;

  &:hover {
    fill: $black;
  }
}

.iconWithOverlay {
  fill: $gray48;
  &:hover {
    fill: $black;
  }
}

.hoverOnlyIconsContainer {
  display: flex;
  margin-right: 12px;
  height: 16px;
  border-right: 1px solid $borderDefault;

  visibility: hidden;
}

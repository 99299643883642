@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.examSessionContainer {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;
  width: 100%;
}

.examSessionOption {
  display: flex;
  flex-direction: row-reverse;
  column-gap: 12px;
  border: 1px solid $strokeOne;
  padding: 20px;
  border-radius: 10px;
  width: 332px;
  height: 56px;
  align-items: center;
  justify-content: flex-end;
}

.examSessionOptionsContainer {
  display: flex;
  column-gap: 32px;
}

.examSessionTitle {
  display: flex;
  @include demi-bold-font;
  font-size: 16px;
}

.examSessionTitleDisabled {
  composes: examSessionTitle;
  color: $gray72;
}

.examSessionLabel {
  @include medium-font;
  font-size: 16px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
$borderColor: $strokeOne;
.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 4px;
  height: max-content;
  gap: 8px;
}

.row {
  display: grid;
  min-height: 116px;
  flex-shrink: 0;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  height: max-content;
  gap: 8px;
}

.headerRow {
  composes: row;
  min-height: 72px;
  gap: 8px;
  color: $surfaceWhite;
}

.pseudoRow {
  display: flex;
  margin-left: -56px;
  padding-left: 56px;
  flex-shrink: 0;
  position: relative;
}

.pseudoColumn {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  position: relative;
  flex: 1;
  &:not(:last-child) .cell {
    border-right: 1px solid $borderColor;
  }
}

.cell {
  display: flex;
  grid-row: 1;
  position: relative;
  flex: 1;
  flex-shrink: 0;
  background-color: #fff;
  flex-direction: column;
  border: 1px solid transparent;
  border-radius: 8px;
  border: 1px solid $borderSubtle;
  padding: 16px;
  &:hover {
    .actionButton {
      display: flex;
    }
  }
}
.disabled {
  background: $surfaceDisabled;
}

.focusedCell {
  border: 1px solid $blue29 !important;
}

.evalutionCell {
  background-color: $toddleBG1;
}

.rowHeaderCell {
  composes: cell;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: $surfaceDark;
  min-height: 72px;
}

.nonCriteriaCell {
  composes: cell;
  background-color: $surfaceDisabled;
  padding: 16px;
}

.nonCriteriaEvaluationCell {
  background-color: $white;
}

.evaluationCellContainer {
  display: grid;

  grid-row-gap: 8px;
}

.evaluationEditCell {
  display: flex;
  border: 1px solid $borderColor;
  border-radius: 4px;
  padding: 16px;
  padding-top: 24px;
  position: relative;
}

.evaluationViewCell {
  composes: evaluationEditCell;
  border-width: 0;
}

.teacherEvaluationCell {
  background-color: rgba(24, 158, 174, 0.05);
}

.emptyEvaluationCell {
  background-color: $white;
}

.studentEvaluationCell {
  background-color: rgba(255, 180, 0, 0.05);
}

.tickSvgComponent {
  position: absolute;
  top: 8px;
  right: 8px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  justify-content: flex-end;
  display: flex;
}

.deleteCell {
  display: flex;

  cursor: pointer;
  position: absolute;
  left: 0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid $strokeTwo;

  justify-content: center;
  align-items: center;
  margin: 24px 0;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.bottomContainer {
  display: flex;
  width: 100%;
  margin-top: 24px;
  flex-shrink: 0;
  justify-content: space-between;
}

.saveTemplateButton {
  display: flex;
  width: max-content;
  height: 40px;
  color: $blue29;
  cursor: pointer;
  font-size: 1.4rem;
  align-items: center;
  @include demi-bold-font;
}

.emptyText {
  @include regular-font;
  display: flex;
  color: #797979;
  font-size: 1.6rem;
  width: 100%;
}

.actionButton {
  display: none;
  margin-right: 4px;
  height: 100%;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 15;
}

.placeholderColor {
  &::placeholder {
    color: $textSubtle;
  }
}

.placeholderTextOnSubtle {
  &::placeholder {
    color: $textOnSubtle;
  }
}

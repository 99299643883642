@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
$borderColor: $strokeTwo;
.attachmentEditorContainer :global {
  .mce-tinymce.mce-container {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent;
  }
  .mce-edit-area {
    padding: 4px;
  }
  .mce-panel {
    border: none !important;
  }
  .mce-top-part {
    padding: 8px 16px !important;
  }
  .mce-top-part:before {
    box-shadow: none !important;
    border-bottom: 1px solid $borderColor !important;
  }
  .mce-toolbar .mce-ico {
    color: $gray48;
    font-size: 1.2rem;
  }
  display: flex;
  flex-direction: column;
  border: 1px solid $borderColor;
  border-radius: 4px;
  flex-shrink: 0;
}

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
}

.editorContainer {
  display: flex;
  margin-bottom: 16px;
}

.attachmentContainer {
  display: flex;
  padding: 0px 24px;
}

.attachmentViewContainer {
  display: flex;

  flex-direction: column;
}

.titleText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.8rem;
  color: $gray13;
  margin-bottom: 16px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.academicYearsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 48px;
}

.innerContainer {
  display: flex;
  flex-direction: column;
}

.academicYearContainerHeading {
  display: flex;
  color: $textDefault;
  line-height: 2.4rem;
}

.academicYearsHeader {
  display: flex;
  border-bottom: 1px solid $strokeTwo;
  margin-top: 32px;
  color: $textDefault;
  line-height: 2rem;
  padding-bottom: 16px;
}

.headerLabel {
  display: flex;
  width: 248px;
}

.academicYear {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $strokeTwo;
  padding: 4px 0 12px 0px;
  column-gap: 40px;
}

.yearLabel {
  display: flex;
  @include regular-font;
  font-size: 1.4rem;
}

.deleteIcon {
  display: flex;
  align-items: center;
}

.buttonContainer {
  display: flex;
  padding: 24px 16px 16px;
  width: 200px;
  column-gap: 16px;
  fill: $iconSubtle;
  align-items: center;
  height: 54px;
  @include demi-bold-font;
}

.addAcademicYearText {
  display: flex;
  column-gap: 12px;
  align-items: center;
  color: $blue29;
  font-size: 1.4rem;
  @include medium-font;
  padding-top: 12px;
  width: max-content;
  cursor: pointer;
}

.emptyContainer {
  display: flex;
  font-size: 1.4rem;
  color: $textSubtle;
  height: 64px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $strokeTwo;
}

.container {
  display: flex;
}
.selectContainer {
  grid-column: 1/3;
}

.modalContainer {
  display: flex;
  width: 640px !important;
  height: 600px !important;
  flex-direction: column;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.footerButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footerText {
  font-size: 1.4rem;
  @include medium-font;
  color: $gray31;
}

.searchContainer {
  position: absolute;
  width: 560px;
  height: auto;
  display: flex;
  flex-direction: column;
  top: 150%;
  animation-name: bottom-top;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.emptyContainer {
  height: 80px;
  background: rgba(43, 43, 43, 0.7);
}

.contentContainer {
  height: 600px;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  border-radius: 24px 24px 0px 0px;
  margin-top: -40px;
}

.headerContainer {
  padding: 0 40px;
}

.closeButton {
  display: flex;
  width: 24px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
  margin-top: 16px;
}

.headerText {
  display: flex;
  @include bold-font;
  font-size: 2.4rem;
  color: $gray13;
  margin-top: 12px;
}

.scrollContainer {
  padding: 0 40px;
  overflow-y: scroll;
  flex-grow: 1;
}

.topicRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topicText {
  margin: 32px 0px;
  @include bold-font;
  color: $gray13;
  font-size: 1.6rem;
}

.subTopicText {
  margin-bottom: 28px;
  @include medium-font;
  color: $gray31;
  font-size: 1.6rem;
}

.radioButton {
  margin-bottom: 28px;
}

.divider {
  height: 2px;
  width: 100%;
  background-color: $toddleBG1;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  align-items: center;
  box-shadow: -2px 0 8px 0 rgba($black, 0.1);
  background-color: $white;
  padding: 16px 40px;
}

@keyframes bottom-top {
  0% {
    top: 5%;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

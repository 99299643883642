@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.questionComp {
  //margin-bottom: 24px;
}

.error {
  display: flex;
  flex-grow: 0;
  font-size: 1.3rem;
  color: $error50;
  margin-top: 6px;
  height: 16px;
}

.loiContainer {
  display: flex;
  flex-direction: column;
}

.loiLabel {
  display: flex;
  font-size: 1.4rem;
  @include demi-bold-font;
  color: $gray13;
  margin-bottom: 4px;
}

.noLoiContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $strokeTwo;
  font-size: 1.5rem;
  min-height: 200px;
}

.noLoiText {
  display: flex;
  @include regular-font;
  color: $gray48;
  font-size: 1.2rem;
}

.inputListContainer {
  display: flex;
  flex-direction: column;
}

.dot {
  display: flex;
  margin-right: 8px;
  width: 4px;
  height: 4px;
  border: 1px solid $black;
  border-radius: 50%;
  background-color: $black;
  flex-shrink: 0;
}

.optionList {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.option {
  display: flex;
  margin-top: 6px;
  align-items: center;
}

.container {
  display: flex;
  flex: 1;
  background-color: transperant;
}

.containerCenter {
  composes: container;
  justify-content: center;
  align-items: center;
}

.containerBottomCenter {
  composes: container;
  justify-content: center;
  align-items: flex-end;
}

.loadingImg {
  width: 40px;
  height: 40px;
}

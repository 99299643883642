@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";

.container {
  display: flex;
  width: 100%;
  min-width: 944px;
  flex-direction: column;
  row-gap: 8px;

  .assessmentContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  }
}

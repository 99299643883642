@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  width: 100%;
  box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.08);
}

.innerContainer {
  display: flex;
  width: 100%;
  padding: 16px 128px;
  max-width: 728px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.rightButtonContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;
  align-items: center;
}

.cancelButtonText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $blue29;
  cursor: pointer;
  max-width: 244px;
  @include multiLineEllipsis(1.6rem, 1);
  &:hover {
    color: $blueActive;
  }
}

.discardButton {
  composes: cancelButtonText;
  color: $gray48;

  &:hover {
    color: $black;
  }
}

.updateContainer {
  display: flex;
  justify-content: space-between;
}

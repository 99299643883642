@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.searchContainer {
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  border: 1px solid $strokeOne;
  border-radius: 4px;
  padding: 0 16px 16px;
}

.searchContainerContacts {
  border-radius: 2px;
  border: 1px solid $strokeTwo;
  padding: 12px 16px;
  margin-bottom: 24px;
  flex: 0 0 auto;
}

.resultContainer {
  overflow-y: auto;
  width: 100%;
}

.gridResultContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 292px);
  grid-template-rows: min-content;
  gap: 16px;
  justify-content: center;
  flex: 1 1 auto;
  &::-webkit-scrollbar {
    width: 0px;
    display: none;
  }
  composes: resultContainer;
}

.listResultContainer {
  display: flex;
  flex-direction: column;
  composes: resultContainer;
}

.buttonDropdown {
  overflow-y: initial;
  height: 100%;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  // margin-bottom: 80px;
}

.container .settingRowContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0;
  border-bottom: 1px solid $strokeOne;
}

.container .settingRowContainerSubmodule {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 16px;
  border-bottom: 1px solid $gray31;
}

.container .title {
  color: $gray31;
  font-size: 1.6rem;
  flex: 1;
}

.container .titleSubmodule {
  font-size: 1.8rem;
  @include bold-font;
  flex: 1;
}

.container .submoduleNotification {
  font-size: 1.6rem;
  @include bold-font;
  flex: 1;
}

.container .rightContainer {
  display: flex;
}

.container .platformType {
  min-width: 124px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray48;
  font-size: 1.6rem;
  margin-left: 48px;
}

.container .platformType:first-child {
  margin-left: 0;
}

.title,
.platformType {
  @include medium-font;
}

.columnTypeChooseCourses {
  width: 410px;
  justify-content: space-between !important;
}

.columnNameChooseCourses {
  width: 144px;
  display: flex;
  justify-content: center;
}

.switchStyle{
  width: 144px;
  display: flex;
  justify-content: flex-end;
}

.chooseCoursesColumnTitle {
  width: 218px;
}

.columnSystemNotificationType {
  min-width: 144px !important;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.header {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid $strokeOne;
  padding: 16px 24px;
  align-items: flex-end;

  .headerControls {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

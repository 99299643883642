@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  width: 600px !important;
  height: 695px !important;
  position: relative;
}

.container {
  padding: 40px 48px;
  padding-bottom: 72px;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  border-top: 1px solid $borderDefault;
  padding-left: 48px;
  padding-right: 48px;
  background: $white;
}

.footerWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.confirm {
  margin-left: 16px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.commentContainer {
  display: flex;
  flex-direction: row;
  background: white;
  border: solid 1px #dbdbdb;
  padding: 16px;
  margin-top: 4px;
}

.profileImage {
  display: flex;
  margin-right: 12px;
  align-self: flex-start;
}

.listContainer {
  //   border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
}

.listItemContainer {
  display: flex;
  padding: 8px 16px 8px 16px;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f9f9fb;
  }
}

.inputText {
  width: 100%;
  min-height: 32px;
  //   max-height: 48px;
  box-sizing: content-box;
}

.inputText[placeholder]:empty:before {
  content: attr(placeholder);
  color: rgb(161, 157, 157);
}

.inputText[placeholder]:empty:focus:before {
  content: "";
}

.inputText:focus {
  outline: 0px solid transparent;
}

.inputTag {
  @include demi-bold-font;
}

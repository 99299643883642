@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UnitPlans/components/LibraryCommonComponents/common.scss";
@import "~UIComponents/CommonStyles/mixins";
@import "../../../common.scss";

$maxWidth: 600px;

.container {
  padding: 56px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  height: max-content;
  flex: auto;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

.backButton {
  position: relative;
  top: -16px;
  left: 0;
  width: max-content;
  .wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: max-content;
    span {
      font-size: 1.6rem;
      color: $salmon60;
      @include demi-bold-font;
      margin-left: 12px;
    }
  }
}

.innerContainer {
  display: flex;
  width: 100%;
  height: max-content;
  margin: 0 auto;
  flex-direction: column;
  padding: 0 32px;
  max-width: 1304px;
  flex: 0 0 auto;
}

.innerContainerRevamp {
  padding: 0;
  max-width: 1008px;
}

.stepList {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.stepContainer {
  width: 100%;
  padding-top: 72px;
  margin-top: 72px;
  border-top: 1px solid $strokeOne;
  composes: assessmentGridLayout;
}

.stepContainerRevamp {
  display: flex;
  padding-top: 56px;
  margin-top: 56px;
  border-top: 1px solid $borderSubtle;
}

.commentContainer {
  composes: innerContainer;
}

.stepLabel {
  @include bold-font;
  font-size: 2.8rem;
  color: $gray31;
}

.stepLabelRevamp {
  color: $textDefault;
  font-size: 1.8rem;
}

.item {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
  width: 100%;
  max-width: 720px;
}

.stepSubText {
  display: flex;
  margin-top: 8px;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray48;
}

.contentWrapper {
  margin-top: 8px;
  width: 100%;
}

.contentWrapperRevamp {
  margin-top: 0;
}

.rubricContainer {
  display: flex;
  position: absolute;
  width: calc(100% - 96px);
  left: 48px;
  margin-top: 96px;
}

.checkListContainer {
  display: flex;
  // width: 960px;
  position: absolute;
  // margin-left: -180px;
  width: calc(100% - 192px);
  left: 96px;
  margin-top: 96px;
}

.print {
  line-height: 0;
  cursor: pointer;
  fill: $gray72;
  &:hover {
    fill: $black;
  }
  margin-left: 16px;
  margin-top: 8px;
}

.imageContainer {
  position: absolute;
  top: 40px;
  right: 120px;
  width: 256px;
  height: 192px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  cursor: pointer;
  @include mediaQuery("medium-device") {
    right: 24px;
    width: 140px;
    height: 104px;
  }
}

.tagsContainer {
  display: flex;
  align-items: center;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
}

.fieldsContainerRevamp {
  width: 100%;
  max-width: 696px;
}

.stepLabelContainer {
  width: 100%;
}

.stepLeftContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.stepLeftContainerRevamp {
  max-width: 200px;
}

@include mediaQuery("mobile") {
  .container {
    padding: 0;
  }
  .backButton {
    display: none;
  }
  .innerContainer {
    padding: 0;
  }
  .stepList {
    padding: 0 32px;
  }
  .stepContainer {
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 40px;
    padding-bottom: 40px;
    border: none;
    border-bottom: 1px solid $strokeOne;
  }
  .stepLabel {
    @include bold-font;
    font-size: 1.8rem;
    color: $gray31;
  }
}

.assessmentToolTypeContainer {
  display: flex;
}

.assessmentToolTypeValue {
  margin-left: 8px;
  @include demi-bold-font;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0 32px 0 24px;
  border-bottom: 1px solid $strokeOne;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}

.leftContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;
  align-items: center;
  cursor: pointer;
  margin-right: 16px;
}

.middleContainer {
  display: flex;
  align-items: center;
  flex: 1;
}

.rightContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;
}

.print {
  display: flex;
  margin-right: 8px;
  cursor: pointer;
  & svg {
    fill: $gray72;
  }
  &:hover {
    & svg {
      fill: $black;
    }
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 24px;
  flex-direction: column;
}

.editContainer {
  background-color: rgba($yellow50, 0.1);
}

.evidenceFeed {
  display: flex;
  margin-top: 24px;
  width: 100%;
  height: 100%;
}

.headerContainer {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  flex-direction: column;
}

.rightContainer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-left: 12px;
  flex: 1;
}

.moreContainer {
  display: none;

  @include mediaQuery("touchable") {
    display: flex;
    visibility: visible;
  }
}

.visible {
  display: flex !important;
  opacity: 1;
  transition: opacity 0.3s;
  visibility: visible !important;
}

.hidden {
  display: none !important;
  transition: opacity 0.3s;
  visibility: hidden !important;
}

.userInfo {
  display: flex;
  flex-direction: row;
}

.nameClassContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 12px;
}

.nameText {
  display: flex;
  @include medium-font;
  color: $gray13;
  font-size: 1.4rem;
}

.subtext {
  display: flex;
  @include regular-font;
  color: $gray48;
  font-size: 1.2rem;
}

.messageText {
  display: flex;
  @include regular-font;
  color: $gray31;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 0;
  border: 0px;
  resize: none;
  outline: none;
  background-color: $white;
  overflow: hidden;
}

.editMessageText {
  padding: 16px;
  border: 1px solid $yellow50;
  border-radius: 4px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 16px;
}

.buttonsContainerWithoutEvidence {
  composes: buttonsContainer;
  justify-content: flex-end;
}

.rightButtons {
  display: flex;
  align-items: center;
}

.addEvidenceText {
  @include medium-font;
  color: $blue29;
  margin: 0 12px;
  font-size: 1.2rem;
}

.cancelButton {
  display: flex;
  color: $gray48;
  &:hover {
    color: $gray31;
  }
  font-size: 1.2rem;
  @include medium-font;
  margin-right: 24px;
  cursor: pointer;
}

.container:hover .moreContainer {
  display: flex;
  transition: opacity 0.3s;
  visibility: visible;
}

.iconContainer {
  display: flex;
  column-gap: 20px;
}

.headerIcon {
  cursor: pointer;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  width: 620px !important;
  height: 760px !important;
}

.editStep {
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  min-height: 0;
}

.modalBody {
  padding-bottom: 40px;
  overflow-y: auto;
  flex-grow: 1;
  min-height: 0;
  height: 620px;
}

.modalHeader {
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  flex-shrink: 0;
  font-size: 2.4rem;
  padding: 0px 48px;
  @include bold-font;
  margin-bottom: 40px;
}

.modalFooter {
  display: flex;
  height: 64px;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 0px 40px;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: $white;
}

.footerButton {
  display: flex;
  width: $model-footer-button-width;
  margin-right: $model-footer-button-margin-right;
  flex-shrink: 0;
}

.lastButton {
  margin-left: 24px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

$borderColor: $strokeTwo;

.container {
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  * {
    unicode-bidi: plaintext;
  }
}

.inputText {
  width: 100%;
  resize: none;
  outline: none;
  white-space: pre-wrap;
  background-color: inherit;
  word-break: break-word;
  @include border-class;
  padding: 16px 24px;
}

.viewInputText {
  composes: inputText;
  border: none;
}
.subText {
  margin: 8px 0;
  color: $gray48;
  font-size: 1.3rem;
  @include regular-font;
}

.inputTextDisable {
  composes: inputText;
  font-size: 1.6rem;
  @include medium-font;
  background-color: $surfaceDisabled;
  color: $textDefault;
  border: none;
}

.error {
  display: flex;
  background-color: rgba($yellow50, 0.1);
  border-color: $yellow50;
}

.inputLength {
  display: flex;
  flex-grow: 0;
  font-size: 1.4rem;
  margin-top: 6px;
  height: 16px;
  align-self: flex-end;
}

.containerClass {
  position: relative;
}

.errorClass {
  position: absolute;
  margin-top: 4px;
}

@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";

.mainContainer {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  background-color: #f9f9fb;
  overflow-y: auto;
  height: 100%;
}
.headerText {
  margin-top: 40px;
  margin-bottom: 32px;
  margin-left: 64px;
  font-size: 24px;
  @include bold-font;
  color: rgb(72, 72, 72);
}

.feedList {
  display: flex;
  flex-shrink: auto;
  padding: 64px;
  margin: 0 auto;
  max-width: 1100px;
  width: 100%;
  flex-direction: column;
}
.orgList {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  padding: 0 64px;
  overflow-y: scroll;
  align-content: start;
}
.orgItemContainer {
  border: 1px solid #ebebeb;
  background-color: white;
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
    border-color: #f1f1f1;
  }
}
.orgItemName {
  color: #484848;
  font-size: 14px;
  @include demi-bold-font;
}
.orgAdminName {
  color: #797979;
  font-size: 12px;
  margin-top: 4px;
}

.countText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $gray31;
  flex-shrink: 0;
  margin-bottom: 24px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  margin-top: 24px;
  width: 100%;
  height: 100%;
}
.centeredContainer {
  display: flex;
  height: 100%;
  width: 100%;
  //max-width: 1200px;
  flex-wrap: wrap;
  margin: 0 auto;
}

.addNewContainer {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin: 0px 16px 16px 0px;
  width: 288px;
  height: 197px;
  flex-shrink: 0;
  position: relative;
  border: 1px dashed $strokeTwo;
  &:hover {
    flex-direction: row;
    flex-wrap: wrap;
    .innerContainer {
      display: none;
    }
    .hoverContainer {
      display: flex;
    }
    .hoverItemContainer {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 50%;
      justify-content: center;
      align-items: center;
      border: 1px solid $strokeOne;
      &:hover {
        background-color: #fafafa;
        .hoverItemText {
          color: $gray31;
        }
      }
      &:active {
        background-color: #fafafa;
        .hoverItemText {
          color: $black;
        }
      }
    }
  }
}

.addMediaText {
  font-size: 12px;
  @include medium-font;
  color: $gray48;
  margin-top: 8px;
}

.innerContainer {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 16px;
}

.hoverContainer {
  display: none;
}

.hoverItemContainer {
  display: none;
}

.hoverItemText {
  font-size: 14px;
  color: $gray48;
  @include medium-font;
  margin-top: 4px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  position: relative;
  flex: 1;
  padding: 20px 20px 20px 16px;
}
.alertMessage {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  position: relative;
}

.closeButton {
  margin-left: 24px;
}

.alertText {
  flex-grow: 1;
  text-wrap: wrap;
  font-size: 14px;
  line-height: 20px;
  color: $textDefault;
  flex: 1;
  a {
    color: $blue29;
  }
  &:hover {
    a {
      text-decoration: underline;
    }
  }
}

.darkText {
  color: $white;
}

.lightText {
  color: $black;
}

.Svg {
  display: flex;
  margin-right: 20px;
}

.toastr :global,
.toastr_center :global {
  #toast-container > .toast-success {
    box-shadow: 0px 7px 14px rgba(0, 46, 57, 0.15);
    animation-duration: 0.5s;
    background-color: $white;
    padding: 0px;
    opacity: 1;
    border-radius: 4px;
  }
  #toast-container > div {
    width: 424px;
  }
  button.toast-close-button {
    display: none;
  }
}

.dark_bg {
  background-color: $black;
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  padding-left: 24px;
}

.multiItemContainer {
  padding-left: 0px;
  padding-top: 10px;
}

.innerContainer {
  display: flex;
  flex: 1;
}

.innerItemContainer {
  flex-direction: column;
}

.buttonInnerContainer {
  display: flex;
  align-items: center;
  height: 20px;
}

.textDot {
  @include dot($width: 4px, $height: 4px, $bgColor: $iconSuccess);
  margin: 0px 8px;
}

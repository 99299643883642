@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.noteLinesContainer {
  display: flex;
  min-height: 270px;
  width: 100%;
  padding-left: 20px;
  position: relative;
  background: white;
  background-image: repeating-linear-gradient(
    $yellow96 0px,
    $yellow96 27px,
    $strokeOne 28px
  );
  -webkit-background-size: 100% 2.8rem;
  -moz-background-size: 100% 2.8rem;
  -ms-background-size: 100% 2.8rem;
  background-size: 100% 2.8rem;
}

.noteContainer {
  display: flex;
  width: 100%;
  border-left: 2px solid $yellow50;
  padding-left: 16px;
  padding-right:16px;
  padding-top: 4px;
  font-size: 1.4rem;
  @include medium-font;
  overflow: hidden;
  white-space: pre-wrap;
  line-height: 2.8rem;
}

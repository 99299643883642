@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/dimensions";

.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  cursor: default;
}
.modalContent {
  display: flex;
  flex-grow: 0;
  margin: auto;
  background-color: white;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  @include modal-content;
  border-radius: 4px;
}

.modalHeader {
  display: flex;
  color: $gray31;
  @include medium-font;
  font-size: 2rem;
  padding: 24px;
  justify-content: center;
  align-items: center;
}

.backButton {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.addButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  font-size: 1.4rem;
  color: $blue29;
}

.modalBody {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 1.5rem;
  color: $gray31;
  padding: 0 32px 24px 32px;
  overflow-y: scroll;
  height: 100%;
}

.modalFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  flex-shrink: 0;
  border-top: 1px solid $gray72;
}

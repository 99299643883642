@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 120px;
  margin-top: 8px;
  overflow-y: auto;
  flex-direction: column;
  border-radius: 4px;
  z-index: 11;
  box-shadow: 0 4px 12px 0px rgba(0, 0, 0, 0.16);
}

.innerContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 8px 24px;
}

.itemContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 12px 0;
  &:not(:last-child) {
    border-bottom: 1px solid $strokeTwo;
  }
  &:hover {
    background-color: $toddleBG1;
  }
}

.noData {
  display: flex;
  color: $gray48;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.6rem;
  flex: 1;
}

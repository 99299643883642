@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.modalContainer{
   max-width: 480px;
   max-height: 586px;
   min-height: 208px;
}
.privateModalContainer{
    max-height: 208px !important;
}
.modalBodyContainer{
    padding:24px;
}
.modalHeaderContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.shareWithTitle{
    @include bold-font;
    font-size: 2rem;
    color:$textDefault;
}
.taggingTypeContainer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.tagIcon{
    padding-top: 4px;
}
.tagMessage{
    max-width: 360px;
    @include medium-font;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $textSubtle;  
}
.taggedListContainer{
    padding-top: 24px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 450px;
    padding-bottom: 24px;
}
.taggedStudentContainer{
    display: flex;
    width: 432px;
    max-width: 432px;
    height: 52px;
    padding-bottom:8px;
    align-items: center;

    &:not(:first-child){
        padding-top:8px;
    }
    
    &:not(:last-child) {
        border-bottom: 1px solid $borderSubtle;
    }
}
.taggedStudentNameText{
    @include medium-font;
    font-size: 1.6rem;
    color:$textDefault;
    text-transform: capitalize;
    padding-left: 16px;
    padding-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}
.privatePostMessage{
    padding-top: 16px;
    @include medium-font;
    font-size: 1.6rem;
    color:$textDefault;
}
@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mainContainer {
  // padding-top:20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
}
.loadingView {
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: white;
}

.fullScreenLoaderContainer {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 11;

  align-items: center;
  justify-content: center;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  width: 480px !important;

  height: unset !important;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  padding: 24px;
}

.header {
  font-size: 2.2rem;
  @include demi-bold-font;
  color: $gray31;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 64px;
  flex-shrink: 0;
  align-items: center;
  padding: 0px 24px 40px;
  // border-top: 1px solid $gray72;
}

.modalBody {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 1.5rem;
  color: $gray31;
  padding: 0 24px 48px 24px;
  overflow-y: auto;
  height: 100%;
}

.cancelIcon {
  cursor: pointer;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
  padding: 40px 64px;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1040px;

  margin: 0 auto;
}

.header {
  display: flex;
  margin: 32px 0;
  justify-content: space-between;
  align-items: center;
}

.innerHeader {
  display: flex;
  align-items: center;
}

.leftHeader {
  display: flex;
  width: 300px;
}

.rightHeader {
  font-size: 1.4rem;
  margin-left: 20px;
  @include medium-font();
}

.loadingContainer {
  width: 100%;
  margin-top: 24px;
}

.option {
  display: flex;
  width: 100%;
}

.filtersContainer {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-end;
  grid-column-gap: 40px;
}

.filterandlabelcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filterBox {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  color: $gray48;
  fill: $gray48;
  &:hover {
    color: $gray31;
    fill: $gray31;
  }
}

.filterLabelText {
  display: flex;
  @include medium-font;
  // color: $gray31;
  font-size: 1.4rem;
}

.filterText {
  display: flex;
  @include bold-font();
  font-size: 1.4rem;
  // color: $gray31;
  @include multiLineEllipsis(1.4rem, 1);
  margin-right: 8px;
  text-align: right;
}

.bottomArrow {
  height: 24px;
  width: 16px;
  text-align: right;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  font-size: 1.2rem;
  color: $black;
  @include medium-font;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tooltipSvgContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.svgContainer {
  fill: $gray48;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  width: 100%;
  height: 72px;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 12px 48px 12px 24px;
  border-bottom: 1px solid $strokeOne;
}

.leftContainer {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.rightContainer {
  display: flex;
}

.logo {
  display: flex;
  height: 100%;
  margin-right: 16px;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
}

.details {
  display: flex;
  flex-shrink: 0;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  display: flex;
  font-size: 1.8rem;
  line-height: 1.44;
  color: $gray13;
  @include bold-font;
  @include multiLineEllipsis(2.4rem, $maxCharacter: 54);
}

.chevronIconContainer {
  display: flex;
  margin-right: 8px;
}

.assignmentImage {
  display: flex;
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  background-color: $toddleBG1;
  background-size: cover;
  border-radius: 8px;
}

.subTitle {
  @include regular-font;
  font-size: 12px;
  color: $textDefault;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

$borderColor: $strokeTwo;
:global {
  .mce-tinymce.mce-tinymce-inline.mce-arrow.mce-container.mce-panel.mce-floatpanel.mce-arrow-up {
    z-index: 1000 !important;
  }

  .mce-floatpanel .mce-i-unlink::before {
    content: url("./Icons/UnLink.svg");
  }

  .mce-floatpanel .mce-btn.mce-active {
    background: $surfaceActive !important;
  }

  .mce-floatpanel .mce-i-newtab::before {
    content: url("./Icons/NewTab.svg");
  }

  .mce-floatpanel .mce-i-link::before {
    content: url("./Icons/LinkEdit.svg");
  }

  .mce-floatpanel .mce-toolbar .mce-ico {
    color: $gray48;
    vertical-align: middle;
    text-align: center;
  }
  .mce-floatpanel .mce-i-remove::before {
    content: url("./Icons/Remove.svg");
  }

  .mce-tooltip {
    opacity: 0.9 !important;
    filter: alpha(opacity=90);
  }

  .mce-tooltip-inner {
    font-size: 10px !important;
    background-color: #373737 !important;
    padding: 10px !important;
    border-radius: 4px !important;
  }

  .mce-tooltip-arrow {
    border-color: #373737 !important;
  }

  .mce-tooltip-arrow-n {
    border-bottom-color: #373737 !important;
  }

  .mce-tooltip-arrow-s {
    border-top-color: #373737 !important;
  }

  .mce-tooltip-arrow-e {
    border-left-color: #373737 !important;
  }

  .mce-tooltip-arrow-w {
    border-right-color: #373737 !important;
  }

  .mce-tooltip-n .mce-tooltip-arrow {
    border-left-color: transparent !important;
    border-right-color: transparent !important;
  }

  .mce-tooltip-nw .mce-tooltip-arrow {
    border-left-color: transparent !important;
    border-right-color: transparent !important;
  }

  .mce-tooltip-ne .mce-tooltip-arrow {
    border-left-color: transparent !important;
    border-right-color: transparent !important;
  }

  .mce-tooltip-s .mce-tooltip-arrow {
    border-left-color: transparent !important;
    border-right-color: transparent !important;
  }

  .mce-tooltip-se .mce-tooltip-arrow {
    border-left-color: transparent !important;
    border-right-color: transparent !important;
  }

  .mce-tooltip-sw .mce-tooltip-arrow {
    border-left-color: transparent !important;
    border-right-color: transparent !important;
  }

  .mce-tooltip-w .mce-tooltip-arrow {
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
  }

  .mce-tooltip-e .mce-tooltip-arrow {
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
  }

  .mce-btn {
    background-color: transparent !important;
    border: none !important;
    border-radius: 4px !important;
  }

  .mce-btn:hover {
    background-color: $surfaceLightHover !important;
  }

  .mce-btn.mc {
    background: $surfaceActive !important;
  }

  .mce-floatpanel {
    border-radius: 4px !important;
    padding: 4px !important;
    box-shadow: 0px 5px 10px rgba(0, 46, 57, 0.15) !important;
  }
}
.container :global {
  .mce-content-body {
    font-family: "AvenirNextLTW01-Regular", Arial, serif, sans-serif, system-ui,
      "Avenir Next LT W01 Demi", "AvenirNextLTW01-UltraLi",
      "Avenir Next W01 Thin", "AvenirNextLTW01-Medium",
      "Avenir Next LT W01 Bold", "Avenir Next W01 Light",
      "AvenirNextLTW01-DemiCn", "AvenirNextLTW01-MediumC_721311",
      "AvenirNextLTW01-BoldCn" !important;
  }

  .mce-tinymce.mce-container {
    border: 1px solid $borderSubtle !important;
    box-shadow: none !important;
    background-color: transparent;
    box-sizing: border-box;
    overflow: hidden;
  }

  .mce-panel {
    border: none !important;
    border-radius: 8px !important;
    background-color: transparent;
  }

  .mce-btn {
    background-color: transparent;
    border: none !important;
  }

  .mce-btn:hover {
    background-color: $surfaceLightHover !important;
  }

  .mce-top-part {
    display: flex !important;
    align-items: center;
    min-height: 48px;
    padding: 8px 0 !important;
    box-sizing: border-box;
  }

  .mce-btn.mce-active .mce-caret,
  .mce-btn.mce-active:hover .mce-caret {
    border-top-color: $surfaceDark !important;
  }

  .mce-btn button,
  .mce-btn-small button,
  .mce-toolbar .mce-btn-group {
    padding: 0 !important;
  }

  .mce-btn .mce-caret,
  .mce-btn-small .mce-caret {
    margin: 0 !important;
  }

  .mce-btn-group .mce-btn {
    margin: 0 0 0 8px !important;
    border-radius: 4px;
    height: 32px !important;
    width: 32px !important;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .mce-btn-group:not(:first-child) {
    border-left: none !important;
    padding: 0 !important;
  }

  .mce-btn-group:not(:first-child):before {
    content: "";
    height: 24px;
    width: 0;
    border-left: 1px solid $surfaceActive;
  }

  .mce-btn-group {
    margin: 0 8px 0 0 !important;
    display: inline-flex !important;
    align-items: center;
  }

  .mce-menubtn {
    width: 52px !important;
  }

  .mce-menubtn button {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }

  .mce-top-part:before {
    box-shadow: none !important;
    border-bottom: 1px solid $borderSubtle !important;
  }

  .mce-menubar .mce-menubtn:hover,
  .mce-menubar .mce-menubtn.mce-active,
  .mce-menubar .mce-menubtn:focus {
    border-color: #595959 !important;
  }

  div.mce-menubtn.mce-opened {
    border-bottom-color: none !important;
  }

  .mce-menubtn .mce-caret {
    margin-top: 0 !important;
  }

  .mce-menu-item {
    display: inline-flex !important;
    padding: 0 !important;
    align-items: center;
    justify-content: center;
    margin: 8px 4px !important;
    height: 24px;
    width: 24px;
    border-radius: 4px !important;
    color: #595959 !important;
    position: relative;
  }

  .mce-menu-item .mce-ico {
    padding: 0 !important;
    height: 10px !important;
    width: 13px !important;
    position: absolute;
  }

  .mce-menu-item.mce-selected {
    background: #595959 !important;
  }

  .mce-menu {
    top: 8px !important;
    border: none !important;
    border-radius: 4px !important;
    margin: none !important;
    padding: 0 !important;
    min-width: none !important;
    box-shadow: 0px 5px 10px rgba(0, 46, 57, 0.15) !important;
  }

  .mce-menu .mce-stack-layout {
    display: flex !important;
    align-items: center;
    height: 40px !important;
    padding: 0 4px !important;
  }

  .mce-toolbar .mce-ico {
    color: $gray48;
    font-size: 0.8rem;
    vertical-align: middle;
    text-align: center;
  }
  .mce-btn.mce-active {
    background: $surfaceActive !important;
  }

  .mce-i-bullist::before {
    content: url("./Icons/BulList.svg");
  }

  .mce-i-numlist::before {
    content: url("./Icons/NumList.svg");
  }

  .mce-btn-small .mce-ico {
    background-size: auto !important;
    background-repeat: no-repeat !important;
  }
  .mce-i-bold::before {
    content: url("./Icons/Bold.svg");
  }
  .mce-i-italic::before {
    content: url("./Icons/Italics.svg");
  }
  .mce-i-underline::before {
    content: url("./Icons/UnderLine.svg");
  }
  .mce-i-alignleft::before {
    content: url("./Icons/AlignLeft.svg");
  }

  .mce-i-aligncenter::before {
    content: url("./Icons/AlignCenter.svg");
  }

  .mce-i-alignright::before {
    content: url("./Icons/AlignRight.svg");
  }

  .mce-i-alignjustify::before {
    content: url("./Icons/AlignJustify.svg");
  }

  .mce-i-link::before {
    content: url("./Icons/Link.svg");
  }

  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
}

.error {
  display: flex;
  flex-grow: 0;
  font-size: 1.3rem;
  color: $error50;
  margin-top: 6px;
  height: 16px;
}

.container {
  position: relative;
}

.disabledContainer {
  background-color: $surfaceSubtle;
  cursor: not-allowed;
  border-radius: 4px;
}

.emojiTarget {
  position: absolute;
  z-index: 99;
  height: 0;
  width: 0;
}

.lockContainer {
  display: flex;
  flex: 1;
  background-color: $surfaceLight;
  border: 1px solid $borderSubtle;
  padding: 16px;
}

.lockContainerText {
  display: flex;
  @include medium-font;
  font-size: 1.6rem;
  color: $gray31;
  flex-direction: column;
  white-space: pre-wrap;
  word-break: break-word;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.innerContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.modalContent {
  width: 67vw !important;
  height: 90vh !important;
}

.leftContainer {
  display: flex;
  flex-basis: 23%;
  background-color: #fafafa;
  border-radius: 4px 4px 0px 4px;
  border-right: 1px solid $gray72;
}
.rightContainer {
  display: flex;
  flex-basis: 77%;
  flex-grow: 1;
  flex-direction: column;
}
.searchContainer {
  display: flex;
  width: 100%;
  flex-basis: 15%;
  align-items: center;
  padding: 0 32px;
}
.resourceContainer {
  display: flex;
  overflow: hidden;
  width: 100%;
  flex-grow: 1;
  flex-basis: 75%;
}

.rightFooterContainer {
  display: flex;
  bottom: 0;
  padding: 16px 32px;
  width: 100%;
  border-top: 1px solid #cdcdd2;
  background-color: $white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 5px 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.loadingContainer {
  display: flex;
  width: 100%;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  max-width: 560px;
  height: 56px;
  border: 1px solid $strokeTwo;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  &:hover {
    border: 1px solid $gray72;
    cursor: pointer;
  }
}

.innerContainer {
  display: flex;
  width: 224px;
}

.iconContainer {
  display: flex;
  margin-right: 16px;
}

.buttonText {
  font-size: 1.6rem;
  color: $gray13;
  @include medium-font;
  line-height: 1.36;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;

  @media (min-width: 1550px) {
    margin-top: 76px !important;
  }
}

.headerButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  @include mediaQuery("mobile") {
    margin-bottom: 32px;
  }
}

.haveAccountText {
  font-size: 1.6rem;
  color: $gray31;
  @include medium-font;
}

.signInButton {
  @include medium-font;
  color: $blue29;
  text-decoration: underline;
  font-size: 1.6rem;
  margin-left: 4px;
  cursor: pointer;
}

.emailContainer {
  display: flex;
  margin-top: 32px;
  margin-bottom: 16px;
}

.inputRowContainer {
  display: grid;
  grid-column-gap: 32px;
  grid-auto-flow: column;
  margin-bottom: 16px;
}

.passwordContainer {
  display: flex;
  margin-bottom: 16px;
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.resourceContentContainer {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, 216px);
  justify-content: center;
  grid-row-gap: 12px;
  grid-column-gap: 12px;
  overflow-y: scroll;
  padding: 0 24px;
  width: 100%;
}

.loadingContainer {
  display: flex;
  width: 100%;
  min-height: 50px;
}

.noResourceContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  font-size: 18px;
  min-height: 150px;
}

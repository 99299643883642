@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  width: 600px !important;
  height: 624px !important;
}

.overlayContainer {
  z-index: 2000 !important;
}

.modalContentCalendarEvent {
  display: flex;
  width: 560px;
  height: unset;
  min-height: 280px;
  // max-height: 780px;
}

.modalOverlayCalendarEvent {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #00000070;
  z-index: 15;
}

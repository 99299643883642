@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 700px;
  height: 640px;
  flex-grow: 0;
  margin: auto;
  background-color: white;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 4px;
  position: relative;
}

.progressBarContainer {
  // cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
}

.footer {
  padding: 12px 40px;
  display: flex;
  justify-content: flex-end;
  height: 64px;
  box-shadow: 5px 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.unitSelectionWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.resourceFeedHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 40px 56px;
}

.resourceFeedHeaderText {
  @include bold-font;
  font-size: 24px;
  color: $black;
}

.cancelIcon {
  cursor: pointer;
  fill: $gray48;
  z-index: 99;
  &:hover {
    fill: $gray31;
  }
}

.scrollContainer {
  display: flex;
  flex: 1;
  overflow-y: auto;
  padding: 0 56px;
}

.modalContent {
  width: 700px !important;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  flex-direction: column;
  padding-bottom: 40px;
}

.footerButtonContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
}

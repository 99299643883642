@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.addEvidenceModalCon {
  width: 87vw !important;
  height: 90vh !important;
}

.addEvidenceOverlayCon {
  background-color: rgba(0, 0, 0, 0.4);
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  width: 640px !important;
  height: 530px !important;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.cancelButton {
  display: flex;
  cursor: pointer;
  position: absolute;
  top: -24px;
  right: -24px;
}

.headerContainer {
  padding: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.header {
  display: flex;
  @include bold-font;
  color: $gray13;
  font-size: 2.2rem;
  margin-bottom: 8px;
}

.likesCount {
  display: flex;
  align-items: center;
  span {
    @include demi-bold-font;
    font-size: 1.6rem;
    color: $salmon60;
    margin-left: 8px;
  }
}

.profileContainer {
  width: 72px;
  height: 60px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  flex-shrink: 0;
  margin-left: 16px;
}

.body {
  padding: 0 40px;
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid $strokeOne;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
  }
}

.leftContainer {
  display: flex;
  align-items: center;
}

.userProfile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.userInfo {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.userName {
  span {
    color: $bar;
    font-size: 1.6rem;
    @include bold-font;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      text-decoration: underline;
    }
  }
}

.userSchool {
  color: $gray48;
  @include medium-font;
  font-size: 1.2rem;
}

.loadingContainer {
  width: 100%;
  margin: 12px 0;
  display: flex;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  width: 100%;
  height: 48px;
  flex-direction: row;
}

.step {
  display: flex;
  flex: 1;
  border-bottom: 4px solid $strokeOne;
  padding: 12px 20px;
  font-size: 1.4rem;
  color: $gray31;
  cursor: pointer;
  &:not(:last-child) {
    border-right: 1px solid $strokeOne;
  }
}

.activeStep {
  border-color: $black;
}

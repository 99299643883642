@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.gridRow {
  display: grid;
  width: 100%;
  border-bottom: 1px solid $borderDefault;
}

.tableHeader {
  composes: gridRow;
  margin-top: 32px;
  padding-bottom: 16px;
}

.headerLabelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableRow {
  composes: gridRow;
  padding: 16px 0;
}

.tableBody {
  display: flex;
  flex-direction: column;
  & > div:last-child {
    padding-bottom: 40px;
  }
}

.rowLabelContainer {
  display: flex;
  flex-direction: column;
}

.rowSubtext {
  color: $textSubtle;
}

.previewContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-height: 350px;
  margin-top: 8px;
}

.expandIconContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 1;
  cursor: pointer;
}

.expandIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  display: flex;
  padding: 8px;
}

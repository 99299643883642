@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  height: 100%;
  width: 356px;
}

.viewContainer {
  padding: 48px 0 48px 48px;
}

.scrollContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: auto;
  padding: 40px 0px;
  border-radius: 8px;
  background-color: $white;
}

.scrollContainerView {
  border: 1px solid $strokeOne;
}

.headerText {
  display: flex;
  font-size: 1.4rem;
  color: $gray72;
  @include bold-font;
  padding: 0px 24px;
  margin-bottom: 24px;
  text-transform: uppercase;
}

.outLineItem {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  height: 56px;
  align-items: center;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  align-items: center;
}

.leftDiv {
  display: flex;
  width: 6px;
  height: 100%;
}

.leftDivSelected {
  border-left: 6px solid $salmon60;
}

.outLineSelected {
  color: $salmon60;
  background-color: rgba($salmon60, 0.04);
  @include demi-bold-font;
}

.labelText {
  font-size: 1.4rem;
  @include multiLineEllipsis(4rem, 1);
  padding-right: 24px;
  //display: block;
}

.depthText {
  font-size: 1.6rem;
  @include medium-font;
  color: $gray13;
  padding-left: 24px;
}

.selectLabel {
  color: $salmon60;
}

.countContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $salmon60;
  color: $white;
  font-size: 1rem;
  @include medium-font;
  margin: 0 24px 0 8px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  padding: 24px 12px;
  &:not(:last-child) {
    border-bottom: 1px solid $strokeOne;
  }
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
}

.disabledContainer {
  background-color: $strokeOne;
  &:not(:last-child) {
    border-bottom: 1px solid $strokeTwo;
  }
}

.leftContainer {
  display: flex;
  flex: 1;
}

.rightContainer {
  display: flex;
  width: 80px;
  justify-content: center;
}

.nameText {
  display: flex;
  @include medium-font;
  color: $gray13;
}

.classText {
  display: flex;
  @include regular-font;
  color: $gray48;
}

.permissionLabel {
  display: flex;
  @include regular-font;
  color: $gray72;
}

.cursorPointer {
  cursor: pointer;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: row;
}
.cascadeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.countCon {
  position: absolute;
  z-index: 10;
  top: -4px;
  right: -4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $salmon60;
  color: $white;
  font-size: 1rem;
  @include medium-font;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.profileContainer {
  display: flex;
  width: 40px;
  height: 40px;
  border: 1px solid $white;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $toddleBG1;
  background-position: center;
  font-size: 10px;
}

.profileText {
  display: flex;
  font-size: 1.4em;
  color: $white;
  @include regular-font;
}

.archived {
  mix-blend-mode: luminosity;
}

.avatarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled {
  background-color: $surfaceDisabled;
  border-radius: 50%;
  opacity: 0.6;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  padding: 40px 40px 64px;
  overflow-y: auto;
}
.emailInput {
  padding: 0 24px;
}
.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 2.2rem;
  @include bold-font;
}

.modalContent {
  width: 604px !important;
  height: 170px !important;
}
.modalHeader {
  display: flex;
  flex-direction: column;
  padding: 40px 40px;
}

.modalTitleText {
  display: flex;
  color: $gray13;
  @include bold-font;
  font-size: 2.4rem;
}

.modalFooter {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 40px;
  flex-shrink: 0;
  height: 72px;
  z-index: 10;
  background-color: $white;
}

.buttonContainer {
  display: grid;
  grid-column-gap: 16px;
  grid-auto-flow: column;
}

.bottomLeftContainer {
  display: flex;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.filterBox {
  display: flex;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid $strokeOne;
  padding: 0px 16px;
  font-size: 1.4rem;

  fill: $gray48;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  width: max-content;

  &:hover {
    border-color: $gray72;
  }
}

.filterText {
  display: flex;
  margin-right: 8px;
  color: $blue29;
  @include demi-bold-font();
}

.gradeNames {
  display: flex;
  @include medium-font();
  color: $gray13;
  margin-left: 4px;
}

.bottomArrow {
  height: 24px;
  width: 16px;
  text-align: right;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UnitPlans/components/LibraryCommonComponents/common.scss";

.container {
  display: flex;
  width: 100%;

  overflow-y: auto;
  flex-direction: column;
  padding: 0 72px;
  //padding: 0 $horizontal-padding;
}

.innerContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  // margin: 0 auto;
  min-height: max-content;
}

.stepContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-row-gap: 16px;
  padding: 32px 0;
  width: 100%;
  max-width: 600px;
}

.maxiumContainer {
  max-width: 992px;
}

.headerTxtContainer {
  display: flex;
  flex-direction: row;
}

.stepLabel {
  display: flex;
  flex-direction: column;
  //margin-bottom: 16px;
  @include bold-font;
  font-size: 2.2rem;
  color: $pink39;
}

.textContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex: 1;
}

.iconContainer {
  display: flex;
  margin-left: 8px;
}

.informationIcon {
  display: flex;
  align-items: center;
}

.stepSubText {
  display: flex;
  margin-top: 8px;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray48;
}

.deleteBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.formContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  word-break: break-word;
  justify-content: space-between;
}

.item {
  display: flex;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 40px;
  }

  &:empty {
    margin-bottom: 0px;
  }

  flex-shrink: 0;
  flex-direction: column;
}

.chooseTemplateText {
  cursor: pointer;
  @include demi-bold-font;
  font-size: 1.6rem;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: $blue29;
}

.print {
  line-height: 0;
  cursor: pointer;
  fill: $gray72;
  margin-right: 16px;
  &:hover {
    fill: $black;
  }
}

.addBlockBtn {
  border: 1px solid $pink39;
  display: flex;
  height: 64px;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @include demi-bold-font;
  font-size: 1.6rem;
  cursor: pointer;
  color: $pink39;
  margin-top: 16px;
}

.warningMsg {
  width: 600px;
  margin-top: 32px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container :global {
  .SingleDatePicker_picker {
    z-index: 15 !important;
  }
  display: flex;
  flex-direction: column;
}

.enableDiscussionContainer {
  display: flex;
  border: 1px solid $borderSubtle;
  flex-direction: row;
  border-radius: 4px;
  width: 100%;
  height: 92px;
  padding: 24px;
  align-items: center;
}

.checkbox {
  display: flex;
  margin-right: 24px;
  cursor: pointer;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
}

.title {
  @include bold-font;
  color: $textDefault;
  font-size: 1.8rem;
}

.subTitle {
  @include medium-font;
  font-size: 1rem;
  color: $gray48;
}

.coursesContainer {
  display: grid;
  grid-row-gap: 24px;
  grid-auto-flow: row;
  width: 100%;
}

.classCard {
  display: flex;
  border: 1px solid $borderSubtle;
  border-radius: 4px;
  padding: 32px;
  flex-direction: column;
}

.classTitleCon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.classTitle {
  @include bold-font;
  font-size: 2rem;
  color: $textDefault;
}

.removeBtn {
  @include medium-font;
  font-size: 1.4rem;
  color: $blue29;
  cursor: pointer;
  &:hover {
    color: $blueActive;
  }
}

.inputField {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
}

.selectStudentHeaderCon {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
}

.addClassModalContainer {
  width: 560px !important;
  height: 540px !important;
  border-radius: 16px !important;
}

.settings {
  padding: 24;
  border-radius: 6px;
}

.settingCard {
  display: flex;
  border-bottom: 1px solid $borderSubtle;
  flex-direction: row;
  padding: 16px 0px;
  align-items: center;
  justify-content: space-between;
  &:first-child {
    padding-top: 0px;
    padding-bottom: 16px;
  }
}

.settingCardLast {
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  align-items: center;
  justify-content: space-between;
}

.settingLeftContainer {
  display: flex;
  flex-direction: row;
  margin-right: 16px;
  flex: 1;
}

.settingTitle {
  @include demi-bold-font;
  color: $textDefault;
  font-size: 1.6rem;
  margin-right: 8px;
}

.settingSubtitle {
  @include medium-font;
  font-size: 1.4rem;
  color: $gray48;
}

.switch {
  display: flex;
}

.warningTriangleStyle {
  display: flex;

  justify-content: center;
  width: 48px;
}
.button {
  margin-top: 16px;
  width: "auto";
}

.innerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 56px 0;
  border-bottom: 1px solid $borderSubtle;
}

.addClassContainer {
  display: flex;
  margin-bottom: 24px;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 200px;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 696px;
}

.desc {
  color: $textSubtle;
  font-size: 1.6rem;
  @include regular-font;
  margin-top: 4px;
}

.selectDropdownContainer {
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 2;
}

.addClassBtn {
  display: flex;
  margin-top: 16px;
}

.cardHeader {
  width: 100%;
  display: grid;
  grid-template-columns: 128px 90px 272px;
  gap: 40px;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid $borderSubtle;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.headingTitleLabel {
  font-size: 1.6rem;
  color: $textDefault;
  @include demi-bold-font;
  flex-grow: 1;
}

.cardContainer,
.cardInnerContainer {
  display: flex;
  flex-direction: column;
}

.settingTitleDisabled {
  color: $textDisabled;
}

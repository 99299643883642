@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.modalContent {
  width: 560px !important;
  height: auto !important;
}

.container {
  display: grid;
  grid-auto-flow: row;
  height: 100%;
  justify-content: space-between;
  position: relative;
  padding: 40px;
}

.cancelIcon {
  display: flex;
  position: absolute;
  top: 24px;
  right: 24px;
  fill: $gray48;
  cursor: pointer;
  &:hover {
    fill: $gray31;
  }
}

.middleBody {
  display: grid;
  grid-auto-flow: row;
  align-content: flex-start;
}

.heading {
  @include bold-font;
  font-size: 2.8rem;
  color: $seaweedTwo;
  margin-bottom: 24px;
}

.subHeading {
  @include medium-font;
  font-size: 1.8rem;
  color: $gray31;
  margin-bottom: 24px;
}

.footer {
  display: grid;
  grid-row-gap: 24px;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: flex-end;
}

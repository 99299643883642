@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.row {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.optionContainer {
  display: flex;
  flex-shrink: 0;
  flex: 1;
  gap: 8px;
}

.headerOptionContainer {
  width: 100%;
  display: flex;
  gap: unset;
  overflow: hidden;
}

.columnItem {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.cell {
  display: flex;
  position: relative;
  flex: 1;
  min-width: 148px;
  max-width: 460px;
  flex-shrink: 0;
  padding: 24px;
  border-radius: 8px;
}

.labelContainer {
  composes: cell;
  background-color: $violet98;
}

.option {
  composes: cell;
  min-width: 148px;
  align-items: center;
  border: 1px solid $borderSubtle;
}

.columnItemHeader {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.optionHeader {
  composes: cell;
  min-width: 148px;
  color: $textOn;
  font-size: 1.6rem;
  @include medium-font;
  background-color: $surfaceDark;
  border-radius: unset;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.showInsight {
  border-radius: 8px;
}

.insightsHeaderOptionContainer {
  gap: 8px;
}

.insightCell {
  padding-top: 12px;
}
.insight {
  display: flex;
  margin-bottom: 8px;
}

.insightDescriptor {
  display: flex;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray72;
}

.insightCount {
  @include demi-bold-font;
  font-size: 2.4rem;
  color: $blue29;
}

.insightStudent {
  font-size: 1.4rem;
  color: $gray48;
  margin-left: 4px;
  align-self: flex-end;
  -webkit-font-smoothing: antialiased;
  line-height: 1.86;
}

.deleteCell {
  cursor: pointer;
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid $strokeTwo;
  justify-content: center;
  align-items: center;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.clickableCell {
  composes: cell;
  cursor: pointer;
  background: $surfaceWhite;
  &:hover {
    background-color: rgba(24, 158, 174, 0.08);
  }
}
.clickableStudentCell {
  composes: cell;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 180, 0, 0.08);
  }
}

.selectableCell {
  composes: cell;
  color: $blue29 !important;
  background-color: rgba(24, 158, 174, 0.08);
}

.selectableCell2 {
  composes: cell;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid $blue29 !important;
  color: $blue29 !important;
  background-color: rgba(24, 158, 174, 0.08);
}

.selectableStudentCell {
  composes: cell;
  background-color: rgba(255, 180, 0, 0.08);
}

.tickSvgContainer {
  position: absolute;
  top: 8px;
  right: 8px;
  justify-content: flex-end;
  display: flex;
}

.tickSvgComponent {
  display: flex;
}

.tableHeader {
  border: none;
  @include medium-font;
  color: $textOn;
  font-size: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableText {
  border: none;
  @include medium-font;
  color: $textDefault;
  font-size: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

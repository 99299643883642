@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 64px;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 48px;
}

.inputContainer {
  display: flex;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.accountText {
  display: flex;
  align-items: center;
  @include medium-font;
  color: $gray48;
  font-size: 1.6rem;
  margin-right: 8px;
}

.signInText {
  display: flex;
  color: $blue29;
  margin-left: 4px;
  @include demi-bold-font;
  cursor: pointer;
  flex-shrink: 0;
}

.bottomContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

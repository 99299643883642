@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.labelContainer {
  display: flex;
  padding: 12px 0;
  justify-content: space-between;
  align-items: center;
}

.accordionCaretIconContainer {
  margin-left: 8px;
  display: flex;
}

.label {
  color: $textDefault;
  line-height: 2.4rem;
}

.labelSiblingContainer {
  display: flex;
  align-items: center;
}

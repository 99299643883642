@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  word-break: break-word;
  padding: 48px 64px;
  flex: 1;
  padding-bottom: 64px;
}

.editContainer {
  padding: 40px 56px 40px 72px !important;
  background-color: $white;
}

.listContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  background-color: $white;
}

.listViewContainer {
  padding: 32px;
  border-radius: 8px;
  border: 1px solid $strokeOne;
}
.rightContainer {
  display: flex;
  align-items: center;
}
.standardLabelCommon {
  font-size: 2rem;
  color: $teal20;
  flex-grow: 1;
  @include bold-font;
}
.standardLabel1 {
  margin: 0px 0px 0px -32px;
}
.standardLabel2 {
  margin: 0px 0px 0px -24px;
}
.SVGWrapper {
  margin: 0px 0px 0px 4px;
  text-align: right;
}
.benchmarkWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.questionHeader {
  text-align: right;
  margin: 0px 4px 0px 0px;
}
.benchmarkQuestionTooltipContainer {
  margin: 0;
  padding: 0px 0px 0px 8px;
}
.tableRow {
  display: grid;
  grid-column-gap: 24px;
  grid-auto-flow: column;

  width: 100%;
}
.questionDescriptionGradesLabel {
  @include demi-bold-font;
  display: grid;
  grid-template-columns: 0% 72% 14% 8%;
  color: $gray48;
  align-items: center;
  grid-column-gap: 24px;
  grid-auto-flow: column;
  width: 100%;
}
.questionDescription {
  @include demi-bold-font;
  display: grid;
  grid-template-columns: 0% 84% 12%;
  color: $gray48;
  align-items: center;
  grid-column-gap: 16px;
  grid-auto-flow: column;
  width: 100%;
}
.gradesLabel {
  text-align: right;
}
.tableRowEdit {
  display: grid;
  grid-template-columns: 20% 42% 18% 12% !important;
  border-right: 1px solid $strokeOne;
  border-left: 4px solid;
}

.tableRow {
  display: grid;
  grid-column-gap: 24px;
  grid-auto-flow: column;
  width: 100%;
}
.tableRowEdit {
  display: grid;
  grid-template-columns: 20% 42% 18% 12% !important;
  border-right: 1px solid $strokeOne;
  border-left: 4px solid;
}
.tableRowView {
  display: grid;
  grid-template-columns: 0% 75% 15% !important;
  //border-left: 1px solid $strokeOne;
}
.tableRowTag {
  display: grid;
  grid-template-columns: 0% 92% 0% !important;
}
.tableRowBenchmarkCount {
  display: grid;
  grid-template-columns: 0% 72% 14% 8% !important;
}
.tableRowBenchmarkCountTagging {
  display: grid;
  grid-template-columns: 0% 85% 8% !important;
}
.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-shrink: 0;
}

.searchBarContainer {
  display: flex;
  flex: 1;
  background-color: $white;
}

.buttoncontainer {
  display: flex;
  margin-top: 40px;
  fill: $blue29;
  &:hover {
    fill: $white;
  }
}
.buttonIcon {
  margin-right: 8px;
  display: inline-block;
  align-items: center;
  margin-top: 3px;
}

.multiSelectContainer {
  display: flex;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

@import "~UIComponents/CommonStyles/colors";

//mixin for boder
@mixin border-class(
  $direction: default,
  $radius: 4px,
  $width: 1px,
  $style: solid,
  $color: $strokeTwo
) {
  border-radius: $radius;

  @if $direction == default {
    border: $width $style $color;
  }
  @if $direction == bottom {
    border-bottom: $width $style $color;
  }
  @if $direction == top {
    border-top: $width $style $color;
  }
  @if $direction == right {
    border-right: $width $style $color;
  }
  @if $direction == left {
    border-left: $width $style $color;
  }
  @if $direction == vertical {
    border-bottom: $width $style $color;
    border-top: $width $style $color;
  }
  @if $direction == horizontal {
    border-left: $width $style $color;
    border-right: $width $style $color;
  }
  @if $direction == "no-top" {
    border: $width $style $color;
    border-top: none;
  }
  @if $direction == "no-bottom" {
    border: $width $style $color;
    border-bottom: none;
  }
  @if $direction == "no-left" {
    border: $width $style $color;
    border-left: none;
  }
  @if $direction == "no-right" {
    border: $width $style $color;
    border-right: none;
  }
}

@mixin mediaQuery($name) {
  @if $name == "touchable" {
    @media (hover: none) and (pointer: coarse) {
      @content;
    }
  } @else if $name == "not-touchable" {
    @media (hover: hover) and (pointer: fine) {
      @content;
    }
  } @else if $name == "tablate" {
    @media (min-width: 768px) and (max-width: 1024px) {
      @content;
    }
  } @else if $name == "mobile" {
    @media (max-width: 776px) {
      @content;
    }
  } @else if $name == "mobile-and-tablet" {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $name == "medium-device" {
    @media (max-width: 1194px) {
      @content;
    }
  }
}

/* mixin for multiline */
@mixin multiLineEllipsis(
  $lineHeight: 2.2rem,
  $lineCount: 1,
  $maxCharacter: default
) {
  //width: 100%;
  display: block;
  display: -webkit-box;
  max-height: $lineHeight * $lineCount;

  line-height: $lineHeight;
  -webkit-line-clamp: $lineCount;

  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;

  overflow: hidden;
  text-overflow: ellipsis;
  @if $maxCharacter != default {
    max-width: #{$maxCharacter}ch;
  }
}

@mixin dot($width: 10px, $height: 10px, $bgColor: $white) {
  width: $width;
  height: $height;
  border-radius: 50%;
  background-color: $bgColor;
}

@mixin dotAnimation(
  $width: 10px,
  $height: 10px,
  $bgColor: $white,
  $dotBeforeColor: $salmon60,
  $dotAfterColor: $salmon60,
  $dotColor: $salmon60
) {
  $dotSpacing: $width + $width/2;

  position: relative;

  @include dot($width: $width, $height: $height, $bgColor: $dotColor);

  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -$dotSpacing;

    @include dot($width: $width, $height: $height, $bgColor: $dotBeforeColor);

    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: $dotSpacing;

    @include dot($width: $width, $height: $height, $bgColor: $dotAfterColor);

    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: $dotColor;
    }

    50%,
    100% {
      background-color: lighten($dotColor, 20%);
    }
  }
}

/*
* Dotted Border - A SASS mixin to help you increase spacing between the dots of border-style:dotted.
* By @florbraz
* Documentation and examples - github.com/florbraz/Dotted-Border-w-custom-spacing-SCSS-Mixin
* V1.0
*/
@mixin dottedBorder(
  $color: #8f8f8f,
  $orientation: horizontal,
  $position: top,
  $spacing: 5px,
  $size: 1px
) {
  background-position: $position;
  @if $orientation == horizontal {
    background-image: linear-gradient(
      to right,
      $color $size/$spacing * 100%,
      rgba(255, 255, 255, 0) 0%
    );
    background-size: $spacing $size;
    background-repeat: repeat-x;
  } @else {
    background-image: linear-gradient(
      $color $size/$spacing * 100%,
      rgba(255, 255, 255, 0) 0%
    );
    background-size: $size $spacing;
    background-repeat: repeat-y;
  }
  .ie9 & {
    border-#{$position}: 1px dotted $color;
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin scrollContainer() {
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow-y: auto;
  & > div {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: max-content;
  }
}

//hack for mimic gap for flex container in safari
//ref:https://coryrylan.com/blog/css-gap-space-with-flexbox
@mixin flexGap($rowGap: 0px, $columnGap: 0px) {
  margin: calc(-1 * #{$rowGap}) 0 0 calc(-1 * #{$columnGap});
  width: calc(100% + #{$columnGap});
  & > * {
    margin: $rowGap 0 0 $columnGap;
  }
}

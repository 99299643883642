@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.header {
  display: flex;
  margin: 48px 0;
  justify-content: space-between;
  align-items: center;
}
.leftHeader {
  display: flex;
  width: 284px;
}

.rightHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.4rem;
  gap: 40px;
  @include medium-font();
}

.filterContainerStyle {
  top: 40;
  right: 0;
  min-width: 200;
  width: max-content;
  max-height: 300;
  padding: 16;
  padding-top: 22;
  padding-bottom: 22;
  overflow-y: auto;
  border-radius: 8;
}

.filterBox {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  color: $gray48;
  fill: $gray48;
  &:hover {
    color: $gray31;
    fill: $gray31;
  }
}

.filterLabelText {
  display: flex;
  @include medium-font;
  font-size: 1.4rem;
  margin-right: 4px;
}

.filterText {
  display: flex;
  @include bold-font();
  font-size: 1.4rem;
  margin-right: 8px;
  text-align: right;
}

.bottomArrow {
  height: 24px;
  width: 16px;
  text-align: right;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
$errorColor: $yellow50;
.container :global {
  strong {
    @include demi-bold-font;
  }
  input[type="number"],
  input[type="text"] {
    border-radius: 0;
    width: 100%;
    border: none;
    font-size: 1.4rem;
    outline: none !important;
  }

  input.rc-time-picker-input {
    width: 100%;
  }

  .SingleDatePicker {
    width: 100%;
  }

  .SingleDatePickerInput__withBorder {
    width: 100%;
    border: none;
  }
  .SingleDatePickerInput {
    background-color: transparent;
    display: flex;
  }
  .react-datepicker__header {
    text-align: center;
    border-bottom: none;
    background-color: $strokeOne;
    @include regular-font;
  }

  .react-datepicker__day--selected {
    border-radius: 0px;
    background-color: $blue29;
    color: #fff;
  }

  .react-datepicker__month {
    margin: 2px 10px 10px 10px;
    font-size: 1.2rem;
  }

  .react-datepicker__current-month {
    @include demi-bold-font;
    font-size: 18px;
  }

  .react-datepicker__day {
    &:hover {
      border-radius: 0px;
    }

    &--highlighted {
      &:hover {
        background-color: $blue29;
      }
    }
  }

  .react-datepicker__navigation--next {
    right: 40px;
    margin-top: 10px;
  }

  .react-datepicker__navigation--previous {
    left: 40px;
    margin-top: 10px;
  }

  .react-datepicker__navigation {
    top: 16px;
  }

  .react_datepicker__day-name {
    line-height: 30px;
    margin: 4px;
  }

  .react-datepicker__current-month {
    margin-top: 10px;
    margin-bottom: 27px;
  }

  .react-datepicker {
    border-radius: 0px;
    @include regular-font;
    font-weight: lighter;
  }

  .react-datepicker__week {
    margin-bottom: 5px;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    width: 32px;
    line-height: 29px;
  }
  .DateInput_input {
    color: $gray13;
    font-size: 1.4rem;
    padding: 11px 0 9px;
    cursor: inherit;
    background-color: transparent;
  }
  .DateInput_fang {
    // top: -34px;
  }
  .DateInput {
    background-color: transparent;
  }
  .DateInput_input__focused {
    border: none;
  }
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 4px;
  padding: 4px 8px;
  border: 1px solid $strokeTwo;
  cursor: pointer;
}

.disabledContainer :global {
  cursor: not-allowed;
  background-color: $surfaceDisabled;
  border: none;
  .SingleDatePickerInput_calendarIcon {
    cursor: not-allowed;
  }
  .DateInput_input__disabled {
    font-style: unset;
  }
}

.errorContainer {
  background-color: rgba($errorColor, 0.1);
  border-radius: 0;
  border: 1px solid $errorColor;
}

.downContainer :global {
  .SingleDatePicker_picker {
    padding-bottom: 24px;
  }
}

.calenderSvg {
  display: flex;
  fill: $gray31;
  svg {
    width: 16px;
    height: 16px;
  }
  cursor: pointer;
}

.monthYearSelectorcontainer {
  display: flex;
  justify-content: center;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
@import "../common.scss";

.container {
  composes: assessmentGridLayout;
}

.containerRevamp {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  gap: 96px;
  max-width: fit-content;
}

.leftContainer {
}

.imageContainer {
  width: 360px;
  height: 240px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;
  cursor: pointer;
  background-color: $toddleBG1;
  flex-shrink: 0;
}

.imageContainerRevamp {
  width: 272px;
  height: 204px;
  border-radius: 8px;
}

.rightContainer {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.rightContainerRevamp {
  justify-content: unset;
}

.topSecion {
  display: flex;
  // flex-grow: 1;
  // flex-shrink: 0;
  flex-direction: column;
  width: 100%;
}

.cardContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
  margin-bottom: 32px;
}

.cardContainerRevamp {
  margin-top: 0;
  margin-bottom: 0;
}

.userInfoCard {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 16px;
}

.name {
  font-size: 1.8rem;
  @include demi-bold-font;
  color: $gray13;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.nameRevamp {
  font-size: 1.6rem;
  color: $textSubtle;
  line-height: 2.4rem;
  &:hover {
    text-decoration: none;
  }
}

.schoolName {
  font-size: 1.6rem;
  @include medium-font;
  color: $gray48;
}

.dropdown {
  margin-right: 12px;
}

.bottomContainer {
  display: flex;
}

.subTitleText {
  @include medium-font;
  font-size: 1.4rem;
  line-height: 1.71;
}
.extraButton {
  display: flex;
  align-items: center;
}

.editButton {
  margin-right: 16px;
}
.print {
  display: flex;
  margin-right: 8px;
  cursor: pointer;
  & svg {
    fill: $gray72;
  }
  &:hover {
    & svg {
      fill: $black;
    }
  }
}

.viewActionsSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer {
  display: flex;
  column-gap: 20px;
}

.leftIconsContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  margin-right: 28px;
}

.headerText {
  display: flex;
  color: $gray31;
  font-size: 3.2rem;
  @include bold-font;
  line-height: 4.4rem;
  @include multiLineEllipsis(3.6rem, 2);
}

.headerTextRevamp {
  color: $textDefault;
  @include multiLineEllipsis(4.3rem, 2);
}

.topSectionRevamp {
  display: flex;
  flex-direction: column-reverse;
  gap: 44px;
}

.commentHelper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  cursor: pointer;
}

.iconBlue {
  color: $blue29;
  fill: $blue29;
  &:hover {
    fill: #248a94;
    color: #248a94;
  }
}

.iconGrey {
  color: $gray48;
  fill: $gray72;
  &:hover {
    fill: $gray31;
    color: $gray31;
  }
}

.actionDetailContainer {
  display: grid;
  column-gap: 56px;
  grid-auto-flow: column;
  width: 100%;
  margin-top: 32px;
}

.moreIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $gray48;
  border-radius: 50%;
  padding: 6px;
  fill: $gray48;
  background: white;
  &:hover {
    background-color: $gray48;
    fill: $white;
  }
}

.mobile {
  display: none;
}

.unitTitle {
  font-size: 1.6rem;
  @include demi-bold-font;
  line-height: 2.4rem;
  color: $textSubtle;
}

@include mediaQuery("mobile") {
  .leftContainer {
    position: relative;
  }

  .rightContainer {
    padding: 0 32px;
  }

  .imageContainer {
    width: 100%;
    border-radius: 0;
    &:after {
      content: "";
      display: flex;
      position: absolute;
      pointer-events: none;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 240px;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.75)
      );
    }
  }

  .headerText {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
  .mobile {
    display: flex;
  }

  .desktop {
    display: none;
  }

  .userInfoCard.mobile {
    position: absolute;
    bottom: 24px;
    left: 24px;
  }

  .name,
  .schoolName {
    color: $white;
  }

  .cardContainer {
    margin: 0;
  }

  .actionDetailContainer {
    padding: 16px 0;
    border-top: 1px solid $strokeOne;
    border-bottom: 1px solid $strokeOne;
    justify-content: center;
    margin-bottom: 24px;
    margin-top: 0;
  }

  .topSection {
    order: 2;
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px 0;
    .wrapper {
      display: flex;
      flex-direction: column;
    }
    .leLabel {
      @include demi-bold-font;
      font-size: 1.2rem;
      margin-bottom: 8px;
      color: $pink39;
    }
  }
  .useInClass {
    width: 100% !important;
  }

  .viewActionsSection {
    width: 100%;
    margin-bottom: 40px;
  }
  .buttonDropdownClass,
  .containerClass {
    width: 100%;
  }
}

@import "~UIComponents/CommonStyles/colors";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown{
  border-radius: 50%;
  &:hover{
    background: $blue29;
    fill: $white;
  }
}
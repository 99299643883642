@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.ratingElementsContainer {
  box-sizing: content-box;
  display: flex;
}

.ratingElement {
  width: 40px;
  height: 32px;
  text-align: center;
  padding-top: 8px;
  border-top: 1px solid $borderSubtle;
  border-bottom: 1px solid $borderSubtle;
  border-left: 1px solid $borderSubtle;
  background-color: $toddleBG1;
  cursor: pointer;
  &:last-child {
    border-right: 1px solid $borderSubtle;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.ratingElementDisabled {
  cursor: not-allowed !important;
  color: $textDisabled;
}

.selectedRatingElement {
  background-color: $interactiveThreeHover;
  border-right: none;
  color: $white;
}

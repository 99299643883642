@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.hideReactMicUI {
  display: none;
}

.modalBodyContainer .description {
  color: $gray48;
  font-size: 1.6rem;
}

.modalBodyContainer .playerContainer {
  padding: 40px 0;
}

.dialogContent {
  height: auto !important;
  min-height: 300px;
  max-height: 360px;
}

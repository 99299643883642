@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.emptyTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.emptyText {
  display: flex;
  margin-left: 8px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: $yellow50;
}

.feedContainer {
  display: grid;
  flex-shrink: 0;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  flex-shrink: 0;
  grid-gap: 32px;
}

.itemCard {
  max-width: 360px;
}

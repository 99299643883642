@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    .titleText {
      text-decoration: underline;
    }
  }
}

.innerContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.headerContainer {
  display: flex;
  height: 44px;
  margin-bottom: 8px;
}

.imagePlaceHolderContainer {
  display: flex;
  width: 60px;
  height: 44px;
  flex-shrink: 0;
}

.textContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  padding: 0 12px;
}

.titleText {
  display: flex;
  @include bold-font;
  color: $gray13;
  font-size: 1.4rem;
  @include multiLineEllipsis(1.8rem, 1);
}

.nameText {
  display: flex;
  @include regular-font;
  color: $gray48;
  font-size: 1.4rem;
  @include multiLineEllipsis(1.8rem, 1);
}

@import "~UIComponents/CommonStyles/colors";

.container {
  width: 100%;
  outline: none;
  padding: 4px 0;
}

[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  // position: absolute;
  color: $gray72;
  background-color: transparent;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
}

.previewHeader {
  color: $gray48;
  @include medium-font;
  margin-left: 120px;
  margin-top: 20px;
}

.feedbackButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $salmon60;
  color: $white;
  padding: 0px 12px;
  border-radius: 8px;
  fill: $white;
  bottom: 24px;
  cursor: pointer;
  height: 28px;
}

.feedbackButtonText {
  margin-left: 8px;
  @include demi-bold-font;
}

.embedPlayerContainer{
  width: 100%;
  height: 100%;
  display: flex;
}
@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  cursor: default;
}

.modalContent {
  margin: auto;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  min-height: 32px;
  padding: 0px 48px;
  font-size: 2.2rem;
  @include bold-font;
  border-radius: 4px;
  flex: 0.1;
}

.modalBody {
  font-size: 1.8rem;
  color: $gray31;
  padding: 24px 64px 16px 48px;
  flex: 0.75;
  overflow-wrap: break-word;
  white-space: pre-line;
}

.modalFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  padding: 0px 64px 0px 48px;
  flex: 0.15;
}
.button1Div {
  display: flex;
  margin-right: 16px;
}
.Modal--xl {
  width: 80%;
  height: 86%;
  border-radius: 4px;

  & .modalHeader {
    align-items: center !important;
  }
  & .modalBody {
    border: 1px solid $gray72;
  }
  & .modalFooter {
    align-items: center !important;
  }
}

.Modal--md {
  max-width: 584px !important;
  width: 90vw;
  height: 328px !important;
  border-radius: 6px;
  & .modalHeader {
    flex: 0.27;
  }
  & .modalFooter {
    flex: 0.3;
  }
  & .modalBody {
    flex: 0.43;
  }
}

.Modal--auto {
  max-width: 584px !important;
  width: 90vw;
  height: max-content;
  border-radius: 6px;
  & .modalHeader {
    padding: 28px 64px 0px 48px;
    flex: none;
  }
  & .modalBody {
    flex: none;
  }
  & .modalFooter {
    flex: none;
    padding: 0px 64px 28px 48px;
  }
}

.button1 {
  background-color: $strokeOne;
  color: #000;
  margin-right: 16px;
  &:hover {
    color: #000;
    background-color: $strokeTwo;
  }
  &:focus {
    background-color: $strokeOne;
    border: solid 2px #cdcdd2;
  }
}
.button2,
.button1 {
  cursor: pointer;
  font-size: 1.4rem;
}
.button2 {
  background-color: $salmon60;
  &:hover {
    background-color: #ff5475;
  }
  &:focus {
    background-color: #ff5475;
    border: 1.5px solid $salmon60;
  }
}

.warningText {
  display: flex;
  color: $yellow50;
  @include medium-font;
  font-size: 14px;
  margin-bottom: 40px;
}

@import "~UIComponents/CommonStyles/colors.scss";
@import "~UIComponents/CommonStyles/typography.scss";
@import "~IBPlanner/common.scss";
.container {
  display: flex;
  //border: 1px solid $strokeTwo;
  max-height: 100%;
  // height: max-content;
  position: relative;
  width: 100%;
}

.scrollContainer {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
}
.cancelIcon {
  display: flex;
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 32px;
  fill: $gray48;
  z-index: 10;
  &:hover {
    fill: $gray31;
  }
}

.header {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 32px;
  align-items: center;
  justify-content: space-between;
}

.helperBulb {
  display: flex;
  margin-left: -6px;
}

////// Helper Body ////

.helperBody {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  font-size: 1.5rem;
  color: $gray13;
  padding: 40px 32px;
}

.helperTitle {
  display: flex;
  @include bold-font;
  font-size: 1.8rem;
  flex-shrink: 0;
}

.helperSubTitle {
  display: flex;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray72;
}

.helperContent {
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  strong {
    color: $gray31;
    @include bold-font;
  }
}

.imageContainer {
  cursor: pointer;
  position: relative;
}

.hoverContainer {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.imageContainer:hover .hoverContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.crossSvg {
  background: black;
  border-radius: 0 0 0 48px;
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  padding-top: 12px;
  padding-left: 8px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  fill: $white;
  z-index: 1200;
}

.modalContent {
  background-color: transparent !important;
  justify-content: center !important;
  align-items: center !important;
}

.overlayContainer {
  z-index: 1100 !important;
}

.topPanel {
  display: flex;
  justify-content: center;
  flex-basis: 10%;
}

.videoViwerContainer {
  display: flex;
  justify-content: center;
  flex-basis: 90%;
  width: 100%;
  align-items: center;
}

.videoViewer {
  display: flex;
  width: 70%;
  height: 70%;
  align-self: center;
}

.audioPlayerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";

.container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid $borderSubtle;
  gap: 32px;
}

.flexRow {
  display: flex;
  gap: 24px;
}

.input {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
}

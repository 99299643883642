.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 100%;
  min-height: 0;
}
.noScrollContainer {
  overflow: hidden;
}
.loadingContainer {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 11;
}

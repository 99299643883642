@import "~UIComponents/CommonStyles/colors";

.codeContainer {
  outline: none;
  color: $black;
  word-wrap: break-word;
}
.codeContainer[contentEditable="true"] {
  cursor: text;

  &:empty {
    &:before {
      content: attr(placeholdertext);
      color: $strokeTwo;
    }
  }
}

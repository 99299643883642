@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.containerList {
  display: flex;
  height: max-content;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
  flex: 1;
}
.listHeader {
  display: grid;
  min-height: 56px;
  align-items: center;
  justify-items: left;
  @include border-class(top, $radius: 0);
}
.listCountText {
  @include demi-bold-font;
  display: flex;
  color: $gray31;
  font-size: 1.6rem;
  margin-bottom: 16px;
}
.headerItem {
  display: grid;
  color: $gray31;
  @include demi-bold-font;
  font-size: 1.4rem;
  &:hover {
    .sortIcon {
      visibility: visible;
    }
  }
}
.leftHeaderItem {
  composes: headerItem;
  justify-self: start;
}
.noTeacherContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  font-size: 18px;
  min-height: 150px;
}
.actionCell {
  justify-self: center;
}
.intCell {
  justify-self: center;
}

.headerTitleContainer {
  display: flex;
}

.headerLabelText {
  min-height: 1.6rem;
  display: flex;
  align-items: center;
  gap: 8px;
}
.subText {
  font-size: 1.2rem;
  color: $gray31;
  @include medium-font;
  @include multiLineEllipsis(1.3rem, 1);
  min-height: 1.6rem;
}

.feedListContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  @include border-class(top, $radius: 0);
}

.sortLabelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.arrowContainer {
  display: flex;
  margin-left: 4px;
}
.subHeader {
  background: $toddleBG1;
  color: $gray72;
  padding: 10px 16px;
}

.headerItemWithSort {
  color: $gray48;
  width: 100%;
}

.sortIcon {
  visibility: hidden;
  cursor: pointer;
  color: $gray48;
}

.enabledSortIcon {
  visibility: visible;
}

.headerContainer {
  position: sticky;
  -webkit-position: sticky;
  top: -8px;
  z-index: 10;
  -webkit-backface-visibility: hidden;

  &::after,
  &::before {
    content: "";
    display: block;
    height: 8px;
    position: sticky;
    -webkit-position: sticky;
  }

  &::before {
    top: 48px;
    box-shadow: 0px 4px 8px -3px $black;
  }
  &::after {
    background-color: $white;
    top: 0;
    z-index: 11;
  }
  & > div {
    background-color: $white;
    position: sticky;
    -webkit-position: sticky;
    top: 0px;
    margin-top: -8px;
    z-index: 12;
  }
}

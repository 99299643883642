@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";
@import "~UnitPlans/common.scss";

$borderColor: $strokeTwo;
$middle_bar_width: 878px;
$right_bar_width: 400px;

$right_pane_width: 400px;
$rightPaneAnimationTime: 0.3s;
$left_pane_width: 1040px;
@keyframes open_right_pane {
  to {
    -webkit-transform: translateX(-$right_pane_width);
  }
}

@keyframes close_right_pane {
  to {
    -webkit-transform: translateX($right_pane_width);
  }
}

.modalContainer {
  display: flex;
  width: 100%;
  height: 100%;
}

.modalContentSmall {
  display: flex;
  width: calc(100% - 400px);
  min-width: $left_pane_width;
  height: 100%;
}

.modleContentFull {
  display: flex;
  width: 100%;
  flex: 1;
  min-height: 0;
  height: 100%;
}

.modalSidebar {
  display: flex;
  height: 100%;
  border-left: 1px $strokeOne solid;
  position: absolute;
  right: -$right_pane_width;
  width: $right_pane_width;
  height: 100%;
  background-color: $white;
  z-index: 11;
}

.smallSideBar {
  // composes: modalSidebar;
  // width: 0px;
  // opacity: 0;
  // transition: width 0.3s, opacity 0.15s;

  right: 0;
  animation: close_right_pane 0.3s;
  animation-fill-mode: forwards;
}

.fullSideBar {
  // composes: modalSidebar;
  // width: $right_bar_width;
  // opacity: 1;
  // transition: width 0.3s, opacity 0.3s;

  visibility: visible;
  animation: open_right_pane 0.3s;
  animation-fill-mode: forwards;
}

.visibleRightPane {
  visibility: visible;
  animation: open_right_pane 0.3s;
  animation-fill-mode: forwards;
}

.hiddenRightPane {
  right: 0;
  animation: close_right_pane 0.3s;
  animation-fill-mode: forwards;
}

.modalHeader {
  width: 100%;
  height: 80px;
  border-bottom: 2px solid $strokeOne;
  display: flex;
  padding-left: 24px;
  align-items: center;
}

.modalTitle {
  @include medium-font;
  font-size: 1.8rem;
  // float: left;
}

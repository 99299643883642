@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.mainContainer {
  display: flex;
  width: 100%;
  height: max-content;
  background-color: $white;
  flex-direction: column;
}

.label {
  display: flex;
  margin-left: 24px;
  @include medium-font;
  font-size: 1.6rem;
  color: $gray48;
  margin: 8px 0px 8px 24px;
}

.listContainer {
  display: flex;
  flex-direction: column;
}

.contentContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-height: 100px;
  justify-content: space-between;
}

.icon {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: solid 1px $strokeTwo;
}

.content {
  max-width: 300px;
  @include regular-font;
  font-size: 1.4rem;
  line-height: 1.57;
  color: $gray13;
  //   color: var(--black);
  b {
    @include demi-bold-font;
  }
}

.rightContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.duration {
  @include regular-font;
  font-size: 1.2rem;
  text-align: right;
  color: $gray48;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";

$borderColor: $strokeTwo;

.mainContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.showLabelBorder {
  border-bottom: 1px solid $strokeTwo;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: 24px 0px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.countStyle {
  @include bold-font;
  font-size: 1.4rem;
  color: $blue29;
  margin-right: 20px;
}

.headerLabel {
  @include bold-font;
  font-size: 1.4rem;
  color: $gray31;
}

.mainContentContainer {
  display: flex;
  flex-direction: column;
  border: solid 1px $strokeTwo;
  margin-bottom: 16px;
  padding: 40px 32px;
}

.hideContainerBorder {
  border: none;
  border-style: none;
  padding: 0px;
}

.arrowContainer {
  display: flex;
  height: 6px;
  align-self: center;
}

.openArrow {
  transform: rotate(-180deg);
  transition: transform 0.3s;
}

.closeArrow {
  transform: rotate(0deg);
  transition: transform 0.3s;
}

.headerLabelOpen {
  color: $blue29;
}

.leftArrowContainer {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.leftOpenArrow {
  transform: rotate(90deg);
  transition: transform 0.3s;
}

.leftCloseArrow {
  transform: rotate(0deg);
  transition: transform 0.3s;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.errorContainer {
  border-bottom: 1px solid $salmon60;
}

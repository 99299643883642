@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
}
.gradeDiv {
  @include medium-font;
  font-size: 1.6rem;

  width: 100%;
  color: $gray13;
}
.gradeCardsDiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px 32px;
  margin: 12px 0 64px 0;
}

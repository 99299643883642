@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
}

.screenContainer {
  width: 100%;
  height: 100%;
}

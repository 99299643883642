@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

$tableBorder: 1px solid $gray92;

.descriptorTable {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
  tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }

  td:not(:first-child) {
    color: $textSubtle;
  }
}

.tableHeader {
  background-color: $surfaceDark;
  color: $white;
  padding: 12px 16px;
  min-height: 48px;

  &:first-child {
    border-top-left-radius: 8px;
    border-right: $tableBorder;
  }
  &:last-child {
    border-top-right-radius: 8px;
  }
}

.tableCell {
  padding: 16px;
  vertical-align: top;
  border: $tableBorder;
  border-top: none;
  &:nth-child(even) {
    border-left: none;
  }

  p,
  ul {
    margin: 0;
  }

  div {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}

.subText {
  margin-top: 4px;
}

.column1 {
  width: 16%;
}

.column2 {
  width: 84%;
}

.headerWithSorting {
  composes: tableHeader;
  display: flex;
  align-items: center;
  cursor: pointer;
}

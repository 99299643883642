@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

$borderColor: $strokeTwo;

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $toddleBG1;

  flex-direction: column;
  height: 64px;
  border-radius: 4px;
}

.containerButton {
  border: 1px solid $strokeOne;
  display: flex;

  height: 64px;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid $strokeTwo;
    .notHoverText {
      display: none;
    }
    .title {
      display: none;
      color: $gray31;
    }
    .renderIcon {
      display: none;
    }
    .hoverButtons {
      display: flex;
    }
    .leftButton {
      display: flex;
    }
    .rightButton {
      display: flex;
    }
  }
}

.disableButton {
  &:hover {
    border: 1px solid $strokeOne;
    .title {
      color: $gray48;
    }
    .renderIcon {
      fill: $gray72;
    }
  }
}

.title {
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $blue29;
  text-align: center;
  @include mediaQuery("touchable") {
    display: none;
  }
}

.subText {
  @include regular-font;
  font-size: 1.4rem;
  color: $gray48;
  text-align: center;
}

.renderIcon {
  display: flex;
  margin-right: 12px;
  fill: $blue29;
  @include mediaQuery("touchable") {
    display: none;
  }
}

.hoverButtons {
  display: none;
  width: 100%;
  height: 100%;
  @include mediaQuery("touchable") {
    display: flex;
  }
}

.leftButton {
  display: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $blue29;
  padding: 0 0.8em;
  @include demi-bold-font;
  font-size: 14px;
  flex: 1;
  &:hover {
    color: $blue29;
    background-color: #fafafa;
  }
  @include mediaQuery("touchable") {
    display: flex;
    border-right: 1px solid $strokeOne;
  }
}

.rightButton {
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0 0.8em;
  color: $blue29;
  @include demi-bold-font;
  font-size: 14px;
  flex: 1;
  &:hover {
    color: $blue29;
    background-color: #fafafa;
  }
  @include mediaQuery("touchable") {
    display: flex;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 142px;
  border: 1px solid $strokeTwo;
  border-radius: 16px;

  &:hover {
    border: 1px solid $gray72;
    cursor: pointer;
  }

  @include mediaQuery("mobile") {
    height: 128px !important;
  }
}

.buttonText {
  font-size: 1.4rem;
  color: $gray13;
  @include medium-font;
  line-height: 1.36;
}

.iconContainer {
  display: flex;
  align-items: center;
}

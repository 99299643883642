@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.modalContent {
  width: 80vw !important;
  height: 90vh !important;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.8rem;
  color: $gray31;
  @include medium-font;
  padding: 0 24px;
  margin-top: 44px;
}

.errorDiv {
  color: $salmon60;
  @include medium-font();
  font-size: 13px;
  margin-top: 4px;
  height: 20px;
}

.stepContainer {
  display: flex;
  flex-direction: column;
  height: calc(100% - 160px);
}
.editStep2 {
  display: flex;
  flex-direction: column;
  padding: 0px 24px 0px;
  overflow: auto;
}

.set {
  margin: 0px 40px 80px;
}

.modalHeader {
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  flex-shrink: 0;
  font-size: 2rem;
  @include demi-bold-font;
}

.modalFooter {
  display: flex;
  z-index: 2;
  height: 64px;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 0px 40px;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: $white;
}

.footerButton {
  display: flex;
  width: $model-footer-button-width;
  margin-right: $model-footer-button-margin-right;
  flex-shrink: 0;
}

.lastButton {
  margin-left: 24px;
}

.userNameComponent {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
}

.modalBody {
  display: flex;
  flex-direction: column;
  padding: 22px 80px;
  flex: 1;
  overflow: scroll;
  flex-shrink: 0;
}

.errorDiv {
  color: $salmon60;
  @include medium-font();
  font-size: 13px;
  margin-top: 4px;
  height: 20px;
}

.dropZoneContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.imageDropZone {
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.profileAvtar {
  cursor: pointer;
  position: relative;
}

.profileBackgroundImage {
  display: flex;
  width: 176px;
  height: 176px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    left: 8px;
    top: 8px;
    border-radius: 100%;
    width: 158px;
    height: 158px;
    box-shadow: 0px 0px 0px 2000px rgba(256, 256, 256, 0.9);
  }
  &:hover {
    &:after {
      display: none;
    }
    .overlayDark {
      display: flex;
      background-color: rgba(0, 0, 0, 0.7);
      justify-content: center;
      align-items: center;
      font-size: 16px;
      @include medium-font;
      color: $white;
      height: 100%;
      width: 100%;
      pointer-events: none;
    }
  }
}

.overlayDark {
  display: none;
}

.profileImage {
  display: flex;
  width: 176px;
  height: 176px;
  border-radius: 4px;

  justify-content: center;
  align-items: center;
  border: 1px dashed $gray72;

  svg {
    width: 32px;
    height: 32px;
  }
  .profileImageBG {
    border-radius: 4px;
    height: 158px;
    width: 158px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $toddleBG1;
    .uploadText {
      font-size: 14px;
      @include medium-font;
    }
  }
}

.inputElement {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  flex-shrink: 0;
}

.horizontalRow {
  display: flex;
  flex-direction: row;
  margin-left: -17px;
  justify-content: space-around;
  margin-top: 32px;
  flex-shrink: 0;
}

.spacing {
  margin-left: -150px;
}

.horizontalRowInput {
  display: flex;
  flex-direction: row;
  // justify-content: left;
  margin-left: -20px;
  margin-top: 32px;
  flex-shrink: 0;
}

.horizontalInputElement {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-right: 24px;
}

.horizontalRadioElement {
  width: 100%;
  margin-left: 20px;
}

.topLevel {
  display: flex;
  justify-content: space-between;
}

.userNameComponent {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
}

.loadingOverlay {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0px;
  left: 0px;
}

.inputText {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 56px;
  border: none;
  resize: none;
  outline: none;
  font-size: 1.6rem;
  background-color: inherit;
  // @include border-class;
  margin-top: 0px;
  padding: 16px 24px;
}

.label {
  @include demi-bold-font;

  font-size: 1.6rem;
  color: $gray13;
  line-height: 2.8rem;
  // margin-top: 10px;
}

.br {
  width: 96%;
  height: 1px;
  margin-top: 20px;
  margin-left: 2%;
  background-color: $strokeOne;
}

.text {
  height: 56px;
  width: 200px;
}

.btnContainer {
  display: flex;
  width: 20px;
  margin-right: 16px;
  flex-shrink: 0;
}

.btn {
  margin-top: 15px;
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  flex-shrink: 0;
  @include bold-font;
  color: white;
  border-radius: 100%;
  background-color: #2dadba;
  border: none;
  outline: 0;
}

.talkbubble {
  display: flex;
  position: sticky;
  z-index: 1;
  height: 56px;
  background-color: lightyellow;
}

.talktext {
  height: 100px;
  position: absolute;
  padding: 1em;
  width: 400px;
  text-align: left;
  line-height: 1.5em;
  background-color: lightyellow;
  z-index: 2;
}

.labelText {
  width: 100%;
  display: flex;
  padding-left: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
  background-color: rgb(248, 248, 248);
}

.inputRadioList {
  @include bold-font;
  margin-top: 12px;
  margin-left: 50px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  // width: 216px;
  height: 208px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid $gray72;
  flex-shrink: 0;
  &:hover {
    border-color: $gray72;
  }
  // margin: 12px;
  flex-direction: column;
}

.selectedContainer {
  border: 1px solid $blue29;
  &:hover {
    border: 1px solid $blue29;
  }
}

.urlViewButton {
  display: none;
  justify-content: center;
  width: 16px;
  height: 16px;
  z-index: 1;
  fill: #5f5f69;
  &:hover {
    fill: #23232d;
  }
}
.container:hover .urlViewButton {
  display: flex;
}

.thumbImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.thumbImage {
  display: flex;
  width: 100%;
  height: 150px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $toddleBG1;
}
.footerBarContainer {
  display: flex;
  bottom: 0;
  height: 56px;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.footerBar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0px 16px 0px 16px;
}
.container:hover .footBarHoverContainer {
  display: flex;
  opacity: 0.4;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, rgba(95, 95, 105, 0), #cdcdd2);
}

.footBarHoverContainer {
  display: none;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.profileInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.profileImage {
  display: flex;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border: 1px solid $strokeOne;
}

.profileName {
  font-size: 1.2rem;
  @include demi-bold-font;
  color: $gray13;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 8px;
  &:hover {
    text-decoration: underline;
  }
}

.source {
  font-size: 1rem;
  width: 100%;
  margin-left: 8px;
  color: $gray48;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-self: flex-end;
  &:hover {
    text-decoration: underline;
  }
}

.container:hover .mediaType {
  display: none;
}

.selectedSvg {
  display: flex;
  cursor: pointer;
  flex-shrink: 0;
  justify-content: flex-end;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  width: 100%;
}

.title {
  font-size: 1.6rem;
  @include demi-bold-font;
  padding-bottom: 4px;
  color: $gray13;
}

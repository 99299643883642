@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

body :global {
  .rc-tooltip-inner {
    color: $white;
    text-align: left;
    text-decoration: none;
    background-color: $black;
    border-radius: 4px;
    min-height: 0;
    font-size: 10px;
    padding: 4px 8px;
    font-size: 1.2rem;
    &:hover {
      text-decoration: none;
    }
  }
  .rc-tooltip {
    z-index: 1100;
  }

  .rc-tooltip-placement-top .rc-tooltip-arrow {
    border-top-color: $black;
  }

  .rc-tooltip-placement-left .rc-tooltip-arrow {
    border-left-color: $black;
  }

  .rc-tooltip-placement-right .rc-tooltip-arrow {
    border-right-color: $black;
  }

  .rc-tooltip-placement-bottom .rc-tooltip-arrow {
    border-bottom-color: $black;
  }
}

.tooltipContainer {
  padding: 8px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.folderTagsContainer {
  display: flex;
  flex-wrap: wrap;
}

.folderTag {
  padding: 4px;
}

.folderTagText {
  display: flex;
  color: $black;
  font-size: 1.4rem;

  @include medium-font;
  @include multiLineEllipsis(1.8rem, 1);
}

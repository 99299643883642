@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
$borderColor: $strokeTwo;
.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  //border: 1px $borderColor solid;
  position: relative;
  justify-content: space-between;
  background-color: $surfaceWhite;
}

.headerContainer {
  display: flex;
  width: 100%;
  min-height: 80px;
  border-bottom: 1px $borderColor solid;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
}

.headerText {
  display: flex;
  @include demi-bold-font;
  font-size: 2rem;
  color: $gray13;
}
.closeButton {
  display: flex;
  width: 24px;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.profileImage {
  display: flex;
  margin-right: 12px;
  align-self: flex-start;
}

.commentfeedcontainer {
  display: flex;
  flex: 1;
  min-height: 0;
}
.loadingAnimationContainer {
  display: flex;
  margin-bottom: 24px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}
.loadingAnimation {
  @include dotAnimation($width: 5px, $height: 5px);
}

.CommentFeed {
  display: flex;
  overflow-y: scroll;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  padding: 16px 0;
}

.commentBoxContainer {
  display: flex;
  width: 100%;
  // background-color: #fff;
  align-items: center;
  flex-direction: column;
  //min-height: 80px;
  // border-top: 1px $borderColor solid;
  padding: 0px 16px 24px 16px;
  // position: absolute;
  bottom: 0;
  //position: relative;
}

.commentBoxOuterWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.commentBoxInnerWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $white;
  border: 1px solid $strokeTwo;
  border-radius: 2px;
  z-index: 8;
  max-height: 176px;
  overflow-y: auto;
}

.commentAudioBtnWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.audioBtnWrapper {
  display: flex;
  margin: 16px 20px 0 0;
  flex-shrink: 0;
  cursor: pointer;
}

.commentAttachmentWrapper {
  display: flex;
}

.noCommentContainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 32px;
  flex-direction: column;
}

.noCommentText {
  display: flex;
  @include regular-font;
  font-size: 1.4rem;
  color: $gray48;
  text-align: center;
}

.noCommentIconBg {
  display: flex;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: $strokeOne;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
}

.cancelIcon {
  display: flex;
  cursor: pointer;
  margin-right: 16px;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.bottomButtonsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.sendButton {
  display: flex;
  padding:0 32px;
  justify-content: center;
}

.seeMoreContainer {
  display: flex;
  padding: 16px;
  color: $blue29;
  @include medium-font;
  font-size: 1.4rem;
  line-height: 22px;
  cursor: pointer;
  flex: 1;
  border-bottom: 1px solid $strokeOne;
}

.commentWrapper {
  .divider,
  .replyDivider {
    margin: 12px 0;
    display: block;
    box-sizing: border-box;
    width: calc(100% - 70px);
    height: 1px;
    background: $strokeOne;
  }
  &:last-child .divider:last-child {
    display: none;
  }
}

.exceedCharacterCountContainer {
  position: absolute;
  bottom: 12px;
  right: 12px;
  color: $textCritical;
  z-index: 10;
  font-size: 1.4rem;
  @include demi-bold-font;
}

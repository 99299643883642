@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.checklistItemContainer {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $borderDefault;
  border-radius: 10px;
  cursor: pointer;
  min-height: 56px;
  &:hover {
    border-color: $borderHover;
  }
  &:hover .actionButton {
    visibility: visible;
  }
}

.rightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}

.iconContainer {
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px;
}

.unselectedComponent {
  border: 1px solid $borderDefault;
  border-radius: 50%;
  width: 17px;
  height: 17px;
}

.actionButton {
  visibility: hidden;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  width: 100%;
}

.modalContent {
  border-radius: 4px !important;
  padding: 40px;

  width: 560px !important;
  height: 440px !important;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.closeIcon {
  fill: $gray48;
  cursor: pointer;
  &:hover {
    fill: $gray31;
  }
}

.headerText {
  display: flex;
  @include bold-font;
  font-size: 3.2rem;
  color: $salmon60;
  margin-bottom: 28px;
}

.detailText {
  display: flex;
  @include medium-font;
  font-size: 2rem;
  line-height: 3.2rem;
  color: $gray31;
}

.border {
  width: 100%;
  display: flex;
  height: 1px;
  border-top: 1px solid $strokeOne;
  margin: 24px 0;
}

.buttonContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 32px;
  margin-top: 32px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container :global {
  .SingleDatePicker_picker {
    z-index: 15 !important;
  }
  display: flex;
  flex-direction: column;
}

.enableDiscussionContainer {
  display: flex;
  border: 1px solid $borderSubtle;
  flex-direction: row;
  border-radius: 4px;
  width: 100%;
  height: 92px;
  padding: 24px;
  align-items: center;
}

.checkbox {
  display: flex;
  margin-right: 24px;
  cursor: pointer;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
}

.title {
  @include demi-bold-font;
  color: $black;
  font-size: 1.6rem;
  margin-bottom: 16px;
}

.subTitle {
  @include medium-font;
  font-size: 1rem;
  color: $gray48;
}

.coursesContainer {
  display: grid;
  grid-row-gap: 24px;
  grid-auto-flow: row;
  width: 100%;
}

.classCard {
  display: flex;
  border: 1px solid $borderSubtle;
  border-radius: 4px;
  padding: 32px;
  flex-direction: column;
}

.classTitleCon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.classTitle {
  @include bold-font;
  font-size: 2rem;
  color: $black;
}

.removeBtn {
  @include medium-font;
  font-size: 1.4rem;
  color: $blue29;
  cursor: pointer;
  &:hover {
    color: $blueActive;
  }
}

.inputField {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
}

.selectStudentHeaderCon {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
}

.addClassModalContainer {
  width: 600px !important;
  height: 600px !important;
}

.settings {
  padding: 24;
  border: 1px solid $borderSubtle;
  border-radius: 6px;
}

.settingCard {
  display: flex;
  border-bottom: 1px solid $borderSubtle;
  flex-direction: row;
  padding: 20px 0px;
  margin: 0px 24px;
  align-items: center;
  justify-content: space-between;
}
.settingCardLast {
  display: flex;
  flex-direction: row;
  padding: 20px 0px;
  margin: 0px 24px;
  align-items: center;
  justify-content: space-between;
}

.lowOpacity {
  opacity: 0.6;
}

.settingLeftContainer {
  display: flex;
  flex-direction: row;
  margin-right: 16px;
  flex: 1;
}

.settingTitle {
  @include medium-font;
  color: $black;
  font-size: 1.6rem;
  margin-right: 8px;
}

.settingSubtitle {
  @include medium-font;
  font-size: 1.4rem;
  color: $gray48;
}

.switch {
  display: flex;
}

.warningTriangleStyle {
  display: flex;

  justify-content: center;
  width: 48px;
}
.button {
  margin-top: 16px;
  width: "auto";
}

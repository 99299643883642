@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  background-color: $white;
  padding: 16px;
  border-radius: 8px;
  &:not(:last-child) {
    border-bottom: 1px solid $borderSubtle;
  }
}

.imageContainer {
  display: flex;
  width: 56px;
  height: 56px;
  background-size: contain;
  margin-right: 16px;
  flex-shrink: 0;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.saveContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.detailContainer {
  display: flex;
  justify-content: space-between;
}

.iconContainer {
  padding-left: 2px;
}
.statusText {
  width: 100%;
  @include medium-font;
  color: $gray48;
  font-size: 1.2rem;
}

.fileName {
  width: 100%;
  @include medium-font;
  color: $gray31;
  font-size: 1.4rem;
  word-break: break-word;
  @include multiLineEllipsis(2rem, 1);
}

.collapseButton {
  display: flex;
}

.labelContainer {
  width: 216px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  color: $textDisabled;
  font-size: 12px;
  @include medium-font;
}

.processing {
  width: 216px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/mixins";

.modalContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.modalHeader {
  display: flex;
  flex-direction: column;
  padding: 32px 40px 0 32px;
}

.modalBody {
  @include scrollContainer;
  flex: 1;
}

.modalInnerContainer {
  display: grid !important;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fill, 64px);
  grid-gap: 24px;
  flex-shrink: 0;
  padding: 32px 40px 0 32px;
  min-height: 0 !important;
}

.modalFooter {
  display: flex;
  box-shadow: 0 -2px 4px 0 rgba($black, 0.1);
  padding: 16px 40px;
  justify-content: flex-end;
  background-color: $white;
  align-items: center;
  column-gap: 16px;
}

.emptyStateContainer {
  display: flex;
  flex: 1;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  column-gap: 16px;
  align-items: center;
}

.pipe {
  width: 1px;
  height: 32px;
  background-color: $gray87;
  margin-right: 8px;
}

.iconContainer {
  height: 28px;
}

.boxContainer {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.textContainer {
  max-width: 192px;
  @include multiLineEllipsis(2.4rem, 1);
}

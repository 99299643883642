@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.topContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.reportContainer {
  margin-top: 48px;
  padding: 0 13.6%;
  position: relative;
  overflow-y: auto;
  height: 100%;
}

.count {
  font-size: 1.6rem;
  @include demi-bold-font;
  color: $gray31;
  line-height: 19px;
  position: sticky;
  top: 0;
  background-color: $white;
  padding-bottom: 24px;
}

.noDataContainer {
  display: flex;
  flex: 1;
}

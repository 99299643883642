@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.checkListContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  transition: all 0.25s ease-out;
}

.checkListContainerCollapsed {
  max-height: 160px;
  overflow: hidden;
}

.checkListContainerExpanded {
  //max-height: 400px;
}

.label {
  display: flex;
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-right: 16px;
}

.label:last-of-type {
  //margin-bottom: 0px;
}

.label:first-of-type {
  //margin-top: 0px;
}
.error {
  display: flex;
  flex-grow: 0;
  font-size: 1.3rem;
  color: $error50;
  margin-top: 6px;
  height: 16px;
}

.expandText {
  color: $blue29;
  margin-top: 8px;
  cursor: pointer;
}

.questionComp {
  margin-bottom: 24px;
}

.emptyText {
  display: flex;
  @include regular-font;
  color: $gray48;
  font-size: 1.2rem;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.customLabel {
  font-size: 1.4rem;
  @include medium-font;
  color: $gray13;
}

.checkIconContainer {
  display: flex;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  border: 1px solid $strokeOne;
}

.checkboxSubtextContainer {
  position: relative;
  top: 4px;
  margin-left: 8px;
}

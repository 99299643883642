@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.successModalContent {
  width: 600px !important;
  height: 389px !important;
}

.removeModalContent {
  width: 600px !important;
  height: 381px !important;
  border-radius: 8px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 48px 60px 56px;
}

.iconContainer {
  display: flex;
  justify-content: center;
}

.textMsg {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  @include bold-font();
  font-size: 1.8rem;
  line-height: 1.33;
}

.infoText {
  display: flex;
  text-align: center;
  margin-top: 16px;
  height: 44px;
  @include medium-font();
  font-size: 1.6rem;
}

.removeChildInfo {
  @extend .infoText;
  width: 400px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.cancelButton {
  &:first-child {
    margin-right: 16px;
  }
}

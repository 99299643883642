@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: $white;
  z-index: 100;
  border-radius: 4px;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.recurringModalContent {
  height: 329px;
  width: 680px;
  border-radius: 16px;
}
.recurringOptionsContainer {
  padding: 48px 40px;
  position: relative;
  height: 100%;
  width: 100%;
}
.recurringButtonsContainer {
  position: absolute;
  bottom: 16px;
  display: flex;
  width: 90%;
  justify-content: flex-end;
}

.modalContent {
  display: flex;
  width: 560px;
  height: unset;
  min-height: 280px;
  background-color: $white;
  z-index: 10;
  border-radius: 4px;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
}

// .modalOverlay {
//   position: fixed;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   background-color: #00000070;
//   z-index: 10;
// }

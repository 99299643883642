@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 0px 48px;
}

.header {
  display: flex;
  margin: 32px 0;
  justify-content: space-between;
  align-items: center;
}
.leftHeader {
  display: flex;
  width: 320px;
}

.rightHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.4rem;
  @include medium-font();
}

.loadingContainer {
  width: 100%;
  margin-top: 24px;
}

.option {
  display: flex;
  width: 100%;
}

.curriculumContainer {
  display: flex;
  flex-direction: column;
  @include medium-font;
}

.plan {
  font-size: 1.2rem;
  color: $gray48;
}

.buddy {
  font-size: 1.4rem;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  margin-bottom: 8px;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonContainer {
  display: flex;
}

.inputLabel {
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $gray13;
  line-height: 2.8rem;
}

.subText {
  // margin-top: 4px;
  color: $gray48;
  @include regular-font;
  font-size: 1.4rem;
}

.h1 {
  display: flex;
  @include bold-font;
  color: $gray13;
  font-size: 2.4rem;
}

.h2 {
  display: flex;
  @include demi-bold-font;
  color: $gray13;
  font-size: 2rem;
}

.subTextTwo {
  font-size: 1.6rem;
  @include regular-font;
  color: $gray13;
  margin-top: 4px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.modalContent {
  width: 600px !important;
  max-height: 616px !important;
  height: 616px !important;
}

.modalBody {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  padding: 40px 40px 12px 40px;
}

.leftContainer {
  display: flex;
  flex-direction: column;
}

.cancelIcon {
  cursor: pointer;
  fill: $gray48;
  z-index: 99;
  &:hover {
    fill: $gray31;
  }
}

.headerMainTxt {
  @include bold-font;
  font-size: 2.4rem;
  color: $gray31;
  margin-bottom: 4px;
}

.centerContainer {
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  flex: 1;
  overflow: auto;
  margin-bottom: 12px;
  white-space: inherit;
  word-break: break-word;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.blockHeading {
  @include bold-font;
  font-size: 1.6rem;
  color: $pink39;
  margin-bottom: 4px;
}

.blockSubText {
  @include medium-font;
  font-size: 1.4rem;
  color: $gray48;
}

.footer {
  padding: 12px 40px;
  display: flex;
  justify-content: flex-end;
  height: 64px;
  box-shadow: 5px 2px 10px 0 rgba(0, 0, 0, 0.2);
}

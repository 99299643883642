@import "~UIComponents/CommonStyles/colors";

.studentList {
  height: 450px;
  overflow: auto;
}

.radioBox {
  height: 72px;
  display: flex;
  align-items: center;
  border-top: 1px solid $borderSubtle;
  border-bottom: 1px solid $borderSubtle;
}

.labelBox {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.searchSection {
  margin: 24px 0;
}

.heading {
    display: flex;
    justify-content: space-between;
}

.studentName {
  color: $textDefault;
}

.headerTitle {
  margin-bottom: 4px;
}

.subheaderTitle {
  color: $textSubtle;
}
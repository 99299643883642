@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: grid;
  overflow-y: auto;

  background-color: $toddleBG1;
  padding: 32px 0;
  align-content: flex-start;
  width: 320px;
}

.backIcon {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin-left: 16px;
  margin-bottom: 40px;
}

.itemListHeader {
  padding: 0 40px;
  margin-bottom: 16px;
  @include demi-bold-font;
  font-size: 1.4rem;
  color: $gray31;
}

.itemList {
  display: grid;
}

.item {
  display: grid;
  border-left: 4px solid transparent;
  padding: 10px 24px;
  @include demi-bold-font;
  color: $black;
  cursor: pointer;
  font-size: 1.4rem;
}

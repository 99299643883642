@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";

$borderColor: $strokeTwo;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.subjectBenchMarkContainer {
  display: flex;
  flex-direction: column;
  //margin-top: 32px;
}
.itemStyle {
  &:not(:last-child) {
    margin-bottom: 48px;
  }
  display: flex;
  flex-direction: column;
}

.itemHeader {
  display: flex;
  margin-bottom: 12px;
}

.itemContent {
  display: flex;
}

.emptyMessageContainer {
  display: flex;
  width: 100%;

  //margin-top: 32px;
}

.emptyMessageTitle {
  @include regular-font;
  margin-top: 8px;
  font-size: 1.6rem;
  color: $gray48;
}

.emptyMessageCentralContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.subjectBenchmarkAddButton {
  cursor: pointer;
}

.subjectBenchmarkHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.subjectBenchmarkHeaderLabel {
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $gray13;
}

.subjectBenchmarkMainContentContainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border: solid 1px $strokeTwo;
  // border: solid 1px var(--white-three);
}

.subjectBenchmarkContentContainer {
  display: flex;
  flex-direction: column;
  margin: 20px 32px 20px 32px;
}

.subjectBenchmarkContentHeader {
  height: 22px;
  @include bold-font;
  font-size: 1.6rem;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $gray13;
}

.subjectBenchmarkContentSubHeader {
  @include medium-font;
  text-transform: capitalize;
  font-size: 1.5rem;
  color: $gray31;
  // color: var(--black-three);
}

.subjectBenchmarkObjectiveContainerFirst {
  display: flex;
  margin-top: 8px;
  flex-direction: column;
}

.subjectBenchmarkObjectiveContainerRest {
  display: flex;
  margin-top: 28px;
  flex-direction: column;
}

.subjectBenchmarkContentList {
  display: flex;
  flex-direction: column;
}

.subjectBenchmarkContentListItemContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.subjectBenchmarkContentListItemContentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subjectBenchmarkContentListItemContent {
  display: flex;
  flex: 1;
  @include regular-font;
  font-size: 1.4rem;
  color: $gray31;
}

.subjectBenchmarkContentListItemDeleteButton {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  transform: rotate(45deg);
  visibility: hidden;
  :hover {
    cursor: pointer;
  }
}

.subjectBenchmarkContentListItemContentContainer:hover
  > .subjectBenchmarkContentListItemDeleteButton {
  visibility: visible;
}

.subjectBenchmarkContentListItemDivider {
  margin-top: 24px;
  height: 2px;
  border: solid 1px $strokeTwo;
}

.overlayContainer {
  z-index: 1005 !important;
}

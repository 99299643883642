@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: grid;
  width: 100%;
  height: 100%;
  background-color: $toddleBG2;
  position: relative;
}

.wrapperContainer {
  display: grid;
  justify-items: flex-start;
  align-content: center;
  grid-row-gap: 16px;
  margin: auto;
  width: 100%;
  max-width: 1112px;
  grid-template-rows: 24px 400px;
}

.cancelIcon {
  display: flex;
  position: absolute;
  top: 40px;
  right: 40px;
  fill: $gray48;
  cursor: pointer;
  &:hover {
    fill: $gray31;
  }
}
.premiumContainer {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-column-gap: 12px;
}

.premiumText {
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $salmon60;
}

.innerContainer {
  display: grid;
  width: 100%;
  grid-column-gap: 40px;
  grid-auto-flow: column;
  grid-template-columns: 508px 1fr;
}

.textContainer {
  display: grid;
  grid-auto-flow: row;
  margin-bottom: 24px;
  align-content: space-between;
}

.topTextContainer {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 32px;
}

.heading {
  @include bold-font;
  font-size: 3rem;
  color: $seaweedTwo;
}

.subHeading {
  @include medium-font;
  font-size: 1.8rem;
  color: $gray31;
}

.bottomTextContainer {
  display: grid;
  grid-row-gap: 24px;
  justify-content: flex-start;
}

.upgradeContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 4px;
}

.upgradeButton {
  display: grid;
  width: 240px;
}

.plansText {
  display: grid;
  @include medium-font;
  text-decoration: underline;
  font-size: 1.6rem;
  color: $blue29;
  cursor: pointer;
}

.upgradeText {
  display: grid;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $salmon60;
}

.teacherText {
  font-size: 1.5rem;
  color: $salmon60;
}

.imageContainer {
  display: grid;
  height: 100%;
}

.image {
  height: 400px;
  justify-self: flex-end;
}

.scrollDownIcon {
  cursor: pointer;
  left: 50%;
  position: absolute;
  bottom: 32px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

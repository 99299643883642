@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  flex-direction: column;
  padding: 0px;
}

.itemContainer {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
  cursor: pointer;
}

.iconContainer {
  display: flex;
  width: 32px;
  height: 32px;
  // marginTop: 8,
  align-self: center;
  // backgroundColor: colors.snow,
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.nameContainer {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  justify-content: center;
  align-items: flex-start;
  flex-basis: 70%;
}

.photoHeading {
  display: flex;
  font-size: 1.8rem;
  color: $black;
  @include bold-font;
}

.subText {
  display: flex;
  font-size: 1.4rem;
  color: $gray48;
  @include medium-font;
}

.svgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 16px;
}

.labelAndSubLabelContainer {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  justify-content: center;
  height: 100%;
}

.buttonContainer {
  display: flex;
  align-items: center;
  height: max-content;
  flex-shrink: 0;
  column-gap: 4px;
}

.labelDetails {
  display: flex;
  cursor: pointer;
  padding-right: 16px;
  border-right: 1px solid #dbdbdb;
  &:not(:last-child) {
    margin-right: 16px;
  }
}

.labelHelper {
  display: flex;
  justify-content: flex-end;

  cursor: pointer;
  &:not(:last-child) {
    margin-right: 8px;
  }
}
.commentHelper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 8px;
  }
}

.extraButton {
  display: flex;
  &:not(:last-child) {
    margin-right: 16px;
  }
}

.detailIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.inputLabel {
  color: $gray13;
  padding-right: 32px;
  //min-height: 2.8rem;
  white-space: pre-wrap;
  // word-break: break-word;
  width: fit-content;
}

.subText {
  //margin-top: 4px;
  color: $gray48;
}

.iconBlue {
  color: $blue29;
  fill: $blue29;
  &:hover {
    fill: #248a94;
    color: #248a94;
  }
}

.iconGrey {
  color: $gray48;
  fill: $iconDefault;
  &:hover {
    fill: $iconHover;
    color: $gray31;
  }
}

.iconPink {
  color: $salmon60;
  fill: $salmon60;
  &:hover {
    fill: #e35259;
    color: #e35259;
  }
}

.h1 {
  display: flex;
  @include bold-font;
  color: $gray13;
  font-size: 2.4rem;
}

.h2 {
  display: flex;
  @include demi-bold-font;
  color: $gray13;
  font-size: 2rem;
}

.propmtsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 12px 0;
}

.propmtLabelArrowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: max-content;
}

.propmtLabelText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.4rem;
  color: $blue29;
  margin-right: 6px;
}

.prompTextContainer {
  display: flex;
  padding: 32px 24px;
  width: 100%;
  background-color: $toddleBG1;
  margin: 8px 0 4px 0;
  @include border-class;
}

.promptText {
  display: flex;
  flex-direction: column;
  word-break: break-word;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray13;
  white-space: pre-wrap;
  line-height: 2rem;
  p {
    height: 1.4rem;
    margin: 0px;
  }
  li {
    margin-top: 14px;
  }
  ul {
    padding-left: 16px;
    margin: 0px;
  }
}

.arrowContainer {
  display: flex;
}

.openArrow {
  transform: rotate(-180deg);
  transition: transform 0.3s;
}

.closeArrow {
  transform: rotate(0deg);
  transition: transform 0.3s;
}

.iconContainer {
  display: flex;
  margin-right: 16px;
  height: 48px;
  width: 48px;
}

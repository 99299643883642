@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.itemContainer {
  height: 185px;
  width: 100%;
  padding: 32px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid $strokeTwo;
}

.imageContainer {
  display: flex;
  width: 120px;
  height: 100%;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: $toddleBG1;
  overflow: hidden;
  border: 1px solid $strokeTwo;
  padding: 14px 0px;
}

.label {
  font-size: 1.4rem;
  @include regular-font;
  color: $gray72;
  line-height: 1.42;
}

.detailsContainer {
  flex-basis: 75%;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  .setName {
    font-size: 2rem;
    @include bold-font;
    color: $black;
    line-height: 1.4;
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.downloadIcon {
  margin-right: 32px;
  margin-top: 12px;
  fill: $iconDefault;
  &:hover {
    cursor: pointer;
    fill: $iconHover;
  }
}

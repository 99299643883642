@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.termsAndConditions {
  font-size: 1.2rem;
  color: $gray48;
  line-height: 1.67;
  @include medium-font;
  margin-right: 40px;
  a,
  a:visited {
    color: $blue29;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.classCard {
  display: flex;
  border: 1px solid $strokeOne;
  border-radius: 6px;
  padding: 32px;
  flex-direction: column;
}

.classTitleCon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.classTitle {
  @include bold-font;
  font-size: 2rem;
  color: $black;
}

.studentInputField {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
}

.selectStudentHeaderCon {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
}

.headerLabel {
  display: flex;
  flex-direction: column;
  @include bold-font;
  font-size: 1.4rem;
  color: $gray13;
}

.removeBtn {
  @include medium-font;
  font-size: 1.4rem;
  color: $blue29;
  cursor: pointer;
  &:hover {
    color: $blueActive;
  }
}

.addClassModalContainer {
  width: 560px !important;
  height: 600px !important;
}

.submissionDeadlineCon {
  display: flex;
  flex-direction: column;
}

.submissionDeadlineHeaderCon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.headerLabel {
  display: flex;
  flex-direction: column;
  @include demi-bold-font;
  font-size: 1.4rem;
  color: $gray13;
}

.horizontalRowContainer {
  display: grid;
  margin-top: 16px;
  grid-auto-flow: column;
  grid-column-gap: 24px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.item {
  // padding: 16px;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  position: relative;
  cursor: pointer !important;
  align-items: center;

  &:hover {
    .menuContainer {
      visibility: visible;
    }
  }
}
.itemDetailContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.itemDetailLeftContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.itemImageContainer {
  width: 64px;
  height: 48px;
  display: flex;
  flex-shrink: 0;
}

.itemContentContainer {
  flex: 1;
  display: flex;
  padding-left: 16px;
  flex-direction: row;
  align-items: center;
}

.othertextcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.othertextInnercontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.otherText {
  display: flex;
  @include regular-font;
  color: $gray48;
  font-size: 1.4rem;
}

.dot {
  display: flex;
  @include regular-font;
  color: $gray72;
  font-size: 1rem;
  padding: 0 4px;
}

.commentIcon {
  display: flex;
  height: 24px;
  display: none;
}

.moreIcon {
  display: flex;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.title {
  @include demi-bold-font;
  font-size: 1.4rem;
  color: $gray31;
  @include multiLineEllipsis(2rem, 1);
}

.createdBy {
  @include regular-font;
  font-size: 1.4rem;
  color: $gray48;
}

.bottomContainer {
  margin-top: 16px;
  padding-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menuContainer {
  display: flex;
  visibility: hidden;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.gridHeaderContainer {
  margin-top: 28px;
  display: grid;
  grid-template-columns: repeat(3, 1fr) 10%;
  padding: 16px 0px 16px 0px;
  border-top: 1px solid $strokeOne;
  border-bottom: 1px solid $strokeOne;
}

.gridHeaderSMSColumn {
  grid-template-columns: repeat(4, 1fr) 10%;
}

.gridHeaderEmailColumn {
  grid-template-columns: repeat(5, 1fr) 10%;
}

.row {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 10%;
}

.rowSMSColumn {
  grid-template-columns: repeat(4, 1fr) 10%;
}

.rowEmailColumn {
  grid-template-columns: repeat(5, 1fr) 10%;
}

.gridHeaderLabel {
  display: flex;
  @include bold-font;
  font-size: 1.4rem;
  color: $gray13;
  justify-content: center;
}

.twoLineTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subTextLabel {
  font-size: 1.2rem;
  color: $textDefault;
}

.gridDataContainer {
  display: flex;
  flex-direction: column;
}

.gridItemContainer {
  display: flex;
  padding: 16px 0px 16px 0px;
  border-bottom: 1px solid $strokeOne;
  align-items: center;
  min-height: 70px;
  color: $gray13;
  justify-content: center;
  @include medium-font;
}

.addButtonContainer {
  composes: gridDataContainer;
  margin-top: 24px;
  span:nth-child(2) {
    margin-left: 8px;
  }
}

.actionsContainer {
  composes: gridItemContainer;
  span {
    cursor: pointer;
  }
  span:nth-child(1) {
    margin-right: 16px;
  }
}

.title {
  @include bold-font;
  font-size: 1.8rem;
  line-height: 1.37;
  margin-bottom: 4px;
}

.subTitle {
  color: $gray48;
  font-size: 1.4rem;
  line-height: 1.36;
}

.headingContainer {
  padding-bottom: 20px;
  border-bottom: 1px solid $gray31;
}

.attendanceSetupHeadingContainer {
  margin-top: 40px;
}

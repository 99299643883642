@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;
}

.startItem {
  display: flex;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: $gray72;
  border-radius: 4px;
  position: relative;
  justify-content: center;
  cursor: pointer;
}

.labelContainer {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  @include bold-font;
  color: $gray72;
  font-size: 1.2rem;
}

.circle {
  display: flex;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  background-color: $white;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.cancelIcon {
  display: flex;
  position: absolute;
  top: 40px;
  cursor: pointer;
  right: 40px;
  z-index: 1;
  fill: rgba(0, 0, 0, 0.4);
}

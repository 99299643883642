@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.searchContainer {
  display: flex;
  flex-direction: column;
  max-height: 380px;
  width: 250px;
  overflow: auto;
}

.contentContainer {
  background-color: $white;
  display: flex;
  flex-direction: column;
  border-radius: 16px 16px 0px 0px;
}

.headerContainer {
  padding: 0 40px;
}

.headerText {
  display: flex;
  @include bold-font;
  font-size: 2.4rem;
  color: $gray13;
  margin-top: 12px;
}

.scrollContainer {
  flex-grow: 1;
}

.topicRow {
  display: flex;
  align-items: center;
  padding: 0px 24px;
  &:hover {
    background-color: $gray98;
  }
}

.topicRowDisabled {
  display: flex;
  align-items: center;
  padding: 0px 24px;
}

.topicText {
  margin: 20px 0;
  @include bold-font;
  color: $teal20;
  font-size: 1.4rem;
}

.textSelected {
  color: $blue29 !important;
}

.subTopicText {
  margin: 12px 0px;
  @include medium-font;
  color: $gray31;
  font-size: 1.4rem;
}

.radioButton {
  margin-bottom: 14px;
}

.divider {
  height: 1px;
  margin-left: 24px;
  margin-right: 24px;
  background-color: $lineBackground;
}

.dividerDark {
  height: 1px;
  width: 100%;
  background-color: $strokeTwo;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.containerOpen {
  border-right: 1px solid $strokeTwo;
  background: $white;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 272px;
}

.containerClose {
  min-width: 0px;
  position: relative;
}

.noDataContainer {
  margin: 0px 24px 0px 24px;
  color: $gray72;
}

.openButtonCloseButtonCommon {
  cursor: pointer;
  position: sticky;
  z-index: 3;
}

.closeButton {
  composes: openButtonCloseButtonCommon;
  box-shadow: 6px -1px 16px rgba(0, 0, 0, 0.25);
  border-top-right-radius: 26px;
  border-bottom-right-radius: 26px;
  padding: 12px 8px 6px 4px;
  top: 8px;
  width: max-content;
}

.openButton {
  composes: openButtonCloseButtonCommon;
  top: 8px;
  background: $gray31;
  border-top-right-radius: 26px;
  border-bottom-right-radius: 26px;
  padding: 10px 8px 4px 4px;
  margin: 12px 0px 0px 0px;
}

.innerContainerHidden {
  display: none;
}

.innerContainerShow {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.header {
  margin: 40px 0px 0px 24px;
  @include bold-font;
  font-size: 1.4rem;
  color: $gray48;
}

.itemsContainer {
  margin: 32px 0px 0px 0px;
}

.itemContainerCommon {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px 12px 20px;
  @include demi-bold-font;
  align-items: center;
  cursor: pointer;
}

.itemContainer {
  composes: itemContainerCommon;
  border-left: 4px solid $white;
}

.itemContainerSelected {
  border-left-color: $salmon60;
}

.ellipseContainer {
  flex: 1;
  @include multiLineEllipsis(2rem, 1);
}

.labelDepth1 {
  composes: ellipseContainer;
  @include demi-bold-font();
  color: $black;
}

.labelDepth1Selected {
  composes: ellipseContainer;
  @include bold-font();
}

.labelDepth2 {
  margin: 16px 24px 8px 48px;
  composes: ellipseContainer;
  cursor: pointer;
}

.labelDepth2Selected {
  composes: ellipseContainer;
  cursor: pointer;
  @include demi-bold-font();
}

.labelDepth2EmptyLabel {
  margin: 16px 24px 8px 48px;
  composes: ellipseContainer;
}

.arrowSvg {
  transition: all 0.3s ease;
  transform: rotate(0deg);
}

.arrowSvgSelected {
  transform: rotate(180deg);
}

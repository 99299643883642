@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.headerContainer {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  border-bottom: 1px solid $borderSubtle;
}

.leftContainer,
.leftInnerContainer,
.rightContainer,
.chevronIconContainer {
  display: flex;
  align-items: center;
}

.leftInnerContainer {
  cursor: pointer;
}

.chevronIconContainer {
  margin-right: 12px;
}

.details {
  display: flex;
  margin-left: 16px;
}

.title {
  font-size: 1.8rem;
  @include bold-font;
  color: $textDefault;
}

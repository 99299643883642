:global {
  @import "./normalize.scss";
  @import "~UIComponents/CommonStyles/colors";
  @import "@toddle-design/theme/dist/assets/fontStyles.scss";

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    ::selection {
      color: initial;
      background: highlight;
    }
  }
  th {
    @include bold-font; //this is the browser default. It was being reset by tailwind.
  }
  blockquote,
  dd,
  dl,
  figure,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  p,
  pre {
    margin: revert;
  }
  img,
  video {
    max-width: initial;
    height: initial;
    // max-width: 100%;
    // height: auto;
  }
  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: initial;
    vertical-align: initial;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  body :global {
    .picker-dialog-bg {
      z-index: 1100;
    }
    .picker-dialog {
      z-index: 1101;
    }

    button:focus {
      //tailwind is adding some property on button outline on focus
      outline: none;
    }
    .toast-title {
      font-weight: 500 !important;
    }
    //Do not change
    .react-viewer-canvas {
      top: 40px !important;
      bottom: 104px !important;
      z-index: 1100 !important;
    }
    .react-viewer-footer {
      z-index: 1100 !important;
    }
    .react-viewer-close {
      z-index: 1101 !important;
    }

    .archivedBg {
      background: repeating-linear-gradient(
        45deg,
        $strokeOne,
        $strokeOne 1px,
        $white 2px,
        $white 8px
      );
    }
    .archivedLabel {
      font-size: 1.2rem;
      color: $textSubtle;
    }
    .disabled {
      cursor: not-allowed;
      color: $textDisabled;
    }
  }

  $placeholderColor: $gray48;

  $placeholderOpacity: 0.54;
  textarea::-webkit-input-placeholder {
    color: $placeholderColor;
  }

  textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: $placeholderColor;
    opacity: $placeholderOpacity;
  }

  textarea:-ms-input-placeholder {
    color: $placeholderColor;
    opacity: $placeholderOpacity;
  }

  textarea::placeholder {
    color: $placeholderColor;
    opacity: $placeholderOpacity;
  }

  ::-webkit-input-placeholder {
    /* Chrome */
    color: $placeholderColor;
    opacity: $placeholderOpacity;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: $placeholderColor;
    opacity: $placeholderOpacity;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $placeholderColor;
    opacity: $placeholderOpacity;
  }
  :-moz-placeholder {
    /* Firefox 4 - 18 */
    color: $placeholderColor;
    opacity: $placeholderOpacity;
  }

  html {
    font-size: 10px;
    overflow: hidden;
  }
  body {
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
  }
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-overflow-scrolling: touch;
  }
  #root {
    background: #f0f3f8;
  }

  a {
    text-decoration: none;
  }
}

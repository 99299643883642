@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
}

.goalGroupContainer {
  display: flex;
  flex-direction: column;

  flex-shrink: 0;
}

.pypElementRowContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 8px;
  margin-top: 8px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 6px 0px;
}

.pypElementText {
  display: flex;
  @include medium-font;
  color: $gray31;
  font-size: 1.4rem;
  word-break: break-word;
  width: 80%;
}

.checkBoxContainer {
  display: flex;
  width: 24px;
  margin-right: 0px;
  align-self: flex-start;
}

.allSelectCheckBoxRow {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  margin-top: 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $strokeOne;
  padding-bottom: 16px;
  cursor: pointer;
}

.selectAllText {
  display: flex;
  @include medium-font;
  color: $gray31;
  font-size: 1.8rem;
  cursor: pointer;
}

.searchBarContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 10px;
}

.searchBar {
  border: 1px solid $strokeTwo;
  flex: 0.7;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.searchBarActive {
  border: 1px solid $blue29;
}

.searchFilterContainer {
  display: flex;
  flex: 0.3;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
}

.searchFilterText {
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.onSearchSectionContainer {
  margin: 4px 0px 16px 0px;
  // border-bottom: 1px solid $lineBackground;
}

.searchBarMargin {
  margin-bottom: 30px;
}

.onSearchSectionHeader {
  font-size: 1.6rem;
  @include bold-font;
  margin-bottom: 16px;
  color: $teal20;
}

.noElementText {
  width: 100%;
  text-align: center;
  margin-top: 12px;
  font-size: 14px;
}

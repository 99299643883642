@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 16px;
  flex-shrink: 0;
}

.attachmentHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 24px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
}
.iconSvgContainer {
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 1px solid $strokeTwo;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.createContainer {
  display: grid;
  position: relative;
}

.seeLessBtn {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-top: 12px;
  color: $blue29;
  font-size: 1.4rem;
  line-height: 2rem;
  cursor: pointer;
  @include medium-font;
}

.seeMoreBtn {
  color: $blue29;
  font-size: 1.4rem;
  line-height: 2rem;
  cursor: pointer;
  @include medium-font;
}
.emptyText {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray72;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  min-height: 100%;
}

.unitResourceCount {
  width: 100%;
  height: 16px;
  font-size: 1.2rem;
  @include medium-font;
  color: $teal20;
}

.footerButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}

.footerText {
  display: flex;
  font-size: 1.6rem;
  color: $gray48;
}

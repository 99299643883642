@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  gap: 40px;
}

.dateContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.classCard {
  display: flex;
  border: 1px solid $strokeOne;
  border-radius: 6px;
  padding: 32px;
  flex-direction: column;
}

.classTitleCon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.classTitle {
  @include bold-font;
  font-size: 2rem;
  color: $black;
}

.studentInputField {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
}

.selectStudentHeaderCon {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
}

.headerLabel {
  display: flex;
  flex-direction: column;
  @include bold-font;
  font-size: 1.4rem;
  color: $gray13;
}

.removeBtn {
  @include medium-font;
  font-size: 1.4rem;
  color: $blue29;
  cursor: pointer;
  &:hover {
    color: $blueActive;
  }
}

.addClassModalContainer {
  width: 560px !important;
  height: 600px !important;
}

.submissionDeadlineCon {
  display: flex;
  flex-direction: column;
}

.submissionDeadlineHeaderCon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.headerLabel {
  display: flex;
  flex-direction: column;
  @include demi-bold-font;
  font-size: 1.4rem;
  color: $gray13;
}

.horizontalRowContainer {
  display: grid;
  margin-top: 16px;
  grid-auto-flow: column;
  grid-column-gap: 24px;
}

.cardContainer {
  border: 1px solid $borderSubtle;
  border-top: none;
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.cardInnerContainer {
  display: grid;
  grid-template-columns: 128px 90px 272px 40px;
  align-items: center;
  padding: 16px;
  gap: 40px;
}

.classNameLabel {
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $textDefault;
  word-break: break-all;
  @include multiLineEllipsis(2.4rem, 1, 15);
}

.deleteClassBtn {
  display: flex;
  justify-content: flex-end;
}

.dateTimeContainer {
  display: flex;
}

.dateContainer {
  margin-right: 8px;
}

.taggedStudentsContainer {
  display: flex;
  padding: 8px;
  border: 1px solid $borderSubtle;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    border: 1px solid $borderHover;
  }
}

.dateContainer :global {
  .SingleDatePickerInput_calendarIcon {
    display: flex;
    align-items: center;
    padding: 12px 4px 12px 16px;
    margin: 0;
  }

  .DateSelector__container___2D2VL input[type="number"],
  .DateSelector__container___2D2VL input[type="text"] {
    font-size: 1.6rem;
    height: 100%;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.viewEmptyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 288px;
  height: 64px;
  color: $textDisabled;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
}

.disabledViewEmptyContainer {
  composes: viewEmptyContainer;
  @include medium-font;
  background-color: $surfaceDisabled;
}

.separator {
  margin-top: 32px;
  border-bottom: 1px solid $gray92;
}

.seeMoreText {
  color: $blue29;
  cursor: pointer;
}

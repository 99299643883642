@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;

  padding: 80px 0 0;
  align-items: center;

  .labels {
    display: flex;
    flex-direction: column;

    margin-top: 32px;
    align-items: center;
    width: 480px;

    .label {
      @include demi-bold-font;

      font-size: 1.8rem;
      line-height: 2.6rem;
      margin-bottom: 8px;
    }

    .subLabel {
      @include medium-font;

      font-size: 1.6rem;
      line-height: 2.6rem;
      color: $gray48;
      text-align: center;
    }
  }
}

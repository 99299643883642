@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  padding: 32px;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}

.noSearchTopContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

.SearchDataText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $gray31;
  margin-bottom: 16px;
}

.noSearchIcon {
  display: flex;
  margin-bottom: 16px;
}

.noSearchText {
  display: flex;
  @include regular-font;
  font-size: 1.6rem;
  color: $gray48;
  text-align: center;
  white-space: pre-line;
  line-height: 3rem;
}

.noSearchDataText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $gray31;
  margin-bottom: 16px;
}

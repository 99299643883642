@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.timeComponent {
  padding: 4px 16px; //added Since date and time are not same heighted
  display: flex;
  height: 56px;
  flex-direction: row;
  align-items: center;
}

.borderElem {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid $strokeTwo;
  border-radius: 4px;
}

.timeSvg {
  display: flex;
  align-items: center;
  position: absolute;
  padding-left: 12px;
  cursor: pointer;
  z-index: 1;
}

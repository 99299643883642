@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid $borderSubtle;
  border-radius: 4px;
  padding: 24px;
}

.containerWithLabelAndIcon {
  display: flex;
  flex-direction: column;
  width: 270px;
  row-gap: 8px;
}

.boxContainer {
  display: flex;
  border: 1px solid $borderSubtle;
  border-radius: 4px;
  background-color: $surfaceLight;
  height: 56px;
  padding: 0 16px;
  align-items: center;
  column-gap: 16px;
}

.iconContainer {
  height: 24px;
}

.textContainer {
  @include medium-font;
  color: $textSubtle;
  font-size: 1.6rem;
  max-width: 170px;
  @include multiLineEllipsis(2rem, 1);
}

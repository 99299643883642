@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.headerCon {
  display: flex;
  width: 100%;
  height: 56px;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 24px;
  border-bottom: 1px solid $borderSubtle;
}

.modalBody {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.addClassesLabel {
  @include bold-font;
  font-size: 2rem;
  color: $textDefault;
}

.footerCon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 72px;
  padding: 16px 24px;
  border-top: 1px solid $borderSubtle;
}

.closeIconCon {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.headerDetails {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}

.subText {
  margin-top: 8px;
  color: $gray48;
  @include regular-font;
  font-size: 1.4rem;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  //overflow-x: auto;
}

.evaluationContentContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  cursor: pointer;
}

.evaluationContent {
  display: flex;
  align-items: center;
  background-color: $surfaceDefault;
  border-radius: 4px;
  padding: 8px 12px;
  &:hover {
    background-color: $surfacePressed;
  }
}

.evaluationIcon {
  display: flex;
  align-items: center;
}

.evaluationTitle {
  display: flex;
  align-items: center;
  margin-left: 8px;
  @include medium-font;
  font-size: 1.2rem;
  @include multiLineEllipsis(1.63rem, 1);
}

@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";

.chipContainer {
  display: flex;
  align-items: center;
  padding: 13px 16px;
  height: 32px;
  border: 1px solid $gray92;
  border-radius: 4px;
  margin-right: 8px;
  &:focus {
    background-color: $gray95;
    outline: none;
  }
}

.focusState {
  background-color: $gray95;
  outline: none;
}

.chipIcon {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.chipCloseIcon {
  margin-left: 8px;
}

@import "~UIComponents/CommonStyles/colors";

.addButtonContainer {
  display: flex;
  width: 100%;
  height: 268px;
  border-radius: 8px;
  padding: 8px;
  border: 1px dashed $gray72;
}

.addButton {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 8px;
  background-color: $surfaceSubtle;
  justify-content: center;
  align-items: center;
}

.addButtonText {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  line-height: 1.912rem;
  color: $textSubtle;
}

.figure {
  width: 100%;
  height: 268px;
  margin: 0;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.figureContainer {
  display: flex;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba($black, 0.7);
  color: $white;
}

.figureContainer:hover .overlay {
  display: flex;
}

@import "~UIComponents/CommonStyles/colors";

.iconContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $borderDefault;
  border-radius: 2px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.player {
  display: flex;
  width: 100%;
  z-index: 1;
}

.controls {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 12px 8px 12px 12px;
  align-items: center;
}
.secondaryMediaControls {
  padding: 8px 12px 8px 8px;
}
.playerButton {
  display: flex;
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: $white;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $white;
  }
  svg {
    color: $violet63 !important;
  }
}

.audioUploading {
  cursor: not-allowed;
  svg {
    color: $iconDisabled !important;
  }
}

.secondaryPlayerButton {
  width: 32px;
  height: 32px;
  background-color: $white !important;
}

.bar {
  user-select: none;
  flex: 1;
  display: flex;
  margin-left: 12px;
  flex-direction: column;
}

.secondaryMediaBar {
  flex-direction: row;
  align-items: center;
  margin-top: 0;
}
.barDuration {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 6px;
  line-height: 12px;
}
.fileName {
  display: flex;
  margin-bottom: 6px;
  font-size: 1rem;
  line-height: 12px;
  @include medium-font;
  color: $textDefault;
}

.barTime {
  color: $gray48;
  font-size: 1rem;
  @include medium-font;
}

.bar__progress {
  height: 1px;
  display: flex;
  width: 100%;
  align-items: center;
  background-color: $gray87;
  cursor: pointer;
}

.barProgressLight {
  background-color: $violet63;
}

.bar__progress__knob {
  position: relative;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: $violet63;
}

.bar_progress_uploading {
  background: $iconDisabled;
}

.audioBGSvg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.1;
}

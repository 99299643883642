@import "~UIComponents/CommonStyles/mixins";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";

.header {
  display: flex;
  margin-bottom: 32px;
  align-items: center;
  padding: 40px 40px 0px 40px;
}

.info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.title {
  display: flex;
  font-size: 2rem;
  @include bold-font;
}

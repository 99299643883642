@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.iconContainer {
  display: flex;
  width: 132px;
  height: 132px;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.iconContainer > img {
  width: 132px;
  height: 132px;
}

.iconText {
  display: flex;
  font-size: 2rem;
  color: $gray13;
  text-align: center;
  @include medium-font;
  white-space: nowrap;
}

.activeIconContainer {
  transform: translateY(-4px);
}

.iconContainer:hover .svgIcon {
  transform: translateY(-4px);
}

.svgIcon {
  transition: 0.2s ease;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.academicSwitcherButton {
  background-color: $gray98;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 0px 16px 0px 20px;
}

.yearContainer {
  display: flex;
  align-items: center;
}

.currentYear {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 16px;
  height: 40px;
  @include medium-font;
}

.yearLabel {
  color: $gray72;
  font-size: 1.2rem;
}

.yearValue {
  color: $black;
  font-size: 1.4rem;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  margin: 48px 28px 24px 40px;
}

.filtersContainer {
  display: flex;
  justify-content: space-between;
  background: $white;
  align-items: center;
}

.leftContainer {
  display: flex;
}
.rightContainer {
  display: flex;
}

.warningContainer {
  background: $yellow96;
  display: flex;
  padding: 16px;
  margin: 40px 0px 0px 0px;
  position: relative;
  align-items: center;
}

.dropdownClasses {
  max-width: 400px;
  word-break: break-all;
}

.warningText {
  margin: 0px 24px 0px 0px;
  @include medium-font;
}

.svgContainerWarning {
  margin: 0px 12px 0px 0px;
  display: flex;
}

.svgContainerClose {
  cursor: pointer;
  position: absolute;
  right: 16px;
  display: flex;
}

.icon {
  display: flex;
  width: 24px;
  height: 24px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.label {
  margin-left: 16px;
}

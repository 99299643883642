.body {
  display: flex;
  flex-direction: column;
}
.container {
  display: flex;
  width: 100%;

  padding: 0px 40px 40px;
  flex: 1;
  min-height: 0;
}
.header {
  display: flex;
  // width: 400px;
  width: 100%;
  padding: 0px 40px;
  flex-shrink: 0;
  justify-content: left;
  align-items: center;
  justify-content: space-between;
}
.dropDownContainer {
  display: flex;
  width: 400px;
  padding: 24px 0px;
  flex-shrink: 0;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

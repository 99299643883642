@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.modalContent {
  width: 70vw !important;
  height: 75vh !important;
  overflow: hidden;
  z-index: 3;
}

.stepContainer {
  height: calc(100% - 150px);
  overflow: auto;
  z-index: 1;
}

.modalHeader {
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  flex-shrink: 0;
  font-size: 2rem;
  padding: 0 24px;
  margin-top: 22px;
  @include demi-bold-font;
}

.modalFooter {
  display: flex;
  z-index: 2;
  height: 64px;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 0px 40px;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
  background-color: $white;
}

.br {
  height: 1px;
  margin: 20px 20px 0;
  background-color: $strokeOne;
}

.footerButton {
  display: flex;
  width: $model-footer-button-width;
  margin-right: $model-footer-button-margin-right;
  flex-shrink: 0;
}

.lastButton {
  margin-left: 24px;
}

.horizontalRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 4vw;
  flex-shrink: 0;
  align-items: center;
  z-index: 2;
}

.currentYearRow {
  background-color: yellow;
}

.horizontalInputElement {
  display: flex;
  width: 48%;
  padding: 0 10px;
}

.horizontalIconElement {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  padding: 0 10px;
  cursor: pointer;
}

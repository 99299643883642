@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.cardContainer {
  border-radius: 4px;
  border: 1px solid $strokeOne;
  padding: 12px 16px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  height: max-content;
  gap: 12px;
  &:hover:not(.buttonType) {
    border-color: $textLink;
  }
}

.labelContainer {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  overflow: hidden;
}

.label {
  font-size: 1.4rem;
  @include demi-bold-font;
  color: $gray13;
  @include multiLineEllipsis(2rem, 1);
}

.checkIconContainer {
  display: flex;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex: 0 0 auto;
  border: 1px solid $strokeOne;
}

.value {
  font-size: 1.2rem;
  @include regular-font;
  margin-top: 2px;
  color: $gray48;
  overflow: hidden;
  @include multiLineEllipsis(1.4rem, 1);
}

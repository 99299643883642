@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  padding: 40px 40px 0 40px;
}

.leftPaneTitleText {
  display: flex;
  font-size: 2.4rem;
  @include bold-font;
  color: $gray13;
  margin-bottom: 40px;
}

.leftPaneTitleTextWithSubtext {
  composes: leftPaneTitleText;
  margin-bottom: 4px;
}

.headerTabContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.feedContainer {
  display: flex;
  flex: 1;
  padding: 0 40px;
  flex-direction: column;
  overflow-y: scroll;
}

.feedInnerContainer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: max-height;
  flex-shrink: 0;
}

.feedDataListContainer {
  border: 1px solid $borderSubtle;
  padding: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 4px;
  height: 120px;
  cursor: pointer;
  &:hover {
    border: 1px solid $borderHover;
  }
}

.imageContainerLe {
  width: 104px;
  height: 88px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
}

.rightPane {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 16px;
  svg {
    flex-shrink: 0;
  }
}

.leDetails {
  display: flex;
  flex-direction: column;
}

.durationAndTool {
  display: flex;
  font-size: 1.2rem;
  @include medium-font;
  color: $gray48;
}

.staffDetails {
  display: flex;
  margin-top: 16px;
}

.staffDetailsPostFeed {
  display: flex;
  margin-top: 9px;
}

.profileImageContainer {
  display: flex;
  width: 24px;
  height: 24px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 50%;
}

.staffName {
  display: flex;
  font-size: 1.4rem;
  color: $gray48;
  @include medium-font;
}

.staffNameWithUserPic {
  composes: staffName;
  align-items: center;
  margin-left: 8px;
}

.loadingContainer {
  width: 100%;
  margin-top: 24px;
  display: flex;
}

.titleText {
  font-size: 1.6rem;
  color: $gray31;
  @include bold-font;
  display: flex;
  margin: 24px 0 16px 0;
}

.feedItemTitleText {
  font-size: 1.8rem;
  color: $gray13;
  margin-bottom: 4px;
  @include demi-bold-font;
  @include multiLineEllipsis(2.2rem, 1);
}

.postItemTitleText {
  font-size: 1.6rem;
  color: $gray13;
  //margin-bottom: 4px;
  @include demi-bold-font;
  @include multiLineEllipsis(2.2rem, 1);
}

.tickEmptyIcon {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid $gray72;
  flex-shrink: 0;
}

.assessmentTypeLabel {
  font-size: 1.6rem;
  //margin-bottom: 4px;
}

.dotContainer {
  margin: 0px 4px;
}

.subTitle {
  display: flex;
  @include regular-font;
  font-size: 1.4rem;
  margin-bottom: 36px;
}

.staffInfo {
  display: flex;
  align-items: center;
}

.staffNameLabel {
  font-size: 1.2rem;
  @include medium-font;
  color: $textSubtle;
  margin-left: 8px;
  line-height: 1.6rem;
}

.searchInputContainer {
  width: 100%;
  max-width: 284px;
}

.folderImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $violet98;
  margin-right: 16px;
}

.feedDataInnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.breadCrumbsContainer {
  @extend .headerTabContainer;
  margin-top: 24px;
  padding: 0 40px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  width: 100%;
  box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.08);
}

.innerContainer {
  display: flex;
  width: 100%;
  height: 64px;
  padding: 16px 0;
  max-width: 1008px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.innerContainerEdit {
  justify-content: flex-end;
}

.resourcesInnerContainer {
  padding: 40px 64px;
  max-width: 792px;
}

.rightButtonContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;
  align-items: center;
}

.buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.buttonsContainerScheduled {
  display: flex;
  justify-content: space-between;
}

.scheduleButton {
  word-break: break-all;
  @include multiLineEllipsis(2.4rem, 1, $maxCharacter: 30);
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container :global {
  .slick-slide {
    outline: none !important;
  }
  .slick-list > div {
    margin-left: 0;
  }
  .slick-track {
    display: flex;
  }
  .slick-dots li {
    margin: 0;
  }
  .slick-dots li button:before {
    width: 16px;
    height: 16px;
  }
  .slick-dots li.slick-active button:before {
    width: 16px;
    height: 16px;
    color: $violet63;
    opacity: 1;
  }
  .slick-dots li {
    width: 16px;
    height: 16px;
  }
  .slick-prev:before {
    content: "";
  }
  .slick-next:before {
    content: "";
  }

  .arrowIconContainer {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  height: 100%;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.progressContainer {
  height: 280px;
  width: 280px;
  position: relative;
}

.timer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 6.4rem;
  color: $gray13;
  @include medium-font;
}

.controls {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 40px;
  background: $white;
  height: 72px;
}

.buttonTextWrapper {
  display: flex;
  align-items: center;
}

.buttonText {
  margin-left: 12px;
}

.modalContent {
  height: 540px !important;
  width: 480px !important;
}

.hideReactMicUI {
  display: none;
}

.audioRecorderStrip {
  position: absolute;
  background-color: $bar;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) -2px 0px 8px 0px;
}

.innerContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 32px;
}

.leftContent {
  display: flex;
  align-items: center;
}

.rightContent {
  display: flex;
  align-items: center;
}

.recordingIcon {
  height: 16px;
  width: 16px;
  background-color: $salmon60;
  border-radius: 50%;
  margin-right: 16px;
}

.recordingIconPause {
  background-color: $gray48;
}

.recordingIconWhite {
  background-color: $white;
  margin: 0;
}

.recordingAnimation {
  animation: blink 0.8s alternate-reverse linear infinite;
}

.pausedText {
  margin-right: 4px;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  // 40% {
  //   opacity: 0.1;
  // }

  // 60% {
  //   opacity: 1;
  // }

  100% {
    opacity: 1;
  }
}

.recordTime {
  font-size: 2rem;
  margin-right: 16px;
  span {
    color: $white;
  }
  span:last-child {
    color: $gray48;
  }
}

.progressBar {
  background-color: $salmon60;
  width: 0%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.buttonsContainer {
  display: flex;
}

.buttonsContainer > div {
  margin-left: 32px;
}
.buttonsContainer > div:first-child {
  margin-left: 0;
}

.pausedTextRadial {
  color: $violet63;
  font-size: 1.6rem;
  position: absolute;
  top: calc(50% - 3.2rem - 24px);
  left: 50%;
  transform: translate(-50%, -50%);
  @include demi-bold-font;
}

.alertContainer{
  position: fixed;
  z-index: 100;
  width: max-content;
  left:0;
  top:100px;
  right: 0;
  margin: 0 auto;
}
.stripAlertContainer{
  display: flex;
  position: fixed;
  width: auto;
  margin: 4px auto;
  justify-content: flex-start;
  top:75px;
  z-index: 11;
  & > div{
    box-shadow: 0 2px 2px $strokeOne;
  }
}
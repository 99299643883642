.container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.item {
  direction: flex;
  flex-basis: 50%;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.itemsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.itemsContainerSmall {
  margin-top: 0px;
}

.noSearchContainer {
  display: flex;
  align-items: center;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  height: 400px;
}

.noSearchTopContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

.noSearchIcon {
  display: flex;
  margin-bottom: 16px;
}

.noSearchDataText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $gray31;
  margin-bottom: 16px;
  text-align: center;
}

@import "~UIComponents/CommonStyles/colors";

.status {
  line-height: 1.5;
  margin: 0;
}

.overdueStatus {
  color: $textCritical;
}

.in30Days {
  color: $textSubtle;
}

.in7Days {
  color: $textWarning;
}

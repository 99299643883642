@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  position: absolute;
  background-color: $white;
  z-index: 10;
}
.searchContainer {
  display: flex;
  width: 100%;
  flex: 1;
  margin-bottom: 32px;
}

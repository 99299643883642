@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.profileIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f0f0f3;
  flex-shrink: 0;
  position: relative;
}

.profileText {
  display: flex;
  color: #ffffff;
  text-align: center;
  align-self: center;
}

.countCon {
  position: absolute;
  z-index: 10;
  top: -4px;
  right: -4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $yellow50;
  color: $white;
  font-size: 1rem;
  @include medium-font;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.archived {
  mix-blend-mode: luminosity;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  flex: 1;
  min-width: 0;
  height: 100%;
  flex-direction: column;
}

.innerContainer {
  display: flex;
  padding: 40px;
  width: 100%;
  max-height: 100%;
  height: max-content;
  flex-direction: column;
}

.headerText {
  display: flex;
  @include bold-font;
  color: $black;
  font-size: 1.8rem;
  flex-shrink: 0;
  margin-bottom: 16px;
  @include multiLineEllipsis(1.8rem, 1);
}

.container :global {
  .sticky-table {
    width: 100%;
    overflow: hidden;
    border: 1px solid $strokeOne;
  }
  .sticky-table-header-wrapper {
    display: flex;
  }
  .sticky-table-header {
    flex: 1;
  }
  .sticky-table-table {
    width: 100%;
  }
}

.cell {
  display: flex;
  padding: 16px 24px;
  border-right: 1px solid $strokeOne;
  border-bottom: 1px solid $strokeOne;
  word-wrap: break-word;
  white-space: pre-line;
  max-width: 360px;
  min-width: 360px;
}

.valueCell {
  composes: cell;
  padding: 24px;
}

.fixedCell {
  composes: cell;
  background-color: $toddleBG1;
  padding: 12px 24px;
}

.gradeText {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  @include bold-font;
  color: $teal20;
}

.emptyState {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 48px;
  // margin-bottom: 64px;
}

.emptyText {
  @include regular-font;
  display: flex;
  color: $gray48;
  font-size: 1.6rem;
  margin-top: 48px;
  text-align: center;
  justify-content: center;
  width: 100%;
  white-space: pre-wrap;
  line-height: 2.4rem;
  word-break: break-word;
}

.emtyTextBoldText {
  @include bold-font;
  color: $gray31;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $strokeOne;
  width: 100%;
}
.containerInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  height: 72px;
  width: 100%;

  .leftContent {
    display: flex;
    align-items: center;

    .goBackDiv {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .headings {
      display: flex;
      flex-direction: column;
    }
  }

  .rightContent {
    display: flex;
    align-items: center;
  }
}

.dialogueBoxContent {
  min-height: 400px !important;
}

.dialogueBoxContentEditToConfirm {
  min-height: 340px !important;
}

.preferencesContainer {
  margin: 0px 16px 0px 0px;
}

.chevronIcon {
  margin: 0px 4px 0px 0px;
  cursor: pointer;
}
.toddleIcon {
  margin: 0px 16px 0px 0px;
  cursor: pointer;
}
.heading {
  @include bold-font;
  color: $gray31;
  font-size: 16px;
}
.subHeading {
  color: $gray48;
  font-size: 12px;
  text-align: left;
}

.saveDraftContainer {
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  font-size: 1.6rem;
  @include medium-font;
}

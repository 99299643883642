@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContainer {
    max-width: 480px;
    max-height: 586px;
    min-height: 208px;
    border-radius: 12px !important;
 }
 .privateModalContainer {
     max-height: 208px !important;
 }
 .modalBodyContainer {
     padding:24px;
 }
 .modalHeaderContainer {  
     display: flex;
     justify-content: space-between;
     align-items: center;
 }
 
 .likeSeenTitle {
    @include bold-font;
    font-size: 2rem;
    color:$textDefault;
 }
 .tabsContainer {
     height: 48px;
 }

 .taggedListContainer {
    padding-top: 24px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 450px;
    padding-bottom: 24px;
}

.userContainer {
    display: flex;
    width: 432px;
    max-width: 432px;
    height: 52px;
    padding-bottom:8px;
    align-items: center;

    &:not(:first-child){
        padding-top:8px;
    }
    
    &:not(:last-child) {
        border-bottom: 1px solid $borderSubtle;
    }
}

.userDetailsContainer {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
}

.usernameText {
    @include medium-font;
    font-size: 1.6rem;
    color:$textDefault;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.userSubText {
    @include medium-font;
    font-size: 1.2rem;
    color:$textSubtle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.BFlexDivCol {
  display: flex;
  flex-direction: column;
}

.BFlexDivRow {
  display: flex;
  align-items: center;
}

.container {
  height: 100%;
  overflow-y: auto;
}

.wrapper {
  composes: BFlexDivCol;
  width: 100%;
  padding: 24px;
}

.upperCon {
  composes: BFlexDivRow;
  flex-shrink: 0;
  justify-content: space-between;
  padding-bottom: 28px;
  width: 100%;
}

.previewLabel {
  composes: BFlexDivCol;
  @include demi-bold-font;
  font-size: 1.4rem;
  line-height: 1.43rem;
  color: $violet63;
  margin-bottom: 4px;
}

.leTitle {
  composes: BFlexDivCol;
  @include bold-font;
  font-size: 2rem;
  color: $gray13;
  margin-bottom: 8px;
  max-width: 196px;
  word-wrap: break-word;
}

.createdByText {
  composes: BFlexDivCol;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray48;
}

.imageContainer {
  width: 80px;
  height: 80px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 2px;
  background-color: $toddleBG1;
  flex-shrink: 0;
  border-radius: 4px;
}

.lowerCon {
  composes: BFlexDivCol;
  width: 100%;
  flex: 1;
  flex-shrink: 0;
  border-top: 1px solid $borderDefault;
}

.stepContainer {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 8px;
  width: 100%;
  padding: 16px 0;
}

.stepLabel {
  font-size: 1.4rem;
  @include demi-bold-font;
  color: $textDefault;
}

.stepSubText {
  composes: BFlexDivRow;
  margin-top: 4px;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray48;
}

.formContainer {
  composes: BFlexDivRow;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  flex-shrink: 0;
  word-break: break-word;
}

.item {
  composes: BFlexDivCol;
  width: 100%;
  flex-shrink: 0;
}

.lePreviewHeader {
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-top: 24px;
}

.innerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right:24px;
}

.assessmentTypeText {
  @include medium-font;
  font-size: 1.4rem;
  color: $gray48;
}

.backIcon {
  display: flex;
  cursor: pointer;
}

.backText {
  font-size: 1.4rem;
  color: $textSubtle;
  @include medium-font;
  @include multiLineEllipsis(1.9rem, 1);
}

.assessmentToolTypeContainer {
  display: flex;
}

.assessmentToolTypeValue {
  margin-left: 8px;
  @include demi-bold-font;
}

.unitContainer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid $borderDefault;
}
.unitDetailsContainer {
  display: flex;
  margin-top: 8px;
}

.unitImageContainer {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.unitLabelContainer {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  @include medium-font;
  width: 260px;
  margin-left: 8px;
}

.unitTitleContainer {
  @include multiLineEllipsis(2rem, 2);
  font-size: 1.4rem;
  @include demi-bold-font;
  color: $textDefault;
}
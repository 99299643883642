@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.styling {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 40px 40px;
  background: $white;
}

.container {
  @extend .styling;
  border-radius: 4px;
  width: max-content !important;
  height: max-content !important;
}

.overlayContainer {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.logoContainer {
  display: flex;
  width: max-content;
  flex-shrink: 0;
  align-items: center;
  flex-direction: row;
  margin-bottom: 32px;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
}

.toddleText {
  display: flex;
  margin-left: 12px;
  color: $gray48;
  font-size: 2rem;
  @include demi-bold-font;
}

.imageContainer {
  margin-bottom: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  // min-height: 164px;
}

.title {
  font-size: 2.4rem;
  @include bold-font;
  color: $bar;
  margin-bottom: 16px;
  text-align: center;
}

.subTitle {
  font-size: 1.6rem;
  text-align: center;
  line-height: 2.8rem;
  color: $gray48;
  @include medium-font;
  margin-bottom: 32px;
}

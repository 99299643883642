.container {
  width: 100%;
}

.buttonComponent {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
}

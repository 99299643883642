@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: rgba($yellow50, 0.15);
  border-radius: 8px;
}

.svgContainer {
  width: 82px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.msgContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 100%;
  text-align: center;
  padding-right: 8px;
}
.textContent {
  margin: auto;
  color: $gray31;
  font-size: 1.4rem;
  @include medium-font;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.overlayContainer {
  z-index: 1010 !important;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.containerWrapper {
  overflow-y: auto;
}

.container {
  padding: 56px 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 1124px;
  margin: 0 auto;
}

.title {
  font-size: 3.6rem;
  color: $teal20;
  @include bold-font;
  margin-bottom: 16px;
}

.subTitle {
  color: #202632;
  font-size: 2rem;
  margin-bottom: 32px;
  @include demi-bold-font;
  max-width: 870px;
  text-align: center;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(412px, 1fr));
  column-gap: 32px;
  row-gap: 32px;
  width: 100%;
}

.card {
  background: $white;
  border: 1px solid $strokeOne;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 40px;
  border-radius: 8px;
  text-align: center;
}

.imgContainer {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  // img {
  //   width: 75%;
  //   overflow: hidden;
  // }
}

.cardTitle {
  font-size: 2.4rem;
  color: $bar;
  @include bold-font;
  margin-bottom: 8px;
}

.cardDescription {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: $gray48;
  @include medium-font;
  text-align: center;
  margin-bottom: 40px;
}

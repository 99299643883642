@import "~UIComponents/CommonStyles/colors";

.yearCountBenchmarkContainer {
  margin: 0px 0px 8px 0px;
  &:last-child {
    margin: 0px 0px 0px 0px;
  }
}

.yearBenchmark {
  margin-right: 24px;
}

.emptyBenchmarkCount {
  text-align: center;
}

.benckmarkCount {
  text-align: center;
  cursor: pointer;
}

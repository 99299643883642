@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  flex-direction: column;
  padding-bottom: 40px;
}

.footerButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.listContainer {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  flex-direction: column;
  padding: 16px 0 24px;
}

.folderItem {
  display: flex;
  padding: 24px 0px;
  border-bottom: 1px solid $strokeOne;
  justify-content: space-between;
  cursor: pointer;
}

.folderContent {
  display: flex;
  margin-right: 12px;
}

.folderName {
  display: flex;
  font-size: 1.6rem;
  margin-left: 16px;
  color: $black;
  @include medium-font;
  @include multiLineEllipsis(2rem, 1);
}

.folderIcon {
  display: flex;
  flex-shrink: 0;
}
.itemRightContainer {
  display: flex;
}

.emptyContainer {
  display: flex;
  width: 100%;
  padding: 0 24px;
  height: 100%;
}

.noSearchTopContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.noSearchIcon {
  display: flex;
  margin-bottom: 16px;
}

.noSearchDataText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $gray31;
  margin-bottom: 8px;
}

.noSearchText {
  display: flex;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray48;
  text-align: center;
  line-height: 2.4rem;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
}

.absoluteContainer {
  display: grid;
  position: absolute;
  grid-auto-flow: column;
  grid-column-gap: 12px;
  align-items: center;
  left: 40px;
  top: 48px;
  cursor: pointer;
  &:hover .leftIcon {
    fill: $gray48;
  }
  @include mediaQuery("medium-device") {
    position: unset;
    margin-left: -32px;
    margin-right: 16px;
  }
}

.leftContainer {
  display: flex;
  flex-direction: row;
}
.leftIcon {
  fill: $gray72;
  align-items: center;
  display: flex;
}
.rightContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  justify-content: flex-end;
}

.title {
  @include bold-font;
  font-size: 2.4rem;
  line-height: 36px;
  color: $gray13;
}

.subTitle {
  @include medium-font;
  font-size: 1.4rem;
  line-height: 22px;
  color: $gray48;
}
.detailContainer {
  display: grid;
  grid-row-gap: 2px;
}

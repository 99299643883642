@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  flex-shrink: 0;
  max-width: 1040px;
  width: 100%;
}
.itemFeedContainer {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, 290px);
}

.cardContainer {
  display: flex;
}

.emptyStateContainer {
  grid-column: 1 / span 2;
  text-align: center;
  margin-top: 40px;
}

.customEmptyStateContainer {
  grid-column: 1 / span 3;
  text-align: center;
}

.emptyStateTextContainer {
  margin-top: 50px;
}

.emptyStateSubTextContainer {
  width: 80%;
  margin: auto;
}

.emptyStateSubTextDesc {
  display: inline-block;
  color: $gray48;
  font-size: 1.4rem;
}

.emptyStateSubTextLink {
  display: inline-block;
  color: $blue29;
  cursor: pointer;
  font-size: 1.4rem;
  @include medium-font;
}

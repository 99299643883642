@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;

  @media (min-width: 1550px) {
    margin-top: 76px !important;
  }
  @include mediaQuery("mobile") {
    padding-top: 64px !important;
  }
  @include mediaQuery("tablate") {
    padding-top: 48px !important;
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  margin-top: 56px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 50px;

  border: 1px solid $strokeTwo;
  border-radius: 2px;
  &:hover {
    border: 1px solid $gray72;
  }
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.orContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 36px;
  max-width: 560px;
}

.orLineContainer {
  display: flex;
  height: 1px;
  flex: 1;
  border-top: 1px solid $strokeTwo;
}

.orText {
  display: flex;
  padding: 0 20px;
  @include regular-font;
  font-size: 1.8rem;
  color: $gray48;
}

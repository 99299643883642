@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.tagsContainer {
  display: flex;
  align-items: center;
}

.tag {
  display: flex;
  align-items: center;
  padding: 1px 8px 1px 8px;
  background: $gray95;
  border-radius: 12px;
  color: $gray31;
  margin-right: 4px;
  font-size: 1rem;
}

.tagLabel {
  word-break: break-all;
  max-width: 150px;
  min-width: 50px;
}

.tagImage {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 2px;
}

.label {
  @include multiLineEllipsis(2rem, 1);
}

.paddingLeft {
  padding-left: 1px;
}

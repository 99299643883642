@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";

$borderColor: $strokeTwo;

.onSearchSectionContainer {
  margin: 20px 0px;
}

.onSearchSectionHeader {
  font-size: 1.6rem;
  @include demi-bold-font;
  margin-bottom: 16px;
}

.dividerNew {
  height: 1px;
  margin: 20px 0px 20px 0px;
  background-color: $lineBackground;
}

.onSearchSectionSubHeader {
  font-size: 1.6rem;
  @include demi-bold-font;
  margin-bottom: 16px;
}

.onSearchExpandedContainer {
  margin-left: 12px;
}

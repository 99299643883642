@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: 420px;
  min-height: 220px;
}

.notificationHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  margin-bottom: 24px;
  margin-top: 32px;
}

.markAllReadText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.4rem;
  color: $blue29;
  cursor: pointer;
}

.headerText {
  display: flex;
  color: $gray31;
  @include bold-font;
  font-size: 1.8rem;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  margin: auto;
  width: 560px;
  height: max-content;
  padding: 32px 40px;
}

.modalDisableContent {
  width: 652px;
}

.headContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headingText {
  font-size: 2rem;
  color: $textDefault;
  @include bold-font;
}

.contentContainer {
  font-size: 1.6rem;
  color: $textDefault;
  @include medium-font;
  margin: 16px 0 40px 0;
  overflow-wrap: break-word;
  white-space: pre-line;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.confirmationTextContainer {
  display: flex;
}

.confirmationContainer {
  margin-top: 24px;
  display: grid;
  grid-row-gap: 16px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
$borderColor: $strokeOne;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.subText {
  // margin-top: 4px;

  @include demi-bold-font;
  font-size: 1.4rem;
  margin-bottom: 16px;
}

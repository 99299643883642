@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  // margin-bottom: 80px;
}

.container .titleContainerSubmodule {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $gray31;
  padding-bottom: 24px;
}

.container .titleContainerSubmoduleNotLabel {
  padding-bottom: 0;
}

.container .titleSubmodule {
  font-size: 1.8rem;
  @include bold-font;
  flex: 1;
}

.container .subTitle {
  color: $gray48;
  font-size: 1.4rem;
  width: 100%;
}

.container .settingsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(496px, auto));
  column-gap: 54px;
}

.container .settingContainer {
  width: 100%;
  border-bottom: 1px solid $strokeOne;
  padding: 24px 0;
  display: flex;
}

.container .settingTitle {
  display: flex;
  align-items: center;
  flex: 1;
  color: $gray31;
  font-size: 1.6rem;
  @include medium-font;
}

.container .settingValue {
  min-width: 48px;
  text-align: center;
}

.titleSubmodule,
.settingTitle {
  padding-right: 12px;
}

.buttonComponentContainer {
  display: flex !important;
  border: 1px solid $strokeOne;
  min-height: 48px;
  padding: 12px;
  border-radius: 4px;
  width: 100%;
  align-items: center;
}

.buttonComponentLabel {
  text-align: left;
  flex: 1;
  color: $gray13;
  @include multiLineEllipsis(1.4rem, 1);
}

.fullWidth {
  width: 100%;
}

.leftContainer {
  flex: 1;
}

.infoIcon {
  display: flex;
  margin-left: 8px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.progressContainer {
  height: 280px;
  width: 280px;
  position: relative;
}

.timer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 6.4rem;
  color: $gray13;
  @include medium-font;
}

.pausedText {
  color: $violet63;
  font-size: 1.6rem;
  position: absolute;
  top: calc(50% - 3.2rem - 24px);
  left: 50%;
  transform: translate(-50%, -50%);
  @include demi-bold-font;
}

.controls {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 40px;
  background: $white;
  // height: 72px;
}

.buttonTextWrapper {
  display: flex;
  align-items: center;
}

.buttonText {
  margin-left: 12px;
}

.modalContent {
  height: 540px !important;
  width: 480px !important;
}

.hideReactMicUI {
  display: none;
}

.recordingIcon {
  height: 16px;
  width: 16px;
  background-color: $salmon60;
  border-radius: 50%;
  margin-right: 16px;
}

.recordingIconWhite {
  background-color: $white;
  margin: 0;
}

.recordingAnimation {
  animation: blink 0.8s alternate-reverse linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  // 40% {
  //   opacity: 0.1;
  // }

  // 60% {
  //   opacity: 1;
  // }

  100% {
    opacity: 1;
  }
}

.modalBodyContainer .description {
  color: $gray48;
  font-size: 1.6rem;
}

.modalBodyContainer .playerContainer {
  padding: 40px 0;
}

.dialogContent {
  height: auto !important;
  min-height: 300px;
  max-height: 360px;
}

.buttonsContainer {
  display: flex;
  flex: 1;
}

.buttonsContainer > div {
  margin-left: 32px;
}
.buttonsContainer > div:first-child {
  margin-left: 0;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  gap: 8px;
  align-items: center;
  flex: 1;
}

.figure {
  display: flex;
  margin: 0;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  & > img {
    width: 100%;
    height: 100%;
  }
  overflow: hidden;
}

.rightContent {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.name {
  display: flex;
  line-height: 1.5;
  color: $textDefault;
  margin: 0;
  flex: 1;
  @include multiLineEllipsis(2.4rem, 1);
}

.id {
  display: flex;
  line-height: 1.6rem;
  color: $textSubtle;
  margin: 0;
  flex: 1;
  @include multiLineEllipsis(1.6rem, 1);
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 318px;
  background-color: $white;
  position: absolute;
  border: 1px solid $strokeOne;
  right: 0;
  z-index: 40;
  top: 32px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  max-height: 276px;
  margin-bottom: 24px;
}

.innerContainer {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
}

.arrowContainer {
  display: flex;
  position: absolute;
  right: 10px;
  right: 8px;
  top: -10px;
  transform: rotate(180deg);
}

.listContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
}

.listInnerContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  padding: 0 24px;
  margin-bottom: 24px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid $strokeOne;
  align-items: center;
}

.noOptionsText {
  display: flex;
  @include medium-font;
  color: $gray48;
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
}

.iconContainer {
  display: flex;
  cursor: pointer;
  fill: $gray48;
  width: 20px;
  //height: 20px;
  &:hover {
    fill: $gray31;
  }
}

.gradeName {
  display: flex;
  @include medium-font;
  color: $gray31;
  font-size: 1.4rem;
}
.headerText {
  display: flex;
  @include bold-font;
  color: $gray31;
  font-size: 1.6rem;
}

.footerContainer {
  display: flex;
  position: absolute;
  bottom: 0;
  height: 56px;
  background-color: $white;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 24px;
  border-top: 1px solid $strokeTwo;
}

.buttonContainer {
  display: grid;
  grid-column-gap: 8px;
  grid-auto-flow: column;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.overlayContainer {
  background: $bar !important;
  opacity: 1 !important;
}

.modalContent {
  height: 540px !important;
  width: 920px !important;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
}

.cancelIcon {
  display: flex;
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
  fill: $gray48;
  z-index: 1;
  &:hover {
    fill: $gray31;
  }
}

.leftContainer {
  flex: 0 0 240px;
  background-position: center;
  background-size: cover;
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 240px;
    height: 160px;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0)
    );
  }
}

.logoContainer {
  display: flex;
  width: max-content;
  flex-shrink: 0;
  align-items: center;
  position: absolute;
  top: 36px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
}

.toddleText {
  display: flex;
  margin-left: 12px;
  color: $white;
  font-size: 1.6rem;
  @include demi-bold-font;
}

.rightContainer {
  flex: 1 0 auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.title {
  font-size: 2.4rem;
  color: $bar;
  @include bold-font;
  margin-bottom: 48px;
}

.inviteRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.inviteRowLeftContainer {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  flex-grow: 1;
}

.subTitle {
  color: $teal20;
  font-size: 1.6rem;
  @include bold-font;
  margin-bottom: 2px;
}

.teachersCount {
  font-size: 1.2rem;
  color: $gray48;
  @include demi-bold-font;
}

.gridResultContainer {
  display: grid;
  grid-template-columns: repeat(2, 292px);
  grid-template-rows: auto;
  gap: 16px;
  justify-content: center;
}

//Card

.cardContainer {
  border-radius: 4px;
  border: 1px solid $strokeOne;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.row {
  display: flex;
  flex-direction: column;
  padding-right: 32px;
  overflow: hidden;
}

.name {
  font-size: 1.4rem;
  line-height: 2rem;
  @include demi-bold-font;
  color: $bar;
  @include multiLineEllipsis(2rem, 1);
}

.email {
  font-size: 1.2rem;
  @include regular-font;
  margin-top: 2px;
  color: $gray48;
  @include multiLineEllipsis(1.4rem, 1);
}

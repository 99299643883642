@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.BFlexDivCol {
  display: flex;
  flex-direction: column;
}

.BFlexDivRow {
  display: flex;
  align-items: center;
}

.container {
  composes: BFlexDivCol;
  height: 100%;
}

.container :global {
  .SingleDatePicker_picker {
    margin-bottom: 32px;
  }
}
.headerCon {
  composes: BFlexDivRow;
  justify-content: space-between;
  padding: 24px 32px;
}

.headerLabel {
  @include bold-font;
  font-size: 2rem;
}

.closeIconCon {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.modalBody {
  composes: BFlexDivCol;
  padding: 0 32px;
  flex: 1;
  overflow-y: auto;
}

.classCard {
  composes: BFlexDivCol;
  margin-bottom: 32px;
  flex-shrink: 0;
}

.classLabel {
  @include bold-font;
  font-size: 1.4rem;
  margin-bottom: 20px;
}

.dateTimeCon {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;
}

.footerCon {
  composes: BFlexDivRow;
  justify-content: space-between;
  height: 72px;
  padding: 0 32px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
}

.resetButton {
  composes: BFlexDivCol;
  @include demi-bold-font;
  font-size: 1.4rem;
  color: $gray48;
  cursor: pointer;
}

.dateTimeCon :global {
  .SingleDatePickerInput_calendarIcon {
    display: flex;
    align-items: center;
    padding: 12px 8px;
    margin: 0;
  }

  .DateInput {
    width: 100%;
  }

  .DateInput_input {
    font-size: 1.6rem !important;
  }
  .SingleDatePicker_picker {
    z-index: 2;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
@import "../common.scss";

.containerWrapper {
  background: $toddleBG1;
}

.itemsContainer {
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  max-width: 1240px;
}

.itemTitle {
  @include bold-font;
  font-size: 2rem;
  line-height: 3.2rem;
}

@include mediaQuery("mobile") {
  .itemsContainer {
    grid-template-columns: 312px;
  }
}
@include mediaQuery("tablate") {
  .itemsContainer {
    grid-template-columns: repeat(2, 286px);
  }
}

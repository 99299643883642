@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.dot {
  display: inline-block;
  margin: 0px 6px;
  width: 4px;
  height: 4px;
  border: 1px solid $gray13;
  border-radius: 50%;
  background-color: $gray13;
  flex-shrink: 0;
  vertical-align: middle;
}

.detailText {
  display: flex;
  @include regular-font;
  font-size: 1.6rem;
  color: $gray31;

  word-break: break-word;
  flex-direction: column;
}

.List {
  padding-left: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}

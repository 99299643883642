@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.overlayContainer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 1001;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  cursor: default;
  background-color: rgba(0, 0, 0, 0.7);
}

.positionFixed {
  position: fixed;
}

.positionFixed {
  position: fixed;
}
//Do Not change
.noFullscreenOverlaycontainer {
  z-index: 1100;
}

.fullScreenModalContent {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  justify-content: space-between;
}

.textContainer {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin-right: 40px;
}

.labelText {
  @include medium-font;
  font-size: 1.4rem;
  color: $gray13;
}

.subText {
  color: $gray48;
  @include regular-font;
  font-size: 1.4rem;
}

.toggleSwitchContainer {
  display: flex;
  justify-content: center;
  width: 52px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

$errorColor: $yellow50;

.responseCreateContainer {
  display: flex;
  padding: 24px;
  width: 100%;
  flex-direction: column;
}

.responseCreateContainerEditMode {
  background-color: rgba($yellow50, 0.1);
}

.errorResponseCreateContainer {
  background-color: rgba($errorColor, 0.1);
  border: 1px solid $errorColor;
}

.evidenceFeed {
  display: flex;
  margin-top: 24px;
  width: 100%;
  height: 100%;
}

.textArea {
  display: flex;
  flex: 1;
  border-radius: 4px;
  -moz-transition: min-height 0.3s;
  -ms-transition: min-height 0.3s;
  -o-transition: min-height 0.3s;
  -webkit-transition: min-height 0.3s;
  transition: min-height 0.3s;
  min-height: 40px;
  padding-top: 8px;
  background-color: $white;
}

.textAreaEditMode {
  min-height: 112px;
  -moz-transition: min-height 0.3s;
  -ms-transition: min-height 0.3s;
  -o-transition: min-height 0.3s;
  -webkit-transition: min-height 0.3s;
  transition: min-height 0.3s;
  border: 1px solid $warning50;
  padding: 16px;
}

.createTopContainer {
  display: flex;
  width: 100%;
  flex: 1;
}

.profileImage {
  display: flex;
  margin-right: 12px;
  align-self: flex-start;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.buttonsContainerWithoutEvidence {
  composes: buttonsContainer;
  justify-content: flex-end;
}

.rightButtons {
  display: flex;
  align-items: center;
}

.cancelButton {
  display: flex;
  color: $gray48;
  &:hover {
    color: $gray31;
  }
  font-size: 1.2rem;
  @include medium-font;
  margin-right: 24px;
  cursor: pointer;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

// Covered units modal

.modalContainer {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  height: auto !important;
  min-height: 300px;
  max-height: 640px;
  border-radius: 12px;
}

.modalHeader {
  display: flex;
  flex-direction: column;
  padding: 32px 32px 4px 32px;

  .title {
    @include multiLineEllipsis(2rem, 2);
    @include bold-font;
    font-size: 1.8rem;
    line-height: 2rem;
    color: $gray31;
  }

  svg {
    cursor: pointer;
    fill: $gray31;
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.modalSubHeader {
  @include medium-font;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: $gray48;
  margin-bottom: 8px;
}

.modalBody {
  display: flex;
  flex-direction: column;
  padding: 0px 32px 20px 32px;
  overflow: overlay;
}

.unitPlanCard {
  display: flex;
  padding: 12px 4px 12px 0px;
  align-items: center;

  &:hover {
    .underlinedInsightCount {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.clickableUnitPlanCard {
  &:hover {
    cursor: pointer;
    background-color: $toddleBG1;

    .openInNewTabClass {
      visibility: visible;
    }

    .title {
      text-decoration: underline;
    }
  }
}

.image {
  height: 48px;
  width: 48px;
  margin-right: 16px;
  border-radius: 4px;
  background-color: $strokeOne;
  background-size: cover;
}

.unitPlanCardWithInsightsCount {
  display: flex;
  padding: 12px 4px 12px 0px;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: $toddleBG1;
    .underlinedInsightCount,
    .title {
      text-decoration: underline;
    }
  }
}

.leCard {
  display: flex;
  padding: 12px 0px;
  align-items: center;
}

.lePopUpContainer {
  display: flex;
  flex-direction: column;
  width: 320px;
  overflow: auto;
  background-color: $white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 20px 24px;
  height: auto;
  min-height: 300px;
  max-height: 320px;
}

.details {
  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    @include demi-bold-font;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: $gray13;
  }

  .subTitle {
    @include medium-font;
    font-size: 1.2rem;
    line-height: 2rem;
    color: $gray48;
  }
}

.openInNewTabClass {
  visibility: hidden;

  svg {
    fill: $gray48;
  }
}

.insightCount {
  color: #008799;
  @include demi-bold-font;
}

.container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #ebebeb;
  gap: 32px;
}

.nameContainer {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.componentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  margin-bottom: -40px;
}

.themeComponent {
  display: flex;
  flex-direction: column;
  min-width: 188px;
  max-width: 248px;
  margin-bottom: 40px;
  cursor: pointer;

  &:hover {
    .themeIcon {
      border: 1px solid $strokeOne;
    }
    .error {
      border-color: $borderCritical;
    }
  }
}

.themeComponentDisabled {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  cursor: not-allowed;
}

.themeComponentActive {
  composes: themeComponent;

  .themeIcon {
    border: 1px solid $blue29;
  }

  .themeLabel {
    color: $blue29;
  }

  &:hover {
    .themeIcon {
      border: 1px solid $blue29;
    }
    .error {
      border-color: $borderCritical;
    }
  }
}

.themeIcon {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  border: 1px solid $strokeTwo;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  position: relative;
}

.themeSvg svg {
  width: 186px;
  height: 100px;
}

.themeLabel {
  display: flex;
  @include medium-font;
  font-size: 1.6rem;
  color: $gray48;
  margin-top: 16px;
  align-items: flex-start;
}

.themeListComp {
  // display: flex;
  // flex-direction: row;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 16px;
  // flex-wrap: wrap;
  height: 100%;
  // flex-shrink: 0;
  // justify-content: space-between;
  width: 100%;
}

.selectedTickComp {
  display: flex;
  justify-content: flex-start;
  position: absolute;
  right: 8px;
  top: 8px;
}

.error {
  display: flex;
  border-color: $borderCritical;
}

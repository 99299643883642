@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background-color: $white;
  padding: 16px 32px 16px 24px;
  border-bottom: 1px solid $strokeOne;
}

.leftContainer {
  display: flex;
  align-items: center;
}

.logoContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.chevronIconContainer {
  display: flex;
  margin-right: 8px;
}

.toddleIconContainer {
  display: flex;
  margin-right: 16px;
}

.titleText {
  display: flex;
  @include bold-font;
  font-size: 2.4rem;
  color: $gray31;
}

.rightContainer {
  display: flex;
}

.buttonContainer {
  display: flex;
  align-items: center;
  width: 178px;
  background-color: $toddleBG1;
  border: 1px solid $strokeTwo;
  border-radius: 4px;
  padding: 12px 16px;
}

.filterIcon {
  display: flex;
}

.buttonText {
  display: flex;
}

.coursesBoxContainer {
  display: flex;
  flex-direction: column;
  width: 295px;
}

.courseRow {
  display: flex;
  align-items: center;
  height: 40px;
}

.leftCircle {
  display: flex;
  margin-left: 16px;
}

.courseText {
  display: flex;
  margin-left: 8px;
  @include medium-font();
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: $black;
}

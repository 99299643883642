@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

$border-radius: 4px;

.container {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  align-items: center;
  height: max-content;
  width: max-content;
}

.roundContainer {
  grid-column-gap: 16px;
}

.normalWithTextContainer {
  grid-column-gap: 24px;
}

.normalContainer {
  grid-column-gap: 24px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
}

.iconContainer {
  display: flex;
  flex-shrink: 0;
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: 32px;
}

.normalIconContainer {
  span {
    transition: opacity 0.3s;
    opacity: 0;
  }
  &:hover {
    span {
      position: absolute;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      transition: opacity 0.3s;
      opacity: 1;
    }
  }
}

.likeNormalIconContainer {
  &:hover {
    span {
      background-color: rgba($salmon60, 0.1);
    }
    fill: $salmon60;
  }
}

.shareNormalIconContainer {
  &:hover {
    span {
      background-color: rgba($pink39, 0.1);
    }
    fill: $pink39;
  }
}

.saveNormalIconContainer {
  &:hover {
    span {
      background-color: rgba($yellow50, 0.1);
    }
    fill: $yellow50;
  }
}

.commentNormalIconContainer {
  &:hover {
    span {
      background-color: rgba($blue29, 0.1);
    }
    fill: $blue29;
  }
}

.buttonContainer {
  height: 32px;
  border: 1px solid $gray72;
  padding: 0 12px;
  border-radius: 2px;
}

.roundButtonContainer {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: $white;
  position: relative;
}

.countText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.4rem;
}

.countNormalText {
  @include demi-bold-font;
  color: $gray48 !important;
}

.countRoundText {
  font-size: 1rem;
  color: $white !important;
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

.titleText {
  display: flex;
  font-size: 1.4rem;
  color: $white;
  margin-left: 4px;
}

.normalWithTextTitleText {
  color: $gray48;
}

.modalContainer {
  display: flex;
  background-color: $white;
  border: 1px solid $toddleBG1;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  z-index: 1000;
  width: 480px;
  min-height: 400px;
  height: max-content;
  border-radius: 4px;
  padding: 32px;
}

.conversationModalContainer {
  width: 400px;
  max-height: 400px;
  min-height: 300px;
  padding: 0px !important;
  height: unset !important;
}

.modalContent {
  width: 640px !important;
  height: 530px !important;
}

.countTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@include mediaQuery("mobile") {
  .normalWithTextContainer {
    grid-column-gap: 24px;
  }
  .button {
    flex-direction: column;
  }
  .roundButtonContainer {
    margin: 12px 0 0 !important;
  }
  .countTextContainer {
    margin: 12px 0 0 !important;
  }
}

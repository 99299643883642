@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 48px;
  overflow-y: scroll;
  // padding: 48px 136px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 64px 24px;
  border-bottom: 1px solid $borderSubtle;

  &:first-child {
    padding-top: 48px;
  }
  &:nth-last-child(2) {
    border-bottom: none !important;
    padding-bottom: 48px;
  }
}

.headerRowOne {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  fill: $iconSubtle;

  .title {
    font-size: 3.2rem;
    line-height: 1.36;
    color: $textDefault;
    @include bold-font;
  }
}

.copyLinkContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.linkOutlinedIcon {
  display: flex;
}

.copyLinkText {
  font-size: 1.4rem;
  line-height: 1.36;
  color: $textSubtle;
  margin-left: 8px;
}

.dropDownItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid $borderSubtle;

  &:last-child {
    border-bottom: none;
  }
}

.dropDownItemIcon {
  display: flex;
}

.dropDownItemLabel {
  @include medium-font;
  font-size: 1.4rem;
  line-height: 1.36;
  color: $textSubtle;
  margin-left: 8px;
}

.headerRowTwo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.leftContainer {
  display: flex;
  align-items: center;
}

.created {
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $textSubtle;
  margin-bottom: 8px;
}
.dot {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  margin: 0 8px 0 0;
  background-color: $textDisabled;
}
.tagList {
  display: flex;
  align-items: center;
}

.shareOutlinedIcon {
  display: flex;
}

.shareTextCompose {
  font-size: 1.4rem;
  line-height: 1.36;
  color: $textSubtle;
  @include medium-font;
  margin-left: 8px;
  margin-right: 12px;
}

.shareText {
  font-size: 1.4rem;
  line-height: 1.36;
  color: $textSubtle;
  @include medium-font;
  margin-left: 8px;
  margin-right: 12px;
}

.caretDownFilledIcon {
  display: flex;
}

.tracker {
  position: absolute;
  z-index: 1;
  height: 20px;
}

.poster {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 194px;
  margin-bottom: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  border: 1px solid $borderSubtle;
  cursor: pointer;
}

.seeMoreLess {
  margin-bottom: 32px;
}

.content {
  display: flex;
  flex-direction: column;
  @include medium-font;
  font-size: 1.6rem;
  line-height: 2rem;
  color: $gray31;

  * {
    font-family: inherit !important;
    color: $textDefault;
  }

  p {
    margin: 0.5em 0em;
  }

  iframe {
    border-radius: 8px;
    border: 1px solid $borderSubtle;
    margin: 0.5em 0em;
  }

  div:first-child > iframe:first-child {
    margin-top: 0;
  }

  div:last-child > iframe:first-child {
    margin-bottom: 0;
  }

  img {
    border-radius: 8px;
    border: 1px solid $borderSubtle;
  }

  a {
    color: $textLink;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    &:first-child {
      margin-top: 0px;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  fill: $iconSubtle;

  .voteContainer {
    display: flex;
    align-items: center;
    height: 20px;

    .voteInfoText {
      @include medium-font;
      font-size: 1.4rem;
      line-height: 1.36;
      color: $textSubtle;
      margin-right: 16px;
    }
  }

  .rightFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .rightFooterButton {
      margin-left: 16px;
    }
  }

  .feedbackContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    .feedbackLabel {
      margin-left: 8px;
      font-size: 1.4rem;
      line-height: 2rem;
      @include medium-font;
      color: $textSubtle;
    }
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.headerText {
  display: flex;
  @include bold-font;
  color: $gray13;
  font-size: 1.6rem;
  padding-bottom: 24px;
  border-bottom: 1px solid $strokeOne;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
  margin-top: 16px;
}

.listContainer {
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
}

.addButtonContainer {
  width: fit-content;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;

  @media (min-width: 1550px) {
    margin-top: 76px !important;
  }
}

.createAccountButton {
  color: $blue29;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: darken($blue29, 10%);
  }
}

.segmentContainer {
  margin-top: 20px;
}

.formComponent {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
}

.inputContainer {
  display: flex;
  flex: 1;
  position: relative;
  margin-bottom: 24px;
}

.forgotPassword {
  display: flex;
  position: absolute;
  right: 0;
  font-size: 1.4rem;
  line-height: 1.36;
  @include medium-font;
  color: $blue29;
  cursor: pointer;
}

.bottomContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

$topPosition: 14vh;
$height: 48px;

.snackbarContainer {
  text-align: center;
  position: absolute;
  width: 100%;
  top: $topPosition;
  z-index: 5000;
}

.snackbar {
  margin: auto;
  width: 720px;
  height: $height;
  padding-top: 13px;
  padding-bottom: 13px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.snackbarOffline {
  border: solid 1px $salmon60;
  background-color: #fddedf;
  text-align: center;
}

.snackbarOfflineText,
.snackbarOnlineText {
  @include medium-font;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $salmon60;
}

.snackbarOnlineText {
  color: $blue29;
}

.snackbarOnline {
  border: solid 1px $blue29;
  background-color: #d5eff1;
  text-align: center;
}

.snackbarContainerUp {
  animation-name: slide-up;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.snackbarContainerDown {
  animation-duration: 1s;
  animation-name: slide-down;
  animation-fill-mode: forwards;
}

@keyframes slide-down {
  0% {
    top: -$height;
  }
  100% {
    top: $topPosition;
  }
}

@keyframes slide-up {
  0% {
    top: $topPosition;
  }
  100% {
    top: -$height;
  }
}

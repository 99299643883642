@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  border-radius: 4px;
  border: 1px solid $strokeTwo;
  flex-shrink: 0;
  border-radius: 4px;
  position: relative;
  width: 100%;
}

.innerContainer {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 32px 0px;
  flex-direction: column;
}

.selectInnerContainer {
  pointer-events: none;
}

.tickIconContainer {
  display: flex;
  position: absolute;
  top: 8px;
  right: 8px;
}

.tickIcon {
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid $strokeTwo;
  border-width: 1px;
}

.containerSelected {
  border: 1px solid $blue29;
}

.containerSelectionMode {
  cursor: pointer;
}

.topContainer {
  display: flex;
  flex-shrink: 0;
  padding: 0px 24px;
  flex-direction: column;
}

.headerContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.headerLeftContainer {
  display: flex;
  flex-direction: column;
  padding-right: 16px;
}

.headerRightContainer {
  display: flex;
}

.titleText {
  display: flex;
  font-size: 1.6rem;
  @include demi-bold-font;
  color: $gray31;
  @include multiLineEllipsis(2.2rem, 1);
  word-break: break-word;
}

.createdByText {
  display: flex;
  font-size: 1.4rem;
  @include medium-font;
  color: $gray48;
  @include multiLineEllipsis(2rem, 1);
}

.descriptionContainer {
  display: flex;
  width: 100%;
  margin-top: 16px;
  flex-shrink: 0;
}

.descriptionText {
  display: flex;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray31;
  width: 100%;
  word-break: break-word;
}

.noDataText {
  display: flex;
  margin-top: 16px;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray72;
  width: 100%;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000070;
  z-index: 10;
}

.selectedSlot {
  width: 100%;
  height: 100%;
  // margin-right: 12px;
  background-color: $white;
  border-radius: 5px;
}

@import "~UIComponents/CommonStyles/colors";

.noPrograms {
  display: flex;
  color: $textSubtle;
  line-height: 2.4rem;
}

.programsCount {
  display: flex;
  color: $textDefault;
  text-decoration: underline;
  line-height: 2.4rem;
  cursor: pointer;
}

.list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.listItem {
  display: flex;
  padding: 0;
  margin: 4px 0;
}

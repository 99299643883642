@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
}
.buttonDropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
}
.moreSvg {
  height: 32px;
  width: 32px;
  //line-height: 30px;
  align-items: center;
  justify-content: center;
  fill: $gray31;
  display: flex;
  cursor: pointer;
  overflow: hidden; // contain gradient on IE9
  flex-shrink: 0;
  &:hover {
    border-radius: 50%;
    background-color: $blue29;
    fill: #fff !important;
  }
}

.buttonFocus {
  border-radius: 50%;
  background-color: $blue29;
  fill: #fff !important;
}

.dropDownDiv {
  justify-content: flex-start;
  z-index: 10;
  display: flex;
  flex-direction: column;
  top: 36px;
  width: max-content;
  right: 0;
  background-color: #fff;
  position: absolute;
  color: $gray48;
  border-radius: 8px;
  border: 1px solid $strokeTwo;
  cursor: pointer;
  align-items: center;
  box-shadow: 0 4px 12px 0px rgba(0, 0, 0, 0.16);
  padding: 8px 0;
  flex-shrink: 0;
}

.dropDownItem {
  display: flex;
  height: 48px;
  width: 100%;
  padding: 0px 16px;
  align-items: center;
  color: $gray48;
  flex-shrink: 0;
  border-left: 4px solid transparent;
  &:hover {
    background-color: rgba($strokeOne, 0.5);
    color: $black;
  }
  &:not(:last-child) {
    border-bottom: 1px solid $toddleBG1;
  }
}

.dropDownItemText {
  display: flex;
  @include medium-font;
  font-size: 1.6rem;
  @include multiLineEllipsis(2rem, 1);
}

.disableTab {
  color: $strokeTwo;
  cursor: not-allowed;
  &:hover {
    // background-color: $gray72;
    color: $strokeTwo;
  }
}

.optionSvg {
  width: 24px;
  margin-right: 12px;
  fill: $gray48;
  align-items: center;
  display: flex;
  flex-shrink: 0;
}

.disableTab .optionSvg {
  fill: $strokeTwo;
}

.dropDownItem:hover .optionSvg {
  fill: #000;
}

.disableTab:hover .optionSvg {
  fill: $strokeTwo;
}

.leftContainer {
  display: flex;
  align-items: center;
}

.buttonComponent {
  display: flex;
  flex-shrink: 0;
  width: 100%;
}

.activeDropDownItem {
  background-color: rgba($strokeOne, 0.5);
  color: $black;
}

.highlightActiveElement {
  color: $salmon60;
  border-left-color: $salmon60;
  &:hover {
    color: $salmon60;
  }
}

.subMenuContainer {
  composes: dropDownDiv;
  position: absolute;
  top: 0px;
}

.rightIconContainer {
  position: absolute;
  right: 16px;
  display: flex;
}

.rightIconContainer {
  fill: $gray48;
}

.dropDownItem:hover .rightIconContainer {
  fill: #000;
}

.disableTab .rightIconContainer {
  fill: $strokeTwo;
}

.dropDownItem:hover .rightIconContainer {
  fill: #000;
}

.labelStyle {
  display: flex;
  font-size: 1.6rem;
  margin-bottom: 8px;
  color: $textDefault;
  @include demi-bold-font;
}

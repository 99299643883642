@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.moreFiltersContainer {
  display: flex;
  flex-wrap: wrap;
  background-color: $surfaceSubtle;
  border-radius: 8px;
  padding: 8px;
  margin-top: 32px;
}

.filter {
  display: flex;
  align-items: center;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 48px;
}

.inputContainer {
  display: flex;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.nameContainer {
  display: flex;
  width: 45%;
}

.bottomContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.skipButton {
  display: flex;
  font-size: 1.6rem;
  color: $gray48;
  cursor: pointer;
  @include demi-bold-font;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-shrink: 0;
  width: 100%;
  align-items: center;
}

.tagStudentNameContainer {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  margin-left: 8px;
  @include medium-font;
  font-size: 1.6rem;
  color: $gray48;
}

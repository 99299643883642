@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.topContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  align-items: center;
}

.addBtnContainer {
  width: 80px;
  height: 32px;
  margin-left: 8px;
}

.labelContainer {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.emptyTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.emptyText {
  display: flex;
  margin-left: 8px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: $yellow50;
}

.addResourceModalContainer {
  width: 840px !important;
  height: 640px !important;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  min-height: 80px;
  border-bottom: 1px solid $strokeTwo;
  align-items: center;
  padding: 0 24px;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  align-items: center;
}

.backButtonContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toddleIconContainer,
.backIconContainer {
  display: flex;
}

.backIconContainer {
  margin-right: 8px;
}

.toddleIconContainer {
  margin-right: 16px;
}

.title {
  font-size: 2.4rem;
  @include bold-font;
}

.subTitle {
  font-size: 1.2rem;
  color: $gray31;
}

.downloadIcon {
  cursor: pointer;
  display: flex;
  align-content: center;
}

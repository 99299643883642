@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.studentFeedContainer {
  display: flex;
  flex-direction: column;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  flex-shrink: 0;
  border-bottom: 1px solid $strokeOne;
}

.countText {
  font-size: 18px;
  color: $gray31;
  @include demi-bold-font();
}

.selectAllButton {
  font-size: 16px;
  color: $blue29;
  cursor: pointer;
  @include demi-bold-font();
}

.checkBox {
  display: flex;
}

.studentItemRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  height: 64px;
  border-bottom: 1px solid $strokeOne;
  cursor: pointer;
}

.studentItemRow:last-child {
  border-bottom: none;
}

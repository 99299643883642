@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.profileCell {
  display: grid;
  justify-content: start;
  color: $gray13;
  @include medium-font;
  font-size: 1.4rem;
  // min-width: 194px;
  //   width: 100%;
  //   flex-basis: 5%;
  align-items: center;
  justify-items: left;
  grid-column-gap: 12px;
  grid-template-columns: auto auto;
}

.profileImage {
  align-self: center;
  height: 24px;
  width: 24px;
  background-position: center;
  background-size: cover;
  border-radius: 12px;
  border: 1px solid #f0f0f3;
}

.allIconContainer {
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: $strokeTwo;
}

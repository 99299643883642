@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalBody {
  display: flex;
  flex-direction: column;
  padding: 0px 48px;
}

.errorDiv {
  color: $salmon60;
  @include medium-font();
  font-size: 13px;
  margin-top: 4px;
  height: 20px;
}

.dropZoneContainer {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: 30%;
}
.errorText {
  color: $error50;
  margin: 4px 0px 0px 0px;
  cursor: pointer;
}
.displayText {
  width: 20%;
  display: flex;
  flex-direction: column;
}

.dropZone {
  cursor: pointer;
  height: 240px;
  text-align: center;
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imageDropZone {
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.imageText {
  width: 100%;
  outline: none;
  margin-top: 20px;
  box-sizing: border-box;
  text-align: center;
}

.profileAvatar {
  cursor: pointer;
  position: relative;
}

.profileBackgroundImage {
  display: flex;
  width: 152px;
  height: 152px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    left: 8px;
    top: 8px;
    border-radius: 100%;
    width: 132px;
    height: 132px;
    box-shadow: 0px 0px 0px 2000px rgba(256, 256, 256, 0.9);
  }

  &:hover {
    &:after {
      display: none;
    }

    .overlayDark {
      display: flex;
      background-color: rgba(0, 0, 0, 0.7);
      justify-content: center;
      align-items: center;
      font-size: 16px;
      @include medium-font;
      color: $white;
      height: 100%;
      width: 100%;
      pointer-events: none;
    }
  }
}

.overlayDark {
  display: none;
}

.profileImage {
  display: flex;
  width: 152px;
  height: 152px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  border: 1px dashed $gray72;

  svg {
    width: 32px;
    height: 32px;
  }

  .profileImageBG {
    border-radius: 4px;
    height: 132px;
    width: 132px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $toddleBG1;
    .uploadText {
      font-size: 14px;
      @include medium-font;
    }
  }
}

.inputElement {
  display: flex;
  flex: 1;
}

.inputElementText {
  display: flex;
  align-items: baseline;
  @include demi-bold-font();
  margin-bottom: 8px;
}

.inputBoxStyle {
  width: 100%;
  padding: 8px 0;
  background-color: $strokeOne;
  border: 0px;
  border-bottom: 1px solid $gray13;
  word-break: break-all;
  color: $gray31;
  &:focus {
    outline: none;
  }
}

.inputStyle {
  border: 2px solid $toddleBG1;
  border-radius: 4px;
  padding: 8px 16px;
}

.userNameComponent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-shrink: 0;
  justify-content: space-between;
  flex-basis: 60%;
  & > * {
    flex-grow: 1;
  }
}

.firstNameComp {
  display: flex;
  width: 298px;
}

.lastNameComp {
  display: flex;
  width: 298px;
  margin-top: 24px;
}

.emailComp {
  margin-top: 48px;
}

.genderComponent {
  display: flex;
  flex-direction: column;
}

.componentLabel {
  display: flex;
  font-size: 1.4rem;
  color: $gray72;
  @include demi-bold-font();
}

.dobComponent {
  display: flex;
  flex-direction: column;
}

.topLevel {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.inputRow {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  flex-basis: 50%;
}

.curriculumSelector {
  display: flex;
  flex: 1;
  margin-left: 40px;
}

.dropdownLabel {
  font-size: 1.6rem;
  @include demi-bold-font;
  color: $textDefault;
  margin-bottom: 8px;
  line-height: normal;
}

.multiInputRow {
  display: flex;
  gap: 16px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 8px;
}

.buttonContainer {
  display: flex;
  padding: 8px;
  border-radius: 6px;
  width: 96px;
  justify-content: space-between;
  border: 1px solid $strokeTwo;
  fill: $iconDefault;
  color: $gray48;
  font-size: 1.4rem;
  line-height: 2rem;
  align-items: center;
  @include demi-bold-font;
}

.arrowContainer {
  display: flex;
  margin-left: 8px;
}

.arrowContainerWithRotation {
  transform: rotate(180deg);
}

.filterOptionTitle {
  display: flex;
  font-size: 1.6rem;
  line-height: 2.186rem;
  @include bold-font;
  color: $gray13;
  margin-bottom: 8px;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  padding: 12px 0px;
}

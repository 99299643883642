@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.settingIcon {
  display: flex;
}

.label {
  font-size: 1.6rem;
  color: $blue29;
  @include medium-font;
  margin-left: 8px;
}

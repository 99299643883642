@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex: 1;
  //padding: 80px 0;
}
.iconContainer {
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  grid-row-gap: 76px;
  grid-column-gap: 76px;
  justify-content: center;
  height: max-content;
  grid-template-columns: repeat(auto-fill, 132px);
}

.dropDownContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dropDownItemContainer {
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  justify-content: flex-start;
  background-color: $white;

  &:not(:last-child) {
    border-bottom: 1px solid $strokeTwo;
  }
  cursor: pointer;
  .hoverItemText {
    display: flex;
    margin-left: 16px;
  }
  .buttonIconAndText {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 24px;
  }
  .googleDriveInnerDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    flex: 1;
    padding-left: 24px;
  }
  .oneDriveInnerDiv {
    display: flex;
    align-items: center;
    padding-left: 24px;
  }

  &:hover {
    .hoverItemText {
      color: $gray31;
    }
    background-color: $toddleBG1;
  }
  &:active {
    .hoverItemText {
      color: $black;
    }
  }
}

.addMediaText {
  display: flex;
  font-size: 1.4rem;
  @include medium-font;
  color: $gray48;
  margin-left: 8px;
}

.innerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 16px;
}

.iconSvgContainer {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  width: 100%;
}

.hoverItemText {
  font-size: 14px;
  color: $gray48;
  @include medium-font;
  margin-top: 4px;
}

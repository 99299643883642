@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  padding: 64px 0 80px;
  grid-row-gap: 40px;
  justify-content: center;
  justify-items: center;
  background-color: $seaweedTwo;
}

.heading {
  display: grid;
  max-width: 440px;
  text-align: center;
  @include bold-font;
  font-size: 4rem;
  color: $white;
  line-height: 1.67;
}

.button {
  display: grid;
  width: 240px;
}

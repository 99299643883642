@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid $borderSubtle;
  border-radius: 8px;
  &:hover {
    border: 1px solid $borderHover;
  }
}

.disabledCard {
  cursor: not-allowed;
}

.cycleDetails {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.labels {
  display: flex;
  flex-basis: 84%;
  flex-direction: column;
  padding-left: 24px;
  height: 100%;
  justify-content: center;
  word-break: break-all;

  .title {
    @include multiLineEllipsis(2.4rem, 1);
    color: $gray13;
    margin-bottom: 8px;
  }
  .standards {
    @include multiLineEllipsis(1.8rem, 2);
    color: $gray48;
  }
  .warningText {
    @include medium-font;

    display: flex;
    align-items: center;
    color: $error50;
    font-size: 1.2rem;
    svg {
      margin-right: 4px;
    }
  }
}

.cardIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 8px 0 0 8px;
}

.cycleActions {
  display: flex;

  width: 60px;
  justify-content: center;
  .dropdownContainer,
  .arrowContainer {
    height: min-content;
    margin: auto;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  position: relative;
  padding: 16px 24px;
  border-left: 4px solid transparent;
  &:hover {
    background: $toddleBG1;
    .deleteSvgIcon {
      visibility: visible;
    }
    .actionIconCon {
      display: inline-flex;
    }
    .recheckButton {
      visibility: visible !important;
    }
  }
  &:last-child > div {
    border-bottom: none;
  }
}

.attachmentContainer {
  display: flex;
  flex-direction: row;
}

.activeCon {
  border-left: 4px solid $salmon60;
  background: $toddleBG1;
  z-index: 20;
}

.attachmentImageContainer {
  display: flex;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $gray72;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  border: 1px solid $strokeTwo;
}

.rightContainer {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 12px;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.nameText {
  display: flex;
  font-size: 1.4rem;
  @include demi-bold-font;
  line-height: 1.43;
  color: $gray31;
  @include multiLineEllipsis(1.4rem, 1);
  word-break: break-word;
}

.typeText {
  display: flex;
  font-size: 1.2rem;
  @include medium-font;
  color: $gray48;
  margin-top: 4px;
}

.actionIconCon {
  display: none;
  margin-left: 16px;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.file-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.file-icon-box {
  border: 1px solid $strokeOne;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: $white;
}
.showActionIconCon {
  display: inline-flex;
}

.deleteSvgIcon {
  display: flex;
  width: 16px;
  align-items: center;
  justify-content: center;
  fill: $gray48;
  visibility: hidden;
  margin-left: 16px;
  &:hover {
    fill: $gray31;
  }
  @include mediaQuery("touchable") {
    visibility: visible;
  }
}

.editAttachmentContainer {
  display: flex;
  align-items: center;
}

.notificationCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: $yellow50;
  position: absolute;
  top: -14px;
  right: -14px;
  z-index: 1;
}

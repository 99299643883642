@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.studentFeedContainer {
  display: flex;
  flex-direction: column;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  flex-shrink: 0;
  border-bottom: 1px solid $strokeOne;
}

.countText {
  font-size: 18px;
  color: $gray31;
  @include demi-bold-font();
}

.selectAllButton {
  font-size: 16px;
  color: $blue29;
  cursor: pointer;
  @include demi-bold-font();
}

.checkBox {
  display: flex;
}

.studentItemRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  height: 64px;
  border-bottom: 1px solid $strokeOne;
  cursor: pointer;
}

.studentItemRow:last-child {
  border-bottom: none;
}

.avatarContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatarInnerContainer {
  display: flex;
  align-items: center;
}

.labelContainer {
  @include multiLineEllipsis(1.8rem, 1);
  @include medium-font;
  color: $textDefault;
  margin-left: 12px;
  font-size: 1.4rem;
}

.labelTagContainer {
  display: flex;
  flex-direction: column;
}

.labelInnerContainer {
  display: flex;
  align-items: center;
}

.tagsContainer {
  display: flex;
  column-gap: 8px;
  margin-top: 4px;
  margin-left: 12px;
}

.archivedText {
  font-size: 1.4rem;
  color: $textSubtle;
  margin-left: 6px;
  @include medium-font;
}

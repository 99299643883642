@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  width: 100%;
  padding: 0;
}

.footerButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.searchContainer {
  display: flex;
  width: 100%;
  padding: 24px 0 0px;
}

.footerText {
  display: flex;
  font-size: 1.6rem;
  color: $gray48;
}

.arrowContainer {
  display: flex;
}

.openArrow {
  transform: rotate(-180deg);
  transition: transform 0.3s;
}

.closeArrow {
  transform: rotate(0deg);
  transition: transform 0.3s;
}

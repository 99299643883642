@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  height: max-content;
  overflow-x: auto;
}

.viewContainer {
  width: 100%;
  margin-left: 0;
  padding-left: 0;
}

.innerContainer {
  display: flex;
  width: max-content;
  min-width: 100%;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 4px;
  gap: 8px;
}

.deleteColumnCellWrapper {
  display: flex;
  height: 56px;
  width: 100%;
  justify-content: center;
}

.row {
  display: flex;
  min-height: 116px;
  flex-shrink: 0;
  width: 100%;
  height: max-content;
  gap: 8px;
}

.pseudoRow {
  display: flex;
  flex-shrink: 0;
}

.cell {
  display: flex;
  position: relative;
  flex: 1;
  min-width: 180px;
  max-width: 200px;
  flex-shrink: 0;
  background-color: $surfaceWhite;
  border: 1px solid transparent;
  border: 1px solid $borderSubtle;
  border-radius: 8px;
  &:first-child {
    border-left: 1px solid $borderSubtle;
    border-right: 1px solid $borderSubtle;
  }
  &:focus-within {
    border: 1px solid $blue29;
    border-radius: 8px;
  }
}

.responsiveCell {
  max-width: none;
}

.rowHeaderCell {
  composes: cell;
  background-color: $surfaceDark;
  color: $surfaceWhite;
  position: relative;
}
.firstColumnCell {
  composes: rowHeaderCell;
  background-color: $decorativeSurfaceTwoL;
  color: $textSubtle;
  position: relative;
}

.emptyFirstColumnCell {
  @extend .firstColumnCell;
  background: $surfaceSubtle;
  border: none;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}

.rowHeaderCellActionButton {
  display: none;
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 15;
}

.rowHeaderCellEdit {
  @extend .rowHeaderCell;
  padding-right: 36px;
  &:hover {
    .rowHeaderCellActionButton {
      display: flex;
      align-items: center;
    }
  }
}

.firstColumnCellActionButton {
  display: none;
  margin-right: 4px;
  height: 100%;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 15;
}

.firstColumnCellEdit {
  @extend .firstColumnCell;
  padding-right: 36px;
  &:hover {
    .firstColumnCellActionButton {
      display: flex;
    }
  }
}

.disabled {
  background: $surfaceDisabled;
}

.pseudoColumn {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;

  flex: 1;
  composes: cell;
  &:not(:last-child) .cell {
    border-right: 1px solid $borderSubtle;
    border-left: 1px solid $borderSubtle;
  }
  &:first-child .cell {
    border-left: 1px solid $borderSubtle;
  }
  background-color: transparent;
  border: none !important;
}

.pseudoFirstColumn {
  composes: pseudoColumn;
  composes: firstColumnCell;
}

.headerRow {
  composes: row;
  min-height: 40px;
  .rowHeaderCell {
    border-top: 1px solid $borderSubtle;
  }
}

.clickableCell {
  composes: cell;
  cursor: pointer;
  &:hover {
    background-color: rgba(24, 158, 174, 0.08);
  }
}

.clickableStudentCell {
  composes: cell;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 180, 0, 0.08);
  }
}

.selectableCell {
  composes: cell;
  border: 1px solid $blue29 !important;
  color: $blue29 !important;
  background-color: rgba(24, 158, 174, 0.08);
}

.selectableStudentCell {
  composes: cell;
  background-color: rgba(255, 180, 0, 0.08);
}

.tickSvgContainer {
  position: absolute;
  top: 8px;
  right: 8px;
  justify-content: flex-end;
  display: flex;
}

.tickSvgComponent {
  display: flex;
}

.insight {
  display: flex;
  margin-bottom: 8px;
}

.insightDescriptor {
  display: flex;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray72;
  word-break: break-word;
}

.insightCount {
  @include demi-bold-font;
  font-size: 2.4rem;
  color: $blue29;
}

.insightStudent {
  font-size: 1.4rem;
  color: $gray48;
  margin-left: 4px;
  align-self: flex-end;
  -webkit-font-smoothing: antialiased;
  line-height: 1.86;
}

.deleteCell {
  display: flex;
  cursor: pointer;
  position: absolute;
  left: 0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid $strokeTwo;

  justify-content: center;
  align-items: center;
  margin: 24px 0;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.optionDeleteCell {
  composes: deleteCell;
  position: unset;
  visibility: visible;
  margin: 0;
}

.bottomContainer {
  display: flex;
  width: 100%;
  margin-top: 24px;
  flex-shrink: 0;
  justify-content: space-between;
}

.borderLeftContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
}

.bottomRightContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.saveTemplateButton {
  display: flex;
  width: max-content;
  height: 40px;
  color: $blue29;
  cursor: pointer;
  @include demi-bold-font;
  font-size: 1.4rem;
  align-items: center;
  @include demi-bold-font;
}

.emptyText {
  @include regular-font;
  display: flex;
  color: #797979;
  font-size: 1.6rem;
  width: 100%;
}

.chooseText {
  display: flex;
  font-size: 1.4rem;
  @include demi-bold-font();
  margin-right: 16px;
  color: $blue29;
  cursor: pointer;
  align-items: center;
}

.placeholderColor {
  &::placeholder {
    color: $textSubtle;
  }
}

.placeholderTextOnSubtle {
  &::placeholder {
    color: $textOnSubtle;
  }
}

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.feedContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 286px);
  grid-auto-rows: min-content;
  width: 100%;
  grid-gap: 24px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.BFlexDivCol {
  display: flex;
  flex-direction: column;
}

.container {
  composes: BFlexDivCol;
  height: 100%;
  width: 100%;
}

.mediaHeaderCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 920px;
  margin: 32px auto 16px auto;
}

.mediaNameText {
  composes: BFlexDivCol;
  @include demi-bold-font;
  font-size: 1.4rem;
  line-height: 1.43;
  color: $gray48;
  max-width: 360px;
  @include multiLineEllipsis(1.4rem, 1);
}

.mediaNameTextRevamp {
  @include demi-bold-font;
  font-size: 1.4rem;
  @include multiLineEllipsis(2rem, 1);
  color: $textDefault;
}

.headerLeftCon {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dot {
  @include dot(2px, 2px, $textSubtle);
}

.mediaType {
  @include medium-font;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: $textSubtle;
}

.mediaIconContainer {
  display: flex;
  width: 24px;
  height: 24px;
}

.rightIconCon {
  composes: BFlexDivCol;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 16px;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.mediaPlaceWrapper {
  composes: BFlexDivCol;
  width: 100%;
  max-width: 920px;
  margin: 0 auto 48px;
  padding-bottom: 40px;
}

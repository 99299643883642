@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.attachmentItem {
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  padding: 8px 12px;
  background: $surfaceDefault;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  row-gap: 8px;
  &:hover {
    background: $surfacePressed;
    .iconsContainer {
      display: flex;
    }
  }
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  display: flex;
}

.rightContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-left: 8px;
}

.titleContainer {
  display: flex;
  font-size: 1.2rem;
  @include medium-font;
  color: $textDefault;
  @include multiLineEllipsis(1.63rem, 1);
  word-break: break-word;
}

.iconsContainer {
  display: none;
  align-items: center;
  gap: 8px;
}

.actionIcon {
  display: flex;
}

.innerContainer {
  display: flex;
}

.attachmentDivider {
  height: 1px;
  width: 100%;
  background-color: $gray92;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container :global {
  display: flex;
  flex-direction: column;
  .SingleDatePicker_picker {
    z-index: 15 !important;
  }
}

.inputField {
  display: flex;
  margin-bottom: 56px;
}

.horizontalRowContainer {
  display: grid;
  margin-top: 56px;
  grid-auto-flow: column;
  grid-column-gap: 24px;
}

.coursesContainer {
  display: grid;
  margin-top: 56px;
  grid-row-gap: 24px;
  grid-auto-flow: row;
  width: 100%;
}

.addClassModalContainer {
  width: 600px !important;
  height: 600px !important;
}

.linkedTextCon {
  display: flex;
  justify-content: center;
  padding: 16px;
  background: $toddleBG1;
  font-size: 1.4rem;
  color: $gray48;
  border-radius: 4px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 80px 1fr;
  background-color: #fff;
}

.bodyContainer {
  display: flex;
  grid-auto-flow: column;
  overflow: hidden;
  position: relative;
}

.toddleIconContainer {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: absolute;
  top: 52px;
  left: 32px;
  z-index: 460;
  align-items: center;
  cursor: pointer;
}

.chevronIconContainer {
  display: flex;
  margin-right: 8px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  margin: 0px 28px 38px 40px;
  align-items: center;
}

.textContainer {
  font-size: 1.4rem;
  color: $gray13;
  font-weight: 500;
}

.actionsContainer {
  display: inline-flex;
  flex: 1;
  justify-content: flex-end;
  gap: 20px;
}

.selectedStandards {
  color: $gray13;
  font-size: 1.6rem;
  @include bold-font;
}

.deleteContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 8px;
}
.iconContainer {
  fill: $gray13;
  display: flex;
  align-items: center;
}
.deleteIcon {
  composes: iconContainer;
  margin-right: 12px;
}
.manageTagsContainer {
  display: flex;
  align-items: center;
  margin-right: 44px;
  @include medium-font;
}
.tagIcon {
  margin-right: 10px;
  fill: $black;
}
.dialogBoxContent {
  min-height: 388px !important;
}
.dialogBody {
  margin-bottom: 24px;
}

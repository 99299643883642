@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.responseContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid $strokeTwo;
  border-radius: 0 0 4px 4px;
  background-color: $white;
}

.responseItemContainer {
  display: flex;
  width: 100%;
  &:not(:last-child) {
    border-bottom: 1px solid $strokeTwo;
  }
  position: relative;
  flex-direction: column;
}

.emptyContainer {
  display: flex;
  margin-top: 16px;
  align-items: center;
  cursor: pointer;
  width: max-content;
}

.addReflectionText {
  @include medium-font;
  color: $black;
  margin: 0 12px;
  font-size: 1.2rem;
}

.loaderContainer {
  margin: 16px 0px;
}

.noDataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

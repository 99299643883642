@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.container :global {
  .Select--single > .Select-control {
    height: 48px;
  }

  .Select-menu-outer {
    width: 400px;
    max-height: unset;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  }
  .Select-menu {
    max-height: unset;
  }
}

.modalBody {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
.header {
  display: flex;
  align-items: center;
  padding: 48px 32px;
  justify-content: space-between;
}

.headerTitle {
  @include bold-font;
  font-size: 2.4rem;
  color: $gray13;
}

.cancelIcon {
  cursor: pointer;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.scrollContainer {
  display: flex;
  overflow-y: auto;
  padding: 0 32px;
  height: 100%;
  padding-bottom: 48px;
}

.scrollInnerContainer {
  display: grid;
  grid-row-gap: 24px;
  width: 100%;
  flex-direction: column;
  height: max-content;
}

.rowList {
  display: grid;
  grid-row-gap: 16px;
  width: 100%;
}

.row {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 200px 48px;
  grid-column-gap: 24px;
}
.fieldLabel {
  @include demi-bold-font;
  font-size: 1.6rem;
}

.deleteBox {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid $strokeTwo;

  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.addButton {
  display: grid;

  grid-auto-flow: column;
  grid-column-gap: 8px;
  cursor: pointer;
  width: max-content;
  flex-shrink: 0;
  align-items: center;
}

.addButtonText {
  @include demi-bold-font;
  color: $blue29;
  font-size: 1.6rem;
}

.footer {
  display: grid;
  width: 100%;
  padding: 16px 32px;
  justify-content: flex-end;
  align-items: center;
  grid-column-gap: 16px;
  grid-auto-flow: column;
  border-top: 1px solid $strokeOne;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.textContainer {
  display: flex;
  flex-direction: column;
}

.labelContainer {
  @include multiLineEllipsis;
}

.subTextContainer {
  @include multiLineEllipsis;
}

.subtextTooltip {
  max-width: 400px;
}

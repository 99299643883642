@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 256px 2fr 1fr;
}

.LEContainer {
  display: grid;
  grid-template-columns: 72fr 28fr;
}

.noPreviewContainer {
  grid-template-columns: 256px 1fr;
}

.leftContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.middleContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-right: 1px solid $borderSubtle;
}

.rightContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.sideNavigationContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $surfaceLight;
}

.unitPlanInfoContainer {
  display: flex;
  margin: 32px 16px 0;
  padding-bottom: 28px;
  border-bottom: 1px solid $borderSubtle;
}

.unitPlanInfo {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.unitPlanImageContainer {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
}

.unitPlanTitle {
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $decorativeSurfaceFourD;
  @include multiLineEllipsis(2.4rem, 1);
  cursor: pointer;
}

.unitPlanSubTitle {
  @include medium-font;
  font-size: 1.2rem;
  color: $textSubtle;
  @include multiLineEllipsis(1.6rem, 2);
  cursor: pointer;
}

.emptyStateContainer {
  padding: 0 60px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.mainContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
}

.label {
  @include medium-font;
  font-size: 1.6rem;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $gray13;
}

.subjectBenchmarkContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

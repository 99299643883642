@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  width: 100%;
  background-color: $white;
  height: 64px;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 0 0 0 32px;
  z-index: 10;
  position: relative;
  border-bottom: 1px solid $strokeTwo;
}

.logoContainer {
  display: flex;
  width: max-content;
  height: 100%;
  flex-shrink: 0;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}

.logo {
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
}

.notificationIcon {
  display: flex;
}

.middleContainer {
  display: flex;
  position: absolute;
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 100%;
  pointer-events: none;
}

.rightContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 32px;
  min-width: 200px;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-right: 24px;
}

.rightIconButton {
  display: grid;
  cursor: pointer;
  fill: $gray72;
  stroke: $gray72;
  &:hover {
    fill: $gray31;
    stroke: $gray31;
  }
  &:first-child {
    padding-right: 32px;
    border-right: 1px solid $gray72;
  }
}

.contributeButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
}

.profileDropdowncontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dropdownItem {
  border-bottom: 1px solid $strokeOne;
  padding: 18px 20px;
  display: flex;
  align-items: center;
  width: 100%;
  &:hover {
    background: $strokeOne;
  }
  &:last-child {
    border-bottom: none;
  }
}

.itemContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
}

.name {
  font-size: 1.6rem;
  @include demi-bold-font;
  color: $black;
}

.itemLink {
  display: flex;
  font-size: 1.2rem;
  color: $gray48;
  @include medium-font;
  // &:hover {
  //   color: $blue29;
  // }
}

.signout {
  display: flex;
  align-items: center;
}

.signoutText {
  color: $gray48;
  font-size: 1.4rem;
  @include medium-font;
  margin-left: 24px;
}

.notificationCount {
  display: flex;
  min-width: 20px;
  height: 15px;
  flex-shrink: 0;
  background-color: $blue29;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  top: -8px;
  left: 8px;
  position: absolute;
}

.notificationCountContent {
  height: 100%;
  display: flex;
  @include demi-bold-font;
  font-size: 1rem;
  align-items: center;
  color: $white;
}

@media (max-width: 1272px) {
  .middleContainer {
    position: unset;
    width: max-content;
  }
  .rightContainer {
    grid-column-gap: 24px;
  }
}

.headerButtonsContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  align-items: center;
}

.rightIconWithoutLogin {
  composes: rightIconButton;
}

@include mediaQuery("mobile") {
  .signInButton {
    display: none !important;
  }
  .middleContainer {
    display: none;
  }
  .rightIconWithoutLogin {
    display: none;
  }
}

@include mediaQuery("tablate") {
  .middleContainer {
    display: none;
  }
  .rightIconWithoutLogin {
    display: none;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.uploadImageComponent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @include border-class($style: dashed);
  height: 300px;
  width: 100%;
  padding: 8px;
  &:hover {
    .deleteSvg {
      display: flex;
    }
  }
}

.smallUploadImageComponent {
  height: 156px;
}

.disbaledImageContainer {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: $toddleBG1;
  opacity: 0.7;
  cursor: not-allowed;
}

.dropZoneContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex: 1;
}

.dropZoneSubText {
  display: flex;
  @include regular-font;
  color: $textSubtle;
  font-size: 1.4rem;
}

.dropZoneText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $textSubtle;
}

.imageDropZone {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.uploadIcon {
  display: flex;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background-color: $blue29;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  fill: white;
}

.smallUploadIcon {
  width: 66px;
  height: 66px;
}

.imageBox {
  background-position: center;
  background-size: cover;
  @include border-class($width: 0px);
  height: 100%;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.deleteSvg {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: rgba($black, 0.5);
  fill: $white;
  border-radius: 50%;
  &:hover {
    background-color: $salmon60;
  }
}

.uploadingContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  background-color: #080812;
  @include border-class($width: 0px);
  border: 0.5px solid #080812;
  align-items: center;
  justify-content: center;
}

.progressBar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 16px;
  left: 16px;
}

.noDataCenterContainer {
  display: flex;
  padding: 24px 0px;
  flex-shrink: 0;
  padding: 32px;
}

.orContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.searchLine {
  display: flex;
  width: 40px;
  height: 2px;
  border: solid 1px $strokeTwo;
}

.orText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $gray48;
  padding: 0 12px;
}

.buttonWrapper {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 24px;
  max-width: 400px;
  grid-template-columns: 50% 50%;
}

.dropZone {
  cursor: pointer;
  text-align: center;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  height: auto;
  width: 560px;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cancelIcon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;

  img {
    border-radius: 8px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
}

.submittedText {
  font-size: 2.4rem;
  color: $seaweedTwo;
  @include bold-font;
  margin-bottom: 16px;
  text-align: center;
}

.thankYouText {
  font-size: 1.4rem;
  line-height: 1.78;
  @include medium-font;
  color: $gray31;
  text-align: center;

  span {
    text-decoration: underline;
    cursor: pointer;
    color: $salmon60;
  }
}

.shareWrapper {
  background-color: $toddleBG1;
  padding: 40px;
}

.shareTitle {
  text-align: center;
  font-size: 1.4rem;
  color: $gray13;
  margin-bottom: 24px;
}

.shareActionWrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.shareAction {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-right: 40px;

  &:last-of-type {
    margin-right: 0;
  }
}

.shareIcon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 8px;
  cursor: pointer;
}

.shareIconTitle {
  font-size: 1.4rem;
  color: $gray13;
}

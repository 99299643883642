@import "~UIComponents/CommonStyles/colors";
.embedVidePlayerContainer{
    width: 100%;
    height: 100%;
    padding:8px;
}
.embedPlayerOverlay{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease;
}
.overlayContainer{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    &:hover{
        .embedPlayerOverlay{
            background-color: rgba($black,0.4);
        }
    }
}
.middleIconContainer{
    width:40px;
    height: 40px;
    border-radius: 50%;
    fill: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($black,0.6);
    align-self: center;
    position: absolute;
    &:hover{
        background-color: $gray31;
    }
}

.linkContainer{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.leftActions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.rightActions {
  display: flex;
  gap: 16px;
}

.templateName {
  font-size: 1.6rem;
  @include demi-bold-font;
  color: $textDefault;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
$borderColor: $strokeOne;

.container {
  display: flex;
  position: relative;
}

.row {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.optionContainer {
  display: flex;
  flex-shrink: 0;
  gap: 8px;
}

.columnItem {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.cell {
  display: flex;
  flex: 1;
  flex-shrink: 0;

  &:focus {
    border: 1px solid $blue29;
    border-radius: 8px;
  }
}

.labelContainer {
  composes: cell;
  background: $violet98;
  border-radius: 8px;
  position: relative;
  padding: 16px;
  &:hover {
    .labelActionButton {
      display: flex;
      align-items: center;
    }
  }
}

.disabled {
  background: $surfaceDisabled;
}

.option {
  composes: cell;
  width: 196px;
  background: $surfaceWhite;
  border-radius: 8px;
  position: relative;
  border: 1px solid $borderSubtle;
  border-radius: 8px;
  padding: 16px;
  &:hover {
    .optionActionButton {
      display: flex;
      align-items: center;
    }
  }
}

.deleteCell {
  cursor: pointer;
  position: absolute;

  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid $strokeTwo;
  justify-content: center;
  align-items: center;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}
.optionDeleteContainer {
  display: flex;
  composes: deleteCell;
  top: -56px;
}

.itemDeleteContainer {
  display: flex;
  composes: deleteCell;
  left: 0;
  align-self: center;
}

.deleteIcon {
  width: 20px;
  height: 20px;
  margin-left: 6px;
  margin-top: 6px;
  fill: $gray48;
}

.emptyText {
  @include regular-font;
  display: flex;
  color: #797979;
  font-size: 1.6rem;
  width: 100%;
}

.labelActionButton,
.optionActionButton {
  display: none;
  position: absolute;
  right: 16px;
  z-index: 15;
}

.placeholderColor {
  &::placeholder {
    color: $textSubtle;
  }
}

.optionDisabled {
  background-color: $surfaceDisabled;
  padding: 0;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  flex-direction: column;
  //padding: 56px 0 40px;
}

.footerButtonContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 32px;
  width: 100%;
}

.basicDetailContainer {
  display: flex;

  margin-bottom: 24px;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0 24px;
}

.basicDetailWithStudentResponse {
  margin-bottom: 0;
}

.mediaPlaceHolderContainer {
  display: flex;
  margin-top: 24px;
  width: 100%;
  position: relative;
  flex-direction: column;
}

.editMediaContainer {
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
  cursor: pointer;
  margin-right: 24px;
  z-index: 10;
}

.tagOptionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
}

.tagOption {
  display: flex;
  border-top: 1px solid $borderSubtle;
  flex-direction: column;
  padding: 24px 0;
  width: 100%;
  flex-shrink: 0;
}

.shareWithOption {
  composes: tagOption;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tagTypeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
}

.tagDetailContainer {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  margin-top: 24px;
  flex-direction: column;
  padding-left: 60px;
  padding-right: 24px;
}

.tagtypeLeftContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.innerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 24px;
}

.tagTypeRightContainer {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.expandedContentContainer {
  display: flex;
  align-items: center;
  margin-right: 32px;
}

.countContainer {
  @include medium-font;
  color: $textSubtle;
  font-size: 1.4rem;
  margin-right: 32px;
}

.iconContainer {
  display: flex;
}

.tagTypeText {
  display: flex;
  font-size: 1.6rem;
  color: $textDefault;
  @include demi-bold-font;
  margin-left: 16px;
}

.tagSubTextContainer {
  display: flex;
  align-items: center;
}

.tagSubText {
  display: flex;
  font-size: 1.4rem;
  color: $gray48;
  @include medium-font;
  margin-top: 4px;
}

.tagIconContainer {
  display: flex;
  cursor: pointer;
}

.noteLinesContainer {
  display: flex;
  min-height: 270px;
  width: 100%;
  padding-left: 20px;
  position: relative;
  background: white;
  background-image: repeating-linear-gradient(
    white 0px,
    white 21px,
    $strokeOne 22px
  );
  -webkit-background-size: 100% 2.2rem;
  -moz-background-size: 100% 2.2rem;
  -ms-background-size: 100% 2.2rem;
  background-size: 100% 2.2rem;
}

.noteContainer {
  display: flex;
  width: 100%;
  border-left: 2px solid $yellow50;
  padding-left: 16px;
  font-size: 1.6rem;
  @include medium-font;
  overflow: hidden;
  white-space: pre-wrap;
}

.privateLabelText {
  display: flex;
  color: $gray13;
  font-size: 1.6rem;
  @include medium-font;
}

.privateCheckBoxContainer {
  display: flex;
  align-items: center;
  margin-left: 32px;
}

.detailsHeaderText {
  display: flex;
  @include bold-font;
  font-size: 1.8rem;
  color: $black;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  &:not(:first-child) {
    margin-top: 48px;
  }
  width: 100%;
  flex-shrink: 0;
}

.pypElementDetail {
  display: flex;
  width: 100%;
}

.pypTagOptions {
  composes: tagOption;
  // border-top-width: 0px !important;
}

.captionContainer {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}

.editInWorkbookIconContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3125), transparent);
}

.editInWorkbookIconWrapper {
  position: absolute;
  right: 16px;
  top: 20px;
  cursor: pointer;
}

.addAllResponsesToJournalContainer {
  @include medium-font;
  font-size: 1.4rem;
  color: $textDefault;
  margin-bottom: 32px;
}

.nonHoverState {
  display: inline-flex;
  color: $textDefault;
  cursor: default;
  text-decoration: none;
}

.hoverState {
  display: inline-flex;
  color: $textDefault;
  text-decoration: underline;
}

.studentResponseContainer {
  padding: 24px 24px 0;
}

.alertContainer {
  width: 100%;
  max-width: 632px;
  position: absolute;
  top: 84px;
  z-index: 1001;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.labelText {
  display: flex;
  font-size: 1.4rem;
  color: $gray31;
  @include medium-font;
  margin-right: 16px;
}
.numFieldContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;

  align-items: center;
}
.button {
  display: flex;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 1px solid $textSubtle;
  align-items: center;
  justify-content: center;
  color: $textSubtle;
  flex-shrink: 0;
  font-size: 1.6rem;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}
.inputText {
  width: 64px;
  height: 40px;
  border: none;
  resize: none;
  outline: none;
  background-color: inherit;
  @include border-class;
  padding: 16px 16px;
  text-align: center;
}
.disabledInputBox {
  composes: inputText;
  border: none;
  color: black;
  background-color: #f9f9f9;
  cursor: not-allowed;
}
.error {
  display: flex;
  border-color: $borderCritical;
}

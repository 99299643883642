@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.ratingElementsContainer {
  width: 449px;
  height: 32px;
  border: 1px solid $strokeOne;
  box-sizing: content-box;
  border-radius: 4px;
  display: flex;
}

.ratingElement {
  width: 50px;
  height: 32px;
  text-align: center;
  padding-top: 8px;
  font-size: 1.1rem;
  @include medium-font;
  border-right: 1px solid $strokeOne;
  background-color: $toddleBG1;
}

.ratingElementInteractable {
  cursor: pointer;
}

.ratingElementDisabled {
  cursor: not-allowed !important;
}

.selectedRatingElement {
  background-color: $violet63;
  border-right: none;
  color: white;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

// Common

.webcamScreen {
  height: 100%;
}

.captureVideoContainer {
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 73px);
  justify-content: center;
  position: relative;
  background-color: $gray13;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.mediaControls {
  width: 100%;
  //   flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.screenContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 1000;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
}

// Record button

.recordButton {
  cursor: pointer;
  margin: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  background-color: $white;
}

.oval {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background-color: $salmon60;
}

// Pause button

.pauseButton {
  cursor: pointer;
  margin: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  background-color: $salmon60;
}

// Resume button

.recordingPausedText {
  font-size: 3.2rem;
  @include demi-bold-font;
  color: $white;
  z-index: 1001;
}

.resumeButton {
  cursor: pointer;
  margin: 40px;
  border-radius: 50%;
  padding: 0px 26px 0 30px;
  height: 80px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  background-color: $white;
}

// Screen Info

.screenInfoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
}

.rightSideCameraOptions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flipCameraButton {
  cursor: pointer;
  margin-right: 52px;
  border-radius: 50%;
  padding: 16px;
  height: 56px;
  width: 56px;
  background: $gray48;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
$errorColor: $borderCritical;
$padding-left: 16px;
.container :global {
  .Select-control {
    cursor: pointer;
    background-color: inherit;
    border: none;
    border-radius: 4px;
    border: 1px solid $strokeTwo;
    // padding-bottom: 8px;
    color: $gray13;
    font-size: 1.6rem;
  }

  .Select-multi-value-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }

  .Select--single > .Select-control {
    height: 56px;
    padding: 4px 0px;
  }
  .Select--multi > .Select-control {
    padding: 8px 0px 8px 12px;
    cursor: text;
    height: 56px;
    box-shadow: none;
  }
  .is-focused > .Select-control {
    border: 1px solid $blue29;
    border-radius: 4px;
    box-shadow: none;
  }
  .Select-menu-outer {
    z-index: 3;
    margin-top: 4px;
    margin-bottom: 16px;
    width: 100%;
    right: 0;

    left: unset;
  }

  .Select--multi .Select-input {
    margin-left: 4px;
  }

  .is-focused:not(.is-open) > .Select-control {
    border: 1px solid $blue29;
    border-radius: 4px;
    box-shadow: none;
  }

  .has-value.Select--single
    > .Select-control
    .Select-value
    .Select-value-label {
    color: $gray13;
    font-size: 1.6rem;
    display: flex;
    flex: 1;
  }

  .Select-value .Select-value-label {
    color: $gray13;
  }
  .Select-option {
    font-size: 1.6rem;

    vertical-align: middle;

    align-self: center;
    padding: 12px $padding-left;
  }
  .Select-option:last-child {
    margin-bottom: 8px;
  }
  .Select-option:first-child {
    margin-top: 8px;
  }
  .Select-option.is-focused {
    @include regular-font;
    background-color: #fff;
    color: $gray13;
    background-color: rgba($strokeOne, 0.5);
    &:hover {
      background-color: rgba($strokeOne, 0.5);
    }
  }
  .Select-option.is-selected {
    background-color: #fff;
    color: $gray13;
  }
  .Select-placeholder {
    display: flex;
    align-items: center;
    padding-left: $padding-left;
    @include regular-font;
    color: $gray72;
  }
  .Select--single .Select-input {
    padding-left: $padding-left;
    font-size: 1.6rem;
    line-height: 44px;
  }
  .Select--mutli .Select-input {
    // padding-left: 16px;
    font-size: 1.6rem;
  }
  .Select--single .Select-control .Select-value {
    padding-left: $padding-left;
    line-height: 48px;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
  }
  .Select-create-option-placeholder {
    &:last-child {
      color: $blue29 !important;
    }
  }

  .is-disabled > .Select-control {
    background-color: $toddleBG1;
    border: none;
    cursor: not-allowed;
  }
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
}

.disabledContainer :global {
  .Select-control {
    background-color: inherit;
    border: none;
    border-radius: 4px;
    border: 1px solid $strokeTwo;
  }
  .Select-placeholder {
    display: flex;
    align-items: center;

    @include regular-font;
  }
  .Select-control .Select-value {
    padding-left: $padding-left;
    line-height: 48px;
  }
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
}

.errorContainer :global {
  .Select-control {
    border: none;
    border-radius: 0;
    border: 1px solid $errorColor;
    padding-bottom: 8px;
  }
  .Select-placeholder {
    display: flex;
    align-items: center;
    padding-left: $padding-left;
    @include regular-font;
  }
  .Select-control .Select-value {
    padding-left: $padding-left;
    line-height: 48px;
  }
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
}

.selectOption {
  cursor: default;
  // border: 0px;
  // border-spacing: 0;
  // border-collapse: separate;
  // height: 48px;
  flex-shrink: 0;
  // outline: none;
  width: 100%;
}

.error {
  display: flex;
  flex-grow: 0;
  border-radius: 4px;
  font-size: 1.3rem;
  color: $errorColor;
  margin-top: 6px;
  height: 16px;
}

.optionSubtext {
  @include medium-font;
  font-size: 1.2rem;
  color: $gray48;
  line-height: 1.67;
  margin-top: 2px;
}

.optionLabel {
  @include multiLineEllipsis(2rem, 1);
}

.multiLineOptionLabel {
  display: flex;
}

.optionIcon {
  margin-right: 16px;
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  width: 24px;
  height: 24px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

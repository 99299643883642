@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: grid;
  flex-shrink: 0;
  margin-top: 24px;
  grid-row-gap: 16px;
}

h4 {
  display: flex;
  flex-direction: row;
}

h4:before,
h4:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid $strokeTwo;
  margin: auto;
}
.dividerText {
  text-align: center;
  width: 40px;
  font-size: 1.4rem;

  color: $gray13;
}

.inviteBox {
  display: grid;
  border-radius: 4px;
  background-color: $toddleBG1;
  height: 240px;
  width: 100%;
  padding: 40px;
  align-content: space-between;
}

.details {
  display: grid;
  grid-column-gap: 8px;
  justify-items: center;
}

.header {
  font-size: 1.8rem;
  @include bold-font;
  color: $gray31;
  line-height: 2.5rem;
}

.subText {
  font-size: 1.4rem;
  @include medium-font;
  color: $gray48;
  line-height: 2.4rem;
  text-align: center;
}

.footer {
  display: grid;
  grid-column-gap: 16px;
  grid-auto-flow: column;
  justify-content: center;
}

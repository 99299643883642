@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.childrenListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  height: 80px;
  padding: 16px 24px;
  border: 1px solid $gray87;
  border-radius: 8px;
}

.leftContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.childInfo {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.childName {
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $gray31;
}

.schoolName {
  @include medium-font;
  font-size: 1.4rem;
  color: $gray31;
}

.rightContent {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.removeText {
  margin-right: 12px;
  @include medium-font;
  font-size: 1.4rem;
  color: $salmon60;
  padding-top: 4px;
}

//theme color variables
@import "@toddle-design/theme/dist/assets/colors.scss";

//Final Color

//Icon colors
$iconDefault: #484848;
$iconDisabled: #dbdbdb;

//Stroke Colors
$strokeOne: #ebebeb;
$strokeTwo: #dbdbdb;

//Primary Colors
$salmon60: #f75961; //uiSalmon

//Feedback Colors:
$warning50: #ffab00;
$link29: #008392;

//Background Colors
$bar: #202632;
$barHover: #2b313c; //charade

$yellowLight: rgba(
  255,
  171,
  0,
  0.1
); //TODO: Check and replace it with other suitable color from theme colors

$blueActive: #238791; //TODO: Check and replace it with other suitable color from theme colors
$uiSalmonActive: #e35259; //TODO: Check and replace it with other suitable color from theme colors
$blueTransparent: #2dadba1a; //TODO: Check and replace it with other suitable color from theme colors
$grass: #4ca88f;
$dodgerBlue: #0079ff; //TODO: Check and replace it with other suitable color from theme colors
// $deepSkyBlueTwo: #0abdd4; //Unused and also not found in theme color

$magenta: #76254f; //TODO: Check and replace it with other suitable color from theme colors
$reddishPink: #f75961; //TODO: Check and replace it with other suitable color from theme colors
$silverChalice: #b0b0b0; //TODO: this is gray-72 color use textDisabled or iconDisabled, whatever is more suitable instead of this
$lineBackground: #eeeeee; //TODO: Check and replace it with other suitable color from theme colors

// Popover
// ------------------------------
$popover-shadow: rgba(0, 0, 0, 0.15);

$pagination-hover-border: rgba(
  0,
  0,
  0,
  0.1
); //this one is used. Try finding a replacment

$model-footer-button-width: 108px;
$model-footer-button-margin-right: 24px;

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;
}

.item {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  &:hover .viewResource {
    display: flex;
  }
}

.viewResource {
  display: none;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 4px;
  @include medium-font;
  color: $blue29;
  cursor: pointer;
  transition: 0.3s;
}

.feedContainer {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;
}

.emptyState {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 56px 0;
  border: 1px solid $strokeTwo;
  border-radius: 4px;
}

.emptyText {
  display: flex;
  color: $gray48;
  font-size: 1.6rem;
  margin-top: 24px;
  text-align: center;
}

.addButton {
  margin-top: 56px;
}

.emptyItemsText {
  display: flex;
  margin-top: 16px;
  @include medium-font;
  font-size: 1.6rem;
  color: $gray31;
}

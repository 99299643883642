@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.selectButtonContainer {
  min-width: 180px;
}

.actionButtonsContainer {
  display: flex;
  justify-content: center;
}

.actionButtonsContainerEditMode {
  width: 120px;
}

.selectButtonContainerWithPadding {
  padding-left: 32px;
  padding-right: 8px;
}

.selectedSettingText {
  font-size: 1.6rem;
  @include medium-font;
  color: $textDefault;
}

.dropdownContainer {
  flex: 1;
}

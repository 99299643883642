@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  min-height: 56px;
  border: 1px solid $strokeTwo;
  padding: 6px 0 6px 12px;
  max-height: 128px;
  flex-shrink: 0;
  border-radius: 4px;
  cursor: pointer;
  justify-content: space-between;
}

.profileIconContainer {
  margin-right: 12px;
}

.leftContainer {
  display: flex;
  width: 90%;
  align-items: center;
}

.textInputContainer {
  border: none;
  border-radius: 0;
  outline: none;
  flex: 1;
  align-self: center;
  line-height: 28px;
  font-size: 1.6rem;
  background: transparent;
}

.focusContainer {
  border-color: $gray72;
}

.rightContainer {
  display: flex;
  align-items: center;
  max-height: 48px;
  min-width: 32px;
  padding-right: 8px;
}

.arrowContainer {
  display: flex;
  fill: $gray48;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.upArrowContainer {
  transform: rotate(180deg);
}

.errorContainer {
  display: flex;
  border-color: $borderCritical;
}

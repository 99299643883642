@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 16px;
  flex-shrink: 0;
}
.innerContainer {
  background-color: $gray98;
}
.emptyText {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray72;
  height: 64px;
  border: 1px solid $gray92;
  border-radius: 8px;
}

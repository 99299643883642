@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  max-height: 720px;

  flex-shrink: 0;
}

.leftContainer {
  display: flex;
  flex: 1;
}

.rightContainer {
  display: flex;
  width: 50%;
  flex-direction: column;
  padding: 64px;
  border-left: 1px solid $strokeOne;
}

.middleDetails {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;
  align-items: center;
}

.categoryNumber {
  @include bold-font;
  font-size: 4.8rem;
  color: $salmon60;
  line-height: 1.33;
  margin-bottom: 80px;
}

.themeGradeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.number {
  @include bold-font;
  font-size: 4.8rem;
  line-height: 1.33;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.detailLabel {
  @include bold-font;
  font-size: 2rem;
  line-height: 1.4;
  color: $gray13;
}

.detailSubLabel {
  @include medium-font;
  font-size: 1.4rem;
  color: $gray13;
  line-height: 1.43;
}

.strips {
  margin-top: 40px;
}

.subText {
  margin-top: 40px;
  @include medium-font;
  font-size: 2rem;
  color: $gray13;
  line-height: 1.8;
}

.imageContainer {
  display: flex;
  margin-top: 124px;
}

@media (max-width: 991px) {
  .rightContainer {
    display: none;
  }
  .container {
    height: 600px;
  }
}

@media (max-width: 767px) {
  .container {
    height: 480px;
  }
}

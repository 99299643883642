@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.attachmentsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, 284px);
    grid-auto-flow: column;
    column-gap: 16px;
    padding-right: 16px;
    padding-bottom: 0px;
}

.smallAttachmentsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-auto-flow: row;
    row-gap: 12px;
    padding: 16px;
    border:1px solid $borderSubtle;
    border-radius: 16px;
}

.studentEvaluationLabel {
    @include medium-font;
    font-size: 1.6rem;
    padding:12px 0;
}

.response {
    @include medium-font;
    color: $textDefault;
    font-size: 1.6rem;
    margin-top: 16px;
    padding: 0 16px;
}
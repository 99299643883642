@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.scrollContainer {
  display: flex;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
}

.footer {
  display: flex;
  width: 100%;
  height: 72px;
  padding: 0 40px;
  align-items: center;
  box-shadow: -2px 0 8px 0 rgba($black, 0.1);
  background-color: $white;
  z-index: 10;
}

.rejectCommentContainer {
  background-color: $toddleBG2;
  padding: 16px 32px;
  display: flex;
  flex-direction: row;
}

.rejectCommentText {
  color: $gray13;
  font-size: 1.4rem;
  @include medium-font;
}

.rejectCommentIcon {
  margin-right: 16px;
}

.details {
  margin: 24px 32px;
}

.detailsV2 {
  margin: 0 auto;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.modalBody {
  @include regular-font;
  color: $gray13;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.feedbackButton {
  z-index: 1002;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $salmon60;
  color: $white;
  padding: 0px 12px;
  border-radius: 8px;
  fill: $white;
  bottom: 24px;
  cursor: pointer;
  height: 28px;
}

.feedbackButtonText {
  margin-left: 8px;
  @include demi-bold-font;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
}

.loadingContainer {
  display: flex;
  position: absolute;
  z-index: 1001;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

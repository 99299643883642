@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: inline-block;
  height: 30px;
  margin: 4px;
  border-radius: 16px;
  background-color: $strokeOne;
  flex-shrink: 0;
}

.itemContainer {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.labelContainer {
  display: flex;
  align-items: center;
}

.labelText {
  display: flex;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray48;
  @include multiLineEllipsis(1.6rem, 1);
}

.removeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  padding: 0px 12px 0 8px;
  cursor: pointer;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  height: 64px;
  &:not(:last-child) {
    border-bottom: 1px solid $strokeOne;
  }
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
}

.leftContainer {
  display: flex;
  flex: 1;
}

.rightContainer {
  display: flex;
  justify-content: center;
}

.titleNamecontainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 16px;
}

.titleText {
  display: flex;
  @include medium-font;
  color: $gray31;
}

.nameText {
  display: flex;
  @include regular-font;
  color: $gray48;
}

.addedContainer {
  display: flex;
  align-items: center;
}

.addedText {
  display: flex;
  @include regular-font;
  color: $blue29;
  margin-left: 8px;
}

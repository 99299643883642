@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  padding-top: 76px;
}

.header {
  display: flex;
  line-height: 4.4rem;
  color: $textDefault;
  margin-bottom: 0;
}

.loginOptionsContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 56px 0 0 0;
  list-style-type: none;
}

.microsoftButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 50px;

  border: 1px solid $strokeTwo;
  border-radius: 2px;
  &:hover {
    border: 1px solid $gray72;
  }
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";

$borderColor: $strokeTwo;
$middle_bar_width: 878px;
$right_bar_width: 40%;
$right_pane_width: 400px;
$left_pane_width: 1040px;

@keyframes open_right_pane {
  to {
    -webkit-transform: translateX(-$right_pane_width);
  }
}

@keyframes close_right_pane {
  to {
    -webkit-transform: translateX($right_pane_width);
  }
}

.modalMainContainer {
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  background: white;
  top: 0;
  left: 0;
  position: fixed;
  flex-direction: row;
}

.modalContentSmall {
  min-width: $left_pane_width;
  width: calc(100% - 400px);
}

.modleContentFull {
  display: flex;
  width: 100%;
}

.modalSidebar {
  // display: flex;
  // // min-width: calc(100% - #{$middle_bar_width});
  // width: $right_bar_width;
  // height: 100%;
  // border-left: 1px $borderColor solid;
  // visibility: visible;

  display: flex;
  height: 100%;
  border-left: 1px $strokeOne solid;
  position: absolute;
  right: -$right_pane_width;
  width: $right_pane_width;
  height: 100%;
  background-color: $white;
  z-index: 11;
}

.smallSideBar {
  // composes: modalSidebar;
  // width: 0px;
  // opacity: 0;
  // transition: width 0.3s, opacity 0.15s;

  right: 0;
  animation: close_right_pane 0.3s;
  animation-fill-mode: forwards;
}

.fullSideBar {
  // composes: modalSidebar;
  // width: $right_bar_width;
  // opacity: 1;
  // transition: width 0.3s, opacity 0.3s;

  visibility: visible;
  animation: open_right_pane 0.3s;
  animation-fill-mode: forwards;
}

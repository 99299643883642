@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.settingContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1048px;
}

.jsonSettingContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  &:not(:last-child) {
    border-bottom: 1px solid $borderSubtle;
  }
}

.checkListTitle {
  font-size: 1.6rem;
  @include medium-font;
  color: $bar;
  padding-top: 24px;
}

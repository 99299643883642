.container {
  display: grid;
  grid-template-columns: 19% auto;
  grid-template-rows: auto 64px;
  align-items: center;

  width: 100%;
  height: 100%;
}

.rightPane {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.footer {
  display: flex;
  grid-column: 2/3;
}

.noSidebarContainer {
  grid-template-columns: 100%;
}

.noSidebarFooter {
  grid-column: 1/3;
}

@import "~UIComponents/CommonStyles/colors";

.container {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: $white;
  height: 100%;
  width: 100%;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.modalContent {
  height: 480px !important;
  width: 400px !important;
  border-radius: 4px;
  box-shadow: 0px 4px 24px $popover-shadow;
}

.modalOverlayContainer {
  background-color: transparent;
}
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  color: $gray31;
  @include bold-font;
  font-size: 2rem;
  padding: 40px 40px 0px 40px;
  margin-bottom: 24px;
}

.body {
  padding: 0 40px;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.footer {
  border-top: 1px solid $strokeTwo;
  padding: 12px 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.emptyTagsContainer {
  margin: 40px 0px 52px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.emptyTagsIcon {
  margin-bottom: 16px;
}
.emptyTagsTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.emptyTagsTextHeader {
  @include bold-font;
  color: $gray13;
  font-size: 1.4rem;
}
.emptyTagsTextDescription {
  font-size: 1.4rem;
}
//tags Container
.tagsContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.noSelectedTags {
  margin: 20px 0px;
  color: $gray72;
  font-size: 1.6rem;

  @include demi-bold-font;
}
.searchResultsItem {
  @include demi-bold-font;
  font-size: 1.4rem;
  height: 48px;
  color: $gray72;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: $toddleBG1;
    color: $gray13;
    margin: 0px -40px;
    padding-left: 40px;
  }
}
.searchResultsContainer {
  flex: 1;
  & > :first-child {
    background: $toddleBG1;
    color: $gray13;
    margin: 0px -40px;
    padding-left: 40px;
  }
}
.addNewTagContainer {
  background: $gray95;
  border-radius: 16px;
  padding: 8px 8px;
  height: 28px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  color: $gray31;
  font-size: 1.2rem;
  margin-bottom: 42px;
}
.addNewTagIcon {
  width: 12px;
  height: 12px;
}
.addNewTagText {
  margin-left: 8px;
}
//selected tags
.selectedTagsContainer {
  margin: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  & > * {
    margin-right: 4px;
    margin-bottom: 4px;
  }
}
.selectedTag {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.4rem;
  @include demi-bold-font;
  color: $white;
  background: $blueActive;
  border-radius: 16px;
  padding: 0px 12px;
}
.selectedTagIcon {
  fill: $white;
  margin-left: 8px;
  cursor: pointer;
  height: 100%;
}

.selectedTagText {
  @include multiLineEllipsis(3rem, 1);
  word-break: break-all;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  
  flex-shrink: 0;
  flex-direction: column;
  position: relative;
}

.detailsHeaderText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $textDefault;
  align-self: center;
}

.detailSubHeaderText {
  display: flex;
  @include bold-font;
  font-size: 1.6rem;
  color: $black;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  &:not(:first-child) {
    margin-top: 24px;
  }
  width: 100%;
  flex-shrink: 0;
  padding: 24px 24px 0 16px;
  border-top: 1.5px solid $strokeTwo;
}

.detailInnerContainer {
  display: flex;
}

.iconContainer {
  display: flex;
  align-items: center;
  margin-right: 8px;
  width: 32px;
  height: 32px;
  justify-content: center;
  border-radius: 4px;
}

.detailDataContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.subDetailContainer {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  padding: 16px 0;
  padding-left: 36px;
  width: 100%;
  justify-content: space-between;
}

.detailText {
  display: flex;
  @include medium-font;
  font-size: 1.6rem;
  color: $gray31;
  margin-top: 4px;
}

.subDetailLeftContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex: 1;
  margin-right: 8px;
}

.subDetailRightContainer {
  display: flex;
  flex-shrink: 0;
  width: 56px;
  height: 56px;
}

.subjectBenchmarkParentContainer {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.benchmarkTextContainer {
  display: flex;
}

.subjectBenchmarkText {
  @include bold-font;
}

.subjectBenchmarkContainer {
  margin-left: 16px;
}

.dot {
  display: flex;
  margin: 0px 6px;
  width: 4px;
  height: 4px;
  border: 1px solid $black;
  border-radius: 50%;
  background-color: $black;
  flex-shrink: 0;
  margin-top: 8px;
}

.pypElementsContainer {
  padding-left: 36px;
  & div{
    border:none !important;
  }
}

.dividerLine {
  width: 100%;
  color: transparent;
  border-bottom: 1.5px solid $strokeTwo;
}
@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.formFieldsParentContainer {
  display: flex;
  margin-bottom: 72px;
  flex: 1;
  overflow: hidden;
}
.formFields {
  display: flex;
  flex-direction: column;
  padding: 0px 32px 0px;
  width: 100%;
  overflow: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 96px;
  padding: 16px 32px;
}

.title {
  font-size: 2.4rem;
  @include bold-font;
  color: $gray31;
}

.cancelIcon {
  cursor: pointer;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.buttonContainer {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  flex-direction: row;
  flex-grow: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  //height: 64px;
  position: absolute;
  border-top: 1px solid #cdcdd2;
  padding: 16px 32px;
  align-items: center;
  background-color: $white;
  z-index: 999;
  box-shadow: 5px 2px 10px 0 rgba(0, 0, 0, 0.2);
}
.cancelDiv {
  display: flex;
  margin-right: 16px;
}

.error {
  display: flex;
  flex-grow: 0;
  font-size: 1.3rem;
  color: $error50;
  margin-top: 6px;
  height: 16px;
}

.emptyState {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 16px;
}

.emptyText {
  display: flex;
  color: $gray48;
  font-size: 1.6rem;
  margin-top: 24px;
  text-align: center;
  width: 60%;
}

.addButton {
  margin-top: 56px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
}

.createdByContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
}

.createdByLeftContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.createdByRightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
}

.taggedTextContainer {
  display: flex;
  margin-left: 12px;
  align-items: center;
}

.taggedCreatedByText {
  display: flex;
  flex-direction: column;
}

.taggedCreatedByTopContainer {
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $textDefault;
}

.taggedCreatedByBottomContainer {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: $textSubtle;
  @include medium-font;
  margin-top: 4px;
  line-height: 1.6rem;
}

.titleTextContainer {
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  flex-shrink: 0;
}

.titleText {
  display: flex;
  @include medium-font;
  font-size: 1.6rem;
  color: $gray13;
  width: 100%;
  word-break: break-word;
  white-space: pre-line;
}

.evidenceTimeText {
  display: flex;
}

.closeButton {
  display: flex;
  width: 24px;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.closeButtonAbsolute {
  position: absolute;
  top: 0;
  right: 0;
}

.attachmentContainer {
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
}

.noAttachment {
  display: flex;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray31;
  padding: 0 24px;
}

.rejectedPost {
  opacity: 0.33;
}

.leInnerContainer {
  display: flex;
  padding-bottom: 16px;
  border-bottom: 1px solid $borderSubtle;
  margin-bottom: 16px;
  align-items: center;
}

.assessmentContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.leIconContainer {
  margin-right: 8px;
  display: flex;
}

.assessmentTitle {
  font-size: 1.4rem;
  @include medium-font;
  @include multiLineEllipsis(1.9rem, 1);
  color: $textDefault;
  &:hover {
    border-bottom: 1px solid $textDefault;
  }
}

.dotContainer {
  display: flex;
  align-items: center;
  margin: 0 6px;
}

.dot {
  display: flex;
  align-items: center;
  @include dot(2px, 2px, $textDisabled);
}

.evidenceContainer {
  display: flex;
  align-items: center;
}

.iconContainer {
  display: flex;
  margin-right: 4px;
}

.icon {
  display: flex;
  align-items: center;
}

.studentResponseContainer {
  display: flex;
  width: 100%;
}

.studentResponseContainerWithAudio {
  display: flex;
  flex-direction: column;
}

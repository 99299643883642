.colorTray {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 16px;
  border-radius: 2px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  z-index: 1;
  grid-gap: 8px;
}

.colorDropContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

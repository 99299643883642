@import "~UIComponents/CommonStyles/colors";

.violet63 {
  color: $violet63;
}
.adminSettings {
  color: $blue40;
}

.yellow50 {
  color: $yellow50;
}

.red66 {
  color: $red66;
}

.green42 {
  color: $green42;
}

.teal42 {
  color: $teal42;
}

.blue40 {
  color: $blue40;
}

.iconSubtle {
  color: $iconSubtle;
}

.insightColor {
  color: #fcefef;
}

.pypModule {
  color: $pink70;
}

.mypModule {
  color: $violet63;
}

.ubdModule {
  color: $blue29;
}

.dpModule {
  color: $yellow38;
}

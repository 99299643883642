@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.mainContainer {
  display: flex;
  width: 100%;
  background-color: $toddleBG2;
  position: relative;
  flex-shrink: 0;
}
.container {
  padding-top: 100px;
  padding-bottom: 60px;
}

.stepsContainer {
  display: grid;
  margin-top: 64px;
  grid-auto-flow: row;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 60px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}

.stepImage {
  width: 160px;
  height: 160px;
}

.stepTitle {
  margin-top: 22px;
  @include bold-font;
  font-size: 1.8rem;
  color: $gray13;
  line-height: 1.75;
  text-align: center;
}

.designation {
  margin-top: 4px;
  @include medium-font;
  font-size: 1.6rem;
  color: $gray48;
  line-height: 1.5;
  text-align: center;
}

.othersText {
  margin-top: 80px;
  @include medium-italic-font;
  font-size: 1.6rem;
  line-height: 2;
  color: $gray48;
}

@media (max-width: 991px) {
  .stepsContainer {
    grid-template-columns: repeat(3, 172px);

    justify-content: space-evenly;

    grid-row-gap: 40px;
  }
  .stepImage {
    width: 120px;
    height: 120px;
  }

  .othersText {
    margin-top: 64px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .stepsContainer {
    grid-template-columns: repeat(2, 140px);
    justify-content: space-between;
    margin-top: 56px;

    grid-row-gap: 40px;
    grid-column-gap: 24px;
  }
  .stepTitle {
    margin-top: 16px;

    font-size: 1.6rem;
  }
  .designation {
    font-size: 1.4rem;
  }
  .othersText {
    margin-top: 56px;
    text-align: center;
    line-height: 1.6;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  height: 32px;
  background-color: $toddleBG1;
  border: 1px solid $strokeTwo;
  border-radius: 16px;
  flex-direction: row;
  padding: 0 12px 0 2px;
  justify-content: space-between;
  margin: 5px;
}

.imageNameContainer {
  display: flex;
  align-items: center;
}

.nameText {
  display: flex;
  margin-left: 8px;
  @include medium-font;
  @include multiLineEllipsis(2rem, 1);
  font-size: 1.4rem;
  color: $gray31;
}

.removeButton {
  display: flex;
  width: 24px;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  padding-left: 8px;
  cursor: pointer;
  fill: $gray72 !important;
  &:hover {
    fill: $gray48 !important;
  }
}

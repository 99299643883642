@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  width: 750px !important;
  height: 350px !important;
}

.editStep {
  display: flex;
  flex-direction: column;
  padding: 32px 40px 0px;
  overflow: auto;
}

.errorDiv {
  color: $salmon60;
  @include medium-font();
  font-size: 13px;
  margin-top: 4px;
  height: 20px;
}

.checkboxContainer {
  display: flex;
  margin-top: 8px;
}

.editStep2 {
  display: flex;
  flex-direction: column;
  padding: 0px 40px 0px;
  overflow: auto;
}

.modalHeader {
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  flex-shrink: 0;
  font-size: 2rem;
  @include demi-bold-font;
}

.modalFooter {
  display: flex;
  height: 64px;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 0px 40px;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: $white;
}

.footerButton {
  display: flex;
  width: $model-footer-button-width;
  margin-right: $model-footer-button-margin-right;
  flex-shrink: 0;
}

.lastButton {
  margin-left: 24px;
}

.userNameComponent {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
}

.modalBody {
  display: flex;
  flex-direction: column;
  padding: 22px 80px;
  flex: 1;
  overflow: scroll;
  flex-shrink: 0;
}

.errorDiv {
  color: $salmon60;
  @include medium-font();
  font-size: 13px;
  margin-top: 4px;
  height: 20px;
}

.dropZoneContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.imageDropZone {
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.profileAvtar {
  cursor: pointer;
  position: relative;
}

.profileBackgroundImage {
  display: flex;
  width: 176px;
  height: 176px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    left: 8px;
    top: 8px;
    border-radius: 100%;
    width: 158px;
    height: 158px;
    box-shadow: 0px 0px 0px 2000px rgba(256, 256, 256, 0.9);
  }
  &:hover {
    &:after {
      display: none;
    }
    .overlayDark {
      display: flex;
      background-color: rgba(0, 0, 0, 0.7);
      justify-content: center;
      align-items: center;
      font-size: 16px;
      @include medium-font;
      color: $white;
      height: 100%;
      width: 100%;
      pointer-events: none;
    }
  }
}

.overlayDark {
  display: none;
}

.profileImage {
  display: flex;
  width: 176px;
  height: 176px;
  border-radius: 4px;

  justify-content: center;
  align-items: center;
  border: 1px dashed $gray72;

  svg {
    width: 32px;
    height: 32px;
  }
  .profileImageBG {
    border-radius: 4px;
    height: 158px;
    width: 158px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $toddleBG1;
    .uploadText {
      font-size: 14px;
      @include medium-font;
    }
  }
}

.inputElement {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  flex-shrink: 0;
}

.horizontalRow {
  display: flex;
  flex-direction: row;
  margin-left: -17px;
  justify-content: space-around;
  flex-shrink: 0;
}

.spacing {
  margin-left: -150px;
}

.horizontalRowInput {
  display: flex;
  flex-direction: row;
  // justify-content: left;
  margin-left: -20px;
  margin-top: 32px;
  flex-shrink: 0;
}

.horizontalInputElement {
  display: flex;
  width: 100%;
  margin-left: 20px;
}

.topLevel {
  display: flex;
  justify-content: space-between;
}

.userNameComponent {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
}

@import "~UIComponents/CommonStyles/mixins";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  flex-grow: 1;
}

.count {
  display: flex;

  font-size: 1.6rem;
  line-height: 2.186rem;
  color: $gray31;
  @include demi-bold-font;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $gray87;
  padding: 8px 40px;
}

.buttonContainer {
  display: flex;
  column-gap: 32px;
}

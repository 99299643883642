@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.labelContainer {
  font-size: 1.6rem;
  @include demi-bold-font;
  padding-bottom: 8px;
}

.middleContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

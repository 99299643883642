@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.enterCodeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 40px;
  border-radius: 8px;
  position: relative;
  background-position: center;
  background-size: cover;
  .cancelIcon {
    display: flex;
    cursor: pointer;
    position: absolute;
    right: 40px;
    top: 40px;
    fill: $black;
    z-index: 1;
    &:hover {
      fill: $gray31;
    }
  }
  .headerWrapper {
    margin-bottom: 32px;
    .header {
      @include bold-font;
      font-size: 3.2rem;
      line-height: 4.8rem;
      color: $blue29;
    }
  }
  .subText {
    @include medium-font;
    font-size: 1.4rem;
    color: $gray31;
    margin-bottom: 12px;
    width: 332px;
  }
  .otpInput {
    margin-bottom: 28px;
  }
  .codeVerificationError {
    color: $salmon60;
    font-size: 1.2rem;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 10;
  position: absolute;
  top: 894px;
  left: 0;
  align-items: center;
  overflow-x: hidden;
  box-shadow: 0px -10px 40px rgba(0, 0, 0, 0.2);
}

.signInLink {
  font-size: 1.6rem;
  color: $bar;
  @include demi-bold-font;
  display: flex;

  @include mediaQuery("mobile-and-tablet") {
    display: none;
  }
}

.getLink {
  text-align: center;
  font-size: 1.6rem;
  color: $bar;
  @include demi-bold-font;
  display: none;

  span {
    text-decoration: underline;
    cursor: pointer;
    color: $blue29;
  }

  @include mediaQuery("mobile-and-tablet") {
    display: block;
  }
}

@include mediaQuery("mobile-and-tablet") {
  .container {
    top: 900px;
  }
  .signUpContainer {
    margin-bottom: 40px;
  }
  .shadowContainer {
    height: 100px;
  }
  .signupButton {
    min-width: 240px !important;
  }
}

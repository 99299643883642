@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid $borderSubtle;
}

.leftContainer {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}

.rightContainer {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 16px;

  .spiltter {
    height: 100%;
    width: 1px;
    background-color: $borderSubtle;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container :global {
  .reactPlayer {
    width: 100% !important;
    height: 100% !important;
    background: $black;
  }
  display: flex;
  flex-shrink: 0;
  height: 100%;
  width: 100%;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.detailsHeaderText {
  display: flex;
  @include bold-font;
  font-size: 1.8rem;
  color: $black;
}

.detailSubHeaderText {
  display: flex;
  @include bold-font;
  font-size: 1.6rem;
  color: $black;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  &:not(:first-child) {
    margin-top: 48px;
  }
  width: 100%;
  flex-shrink: 0;
}

.detailDataContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.subDetailContainer {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $strokeTwo;
  flex-shrink: 0;
  padding: 24px 0;
  width: 100%;
  justify-content: space-between;
}

.subDetailEmptyContainer {
  display: flex;
  flex-shrink: 0;
  padding: 24px 0;
  border-bottom: 1px solid $strokeTwo;
  width: 100%;
}

.detailText {
  display: flex;
  @include medium-font;
  font-size: 1.6rem;
  color: $gray31;
  margin-top: 4px;
  flex-direction: column;
}

.tagStudentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  align-items: center;
}

.tagStudentLeftConainer {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}

.moreText {
  display: flex;
  @include bold-font;
  font-size: 1.4rem;
  color: $blue29;
}

.subDetailLeftContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex: 1;
}

.subDetailRightContainer {
  display: flex;
  flex-shrink: 0;
  width: 56px;
  height: 56px;
}

.subjectBenchmarkParentContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  &:first-of-type {
    margin-top: 20px;
  }
}

.benchmarkTextContainer {
  display: flex;
  margin-top: 6px;
}

.subjectBenchmarkText {
  @include bold-font;
  color: $teal20;
}

.subjectBenchmarkContainer {
  margin-left: 0px;
}

.subjectBenchmarkNodeContainer {
  margin-top: 16px;
}

.subjectBenchmarkTitleText {
  @include medium-font;
  font-size: 16px;
  color: #000000;
  margin-left: 0px;
}

.subjectBenchmarkContentContainer {
  margin-left: 16px;
  padding-top: 2px;
}

.subjectBenchmarkContentText {
  @include regular-font;
  font-size: 16px;
  color: #000000;
  margin-top: 6px;
  &::first-letter {
    text-transform: capitalize;
  }
}

.number {
  display: flex;
  margin: 0px 6px;
  flex-shrink: 0;
  @include bold-font;
  font-size: 1.4rem;
  margin-top: 1px;
}

.dot {
  display: flex;
  margin: 0px 8px 0px 0px;
  width: 4px;
  height: 4px;
  border: 1px solid $black;
  border-radius: 50%;
  background-color: $black;
  flex-shrink: 0;
  margin-top: 8px;
}

.itemContainer {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  justify-content: "space-between";
}

@import "~UIComponents/CommonStyles/colors";

.tableContainer {
  padding: 0px 0px 12px 40px;
  margin-right: 28px;
  overflow: auto;
  flex: 1 1 auto;
  padding-bottom: 12px;
}

.table {
  border-collapse: separate;
  border-spacing: 0px;
  width: 100%;
  margin: 0px 0px 0px 0px;
  min-width: 1100px;
}

.tableHeader th {
  background-color: $black;
  color: $white;
  padding: 14px 16px;
  font-size: 1.6rem;
  line-height: 20px;
  min-height: 48px;
  text-align: start;
  // border-right: 1px solid $strokeOne;
  z-index: 11;
  position: sticky;
  top: 0px;
  // border-left: 1px solid $strokeTwo;
  border-right: 1px solid $strokeTwo;
  &:hover {
    .addIcon {
      visibility: visible;
    }
  }
}

.tableCell {
  &:first-child {
    position: sticky;
    left: 0px;
    z-index: 13;
    top: 0px;
  }
}

.learningStandardsHeader {
  display: flex;
}

.checkBoxContainer {
  margin: 0px 96px 0px 0px;
}
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
//icons
.iconCotainer {
  fill: $gray48;
  cursor: pointer;
  &:hover {
    fill: $black;
  }
}

.addIconContainer {
  position: absolute;
  left: -28px;
  padding-right: 25px;
  z-index: -10;

  &:hover {
    .addIcon {
      visibility: visible;
    }
  }
}

.addIcon {
  display: flex;
  align-items: center;
  visibility: hidden;
  composes: iconCotainer;
}

.questionHeader {
  margin: 0px 4px 0px 0px;
}

.quesWrapper {
  margin: 0px 0px 0px 4px;
  text-align: right;
}

.benchmarkQuestionTooltipContainer {
  margin: 0;
  padding: 0px 0px 0px 8px;
}

.staarQuesContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 2;
}

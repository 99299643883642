@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/mixins";

.header {
  display: grid;
  padding: 16px 40px 8px 40px;
  align-items: center;
  grid-template-columns: auto repeat(3, max-content);
  margin-bottom: 0;
}

.headerWithOnlySearch {
  width: 50%;
  padding: 16px 40px 8px 40px;
  display: flex;
}

.headerWithMarginBottom {
  margin-bottom: 16px;
}

.moreFilters {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0;
  background-color: $surfaceDisabled;
  margin-top: 0; //to override margin top of mixin
}

.filter {
  display: flex;
  align-items: center;
  margin-right: 40px;
}

/*This is added to start myp objectives filter new line
 * when sufficient space is not available
 */
.mypObjectivesFilter {
  width: 420px;
}

.resetButtonContainer {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 24px;
  padding-left: 24px;
  border-left: 1px solid $borderSubtle;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.cardContainer {
  border-bottom: 1px solid $strokeOne;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
}

.label {
  font-size: 1.6rem;
  @include demi-bold-font;
  color: $gray31;
}

.checkIconContainer {
  display: flex;
  width: 16px;
  height: 16px;
  margin-left: 28px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  border: 1px solid $strokeOne;
}

.value {
  font-size: 1.2rem;
  @include medium-font;
  margin-top: 2px;
  color: $gray48;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

$marginOutside: 56px;

.table {
  width: calc(100% + #{$marginOutside});
  border-collapse: collapse;
  table-layout: fixed;
  margin-left: -$marginOutside;
  th,
  td {
    border: 1px solid $strokeOne;
    text-align: center;
    padding: 0px 20px 0 20px;
  }
  td {
    text-align: left;
    vertical-align: top;
    padding: 12px 0 12px 12px;
  }
}

.checkboxCell {
  height: 56px;
  width: 72px;
}

th.deleteIconCell,
td.deleteIconCell {
  height: 56px;
  width: 56px;
  border: none;
}

.deleteIcon {
  visibility: hidden;
  display: flex;
  align-items: center;
}

.row:hover .deleteIcon {
  visibility: visible;
}
.checkboxCellContent {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.inputField {
  width: 100%;
  border: none;
  &:focus {
    outline: none;
  }
}

.actionButton {
  width: 140px;
  margin-top: 24px;
}

.selectedradioButton {
  background-color: $grass;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  cursor: pointer;
}

.radiobutton {
  border: 1px solid $gray48;
  border-radius: 50%;
  background-color: $white;
  height: 18px;
  width: 18px;
  cursor: pointer;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

$right_pane_width: 416px;
$rightPaneAnimationTime: 0.3s;

@keyframes openRightPane {
  to {
    -webkit-transform: translateX(-$right_pane_width);
  }
}

@keyframes closeRightPane {
  to {
    -webkit-transform: translateX($right_pane_width);
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.labelContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.labelRightContainer {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.editLabel {
  display: flex;
  column-gap: 8px;
  color: $textLink;
  cursor: pointer;
}

.boxOuterContainer {
  display: flex;
  height: 72px;
  width: 100%;
  border-radius: 8px;
  border: 1px dashed $strokeTwo;
  padding: 8px;
}

.boxInnerContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  color: $textLink;
  background-color: $surfaceLight;
  border-radius: 8px;
  cursor: pointer;
}

.objectListContainer {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-top: 8px;
}

.objectItemContainer {
  display: grid;
  grid-template-columns: 120px 1fr;
  column-gap: 16px;
  &:hover .actionsContainer {
    visibility: visible;
  }
}

.objectImage {
  height: 92px;
  width: 120px;
  border-radius: 4px;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.objectInfoContainer {
  display: flex;
  flex-direction: column;
}

.textContainer {
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
}

.titleText {
  @include multiLineEllipsis(2.2rem, 1);
}

.remarkText {
  @include multiLineEllipsis(1.9rem, 2);
}

.titleRemarkContainer {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.actionsContainer {
  display: flex;
  column-gap: 4px;
  visibility: hidden;
}

.sourceText {
  margin-top: auto;
  @include medium-font;
  font-size: 1.2rem;
  color: $textSubtle;
  @include multiLineEllipsis(1.6rem, 1);
}

.modalContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

.scrollContainer {
  @include scrollContainer;
  flex: 1;
}

.innerContainer {
  width: 600px;
  align-self: center;
  padding: 40px 0 52px 0;
}

.footerContainer {
  display: flex;
  justify-content: space-evenly;
  padding: 12px 40px;
  box-shadow: 8px 0px 16px rgba(0, 0, 0, 0.05);
}

.tooltipText {
  max-width: 400px;
}

.inputField {
  margin-top: 20px;
}

.inputFieldHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.rightPane {
  display: flex;
  height: 100%;
  border-left: 1px $strokeTwo solid;
  position: absolute;
  right: -470px;
  width: $right_pane_width;
  height: 100%;
  background-color: $white;
  z-index: 101;
  padding-right: 56px;
}

.visible {
  visibility: visible;
  animation: openRightPane $rightPaneAnimationTime;
  animation-fill-mode: forwards;
}

.hidden {
  right: 0;
  animation: closeRightPane $rightPaneAnimationTime;
  animation-fill-mode: forwards;
}

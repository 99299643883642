@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  border-top: 1px solid $borderSubtle;
  height: 56px;
  align-items: center;
}

.icon {
  height: 16px;
  width: 16px;
}

.navigator {
  display: flex;
  margin-left: 40px;
  margin-right: 8px;
}

.navigatorIcon {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 8px;
  margin-right: 8px;
}

.keyNameChamp {
  display: flex;
  background-color: $surfacePressed;
  padding: 3px 6px;
  border-radius: 4px;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
}

.navigateText {
  @include medium-font();
  font-size: 12px;
  color: $textSubtle;
  display: flex;
  align-items: center;
}

.enter {
  display: flex;
  margin-left: 40px;
}

.enterText {
  display: flex;
  margin-left: 8px;
  align-items: center;
  @include medium-font();
  font-size: 12px;
  color: $textSubtle;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";

$borderColor: $strokeTwo;

.itemStyle {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}

.itemHeader {
  display: flex;
}

.itemContent {
  display: flex;
  margin-top: 16px;
}

.emptyMessageContainer {
  border: 1px solid $borderColor;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  height: 144px;
  margin-top: 32px;
}

.emptyMessageTitle {
  @include regular-font;
  margin-top: 8px;
  font-size: 1.6rem;
  color: $gray48;
}

.emptyMessageCentralContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 48px;
  }
}

.addButton {
  cursor: pointer;
  line-height: 8px;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.headerLabel {
  @include demi-bold-font;
  font-size: 1.8rem;
  color: $gray13;
}

.mainContentContainer {
  display: flex;
  flex-direction: column;
  border: solid 1px $strokeTwo;
  border-radius: 4px;
  position: relative;
  margin-top: 16px;
}

.innerContainer {
  display: flex;
  padding: 40px 32px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding: 40px 32px 0 32px;
  border-bottom: 1px solid #dbdbdb;
}
.contentContainer:last-of-type {
  border-bottom: none;
}

.contentHeader {
  margin-bottom: 24px;
  // height: 22px;
  @include bold-font;
  font-size: 1.6rem;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $blue29;
  // color: var(--black-two);
}

.contentSubHeader {
  @include demi-bold-font;
  font-size: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $gray31;
  margin-bottom: 16px;
  // color: var(--black-three);
  &:first-letter {
    text-transform: capitalize;
  }
}

.objectivesContainer {
  display: flex;
  flex-direction: column;
}

// .objectivesContainer:last-of-type {
//   border-bottom: none;
// }

.objectiveContainer {
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 40px;
}

.objectiveContainer:last-of-type {
  border-bottom: none;
  margin-bottom: 0;
}

.contentList {
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  margin-bottom: 32px;
}

.contentListItemContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.contentListItemContentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.contentListItemContent {
  // display: flex;
  flex: 1;
  @include regular-font;
  font-size: 1.6rem;
  word-break: break-word;
  line-height: 2.2rem;
  color: $gray31;
}

.contentListItemContent::first-letter {
  text-transform: capitalize;
}

.contentListItemDeleteButton {
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: solid 1px $salmon60;
  cursor: pointer;
  // transform: rotate(45deg);
  visibility: hidden;
  display: none;
}

.contentListItemContentContainer:hover > .contentListItemDeleteButton {
  visibility: hidden;
  display: none;
}

.contentListItemDivider {
  margin-top: 24px;
  height: 2px;
  border: solid 1px $strokeTwo;
  // border: solid 1px var(--white-three);
}

.lockContainer {
  background-color: $surfaceDisabled;
  cursor: not-allowed;
  pointer-events: none;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";

$borderColor: $strokeTwo;

.mainContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  &:first-child {
    border-top: 1px solid $strokeTwo;
  }
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: 32px 0px;
}

.headerLabel {
  display: flex;
  @include demi-bold-font;
  font-size: 1.4rem;
  color: $gray31;
  flex-shrink: 0;
}

.banchmarksContainer {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  border-bottom: solid 1px $strokeTwo;
}

.mainContentContainer {
  display: flex;
  flex-direction: column;
  border: solid 1px $strokeTwo;
  margin-bottom: 32px;
  padding: 40px 32px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding: 40px 24px 40px 24px;
  border-bottom: 1px solid $strokeTwo;
}

.contentHeader {
  @include bold-font;
  font-size: 1.6rem;
  color: $gray13;
}

.contentSubHeader {
  // @include demi-bold-font;
  text-transform: capitalize;
  font-size: 1.5rem;
  @include medium-font; //reverify if medium was applied or demi-bold was applied
  color: $gray31;
}

.objectiveContainer {
  display: flex;
  padding: 32px 0 32px 0;
  flex-direction: column;
  border-bottom: solid 1px $strokeTwo;
}

.objectiveContainer:last-of-type {
  border-bottom: none;
  padding: 32px 0 0 0;
}

.contentList {
  display: flex;
  flex-direction: column;
}

.contentListItemContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  cursor: pointer;
  color: $gray48;
  &:hover {
    color: $gray31;
  }
}

.contentListItemContainer:first-of-type {
  margin-top: 24px;
}

.contentListItemContentContainer {
  display: flex;
}

.contentListItemContentInnerContainer {
  align-items: flex-start;
  display: flex;
}

.contentListItemContent {
  flex: 1;
  @include regular-font;
  font-size: 1.4rem;
  word-break: break-word;
  color: $gray48;
  &:hover {
    color: inherit;
  }
}

.contentListItemContentNumber {
  height: 100%;
  margin-right: 4px;
  display: flex;
  @include regular-font;
  font-size: 1.4rem;
  color: $gray48;
  &:hover {
    color: inherit;
  }
}

.contentListItemContent::first-letter {
  text-transform: capitalize;
}

.contentListItemCheckbox {
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 16px;
  justify-content: center;
  margin-top: -2.5px;
  visibility: visible;
  cursor: pointer;
}

.contentListItemDivider {
  margin-top: 24px;
  height: 2px;
  border: solid 1px $strokeTwo;
}

.arrowContainer {
  display: flex;
  height: 6px;
  align-self: center;
}

.openArrow {
  transform: rotate(-180deg);
  transition: transform 0.3s;
}

.closeArrow {
  transform: rotate(0deg);
  transition: transform 0.3s;
}

.headerLabelOpen {
  color: $blue29;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputListItemCon {
  composes: container;
  position: relative;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 16px;
  &:hover {
    .deleteCell,
    .editCell {
      display: flex;
    }
    .editCellIconButton {
      display: flex;
    }
  }
}

.inputItemCon {
  display: flex;
}

.addAnotherButton {
  display: flex;
  @include demi-bold-font;
  color: $blue29;
  font-size: 1.4rem;
  cursor: pointer;
  align-items: center;
}

.addButtonDisable {
  cursor: not-allowed;
}

.addButtonContainer {
  display: flex;
  width: 100%;
  margin-top: 1.2rem;
  align-items: center;
}

.addButtonSvgContainer {
  padding: 4px;
  border: 1px solid $blue29;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
}

.tickIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid $gray72;
}

.selectedTickIcon {
  fill: $white;
  background-color: $blue29;
  border-color: $blue29;
}

.deleteCell {
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 32px;
  width: 32px;
  fill: $gray31;
}

.editCell {
  composes: deleteCell;
  fill: $gray48;
}

.editCellIconButton {
  display: none;
}

.dragSvgIcon {
  display: flex;
  position: absolute;
  left: -20px;
}

.noDrag {
  display: none;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
$rightPaneAnimationTime: 0.3s;

@keyframes open_right_pane {
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes close_right_pane {
  to {
    -webkit-transform: translateX(100%);
  }
}

.rightPane {
  display: flex;
  position: absolute;
  //right: -$right_pane_width;
  //width: $right_pane_width;
  height: 100%;

  background-color: $white;
  z-index: 11;
}

.rightPaneFullScreen {
  top: -104px;
  height: calc(100% + 104px);
  z-index: 302;
}

.rightPaneLine {
  display: flex;
  min-width: 3px;
  height: 100%;
  box-shadow: -2px 0 5px 0 rgba(0, 0, 0, 0.1);
}

.visible {
  visibility: visible;
  animation: open_right_pane 0.3s;
  animation-fill-mode: forwards;
}

.hidden {
  right: 0 !important;
  animation: close_right_pane 0.3s;
  animation-fill-mode: forwards;
}

.fullScreenOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(43, 43, 43, 0.6);
  z-index: 301;
}

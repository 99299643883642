@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.headerContainer {
  width: 100%;
  height: 56px;
  border-bottom: 1px solid $borderSubtle;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
}

.headerTitle {
  font-size: 2rem;
  @include bold-font;
  color: $textDefault;
}

.actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.footerContainer {
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  border-top: 1px solid $borderSubtle;
}

.footerButtonsContainer {
  display: flex;
  gap: 12px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.nameContainer {
  display: grid;
  grid-template-rows: auto;
}

.name {
  color: $black;
  @include multiLineEllipsis(1.8rem, 1);
}

.rowWrapper {
  display: inline-block;
}

.seperator {
  padding: 0 8px;
}

.email {
  color: $gray48;
  @include multiLineEllipsis(1.7rem, 1);
  font-size: 1.3rem;
  display: inline-block;
}

.archived {
  font-size: 1.2rem;
  color: $textSubtle;
}

.statusWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 12px;
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  padding: 0px 40px;
}

.dropDownContainer {
  display: flex;
  width: 400px;
  padding: 24px 0px;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.practice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:not(:last-child) {
    border-bottom: 1px solid $strokeOne;
  }
  padding: 24px 0;
  &:last-child {
    padding-bottom: 0;
  }
  cursor: pointer;
}

.disabledPractice {
  cursor: not-allowed;
}

.starIcon {
  fill: $yellow50;
}

.practiceDetails {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}

.practiceCode {
  @include bold-font;
  font-size: 1.4rem;
  color: $blue29;
  display: flex;
  line-height: 1.43;
}

.practiceLabel {
  @include demi-bold-font;
  font-size: 1.4rem;
  color: $gray31;
  line-height: 1.43;
}

.checkbox {
  display: flex;
  margin-right: 4px;
}

.practiceType {
  display: flex;
  text-transform: lowercase;
  margin-left: 4px;
  color: $gray48;
  @include medium-italic-font;
}

.approvedPractice {
  display: flex;
  margin-left: 8px;
}

.highlightText {
  display: inline;
  @include demi-bold-font;
}

.highlightDialogueBody {
  display: inline;
  @include demi-bold-font;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
}

.topContainer {
  display: none;
  flex-direction: column;
}
.overlay {
  display: flex;
  position: absolute;
  flex-direction: column;
  padding: 48px 40px;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  //background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  top: 0;
  left: 0;
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.title {
  @include bold-font;
  font-size: 2.1rem;
  color: $white;
  line-height: 1.33;
}

.dotContainer {
  display: flex;
  flex-direction: row;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  margin-right: 12px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .bottomContainer {
    flex-direction: column;
  }
  .title {
    margin-bottom: 24px;
  }
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: space-between;
    padding: 40px 64px;
  }
  .topContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .heading {
    @include bold-font;
    font-size: 4rem;
    color: $salmon60;
    line-height: 1.38;
    margin-bottom: 24px;
  }
  .subHeading {
    @include medium-font;
    font-size: 2.2rem;
    line-height: 1.75;
    color: $white;
    text-align: center;
  }
  .middleDetails {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 40px;
    margin-top: 40px;
    width: 100%;
    grid-template-columns: repeat(2, 240px);
    justify-content: center;
  }
  .themeGradeContainer {
    display: grid;
    padding: 32px;
    border: 1px solid transparent;
    height: 200px;
    width: 100%;
    justify-items: center;
    border-radius: 4px;
  }
  .number {
    @include bold-font;
    font-size: 4rem;
    color: $white;
    justify-content: center;
    line-height: 1.33;
  }
  .detailLabel {
    @include bold-font;
    font-size: 1.8rem;
    line-height: 1.71;
    color: $white;
    text-align: center;
  }
  .detailSubLabel {
    composes: detailLabel;
    @include medium-font;
  }
}

@media (max-width: 767px) {
  .heading {
    font-size: 3.6rem;
  }
  .subHeading {
    font-size: 2rem;
  }
  .middleDetails {
    grid-column-gap: 24px;
    margin-top: 24px;
    grid-template-columns: unset;
    grid-auto-columns: 1fr;
  }
  .themeGradeContainer {
    padding: 16px;

    height: 124px;
  }
  .title {
    margin-bottom: 16px;
    font-size: 1.6rem;
  }
  .overlay {
    padding: 40px 32px 24px;
  }
  .heading {
    font-size: 3.2rem;
    margin-bottom: 20px;
  }
  .subHeading {
    font-size: 1.6rem;
  }
  .number {
    font-size: 3.2rem;
  }
  .detailLabel {
    font-size: 1.3rem;
  }
  .detailSubLabel {
    font-size: 1.3rem;
  }
}

@import "~UIComponents/CommonStyles/colors";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  padding-bottom: 32px;
}

.item {
  display: flex;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}
.inputField {
  display: flex;
  margin-top: 32px;
}
.inputDateField {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-shrink: 0;
  margin-top: 32px;
}

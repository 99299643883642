@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  align-items: center;
  margin: 0;
}

.count {
  @include demi-bold-font;

  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: $salmon60;
  color: $white;
  font-size: 1.2rem;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

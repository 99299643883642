@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  height: 740px !important;
  width: 720px !important;
  margin-top: 80px;
  margin-bottom: 80px;

  @media (max-height: 740px) {
    margin-top: 64px;
    margin-bottom: 64px;
  }
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
}

.headerContainer {
  padding: 24px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $borderSubtle;
}

.leftHeaderWrapper {
  display: flex;
  align-items: center;
  flex: 1;
}

.rightHeaderWrapper {
  display: flex;
  align-items: center;

  .rightHeaderElement {
    margin-left: 16px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.headerText {
  color: $textDefault;
  font-size: 2.4rem;
  line-height: 1.36;
  @include bold-font;
  margin-right: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
}

.contentInnerContainer {
  flex: 1;
  padding: 16px 0;
  border-bottom: 1px solid $borderSubtle;

  &:last-child {
    border-bottom: none;
  }

  &:first-child {
    padding-top: 0;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 80px;

  @media (min-width: 1550px) {
    margin-top: 76px !important;
  }
  @include mediaQuery("mobile") {
    padding-top: 64px !important;
  }
  @include mediaQuery("tablate") {
    padding-top: 48px !important;
  }
}

.buttonsContainer {
  display: flex;

  margin-bottom: 24px;
  margin-top: 48px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(48px, 1fr));
  grid-gap: 16px;
  @include mediaQuery("mobile") {
    grid-template-columns: repeat(2, 1fr);
  }
}

.buttonContainer {
  flex: 1;

  &:last-child {
    margin-right: 0px;
  }
}

.signInCodeContainer {
  display: flex;
  flex-direction: column;
  margin-top: 56px;
  margin-bottom: 40px;
}

.signInText {
  color: $gray13;
  @include demi-bold-font;
  line-height: 1.36;
}

.inputRowContainer {
  display: flex;
  margin-top: 8px;
}

.inputFieldContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 8px 16px;
  border: 1px solid $strokeTwo;
  border-radius: 8px;
  width: 100%;
}

.inputField {
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 4px;
}

.orContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.orLineContainer {
  display: flex;
  height: 1px;
  flex: 1;
  border-top: 1px solid $strokeTwo;
}
.orText {
  display: flex;
  padding: 0 24px;
  @include medium-font;
  font-size: 1.6rem;
  color: $gray48;
}

.submitButton {
  margin-left: 16px;
}

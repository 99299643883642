@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.containerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: max-content;
}

.container {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.header {
  display: flex;
  font-size: 4rem;
  line-height: 6rem;
  @include bold-font;
  margin-bottom: 64px;
  color: $pink39;
  max-width: 960px;
  text-align: center;
}

.itemsContainer {
  display: grid;
  width: 100%;
  justify-content: center;
  margin-bottom: 64px;
  padding: 0 20px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  border-radius: 8px;
}

.itemTitle {
  display: flex;
  align-items: flex-start;
  text-align: center;
  @include demi-bold-font;
  font-size: 2rem;
  line-height: 3rem;
  color: $bar;
  text-align: center;
}

.itemContent {
  display: flex;
  align-items: flex-start;
  text-align: center;
  @include medium-font;
  font-size: 1.6rem;
  line-height: 2.6rem;
  text-align: center;
  color: $bar;
}

@include mediaQuery("mobile") {
  .container {
    padding: 40px 32px;
  }

  .header {
    font-size: 2.4rem;
    line-height: 3.4rem;
    margin-bottom: 40px;
  }

  .itemsContainer {
    margin-bottom: 48px;
    grid-row-gap: 48px;
  }

  .imageContainer {
    margin-bottom: 16px;
  }

  .itemTitle {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  .itemContent {
  }
}

@include mediaQuery("tablate") {
  .container {
    padding: 64px 0;
  }
  .header {
    font-size: 3.2rem;
    line-height: 4.8rem;
    margin-bottom: 48px;
    max-width: 600px;
  }
  .itemsContainer {
    grid-row-gap: 56px;
  }
}

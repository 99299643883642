@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: grid;
  grid-auto-flow: row;
  row-gap: 48px;
  width: 100%;
}

.noDataContainer {
  display: flex;
  flex: 1;
}

.resourceCard {
  display: flex;
  flex-direction: column;
}

.title {
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $black;
}

.description {
  font-size: 1.4rem;
  color: $gray48;
  margin-top: 4px;
}

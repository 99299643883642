@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.stepContainer {
  height: 48px;
  width: 77px;
  border-radius: 4px;
  display: inline-flex;
  border: 1px solid $strokeTwo;
}

.stepValueWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
  height: 100%;
  border-right: 1px solid $strokeTwo;
}

.stepperWrapper {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 27px;
}
.incrementArrow {
  border-bottom: 1px solid $strokeTwo;
  padding-left: 8px;
  box-sizing: border-box;
  padding-top: 8px;
  height: 24px;
}
.decrementArrow {
  padding-left: 8px;
  box-sizing: border-box;
  padding-top: 8px;
  height: 24px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  height: 64px;
  width: 100%;
  border-top: 1px solid $strokeTwo;
}

.evidenceCountCon {
  @include demi-bold-font;

  font-size: 1.6rem;
  color: $gray13;
}

.btnCon {
  display: flex;
  align-items: center;

  .cancelButton {
    margin-right: 16px;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: grid;
  height: 104px;
  border-radius: 8px;
  width: 100%;
  border: 1px solid $strokeOne;
  background-color: $white;
  cursor: pointer;
  padding: 0 32px;
  align-content: center;
  grid-row-gap: 4px;
  border-left-width: 8px;
  position: relative;
  &:hover {
    border-color: $gray72;
    // border-left-width: 8px;
  }
}

.classLabel {
  @include bold-font;
  font-size: 1.6rem;
  color: $gray13;
}

.unitPlanCount {
  @include medium-font;
  font-size: 1.6rem;
  color: $gray48;
}

.startPlanningText {
  @include medium-font;
  font-size: 1.6rem;
  color: $blue29;
}

.icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: $gray72;
}

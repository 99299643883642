@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 72px;
}

.audioRecorder {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  color: $gray13;
  font-size: 2.4rem;
  @include medium-font;
}

.instructionField {
  @include regular-font;
  width: 600px;
  font-size: 1.6rem;
  color: $bar;
  margin-bottom: auto;
}

.instructionHeading {
  @include bold-font;
  font-size: 1.8rem;
  color: #000000;
}

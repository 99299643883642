@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: grid;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $gray92;
  grid-template-columns: 48px auto 96px;
  grid-gap: 16px;
  align-items: center;
  background-color: $white;
}

.orgName {
  display: flex;
  @include demi-bold-font;
  font-size: 1.6rem;
  line-height: 2.1rem;
  color: $black;
}

.identityTitle {
  display: flex;

  line-height: 1.9rem;
  color: $gray48;
}

.figureContainer {
  display: flex;
  height: 48px;
  width: 48px;
  margin: 0;
}

.figure {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.selectButtonContainer {
  min-width: unset;
  width: 96px;
  height: 40px;
}

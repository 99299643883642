@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 0 32px;
  border-bottom: 1px solid $strokeTwo;
  background: $black;
  justify-content: space-between;
}

.logoCon {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  flex-shrink: 0;
}

.chevronIconContainer {
  display: flex;
  margin-right: 8px;
}

.title {
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  color: $gray13;
  @include bold-font;
  @include multiLineEllipsis(2.4rem);
  margin-left: 16px;
  max-width: 450px;
  flex: 1;
  color: $white;
}

.newTabIcon {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-items: center;
}

.turnInButtonContainer {
  margin-left: 24px;
}

.rightPanel {
  display: flex;
  justify-content: space-around;
}

.similarityReportBorderStyle {
  display: flex;
  align-items: center;
  height: 32px;
  padding-right: 20px;
  border-right: 1px solid $white;
}

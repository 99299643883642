@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    border-bottom: 1px solid $strokeOne;
  }

  padding: 24px 0;
  cursor: pointer;
}

.standardLabelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rightContainer {
  display: flex;
  margin-right: 8px;
}

.standardLabel {
  @include bold-font;
  font-size: 1.6rem;
  color: $gray13;
  @include multiLineEllipsis(2.2rem, 1);
  flex-grow: 1;
  margin-right: 32px;
}

.selectedCount {
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: $salmon60;
  color: $white;
  font-size: 1.2rem;
  @include demi-bold-font;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.practiceList {
  display: flex;
  flex-direction: column;
}

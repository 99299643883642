@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
}

.standardsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 24px 0 40px;

  @include scrollContainer();
}

.noDataText {
  display: flex;
  text-align: center;
  width: 100%;
  font-size: 1.4rem;
  color: $gray48;
  flex: 1;
  align-items: center;
  justify-content: center;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 87px;
  background-color: $blue96;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 10;
  border-bottom: 1px solid $borderSubtle;
}

.innerContainer {
  display: flex;
}

.actionButtonsContainer {
  display: grid;
  grid-column-gap: 16px;
  grid-auto-flow: column;
  align-items: center;
}
.actionButton {
  display: flex;
  flex-direction: row;
  margin-left: 12px;
  cursor: pointer;
}

.countText {
  display: flex;
  font-size: 1.6rem;
  color: $black;
  @include medium-font;
  align-items: center;
}

.buttonText {
  display: flex;
  font-size: 1.6rem;
  @include medium-font;
  cursor: pointer;
}

.cancelIcon {
  display: flex;
  cursor: pointer;
  right: 32px;
  fill: $gray48;
  position: absolute;
}

.leftContainer {
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 584px;
}

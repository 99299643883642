@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.hierarchyContainer {
  display: flex;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 128px;
  background-color: $surfaceWhite;
  z-index: 2;
}

.breadCrumbLabel {
  @include demi-bold-font;
  font-size: 1.6rem;
  @include multiLineEllipsis(2rem, 1);
}

.parent {
  color: $textSubtle;
  cursor: pointer;
}

.child {
  color: $textDisabled;
}

.iconContainer {
  display: flex;
  margin: 0 6px;
}

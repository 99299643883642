@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/mixins";

.suggestionsBox {
  display: flex;
  overflow-y: scroll;
  flex: 1;
  flex-direction: column;
}

.suggestionTextBox {
  display: flex;
  flex: 1;
  padding: 12px;
  @include border-class("bottom", $radius: 0);
  &:hover {
    background-color: $toddleBG1;
    cursor: pointer;
  }
}

.emptyResult {
  display: flex;
  flex: 1;
  justify-content: center;
  align-self: center;
}

.loadingContainer {
  display: flex;
  flex: 1;
  width: 100%;
  margin-top: 12px;
}

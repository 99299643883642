@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;

  flex-direction: column;
}

.createButton {
  display: flex;
  height: 48px;
  width: 100%;
  border-radius: 2px;
  border: 1px solid $blue29;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: $blue29;
    .createText {
      color: white;
    }
    .addIcon {
      fill: white;
    }
  }
  // background-color: $toddleBG1;
  flex-shrink: 0;
  padding: 16px;
  cursor: pointer;
}

.createText {
  display: flex;
  @include demi-bold-font();
  font-size: 1.8rem;
  color: $blue29;
  line-height: 1.44;
}

.chooseText {
  display: flex;
  font-size: 1.8rem;
  color: $gray13;
  @include bold-font();
  margin-top: 32px;
}

.addIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  fill: $blue29;
}

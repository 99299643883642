@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  min-height: 56px;
  border: 1px solid $strokeTwo;
  padding: 6px 0 6px 12px;
  max-height: 128px;
  flex-shrink: 0;
  border-radius: 4px;
  cursor: pointer;
}

.leftContainer {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  overflow-y: auto;
  align-items: center;
  background: transparent;
}

.textInputContainer {
  border: none;
  border-radius: 0;
  outline: none;
  flex: 1;
  margin-left: 4px;
  align-self: center;
  line-height: 28px;
  font-size: 1.6rem;
  background: transparent;
}

.focusContainer {
  border-color: $gray72;
}

.rightContainer {
  display: flex;
  align-items: center;
  max-height: 48px;
  min-width: 32px;
  padding-right: 8px;
}

.arrowContainer {
  display: flex;
  fill: $gray48;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.doneButton {
  display: flex;
  color: $salmon60;
  @include medium-font;
  font-size: 1.6rem;
  height: 100%;
  // padding-right: 24px;
  align-items: center;
  cursor: pointer;
}

.errorContainer {
  display: flex;
  border-color: $borderCritical;
}

.errorText {
  display: flex;
  margin-top: 8px;
  color: $salmon60;
  @include demi-bold-font;
  font-size: 1.2rem;
  height: 14px;
  text-align: left;
  width: 100%;
}

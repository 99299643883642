@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.rndContainer {
  box-shadow: 1px 1px 20px -8px rgba(0, 0, 0, 0.5);
  border: 1px solid $strokeOne;
  background: $white;
  border-radius: 4px;
  overflow: hidden;
  z-index: 2147483001;
}

.rndContent {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  padding: 0 12px 12px 12px;
  border: 1px solid $strokeOne;
}

.toolBar {
  padding: 8px 12px;
  display: flex;
  justify-content: flex-end;
}

.closeButton {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
$right_pane_width: 400px;
$rightPaneAnimationTime: 0.3s;
$left_pane_min_width: 1040px;
@keyframes open_right_pane {
  to {
    -webkit-transform: translateX(-$right_pane_width);
  }
}

@keyframes close_right_pane {
  to {
    -webkit-transform: translateX($right_pane_width);
  }
}

.overlayContainer {
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 400;
  background-color: #fff;
}

.container {
  display: flex;
  height: 100%;
  width: 100%;
}

.leftPane {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-shrink: 0;
  position: relative;
}

.smallLeftPane {
  composes: leftPane;
  width: calc(100% - #{$right_pane_width});
  min-width: $left_pane_min_width;
  //transition: width $rightPaneAnimationTime;
}

.fullLeftPane {
  composes: leftPane;
  width: 100%;
}

.rightPane {
  display: flex;
  height: 100%;
  border-left: 1px $strokeOne solid;
  position: absolute;
  right: -$right_pane_width;
  width: $right_pane_width;
  height: 100%;
  background-color: $white;
  z-index: 11;
}

.visibleRightPane {
  visibility: visible;
  animation: open_right_pane 0.3s;
  animation-fill-mode: forwards;
}

.hiddenRightPane {
  right: 0;
  animation: close_right_pane 0.3s;
  animation-fill-mode: forwards;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.modalContent {
  width: 920px !important;
  height: 600px !important;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}

.banner {
  width: 240px;
  flex: 0 0 240px;
  background-size: cover;
  background-position: center;
}

.content {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
}

.headerContainer {
  padding: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 0 0 auto;
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.header {
  display: flex;
  flex: 1;
  color: $gray13;
  @include bold-font;
  font-size: 2.4rem;
  @include multiLineEllipsis(3.2rem, 2);
}

.createdBy {
  display: flex;
  font-size: 1.6rem;
  @include medium-font;
  color: $gray48;
  span {
    @include demi-bold-font;
    margin-right: 4px;
  }
}

.body {
  padding: 0 40px;
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 12px;
}

.shareActionsContainer {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 40px;
  flex: 0 0 auto;
}

.shareAction {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-right: 32px;
  cursor: pointer;

  &:last-of-type {
    margin-right: 0;
  }
}

.shareIcon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 8px;
}

.shareTitle {
  font-size: 1.2rem;
  color: $gray48;
}

.contactSearchContainer {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}

// .divider {
//   width: 100%;
//   text-align: center;
//   position: relative;
//   margin: 12px 0;
//   background: linear-gradient(0deg, white, #ffffff);

//   span {
//     padding: 0 12px;
//     background: white;
//     position: relative;
//     z-index: 1;
//     font-size: 1.4rem;
//     color: $gray13;
//     @include medium-font;
//     background-image: linear-gradient(
//       to bottom,
//       rgba(255, 255, 255, 0),
//       #ffffff 50%
//     );
//     height: 64px;
//   }

//   &:after {
//     content: "";
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     height: 1px;
//     width: 100%;
//     background: $strokeOne;
//     left: 0;
//   }
// }

.footer {
  border-top: 1px solid $strokeTwo;
  padding: 12px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
}

.footerButtonContainer {
  display: flex;
  align-items: center;
}

.selectedCount {
  display: flex;
  font-size: 1.6rem;
  @include medium-font;
  color: $blue29;
}

.emailShareModalContent {
  height: auto;
  padding: 40px;
}

.emailShareModalHeader {
  font-size: 2.4rem;
  @include bold-font;
  margin-bottom: 32px;
}

.emailShareModalActions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  margin-top: 40px;
}

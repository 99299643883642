@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.overlayContainer {
  background: rgba($bar, 0.9) !important;
  opacity: 1 !important;
}

.modalContent {
  height: 560px !important;
  width: 476px !important;
  border-radius: 8px;
}

.countDownModal {
  height: 257px !important;
  width: 440px !important;
  border-radius: 8px;
}

.enterCodeModal {
  height: 380px !important;
  width: 680px !important;
  border-radius: 8px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 32px 38px;
  position: relative;
  align-items: stretch;
  .headerWrapper {
    margin-bottom: 32px;
    .header {
      font-size: 2rem;
      line-height: 3.2rem;
      @include bold-font;
      color: $bar;
    }

    .subHeading {
      font-size: 1.6rem;
      line-height: 2.4rem;
      @include medium-font;
      color: $pink39;
      margin-top: 4px;
    }
  }

  .image {
    margin-bottom: 32px;
    height: 248px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 100%;
    img {
      width: 100%;
    }
  }

  .subText {
    font-size: 1.4rem;
    line-height: 2rem;
    @include medium-font;
    color: $gray48;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UnitPlans/components/LibraryCommonComponents/common.scss";

.container {
  display: flex;
  width: 100%;
  overflow-y: auto;
  flex-direction: column;
  padding: 0 72px;
}

.innerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: max-content;
}

.stepContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  grid-row-gap: 16px;
  width: 100%;
  max-width: 1008px;
  &:not(:first-child) {
    border-top: 1px solid $borderSubtle;
  }
  padding: 40px 0;
}

.maxiumContainer {
  max-width: 992px;
}

.headerTxtContainer {
  display: flex;
  flex-direction: row;
}

.stepLabel {
  display: flex;
  flex-direction: column;
  @include bold-font;
  font-size: 1.8rem;
  color: $textDefault;
  flex-basis: 200px;
}

.textContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex: 1;
}

.iconContainer {
  display: flex;
  margin-left: 8px;
}

.informationIcon {
  display: flex;
  align-items: center;
}

.stepSubText {
  display: flex;
  margin-top: 8px;
  @include regular-font;
  font-size: 1.6rem;
  color: $textSubtle;
}

.deleteBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.formContainer {
  width: 100%;
  max-width: 696px;
  display: flex;
  flex-direction: column;
  word-break: break-word;
  justify-content: space-between;
}

.item {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 40px;
  }

  &:empty {
    margin-bottom: 0px;
  }
}

.chooseTemplateText {
  cursor: pointer;
  @include demi-bold-font;
  font-size: 1.6rem;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: $blue29;
}

.print {
  line-height: 0;
  cursor: pointer;
  fill: $gray72;
  margin-right: 16px;
  &:hover {
    fill: $black;
  }
}

.addBlockBtn {
  border: 1px solid $pink39;
  display: flex;
  height: 64px;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @include demi-bold-font;
  font-size: 1.6rem;
  cursor: pointer;
  color: $pink39;
  margin-top: 16px;
}

.alertContainer {
  display: flex;
  margin: 32px auto 0px;
}

.dropzoneClass {
  display: flex;
  align-items: stretch;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
  // padding: 0 40px;
}
.rightPane {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.leftPane {
  display: flex;
  justify-content: flex-start;
}

.selectedText {
  display: flex;
  font-size: 1.4rem;
  color: $salmon60;
  @include regular-font;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.mainContainer {
  display: flex;
  width: 100%;
  background-color: $seaweedTwo;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
}
.container {
}

.stepsContainer {
  display: grid;
  margin-top: 40px;
  grid-auto-flow: column;

  grid-gap: 20px;
}

.step {
  display: flex;
  flex-direction: column;
  width: 240px;
  border-radius: 4px;
  background-color: $white;
  padding: 24px;
}

.dateText {
  @include bold-font;
  font-size: 2.4rem;
  line-height: 1.67;
}

.year {
  @include medium-font;
  font-size: 1.6rem;
  line-height: 1.5;
  color: $gray48;
}

.details {
  @include medium-font;
  font-size: 1.6rem;
  line-height: 1.75;
  color: $gray13;
  margin-top: 24px;
}
.button {
  margin-top: 56px;
  width: 256px;
  align-self: center;
}

@media (max-width: 991px) {
  .stepsContainer {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
    grid-gap: 32px;
  }
}

@media (max-width: 767px) {
  .stepsContainer {
    margin-top: 24px;
    grid-auto-flow: row;
    grid-template-columns: unset;
    grid-row-gap: 20px;
  }
  .step {
    padding: 20px 24px;
  }
  .details {
    margin-top: 16px;
  }
  .button {
    margin-top: 32px;
    width: 100%;
  }
}

.disclaimerText {
  display: flex;
  margin-top: 64px;
  font-size: 1.6rem;
  color: $white;
  @include medium-italic-font;
  max-width: 720px;
  text-align: center;
  line-height: 2.2rem;
}

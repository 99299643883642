@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-direction: column;
}

.assessmentList {
  margin-top: 0px;
}

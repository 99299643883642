@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border-width: 1px;
  border-color: $teal20;
  align-items: center;
  justify-content: center;
  border-style: solid;
  //background-color: white;
}

.imageContainer {
  display: flex;
  flex: 1;
  background-color: $strokeOne;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-shrink: 0;
  border-radius: 4px;
}

.text {
  display: flex;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $teal20;
  text-align: center;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
}

.inputField {
  display: flex;
  margin-bottom: 24px;
}

.attachmentField {
  display: flex;
  margin-bottom: 48px;
}

.addResourceModalContainer {
  width: 840px !important;
  height: 640px !important;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.mainContainer {
  display: flex;
  flex-direction: column;
  padding: 40px 0 24px 0;
}

.tabsContainer {
  display: flex;
}

.createDiv {
  margin-top: 24px;
}

// .item:last-of-type {
//   border-bottom: none;
// }

.description {
  margin-top: 0px;
  max-height: 70px;
  @include regular-font;
  font-size: 1.4rem;
  line-height: 1.63;
  color: $gray72;

  overflow: scroll;
}

.addMoreButton {
  display: flex;
  // text-decoration: underline;
  @include medium-font;
  font-size: 1.4rem;
  color: $blue29;
  cursor: pointer;
}

.divider {
  display: flex;
  width: 100%;
  height: 0.1px;
  margin-top: 24px;
  border-bottom: solid 1px $strokeTwo;
}

.modalContent {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 450;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.emptyText {
  display: flex;
  margin-top: 16px;
  @include medium-font;
  font-size: 1.6rem;
  color: $gray31;
}

.addButton {
  padding: 1px;
  border: 1px solid;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  margin-left: auto;
}

.headerContainer {
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  justify-content: space-between;
}

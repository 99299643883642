@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  //height: 88px;
  //box-shadow: 2px 0 8px 0 rgba($black, 0.1);
  background-color: $white;
  flex-direction: column;
  margin-bottom: 24px;
  position: relative;
}

.titleContainer {
  display: flex;
  margin-top: 40px;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.leftContainer {
  display: flex;
  width: 80%;
  align-items: center;
}

.rightContainer {
  display: flex;
  align-items: center;
  align-items: center;
  flex-direction: row;
  height: 18px;
}

.previewButton {
  display: flex;
  @include demi-bold-font;
  color: $blue29;
  font-size: 1.6rem;
  cursor: pointer;
}

.separatorline {
  display: flex;
  border-right: 1px solid $strokeTwo;
  width: 1px;
  height: 100%;
  margin: 0 12px;
}

.titleText {
  display: flex;
  @include bold-font;
  font-size: 2.4rem;
  color: $gray13;
}

.closeButton {
  display: flex;
  width: 24px;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.backContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 16px;
}

.backBtnText {
  display: flex;
  @include medium-font;
  color: $salmon60;
  font-size: 1.4rem;
  margin-left: 8px;
  margin-top: 3px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.mainContainer {
  display: flex;
  width: 100%;
  height: max-content;
  //max-height: 150px;
  background-color: $white;
  cursor: pointer;
  flex-shrink: 0;
  padding: 0 24px;
}

.isRead {
  background: #ffffff;
  &:hover {
    background: $toddleBG1;
  }
}

.notRead {
  background: rgba($blue29, 0.07);
  &:hover {
    background: #e7f5f6;
  }
}

.contentContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 0;
  border-bottom: 1px solid $strokeOne;
}

.communityContentContainer {
  border-bottom: 0;
  padding: 16px 0;
}

.icon {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: solid 1px $strokeTwo;
  flex-shrink: 0;
}

.contentTextContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 16px;
}

.contentHeaderContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 4px;
  align-items: center;
}

.contentHeader {
  display: flex;
  font-size: 1.4rem;
  color: $gray31;
  flex-shrink: 0;
  flex: 1;
  @include bold-font;
}

.content {
  font-size: 1.4rem;
  color: $gray31;
  flex-shrink: 0;
  @include medium-font;
  line-height: 1.8rem;
  @include multiLineEllipsis(2.2rem, 3);
  b {
    @include demi-bold-font;
  }
  * {
    font-size: 1.4rem !important;
  }
}

.duration {
  display: flex;
  @include regular-font;
  font-size: 1.2rem;
  text-align: right;
  color: $gray48;
  align-items: flex-end;
}

.iconContainer {
  display: flex;
  min-width: 72px;
  width: 72px;
  height: 72px;

  border-radius: 4px;
  position: relative;
}

.communityIconContainer {
  width: 56px;
  height: 56px;
}

.readDot {
  display: flex;
  position: absolute;
  left: -12px;
  top: 50%;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  background-color: $salmon60;
}

.imageContainer {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-shrink: 0;
  position: absolute;
  background-color: $strokeOne;
  // .initials {
  //   font-size: 4rem;
  //   color: $gray72;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   @include demi-bold-font;
  // }
  // .iconWrapper {
  //   display: inline-flex;
  //   z-index: 1;
  // }
}

.overlay {
  display: flex;
  background-color: rgba($black, 0.5);
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.detailContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  font-size: 1.2rem;
  color: $gray48;
  @include medium-font;
}

.dotContainer {
  margin: 0 4px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.bottomContainer {
  overflow-y: auto;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  max-width: 880px;
  min-width: 440px;
  width: 100%;
  padding: 0 40px;
  margin: 0 auto;
}

.notificationContainer {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
}

.headerText {
  @include bold-font;
  color: $bar;
  font-size: 2rem;
}

.settingsContainer {
  margin: 32px 0;
}

.childrenContainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 48px;
}

.childrenHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.button {
  width: 84px;
  height: 32px;
}

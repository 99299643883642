@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
@import "../common.scss";

.container {
  padding-bottom: 100px;
}

.itemsContainer {
  grid-template-columns: repeat(auto-fill, minmax(326px, 1fr));
  grid-column-gap: 26px;
  margin-bottom: 0;
}

.item {
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.04);
}

.itemTitle {
  @include bold-font;
  font-size: 2rem;
  line-height: 3.2rem;
}

@include mediaQuery("mobile-and-tablet") {
  .container {
    padding-bottom: 32px;
    grid-row-gap: 28px;
  }
  .item {
    padding: 32px 38px;
  }
  .imageContainer {
    margin-bottom: 24px;
  }
  .itemContent {
    color: $gray13;
  }
}

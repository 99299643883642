@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

$borderColor: $strokeTwo;

.headerDetailContainer {
  display: flex;
  width: 100%;
  padding: 0 16px;
  flex-direction: column;
  min-height: 80px;
  border-bottom: 1px $borderColor solid;
  justify-content: center;
}
.headerContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.headerText {
  display: flex;
  @include demi-bold-font;
  font-size: 2rem;
  color: $gray13;
}
.closeButton {
  display: flex;
  width: 24px;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.detailText {
  display: flex;
  color: $blue29;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1.4rem;
  @include medium-font;
}

.backButton {
  display: flex;
  justify-content: center;
  margin-right: 18px;
  &:hover {
    cursor: pointer;
  }
}

.headerTextWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
}

.footerButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

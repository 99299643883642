@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.sectionItem {
  display: flex;
  width: 100%;
  flex-shrink: 0;

  &:hover {
    .sectionLabel {
      text-decoration: underline;
    }
  }
}

.containerDisable {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  opacity: 0.5;
  pointer-events: none;
  &:hover {
    .sectionLabel {
      text-decoration: underline;
    }
  }
}

.imageContainer {
  display: flex;
  height: 64px;
  width: 88px;
  flex-shrink: 0;
}

.sectionItemDetails {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.sectionLabel {
  font-size: 1.6rem;
  color: $gray31;
  margin-bottom: 4px;
  @include medium-font;
  @include multiLineEllipsis(2.2rem, 1);
  word-break: break-all;
}

.sectionItemType {
  font-size: 1.4rem;
  margin-bottom: 4px;
}

.sectionAuthorAndDuration {
  font-size: 1.4rem;
  color: $gray48;
  @include multiLineEllipsis(1.8rem, 1);
}

.rightContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // padding-right: 16px;
  flex-shrink: 0;
  width: 6%;
}

.clickableTitle {
  width: fit-content;
  width: -moz-fit-content;
  &:hover {
    text-decoration: underline !important;
  }
}

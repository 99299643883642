@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: 124px;

  @media (min-width: 1550px) {
    margin-top: 140px !important;
  }
  @media (min-width: 1024px) and (max-width: 1140px) {
    padding-top: 64px !important;
    margin: 0;
  }
  @include mediaQuery("mobile") {
    padding-top: 64px !important;
    margin: 0;
  }
  @include mediaQuery("tablate") {
    padding-top: 48px !important;
    margin: 0;
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 24px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 50px;

  border: 1px solid $strokeTwo;
  border-radius: 2px;
  &:hover {
    border: 1px solid $gray72;
  }
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.orContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 48px;
}

.orLineContainer {
  display: flex;
  height: 1px;
  flex: 1;
  border-top: 1px solid $strokeTwo;
}
.orText {
  display: flex;
  padding: 0 24px;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $gray48;
}

.createAccountButton {
  color: $blue29;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: darken($blue29, 10%);
  }
}

.accountContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 28px 28px 28px 0;
  max-width: 560px;
  max-width: 560px;
  max-height: 104px;
  border-radius: 6px;
  border: solid 1px $strokeTwo;
  background-color: #ffffff;
  margin: 8px 0px;

  @include mediaQuery("mobile") {
    padding: 24px 24px 24px 0;
  }
  @include mediaQuery("tablate") {
    padding: 24px 24px 24px 0;
  }

  &:hover {
    border: 1px solid $gray72;
  }
}

.accountDetails {
  flex: 1;
}

.accountTitle {
  font-size: 1.6rem;
  @include demi-bold-font;
  line-height: 1.36;
  color: $gray13;
  margin-bottom: 4px;
}

.accountDescription {
  font-size: 1.6rem;
  @include regular-font;
  line-height: 1.36;
  color: $gray48;
  margin-bottom: 4px;

  @include mediaQuery("mobile") {
    font-size: 1.4rem !important;
  }
}

.accountIcon {
  display: flex;
}

.accountActionIcon {
  width: 9px;
  height: 16px;
  fill: $iconDefault;

  &:hover {
    fill: $iconHover;
  }
}

.signInAsVisitor {
  display: flex;
  align-items: center;
  @include medium-font;
  color: $gray13;
  font-size: 1.6rem;
  line-height: 1.36;
}

.signInText {
  display: flex;
  color: $blue29;
  margin-left: 4px;
  @include medium-font;
  cursor: pointer;
  text-decoration: underline;
}

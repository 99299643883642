@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  width: 86vw !important;
  height: 90vh !important;
}

.modalHeader {
  display: flex;
  color: $gray13;
  @include bold-font();
  font-size: 2.4rem;
  padding: 40px;
  flex-shrink: 0;
  justify-content: space-between;
}

.modalBody {
  display: flex;
  margin-bottom: 64px;
  overflow-y: auto;
  width: 100%;
  //height: max-content;
  padding: 0 40px 40px;
  height: 100%;
}

.innerWrapper {
  width: 100%;
}

.actionButtons {
  display: flex;
}

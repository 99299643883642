@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.BFlexDivCol {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.container :global {
  composes: BFlexDivCol;
  .SingleDatePicker_picker {
    z-index: 15 !important;
  }
}
.contentWrapper {
  display: flex;
  width: 100%;
  flex: 1;
  overflow-y: scroll;
}

.content {
  display: flex;
  width: 100%;
  padding: 40px 128px;
  max-width: 984px;
  height: max-content;
  min-height: 100%;
  flex-direction: column;
  margin: 0 auto;
}

.inputField {
  display: flex;
  margin-bottom: 24px;
}

.horizontalRowContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;
}

.coursesContainer {
  display: grid;
  grid-row-gap: 24px;
  grid-auto-flow: row;
  width: 100%;
}

.recurringMeeting {
  margin-top: 56px;
}

.rangeText {
  @include medium-font;
}

.recurringItemsContainer {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}

.offestSelection {
  margin-bottom: 32px;
  display: flex;
}

.weekdayRepeat {
  margin-bottom: 32px;
}

.daysContainer {
  display: inline-flex;
  width: 80%;
}

.dayButton {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 28px;
  border: 2px solid $strokeTwo;
  background: $white;
  color: $gray48;
}

.selectedDayButton {
  border: 2px solid $blue29;
  background: $blue29;
  color: $white;
}

.dayLabel {
  @include demi-bold-font;
  font-size: 1.6rem;
  margin: auto;
  display: inline-block;
}

.endDateContainer {
  display: inline-block;
  width: 200px;
}

.addClassModalContainer {
  width: 600px !important;
  height: 600px !important;
}

.linkedTextCon {
  display: flex;
  justify-content: center;
  padding: 16px;
  background: $toddleBG1;
  font-size: 1.4rem;
  color: $gray48;
  border-radius: 4px;
}

.separator {
  background-color: $strokeTwo;
  height: 1px;
  border: 0;
  margin: 32px 0px;
}

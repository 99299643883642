@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container :global {
  .Select-control {
    border-radius: 4px !important;
  }
  .is-disabled > .Select-control {
    background-color: $gray98 !important;
    border: 1px solid $strokeTwo !important;
    border-radius: 4px !important;
  }
  .Select.is-disabled .Select-arrow-zone {
    display: none !important;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
}

.subjectGroupPairs {
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  grid-row-gap: 24px;
}

.pairContainer {
  display: flex;
  flex-direction: column;
}

.titleContainer {
  display: flex;
  margin-bottom: 8px;
}

.titleText {
  flex: 1;
  font-size: 1.8rem;
  @include demi-bold-font;
}

.removeContainer {
  display: flex;
  margin-left: 8px;
  color: $error50;
  cursor: pointer;
  align-items: center;

  span {
    margin-right: 4px;
  }
}

.pair {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid $gray92;
  border-radius: 8px;
  padding: 24px;
}

.childPair {
  display: flex;
  align-items: center;
  flex: 1;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 40px;
}

.addButtonContainer {
  display: flex;
  width: max-content;
  margin-top: 24px;
  cursor: pointer;

  align-items: center;
}

.addButtonSvgContainer {
  display: flex;
  height: 24px;
  width: 24px;
  background-color: $blue29;
  border-radius: 50%;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
}

.addAnotherButton {
  display: flex;
  @include demi-bold-font;
  color: $blue29;
  font-size: 1.4rem;
}

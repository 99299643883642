@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.wrapper {
  display: flex;
  flex: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 200;
}

.container :global {
  a {
    color: $blue29;
  }
  width: 840px;
  height: 600px;
  background-color: $white;
  margin: auto;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  position: relative;
}
.leftContainer {
  padding: 40px;
  width: 280px;
  flex-shrink: 0;
}
.image {
  width: 100%;
  padding-bottom: 100%;
  background-position: center center;
  background-size: cover;
}
.rightContainer {
  padding: 40px 40px 0 0;
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 2.8rem;
  color: $blue29;
  @include bold-font;
}
.content {
  margin-top: 24px;
  @include medium-font;
  font-size: 1.8rem;
  line-height: 1.7em;
  overflow-y: auto;
  white-space: pre-line;
  padding-right: 8px;
  padding-bottom: 16px;
}
.closeBtn {
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
  fill: $gray72 !important;
  &:hover {
    fill: $gray48 !important;
  }
}

@media (max-width: 991px) {
  .container {
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: 0;
    overflow-y: auto;
  }
  .leftContainer {
    padding: 24px;
  }
  .rightContainer {
    padding: 0 24px;
  }
  .title {
    font-size: 2.4rem;
  }
  .content {
    margin-top: 16px;
    font-size: 1.6rem;
    overlay: unset;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  align-items: center;
  padding: 114px 100px;
}

.reverseContainer {
  flex-direction: row-reverse;
}

.textContainer {
  display: grid;
  max-width: 440px;
  grid-row-gap: 24px;
  flex-shrink: 0;
  margin-right: 80px;
}

.reverseTextContainer {
  margin-right: 0;
  margin-left: 80px;
}
.title {
  @include bold-font;
  line-height: 1.63;
  font-size: 3.2rem;
  color: $salmon60;
}

.description {
  @include medium-font;
  font-size: 2rem;
  color: $gray48;
  line-height: 2;
}

.imageContainer {
  display: flex;
  flex: 1;
}

.image {
  width: 100%;
}

@media (max-width: 991px) {
  .container {
    flex-direction: column-reverse;
    padding: 80px 64px;
  }
  .imageContainer {
    margin-bottom: 40px;
    margin-left: -24px;
  }

  .textContainer {
    margin-right: 0;
    max-width: 100%;
    grid-row-gap: 20px;
  }

  .reverseTextContainer {
    margin-left: 0;
  }

  .title {
    font-size: 3rem;
  }

  .description {
    font-size: 1.8rem;
  }
}

@media (max-width: 767px) {
  .container {
    flex-direction: column-reverse;
    padding: 56px 24px;
  }
  .imageContainer {
    margin-bottom: 24px;
    margin-left: -12px;
  }

  .textContainer {
    margin-right: 0;
    max-width: 100%;
    grid-row-gap: 16px;
  }

  .title {
    font-size: 2.8rem;
  }

  .description {
    font-size: 1.6rem;
  }
}

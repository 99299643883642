@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  border-bottom: 1px solid $strokeTwo;
  flex-shrink: 0;
  padding: 24px 0;
  width: 100%;
  justify-content: space-between;
}

.detailText {
  @include regular-font;
  font-size: 1.6rem;
  color: $gray31;
}

.richText {
  word-break: break-word;
  unicode-bidi: plaintext;
  * {
    font-size: 1.6rem !important;
    unicode-bidi: plaintext;
  }
  a {
    color: $blue29;
  }
}

.whiteSpacePreWrap {
  white-space: pre-line;
}

.customRichText {
  word-break: break-word;
  * {
    font-size: inherit;
  }
  a {
    color: $blue29;
  }
}

a {
  color: $blue29;
}

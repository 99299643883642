@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  align-items: center;
  width: 218px;
}

.courses {
  color: $gray31;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  flex: 1;
  width: 186px;
}

.courseName {
  flex: 1;
  @include multiLineEllipsis(1.6rem, 1);
}

.more {
  // margin-left: 4px;
  width: 100%;
}

.editIcon {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.checkListContainer {
  width: 644px;
  height: 455px;
}

.checkListBody {
  height: 383px;
  padding: 16px 24px;
  overflow-y: auto;
}

.checkListFooter {
  height: 72px;
  border-top: 1px solid $strokeOne;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttonContainer {
  margin-left: 16px;
  display: flex;
  align-items: center;
}
.buttonContainer:first-child {
  margin-left: 0;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.collapseText {
  display: flex;
  cursor: pointer;
  @include demi-bold-font;
  color: $blue29;
  margin-top: 8px;
  width: max-content;
}

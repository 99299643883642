@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

$home-left-banner-url: "https://cloud.toddleapp.com/assets/webapp/login_page/HomeLeftBanner.svg";

$paddingHorizontal: 32px;
.duck {
  display: block;
  width: 120px;
  margin: 1.5rem auto;
}
.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.leftSide {
  display: flex;
  width: 38%;
  background-color: $violet54;
  height: 100%;
  flex-direction: column;

  position: relative;

  @media (min-width: 1024px) and (max-width: 1140px) {
    display: none;
  }
  @include mediaQuery("mobile") {
    display: none;
  }
  @include mediaQuery("tablate") {
    display: none;
  }
}

.leftBanner {
  width: 100%;
  height: 100%;
  background-image: url($home-left-banner-url);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $violet54;

  opacity: 1;
  animation: fadeInAnimation ease 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
}

.leftSideLogoContainer {
  display: flex;
  position: absolute;
  padding: 60px;
}

.toddleLogo {
  display: flex;
}
.toddleLogoText {
  display: flex;
  margin-left: 20px;
  fill: $white;

  @media (min-width: 1024px) and (max-width: 1140px) {
    fill: $salmon60 !important;
  }
  @include mediaQuery("mobile") {
    fill: $salmon60 !important;
  }
  @include mediaQuery("tablate") {
    fill: $salmon60 !important;
  }
}

.quoteContainer {
  display: flex;
  position: absolute;
  bottom: 80px;
  flex-direction: column;
  padding: 0 128px 0 80px;
}

.quoteMark {
  display: flex;
  fill: $strokeTwo;
  margin-bottom: 16px;
}

.quoteText {
  display: flex;
  @include italic-font;
  color: $white;
  font-style: italic;
  font-size: 2.8rem;
  line-height: 4.2rem;
}

.authorName {
  display: flex;
  color: $white;
  font-size: 2rem;
  @include demi-bold-font;
  margin-top: 64px;
}

.rightSide {
  display: flex;
  width: 62%;
  background-color: #ffffff;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  overflow-y: auto;

  @media (min-width: 1024px) and (max-width: 1140px) {
    width: 100%;
  }
  @include mediaQuery("mobile") {
    width: 100%;
  }
  @include mediaQuery("tablate") {
    width: 100%;
  }
}
.rightSideInnerContainer {
  display: flex;
  height: 100%;
  width: 70%;
  padding: 0 $paddingHorizontal;
  align-items: center;
  flex-direction: column;
  position: relative;

  @media (min-width: 1550px) {
    width: 60%;
  }
  @media (min-width: 1024px) and (max-width: 1140px) {
    width: 60%;
  }
  @include mediaQuery("mobile") {
    width: 100%;
  }
  @include mediaQuery("tablate") {
    width: 60%;
  }
}

.privacyCenter {
  @include medium-font;
  color: $gray48;
}

.errorText {
  display: flex;
  margin-top: 8px;
  color: $salmon60;
  @include demi-bold-font;
  font-size: 1.2rem;
  height: 14px;
}

.backBtnText {
  display: flex;
  font-size: 1.4rem;
  @include medium-font;
  color: $gray13;
  margin-left: 8px;
  margin-top: 2px;
}

.backBtnContainer {
  display: flex;
  flex-direction: row;
  width: max-content;
  align-items: center;
  margin-bottom: 40px;
  height: 24px;
  flex: 1;
}

.backBtnInnerContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.stepContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  line-height: 20px;
  font-size: 1.4rem;
  @include medium-font;
  color: $textSubtle;
}

.privacyText {
  padding-right: 16px;
  @include medium-font;
  color: $gray31;
}

.underLineButton {
  text-decoration: underline;
  cursor: pointer;
  color: $gray31;
}

.tradeMarkTextContainerLeft,
.tradeMarkTextContainerCenter {
  text-align: center;
  @include medium-font;
  color: $gray48;
  padding: 0 $paddingHorizontal;
  flex: 1;
  flex-shrink: 0;
  margin-top: 56px;
  margin-bottom: 56px;
  font-size: 1.2rem;
  width: 100%;

  @media (min-width: 1024px) and (max-width: 1310px) {
    margin-top: 36px !important;
  }
  @include mediaQuery("tablate") {
    margin-top: 36px !important;
  }
  @include mediaQuery("mobile") {
    margin-top: 20px !important;
  }
}

.tradeMarkTextContainerLeft {
  text-align: left;
  padding: 0;
}

.rightsidelogocontainer {
  display: none;
  width: 100%;
  margin-bottom: 56px;
  @media (min-width: 1024px) and (max-width: 1140px) {
    display: flex;
  }
  @include mediaQuery("mobile") {
    display: flex;
  }
  @include mediaQuery("tablate") {
    display: flex;
  }
}

.writeToUsButton {
  color: $blue29;
  text-decoration: underline;
  cursor: pointer;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

$borderColor: $strokeOne;

.card {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: white;
  flex-shrink: 0;
  padding: 32px 24px;
  box-shadow: 1px 0 0 0 $borderColor, 0 1px 0 0 $borderColor,
    1px 1px 0 0 $borderColor, 1px 0 0 0 $borderColor inset,
    0 1px 0 0 $borderColor inset;
  position: relative;
  border-collapse: collapse;
  justify-content: center;
  cursor: pointer;
}

.cardActive {
  background-color: $teal20;
  box-shadow: 1px 0 0 0 $white, 0 1px 0 0 $white, 1px 1px 0 0 $white,
    1px 0 0 0 $white inset, 0 1px 0 0 $white inset;
}

.tickContainer {
  position: absolute;
  top: 8px;
  right: 8px;
}

.subText {
  color: $gray48;
  font-size: 1.4rem;
  @include regular-font;
}

.subTextSelected {
  composes: subText;
  color: $white;
}

.titleText {
  font-size: 1.4rem;
  @include demi-bold-font;
  color: $gray13;
  text-transform: capitalize;
}

.titleTextSelected {
  composes: titleText;
  color: $white;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  width: 600px !important;
  height: 422px !important;
}

.container {
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btnWrapper {
    margin-bottom: 5px;
    margin-top: 16px;
    width: 320px;
}

.subHeaderWrapper {
    margin: 24px 0;
    text-align: center;
}

.headerWrapper {
    margin-top: 36px;
}

.doneBtn {
    height: 56px;
}
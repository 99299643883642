@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.moreButtonContainer {
  display: flex;
  @include mediaQuery("touchable") {
    width: 48px;
    height: 48px;
    align-items: flex-start;
    justify-content: flex-end;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  justify-content: space-between;
  border: 2px solid $toddleBG1;
  border-radius: 4px;
}
.crossSvg {
  width: 16px;
  fill: $gray48;
  cursor: pointer;
  visibility: visible;
  margin: 8px 8px 0 0;
  align-self: center;
}
.crossSvgHidden {
  composes: crossSvg;
  visibility: hidden;
  //display: none;
}
.searchInput {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
}
.searchInputActive {
  composes: searchInput;
  width: 100%;
  height: 100%;
}
.searchSvg {
  display: block;
  align-self: center;
  min-width: 16px;
  fill: $gray48;
  cursor: pointer;
  margin: 6px 16px 0px 16px;
}
.searchSvgActive {
  fill: $gray31;
}
.inputText {
  display: flex;
  width: 100%;
  border: none;
  resize: none;
  outline: none;
  background-color: inherit;
}

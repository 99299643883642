@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
}

.buttonWrapper {
  display: flex;
}

.customButton {
  display: flex;
  height: 48px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  padding: 0 12px;
  align-items: center;
  justify-content: center;
  color: $blue29;
  font-size: 1.6rem;
  @include medium-font;
  border: 1px solid $strokeTwo;
  background: $white;
}

.customButtonDisabled {
  border: 0px;
  background: #f8f8f8;
  cursor: unset;
}

.buttonTextWrapper {
  display: flex;
  align-items: center;
}

.buttonText {
  margin-left: 12px;
}

.modalContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.headerContainer {
  padding: 40px 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  color: $gray13;
  @include bold-font;
  font-size: 2.4rem;
}

.cancelIcon {
  cursor: pointer;
}

.modalContent {
  width: 480px !important;
  height: 540px !important;
}

.customMediaWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.deleteIcon {
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.button {
  cursor: pointer;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  @include medium-font;
  flex-shrink: 0;
  border-radius: 4px;
  border: 2px solid;
  overflow: hidden; // contain gradient on IE9
  user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  -webkit-appearance: none;
  padding: 0 16px;
}

.sm {
  composes: button;
  min-width: 104px;
  height: 40px;
  padding: 0 16px;
  border: 1px solid;
}

.lg {
  composes: button;
  height: 48px;
  min-width: 144px;
}

.smLabel {
  display: flex;
  font-size: 1.4rem;
}

.lgLabel {
  display: flex;
  font-size: 1.6rem;
}

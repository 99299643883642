@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
}

$borderColor: $strokeOne;

.list {
  display: flex;
  flex-direction: column;

  flex-shrink: 0;
}

.viewList {
  display: flex;
  flex-direction: column;
  //padding: 24px 0;
  flex-shrink: 0;
}

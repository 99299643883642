@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.moreThanOneOptionContainer {
  display: flex;
  width: 320px;
  padding: 12px 8px 12px 12px;
  background-color: $white;
  box-shadow: 0px 4px 16px rgba($black, 0.25);
  border-radius: 8px;
}

.noOptionTitle {
  display: flex;
  @include medium-font;
  font-size: 1.4rem;
  line-height: 2rem;
  margin-left: 8px;
}

.noOptionMessage {
  display: flex;
  font-size: 1.4rem;
  @include medium-font;
  padding: 12px 8px 12px 12px;
  background-color: $white;
  box-shadow: 0px 4px 16px rgba($black, 0.25);
  border-radius: 8px;
}

.buttonComponent {
  display: flex;
  align-items: center;
}

.buttonLabel {
  display: flex;
  @include medium-font;
  font-size: 1.4rem;
  line-height: 1.912rem;
  width: max-content;
}

.selectionCount {
  display: flex;
  @include demi-bold-font;
  font-size: 1.4rem;
  line-height: 1.912rem;
  margin-left: 8px;
}

.arrowContainer {
  display: flex;
  margin-left: 8px;
}

.rotateArrow {
  display: flex;
  transform: rotate(-180deg);
}

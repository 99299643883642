@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.headerCon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 8px 24px;
}

.addStudentsLabel {
  @include bold-font;
  font-size: 2rem;
}

.modalBody {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  overflow-y: auto;
}

.searchContainer {
  display: flex;
  height: 40px;
  margin: 12px 0;
  flex-shrink: 0;
}

.footerCon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 0 24px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
}

.footerBtnCon {
  display: flex;
  align-items: center;
}

.studentSelectedLabel {
  display: flex;
  font-weight: 500;
  color: $textSubtle;
}

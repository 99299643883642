@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.groupContainer {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 56px;
  }
}

.groupLabel {
  display: flex;
  @include demi-bold-font();
  color: $gray13;
  font-size: 1.6rem;
  padding-bottom: 12px;
  @include border-class($direction: bottom, $radius: 0);
  margin-bottom: 40px;
}

.list {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.listHeader {
  display: flex;
  @include demi-bold-font();
  color: $gray31;
  font-size: 1.6rem;
  margin-bottom: 4px;
}

.listItems {
  display: flex;
  font-size: 1.6rem;
  color: $gray31;
  @include regular-font();
}

.mainContainer {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 48px;
  }
}

.mainContainerView {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.addButton {
  cursor: pointer;
  line-height: 8px;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.headerLabel {
  color: $gray13;
}

.headerLabelView {
  @include demi-bold-font;
  font-size: 1.4rem;
}

.mainContentContainer {
  display: flex;
  flex-direction: column;
  border: solid 1px $strokeTwo;
  border-radius: 4px;
  position: relative;
}

.mainContentContainerViewMode {
  border: none;
}

.innerContainer {
  display: flex;
  padding: 40px 32px;
  flex-direction: column;
}

.innerContainerViewMode {
  display: flex;
  padding: 8px 0;
  flex-direction: column;
}

.emptyText {
  display: flex;
  color: $gray31;
  font-size: 1.4rem;
  @include medium-font;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
}

.checkListContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  min-width: 100%;
  width: max-content;
  margin-bottom: 24px;
  border-radius: 4px;
  gap: 8px;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.titleText {
  display: flex;
  @include demi-bold-font;
  font-size: 1.8rem;
  color: $gray13;
  margin-bottom: 32px;
}

.criteriaContainer {
  flex: 1;
}

.bottomRightContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.emptyText {
  @include regular-font;
  display: flex;
  color: #797979;
  font-size: 1.6rem;
  width: 100%;
}

.chooseText {
  display: flex;
  font-size: 1.4rem;
  @include demi-bold-font();
  margin-right: 16px;
  color: $blue29;
  cursor: pointer;
  align-items: center;
}

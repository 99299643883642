@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.filterBox {
  display: flex;
  border-radius: 8px;
  min-width: 184px;
  border: 1px solid $strokeOne;
  padding: 16px 24px;
  font-size: 1.4rem;

  fill: $gray48;
  justify-content: space-between;

  align-items: center;
  width: max-content;

  &:hover {
    border-color: $gray72;
  }
}

.filterText {
  display: flex;
  margin-right: 8px;
  font-size: 1.4rem;
  color: $black;
  @include demi-bold-font();
}

.selectedOptionsLabels {
  display: flex;
  @include medium-font();
  color: $gray48;
  font-size: 1.4rem;
  margin-left: 4px;
  pointer-events: none;
}

.bottomArrow {
  height: 24px;
  width: 16px;
  text-align: right;
  margin-top: 4px;
}

.searchBarWrapper {
  display: flex;
  width: 100%;
  padding: 12px 16px 0px 16px;
  flex-shrink: 0;
}

.noResultText {
  display: flex;
  width: 100%;
  padding: 0px 16px;
  margin-top: 12px;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.checkListContainerWithQuery {
  display: flex;
  flex-direction: column;
  min-height: 72px;
}

@import "~UIComponents/CommonStyles/mixins";

$middle-pane-width: 600px;
$footer-hight: 72px;
$horizontal-padding: 24px;
$breakpoint: 1024px;

.assessmentGridLayout {
  display: grid;
  width: 100%;
  grid-template-columns: 360px 1fr;
  justify-content: space-between;
  column-gap: 120px;
  @media (max-width: $breakpoint) {
    row-gap: 48px;
    grid-template-columns: 1fr;
  }

  @include mediaQuery("mobile") {
    row-gap: 0;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  background-color: $white;
  border: 1px solid $strokeOne;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.leftContainer {
  display: flex;
  align-items: center;
  .backButtonWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    .logo {
      margin: 0 16px 0 8px;
      display: flex;
    }
  }
}

.middleContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.rightContainer {
  display: flex;
  justify-content: flex-end;
}

.headerTabBackButton {
  fill: $gray31;
  display: flex;
}

.header {
  display: flex;
  flex-direction: column;
}

.headerText {
  font-size: 1.8rem;
  @include bold-font;
  color: $black;
}

.subHeaderText {
  color: $gray48;
}

.backButtonWrapper:hover .headerTabBackButton {
  fill: $black;
}

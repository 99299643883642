@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.button {
  display: grid;
  @include medium-font;
  font-size: 1.6rem;
  height: 48px;
  width: 100%;
  cursor: pointer;
  outline: none;
  align-items: center;
  color: $white;
  border-radius: 4px;
  justify-content: center;
  grid-auto-flow: column;
  padding: 0 16px;
  min-width: 224px;
}
.fbButton {
  composes: button;
  background-color: #4267b2;
}

.twitterButton {
  composes: button;
  background-color: #1da1f2;
}

.iconContainer {
  display: flex;
  margin-right: 24px;
  fill: $white;
}

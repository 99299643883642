@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.checklistItemContainer {
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
  align-items: center;
  cursor: pointer;
  &:hover .rightContainer {
    visibility: visible;
  }
}

.leftContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
}

.iconContainer {
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px;
}

.rightContainer {
  visibility: hidden;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.modalContent {
  height: auto !important;
  max-width: 480px !important;
  width: 100vw !important;
}

.container {
  display: grid;
  grid-auto-flow: row;
  height: 100%;
  justify-content: space-between;
  position: relative;
  padding: 40px;
  justify-content: center;
  width: 100%;
}

.cancelIcon {
  display: flex;
  position: absolute;
  top: 24px;
  right: 24px;
  fill: $gray48;
  cursor: pointer;
  &:hover {
    fill: $gray31;
  }
}

.middleBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading {
  @include bold-font;
  font-size: 2.8rem;
  color: $seaweedTwo;
  margin-bottom: 24px;
}

.subHeading {
  @include medium-font;
  font-size: 1.8rem;
  color: $gray31;
  margin-bottom: 24px;
}

.footer {
  display: flex;
  margin-bottom: 24px;
}

.logoContainer {
  display: flex;
  width: max-content;
  flex-shrink: 0;
  align-items: center;
  flex-direction: row;
  margin-bottom: 32px;
}

.logo {
  display: flex;
  height: 100%;
  align-items: center;
  flex-shrink: 0;
}

.communityText {
  display: flex;
  margin-left: 12px;
  color: $bar;
  font-size: 1.8rem;
  @include bold-font;
}

.detailText {
  display: flex;
  color: $gray48;
  font-size: 1.4rem;
  margin-bottom: 32px;
  @include medium-font;
  line-height: 2.4rem;
  text-align: center;
}

.signInLink {
  display: flex;
  font-size: 1.4rem;
  color: $gray13;
  @include medium-font;
  justify-content: center;
}

.imageContainer {
  display: flex;
  height: 178px;
  margin-bottom: 32px;
  img {
    width: auto;
    height: 100%;
  }
}

.signInLinkButton {
  color: $blue29;
  text-decoration: underline;
  margin-left: 4px;
  cursor: pointer;
}

@include mediaQuery("mobile") {
  .modalContent {
    height: 100vh !important;
    max-width: 100vw !important;
    width: 100vw !important;
  }
  .detailText {
    margin-bottom: 48px;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.guidelinesContainer {
  flex: 0 0 360px;
  background: #f5f5f8;
  padding: 40px 32px;
  overflow-y: auto;
}

.header {
  color: $gray31;
  display: flex;
  width: 100%;
  font-size: 1.8rem;
  @include bold-font;
}

.guidelinesSubText {
  display: flex;
  width: 100%;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray48;
  line-height: 1.43;
  margin-top: 10px;
}

.subText {
  composes: guidelinesSubText;
  margin-top: 32px;
}

.checkListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
}

.checkListItem {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 4px;
}

.checkIconContainer {
  display: flex;
  width: 6px;
  height: 6px;
  margin-right: 12px;
  margin-top: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  background: $yellow50;
}

.checkListItemText {
  display: flex;
  width: 100%;
  @include medium-font;
  font-size: 1.4rem;
  line-height: 1.71;
  color: $gray48;
}

.guidelineConditions {
  display: flex;
  width: 100%;
  @include italic-font;
  font-size: 1.4rem;
  color: $gray48;
  margin-top: 24px;
}

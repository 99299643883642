@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: min-content;
}

.thumbnail {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  height: 196px;
  cursor: pointer;
}

.star {
  position: absolute;
  z-index: 1;
  padding: 16px 0 0 16px;
}

.containerDisable {
  opacity: 0.5;
  pointer-events: none;
}

.animate {
  position: relative;
  animation-name: zoom-in;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

.deleteAnimation {
  animation-name: zoom-out;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

.container:hover {
  .overlay {
    opacity: 1;
  }
}

.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.expand-btn {
  display: flex;
  border-width: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.imageContainer {
  display: flex;
  width: 100%;
  height: 104px;
  cursor: pointer;
}

.footer {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 16px 0 12px;
  background: $white;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
}

.labels {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.heading {
  @include demi-bold-font;
  @include multiLineEllipsis(2rem, 1);
  word-break: break-all;
  font-size: 1.4rem;
  color: $gray31;
  margin-bottom: 2px;
  cursor: pointer;
  width: max-content;
  max-width: 100%;
  white-space: nowrap;
  display: block;
}

.clickableHeading {
  &:hover {
    text-decoration: underline;
  }
}
.description {
  @include medium-font;
  @include multiLineEllipsis(1.6rem, 1);
  word-break: break-all;
  font-size: 1.2rem;
  color: $gray48;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border: 1px solid $strokeOne;
  border-radius: 4px;
  margin-right: 10px;
}

.viewOverlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: 8px 8px 0 0;
}

.viewIcon {
  margin-right: 12px;
  height: min-content;
}
.viewIcon,
.tickOffIcon {
  visibility: hidden;
}

.thumbnail:hover {
  .viewOverlay {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .viewIcon,
  .tickOffIcon {
    visibility: visible;
  }
}

@keyframes zoom-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes zoom-out {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0);
  }
}

.tickIcon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.clubbedOverlay {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba($black, 0.7);
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  top: 0;

  .label {
    @include medium-font;

    display: flex;
    color: $textOn;
    font-size: 2rem;
  }
}

.assessmentOverlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:hover {
    .assessmentOverlayCircle {
      display: none;
    }
  }
}

.assessmentOverlayCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: rgba($surfaceDark, 0.6);
  border-radius: 50%;
}

.assessmentIcon {
  background: $surfaceWhite;
  border-radius: 4px;
}

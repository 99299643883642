@import "~UIComponents/CommonStyles/mixins";

.selectedOptions {
  display: flex;
  max-width: 120px;
  @include multiLineEllipsis(1.912rem, 1);
  word-break: break-all;
}

.moreTextContainer {
  margin-left: 4px;
}

.moreThanOneOptionSelected {
  display: flex;
}

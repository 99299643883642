@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.listContainer {
  display: flex;
  width: 100%;
  min-height: 80px;
  max-height: 300px;
  position: absolute;
  top: 56px;
  border: 1px solid $strokeOne;
  border-radius: 0 0 4px 4px;
  background-color: $white;
  flex-direction: column;
  margin-bottom: 32px;
}

.listInnerContainer {
  display: flex;
  width: 100%;
  min-height: 100%;
  overflow-y: scroll;
  flex-direction: column;
  background-color: $white;
  min-height: 86px;
}

.borderShadow {
  box-shadow: inset 0 -8px 8px -8px rgba($black, 0.1);
}

.selectorItem {
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
  &:not(:first-child) {
    border-top: 1px solid $strokeTwo;
  }
}

.addSchoolItem {
  composes: selectorItem;
  padding: 16px 24px;
  border-width: 0px !important;
  border-style: none !important;
  //box-shadow: 0 -2px 16px 0 rgba($black, 0.08);
}

.itemLeftContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 16px;
  justify-content: center;
}

.schoolName {
  display: flex;
  color: $gray13;
  font-size: 1.4rem;
  @include medium-font;
}

.schoolAddress {
  display: flex;
  color: $yellow50;
  font-size: 1.4rem;
  @include medium-font;
}

.noDataContainer {
  display: flex;

  padding: 24px;
  width: 100%;
  flex-direction: column;
  background-color: $toddleBG1;
}
.noDataLabelText {
  width: 100%;
  color: $gray48;
  font-size: 1.4rem;
  @include medium-font;
  word-break: break-word;
}

.noDataSubText {
  color: $gray72;
  font-size: 1.2rem;

  @include medium-font;
}

.listItemsContainer {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
}

.noSchoolText {
  display: flex;
  color: $salmon60;
  font-size: 1.6rem;
  @include medium-font;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  // margin-bottom: 80px;
}

.header {
  padding-bottom: 24px;
  border-bottom: 1px solid $gray31;
  margin-bottom: 20px;
}

.title {
  font-size: 1.8rem;
  @include bold-font;
  color: $bar;
  padding-bottom: 4px;
}

.subTitle {
  font-size: 1.4rem;
  color: $gray48;
}

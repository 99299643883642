@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  width: 72vw !important;
  height: 90vh !important;
  max-width: 1036px !important;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.progressBarContainer {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 1;
}

.backButton {
  position: absolute;
  top: 20px;
  left: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}

.backButtonText {
  margin-left: 8px;
  color: $blue29;
}

.cancelIcon {
  display: flex;
  cursor: pointer;
  position: absolute;
  right: 32px;
  top: 20px;
  fill: $gray48;
  z-index: 1;
  &:hover {
    fill: $gray31;
  }
}

.header {
  color: $gray13;
  @include bold-font;
  font-size: 2.2rem;
  padding: 44px 32px 20px;
}

.body {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  padding: 32px;
}

.footer {
  box-shadow: 5px 2px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 8px 32px;
  display: flex;
  align-items: center;
  min-height: 64px;
  justify-content: center;
}

.buttonContainer {
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

// Header Tab

.headerTabContainer {
  display: flex;
  flex-direction: column;
}

.headerTabRow {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  height: 70px;
  padding: 0 24px;
}

.headerTabBackButton {
  cursor: pointer;
}

.logo {
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 8px;
}

.headerTab {
  margin-left: 16px;
  font-size: 1.8rem;
  color: $gray13;
  @include bold-font;
}

.fullScreenButton {
  font-size: 1.4rem;
  @include demi-bold-font;
  color: $gray48;
  padding: 4px 8px;
  cursor: pointer;
  width: max-content;
  display: inline-block;
}

.leftIconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.lePopUpContainer {
  display: flex;
  flex-direction: column;
  width: 320px;
  background-color: $white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  min-height: 160px;
  max-height: 320px;
}

.heading {
  display: flex;
  padding: 20px 24px 0px 24px;
  @include bold-font;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: $gray31;
}

.assessmentContainer {
  display: flex;
  flex-direction: column;
  padding: 4px 24px 8px 24px;
  overflow: auto;
}

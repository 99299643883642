@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.webcamScreen {
  height: 100%;
}

.captureImageContainer {
  display: flex;
  width: 100vw;
  height: calc(100vh - 73px);
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: $gray13;
}

.mediaControls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.captureCameraBtnContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1001;
}

// captureImage button

.captureImageButton {
  cursor: pointer;
  margin: 40px;
  border-radius: 50%;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  height: 80px;
  width: 80px;
  padding: 28px 26px;
  background-color: $white;
}

.rightSideCameraOptions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flipCameraButton {
  cursor: pointer;
  margin-right: 52px;
  border-radius: 50%;
  padding: 16px;
  height: 56px;
  width: 56px;
  background: $gray48;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: grid;
  position: relative;
}

.carouselController {
  display: grid;
  position: absolute;
  width: 100%;
  bottom: -24px;
  justify-content: center;

  grid-column-gap: 24px;
  grid-auto-flow: column;
}

.carouselItem {
  border-top: 1px solid transparent;
  padding-top: 6px;
  @include medium-font;
  font-size: 1.2rem;
  color: $gray48;
  cursor: pointer;
}

.currentItem {
  color: $salmon60;
  border-color: $salmon60;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  border-radius: 2px;

  border: 1px solid $strokeTwo;
  position: relative;
  cursor: pointer;
  &:hover {
    .hoverContainer {
      display: flex;
    }
  }
  width: 100%;
}

.hoverContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 12;
  border: 1px solid $strokeTwo;
  border-radius: 8px;
  background: $white;
  box-shadow: 0 4px 12px 0px rgba(0, 0, 0, 0.16);
  padding: 8px 0;
}

.roundedContainer {
  border-radius: 8px;
  overflow: hidden;
}

.hoverItemContainer {
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  justify-content: flex-start;
  background-color: $white;
  padding: 0 24px;
  cursor: pointer;
  .hoverItemText {
    display: flex;
    margin-left: 10px;
    font-size: 1.6rem;
    text-align: start;
  }
  .buttonIconAndText {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  .googleDriveInnerDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    flex: 1;
  }

  &:hover {
    .hoverItemText {
      color: $black;
    }
    .innerIcon {
      svg {
        fill: $iconHover;
      }
    }
    background-color: $toddleBG1;
  }
  &:active {
    .hoverItemText {
      color: $black;
    }
  }
}

.divider {
  margin: 8px 0;
  height: 1px;
  background: $strokeOne;
}

.addMediaText {
  display: flex;
  font-size: 1.4rem;
  @include medium-font;
  color: $gray48;
  margin-left: 8px;
}

.innerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 16px;
}

.iconSvgContainer {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  width: 100%;
}

.hoverItemText {
  font-size: 14px;
  color: $gray48;
  @include medium-font;
  //margin-top: 4px;
}

.iconBarContainer {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  align-items: center;
  margin-right: 16px;
  fill: $iconDefault;
  &:hover {
    fill: $iconHover;
    cursor: pointer;
  }
}

.iconButtonContainer {
  display: flex;
  margin-right: 16px;
  width: initial;
  height: initial;
  align-items: stretch;
  justify-content: stretch;
  background-color: none;
  padding: 0;
  cursor: pointer;
}

.innerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  svg {
    fill: $iconDefault;
  }
}

.newDropdownMenu {
  max-height: unset;
}

.modalContainer {
  display: flex;
  width: 480px !important;
  height: 292px !important;
  flex-direction: column;
}

.modalHeader {
  display: flex;
  padding: 32px 32px 0;
  justify-content: space-between;
  align-items: center;
}

.modalBody {
  @include scrollContainer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 32px 32px;
  flex: 1;
}

.modalFooter {
  display: flex;
  box-shadow: 0 -2px 4px 0 rgba($black, 0.1);
  padding: 16px 32px;
  justify-content: space-between;
  background-color: $white;
  align-items: center;
}
.closeIcon {
  display: flex;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;

  @media (min-width: 1550px) {
    margin-top: 76px !important;
  }
}

.headerButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  @include mediaQuery("mobile") {
    margin-bottom: 32px;
  }
}

.inviteCodeContainer {
  display: flex;
  margin-top: 32px;
  margin-bottom: 16px;
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  overflow-y: auto;
  padding: 48px 80px;
}

.innerContainer {
  display: grid;
  height: max-content;
  align-content: flex-start;

  width: 100%;
}

.header {
  display: grid;
  grid-auto-flow: column;
  // margin-bottom: 16px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
}

.toddleIcon {
  width: 40px;
  height: 40px;
  fill: $salmon60;
}

.toddleLogoText {
  display: flex;
  margin-left: 20px;
  fill: $gray48;
}

.middleBody {
  display: grid;
  justify-items: center;
}

.selectLabel {
  @include bold-font;

  font-size: 2.4rem;
  color: $gray31;
  line-height: 36px;
}

.subLabel {
  @include medium-font;
  font-size: 1.6rem;
  margin-top: 0px;
  color: $gray48;
  line-height: 22px;
}

.cardList {
  display: grid;
  grid-template-columns: repeat(5, 184px);
  grid-column-gap: 24px;
  grid-row-gap: 32px;
  margin: 48px 0;
}

.card {
  display: grid;
  padding: 24px;
  border: 1px solid $strokeOne;
  border-radius: 8px;
  color: $gray48;
  cursor: pointer;
  justify-items: center;
  align-content: center;
  position: relative;
}

.tickIcon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.icon {
  display: grid;
  margin-bottom: 12px;
}

.classTitle {
  @include demi-bold-font;
  font-size: 1.8rem;
  color: $gray48;
  text-align: center;
  margin-bottom: 8px;
}

.itemCounts {
  font-size: 1.4rem;
  color: $gray48;
  @include medium-font;
}

.footerButton {
  width: 240px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  margin-top: 48px;
}

.container:first-child {
  margin-top: 0;
}

.settingRowContainerSubmodule {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 24px;
  border-bottom: 1px solid $gray31;
}

.titleSubmodule {
  font-size: 1.8rem;
  @include bold-font;
  flex: 1;
}

.sectionTitleContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 48px;
}

.descSubmodule {
  @include medium-font;
  color: $gray48;
  font-size: 1.4rem;
  width: 100%;
  margin-top: 4px;
  padding-bottom: 20px;
  border-bottom: 1px solid $gray31;
}

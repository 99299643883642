@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
$borderColor: $strokeOne;

.container {
  display: flex;
  flex-direction: row;
  width: calc(100% + 72px);
  padding-left: 72px;
  margin-left: -72px;
  position: relative;

  &:hover {
    .leftMenuContainer {
      visibility: visible;
    }
  }

  &:last-child {
    .innerContainer {
      border-bottom: 1px solid $borderColor;
    }
  }
  &:first-child {
    .innerContainer {
      border-top: 1px solid $strokeOne;
    }
    .strandInnerContainer {
      border-width: 0px 0px 0px 0px !important;
    }
  }

  &:not(:first-child) {
    .strandInnerContainer {
      padding-top: 40px !important;
      border-top: 1px solid $borderColor !important;
    }
    .innerViewContainer1 {
      padding-top: 40px !important;
    }
  }
}

.strandContainer {
  &:not(:first-child) {
    .leftMenuContainer {
      margin-top: 40px;
    }
  }
}

.innerContainer {
  display: grid;
  flex-direction: row;
  padding: 12px 0px;
}

.borderTop {
  border-top: 1px dashed $borderColor;
}

.strandInnerContainer {
  border-width: 1px 0px 0px 0px !important;
}

.innerViewContainer1 {
  padding: 12px 0px;
  border: none !important;
}

.innerViewContainer2 {
  padding: 12px 0px;
}

.innerViewContainer3 {
  padding: 6px 0px;
  border: none !important;
}

.labelContainer {
  display: flex;
  flex-direction: row;
}

.labelText {
  display: flex;
  flex-direction: column;
}

.borderBottom {
  border-bottom: 1px solid $strokeTwo;
}

.insightsCountHeader {
  composes: labelText;
  font-size: 1.6rem;
  @include bold-font;
  color: $gray13;
}

.depthText1 {
  composes: labelText;
  font-size: 1.8rem;
  @include bold-font;
  color: $gray13;
}

.depthText2 {
  composes: labelText;
  font-size: 1.6rem;
  @include demi-bold-font;
  color: $gray13;
}

.depthText3 {
  composes: labelText;
  font-size: 1.4rem;
  @include medium-font;
  color: $gray31;
}

.depthText1 {
  composes: labelText;
  font-size: 1.8rem;
  @include bold-font;
  color: $gray13;
}

.depthViewText0 {
  composes: labelText;
  font-size: 1.6rem;
  @include bold-font;
  color: $teal20;
}

.subText {
  @include medium-font;
  font-size: 1.4rem;
  color: $gray48;
  margin-top: 4px;
}

.depthViewText1 {
  composes: labelText;
  font-size: 1.8rem;
  @include bold-font;
  color: $gray13;
}

.depthViewText2 {
  font-size: 1.6rem;
  @include medium-font;
  color: $gray31;
}

.depthViewText3 {
  font-size: 1.6rem;
  @include medium-font;
  color: $gray31;
}

.checkboxContainer {
  display: flex;
  width: 24;
  margin-right: 8px;
  padding-top: 3px;
}

.code {
  display: flex;
  @include medium-font;
  color: $gray31;
  font-size: 1.4rem;
}

.grade {
  // display: flex;
  flex-shrink: 0;
  width: 100%;
}

.gradeView {
  display: flex;
  justify-content: flex-end;
}

.codeEmpty {
  color: $gray72;
}

.arrows {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
}
.benckmarkCount {
  text-align: center;
  cursor: pointer;
}

.yearBenchmark {
  margin-right: 24px;
}

.emptyBenchmarkCount {
  text-align: center;
}
.yearCountBenchmarkContainer {
  margin: 0px 0px 8px 0px;
  &:last-child {
    margin: 0px 0px 0px 0px;
  }
}
.actionContainer {
  display: flex;
  flex-direction: row;
  padding-left: 8px;

  height: 100%;
}

.iconCotainer {
  fill: $gray72;
  &:hover {
    fill: $gray31;
  }
  cursor: pointer;
}

.leftArrowContainer {
  composes: iconCotainer;
  transform: rotate(180deg);
  margin-right: 12px;
}

.depthIndicator {
  display: flex;
  width: 32px;
  height: calc(100% + 24px);
  flex-shrink: 0;
  align-self: center;
}
.leftMenuContainer {
  display: flex;
  position: absolute;
  left: -32px;
  align-items: center;
  flex-direction: column;
  visibility: hidden;
  justify-content: flex-end;
  &:hover {
    visibility: visible;
  }
  height: 32px;
  flex-direction: row;
  width: 88px;
  margin-top: 12px;
}

.actionButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 32px;
}

.plusIcon {
  composes: iconCotainer;
  margin-top: 3px;
  margin-left: 4px;
}

.bullet {
  display: flex;
  margin-right: 8px;
  color: $gray48;
}

.codeLabel {
  @include italic-font;
}

.insightsCount {
  display: flex;
  justify-content: center;
  color: $black;
  font-size: 1.6rem;
  cursor: pointer;

  &:hover {
    color: $blue29;
  }
}

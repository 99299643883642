@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
$borderColor: $strokeOne;

.rowContainer {
  display: grid;
  grid-template-columns: 50% 15% 35%;
  flex-direction: row;
  width: 100%;
  &:hover {
    .deleteButtonContainer {
      visibility: visible;
    }
  }
}

.rowATLSContainer {
  grid-template-columns: 68% 32%;
}

.rowWithoutGradeContainer {
  grid-template-columns: 100%;
}

.rowWithDescriptionContainer {
  grid-template-columns: 20% 48% 32%;
}

.cellContainer {
  display: flex;
  &:not(:last-child) {
    border-right: 1px solid $borderColor;
  }
  border-bottom: 1px solid $borderColor;
  padding: 16px 28px;
  flex-direction: column;
}

.numberText {
  display: flex;
  @include demi-bold-font;
  color: $gray13;
  font-size: 1.6rem;
  padding: 16px 0px 16px 24px;
  flex-shrink: 0;
  width: 32px;
}

.labelContainer {
  composes: cellContainer;
  display: flex;
  flex-direction: row;
  padding: 0;

  // align-items: center;
  position: relative;
}

.textAreaContainer {
  display: flex;
  flex: 1;
  flex-shrink: 0;
}
.rightContainer {
  display: grid;
  grid-column-gap: 24px;
  grid-auto-flow: column;
  margin-left: 8px;
  grid-template-columns: 60px 60px;
}
.gradeButtonContainer {
  display: flex;
  flex-shrink: 0;
  position: relative;
}

.gradeButton {
  display: flex;
  @include demi-bold-font;
  color: $blue29;
  font-size: 1.6rem;

  cursor: pointer;
}

.deleteButtonContainer {
  display: flex;
  visibility: hidden;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 12px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
  @include mediaQuery("touchable") {
    visibility: visible;
  }
}

.gradeInnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include regular-font;
  color: $gray31;
  font-size: 1.6rem;
}

.loadingContainer {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 12px;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.headerTitleText {
  display: flex;
  @include demi-bold-font;
  color: $gray13;
  word-break: break-word;
  font-size: 1.6rem;
}

.headerSubText {
  display: flex;
  @include regular-font;
  color: $gray48;
  font-size: 1.4rem;
}

@import "~UIComponents/CommonStyles/typography";

.buttonContainer {
  display: flex;
  align-items: center;
  @include demi-bold-font;
}
.buttonLabel {
  margin-right: 8px;
  display: flex;
  @include medium-font;
}

.iconContainer {
  display: flex;
  margin-left: 4px;
}

.activatedIconContainer {
  transform: rotate(180deg);
}

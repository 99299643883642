@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  width: 600px !important;
  height: 324px !important;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
}

.headerTitle {
  display: flex;
  @include bold-font();
  font-size: 2.8rem;
}

.description {
  margin-top: 8px;
  @include medium-font();
  font-size: 1.6rem;
  color: $bar;
}

.inputField {
  margin-top: 32px;
}

.button {
  margin-top: 16px;
}

.errorText {
  display: flex;
  margin-top: 8px;
  color: $salmon60;
  @include demi-bold-font;
  font-size: 1.2rem;
  height: 14px;
}

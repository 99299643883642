@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.containerWrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;

  position: relative;
  flex-direction: column;
}

.containerWithoutRightPane {
  display: flex;
  flex-direction: row;
  grid-template-columns: unset;
}

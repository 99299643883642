@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  padding: 0 16px 16px 16px;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.editContainer {
  margin-top: 48px;
}

.listContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.countText {
  display: flex;
  @include medium-font;
  color: $gray31;
  flex-shrink: 0;
  font-size: 1.6rem;
}

.seeOthers {
  @include medium-font;
  font-size: 1.4rem;
  color: $blue29;
  display: flex;
  padding: 8px 16px;
  cursor: pointer;
  width: max-content;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.checkbox {
  // height: 16px;
  // width: 16px;
  cursor: pointer;
  line-height: 24px;
  flex-shrink: 0;
  fill: $gray48;
  margin-top: 0.5px;
  pointer-events: none;
}
.disableCheckbox {
  composes: checkbox;
  fill: $gray72;
  cursor: not-allowed;
  pointer-events: none;
}
.checkboxLabel {
  font-size: 1.4rem;
  font-weight: 300;
  padding-left: 16px;
  text-overflow: ellipsis;
  @include medium-font; //re-verify which font is applied actually
  //white-space: nowrap;
  overflow: hidden;
}
.checkboxContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  //align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  //padding-top: 2px;
  // min-height: 40px;
  color: $gray48;
  cursor: pointer;
  width: 100%;
}
.checkboxContainerActive {
  .checkbox {
    fill: $seaweedTwo;
  }
  .checkboxLabel {
    color: $seaweedTwo;
  }
}
.checkboxContainer:hover {
  .checkbox {
    fill: $seaweedTwo;
  }
  .checkboxLabel {
    color: $seaweedTwo;
  }
}
.checkboxContainerDisable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  // align-items: center;
  flex-grow: 0;
  padding-top: 2px;
  color: $gray72;
  cursor: not-allowed;
  &:hover {
    .checkbox {
      fill: $gray72;
    }
    .checkboxLabel {
      color: $gray72;
    }
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.rootContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  justify-content: space-between;
  border: 2px solid transparent;
  border-radius: 4px;
  align-items: center;
}

.containerBox {
  composes: container;
  flex-direction: row;
  justify-content: flex-start;
  padding: 12px 16px;
  border: 1px solid $strokeOne;

  &:hover {
    border-color: $gray72;
  }
  &:focus-within {
    border-color: $blue29;
  }
}

.crossSvg {
  display: flex;
  cursor: pointer;
  visibility: visible;
  // margin: 8px 0px 0 0;
  align-self: center;
}
.crossSvgHidden {
  composes: crossSvg;
  visibility: hidden;
  //display: none;
}
.searchInput {
  display: flex;
  //height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  transition: all 0.3s ease;
}

.searchInputActive {
  composes: searchInput;
  width: 100%;
  border-bottom: 2px solid $gray13;
  // border-bottom: 2px solid $gray13;
  padding-bottom: 4px;
}

.notAnimateSearchInput {
  transition: none;
}

.searchSvg {
  display: flex;
  align-self: center;
  cursor: pointer;
  margin: 0px 16px 0px 0;
}

.inputText {
  display: flex;
  width: 100%;
  border: none;
  resize: none;
  outline: none;
  background-color: transparent;
}

.error {
  display: flex;
  background-color: rgba($yellow50, 0.1);
  border-color: $yellow50;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
}

.editContainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  position: relative;
  flex: 1;
}

.inputText {
  display: flex;
  @include medium-font;
  font-size: 1.6rem;
  color: $gray31;
  flex-direction: column;
  white-space: pre-wrap;
  word-break: break-word;
}

.emptyText {
  composes: inputText;
  font-size: 1.4rem;
  color: $gray48;
}

.errorText {
  display: flex;
  margin-top: 8px;
  color: $salmon60;
  @include demi-bold-font;
  font-size: 1.2rem;
}

.warningsText {
  display: flex;
  margin-top: 8px;
  color: $teal20;
  @include demi-bold-font;
  font-size: 1.2rem;
  height: 14px;
}

.responseHeaderText {
  // margin-top: 4px;
  color: $gray48;
  @include regular-font;
  font-size: 1.4rem;
  margin-bottom: 8px;
}

.error {
  display: flex;
  background-color: rgba($yellow50, 0.1);
  border-color: $yellow50;
}
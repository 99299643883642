@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

//Do not change
.overlayContainer {
  z-index: 1100 !important;
}
.modalContent {
  background-color: transparent !important;
}
.modalBody {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.cancelButton {
  display: flex;
  position: absolute;
  top: 16px;
  right: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    width: 32px;
    height: 32px;
  }
}
.imageContainer {
  background: rgba(55, 55, 55, 0.7);
  top: 0px;
  left: 0px;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 1000;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reactPlayer {
  display: flex;
  width: 100%;
  height: 100%;
}
.topPanel {
  display: flex;
  justify-content: center;
  flex-basis: 10%;
}

.crossSvg {
  background: black;
  border-radius: 0 0 0 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
}
.closeIcon {
  margin-left: 14px;
  margin-top: 8px;
}
.imageView {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 16px auto;
  flex-direction: column;
  background-size: cover;
}
.noteView {
  display: flex;
  width: 540px;
  height: 360px;
  flex-direction: column;
  background-color: #fff;
  padding: 24px;
  overflow-y: auto;
}
.noteViewWrapper {
  border-left: 2px solid $yellow50;
  height: auto;
  padding-left: 16px;
  padding-top: 4px;
  padding-right: 16px;
  font-size: 1.4rem;
  color: $gray13;
  @include medium-font;
  white-space: pre-wrap;
  line-height: 3rem;
  min-height: 312px;
  word-break: break-word;
}

.noteLinesContainer {
  height: auto;
  padding-left: 20px;
  position: relative;
  background: white;
  // background: -webkit-linear-gradient(top, #dfe8ec 0%, white 8%) 0 57px;
  // background: -moz-linear-gradient(top, #dfe8ec 0%, white 8%) 0 57px;
  // background: linear-gradient(top, #dfe8ec 0%, white 8%) 0 57px;
  // background: linear-gradient(-90deg, red, yellow);
  background-image: repeating-linear-gradient(
    $yellow96 0rem,
    $yellow96 2.9rem,
    $strokeOne 3rem
  );
  -webkit-background-size: 100% 3rem;
  -moz-background-size: 100% 3rem;
  -ms-background-size: 100% 3rem;
  background-size: 100% 3rem;
}

.audioAttachment {
  display: flex;
  position: absolute;
  width: 440px;
  align-self: center;
  z-index: 1000;
  bottom: 52px;
  border-radius: 8px;
  border: 1px solid $surfaceLight;
  background-color: $surfaceLight;

  &:hover {
    border: 1px solid $borderHover;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.modalContent {
  width: 600px !important;
  max-height: 496px !important;
  height: 496px !important;
}

.modalBody {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  padding: 40px 40px 4px 40px;
}

.leftContainer {
  display: flex;
  flex-direction: column;
}

.cancelIcon {
  cursor: pointer;
  fill: $gray48;
  z-index: 99;
  &:hover {
    fill: $gray31;
  }
}

.headerMainTxt {
  @include bold-font;
  font-size: 2.4rem;
  color: $gray31;
  margin-bottom: 4px;
}

.blocksContainer {
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  overflow-y: auto;
  flex: 1;
}

.blockItem {
  display: grid;
  grid-template-columns: 32px 1fr 24px;
  grid-gap: 24px;
  padding: 20px 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid $strokeOne;
  }
}

.checkBox {
  display: flex;
}

.blockIcon {
  display: flex;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.iconContainer {
  display: flex;
  margin-left: 8px;
}

.informationIcon {
  display: flex;
  align-items: center;
}

.blockHeading {
  display: flex;
  @include bold-font;
  font-size: 1.6rem;
  color: $pink39;
  margin-bottom: 4px;
}

.blockSubText {
  @include medium-font;
  font-size: 1.4rem;
  color: $gray48;
}

.footer {
  padding: 12px 40px;
  display: flex;
  justify-content: flex-end;
  height: 64px;
  box-shadow: 5px 2px 10px 0 rgba(0, 0, 0, 0.2);
}

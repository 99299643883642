@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.hoverWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .section {
    display: flex;
    flex-direction: column;
    .heading {
      @include bold-font;
    }
    .content {
      display: flex;
      flex-direction: column;
    }
  }
}

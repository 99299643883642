@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.titleContainer {
  display: flex;
  align-items: center;
  width: max-content;
  &:hover {
    .title {
      color: $black;
    }
    .dropdownArrow {
      fill: $black;
    }
  }
}

.title {
  font-size: 1.8rem;
  color: $gray31;
  @include bold-font;
  margin-right: 8px;
  word-break: keep-all;
}

.dropdownArrow {
  fill: $gray31;
}

.orgName {
  font-size: 1.4rem;
  color: $gray48;
  @include medium-font;
}

.acronym {
  padding: 4px;
  border-radius: 8px;
  display: flex;
  text-align: center;
  width: 100%;
  height: 32px;
  align-items: center;
  font-size: 1.1rem;
  justify-content: center;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;

  @media (min-width: 1550px) {
    margin-top: 76px !important;
  }
}

.innerContainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 48px;
}

.inputContainer {
  display: flex;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.emailText {
  @include demi-bold-font;
  color: $gray13;
}

.resendButton {
  @include medium-font;
  color: $salmon60;
  cursor: pointer;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}

.confirmButton {
  margin-top: 24px;
  width: 100%;
}
.notGetEmailButtonContainer {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.notGetEmailButtonText {
  display: flex;
  @include medium-font;
  color: $blue29;
  font-size: 1.6rem;
  align-items: center;
  margin-left: 8px;
}

.arrowIcon {
  width: 16px;
  height: 18px;
  transform: rotate(180deg);
}
.noEmailText {
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  width: 100%;
}
.instructionsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.instructionHeaderText {
  display: flex;
  @include medium-font;
  color: $gray48;
  margin-bottom: 24px;
  width: 100%;
  font-size: 1.6rem;
}

.instructionContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
}

.dot {
  display: flex;
  @include medium-font;
  color: $gray48;
  margin-right: 8px;
  font-size: 1.6rem;
}

.instructionText {
  display: flex;
  @include medium-font;
  color: $gray48;
  width: 100%;
  font-size: 1.6rem;
}

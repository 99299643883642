@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include bold-font;
}

.pypContainer {
  background-color: $pink98;
  color: $pink70;
}

.mypContainer {
  background-color: $violet98;
  color: $violet63;
}

.ubdContainer {
  background-color: $blue96;
  color: $blue29;
}

.dpContainer {
  background-color: $yellow96;
  color: $yellow38;
}

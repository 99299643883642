@import "~UIComponents/CommonStyles/mixins";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/colors";

$iconHeight: 48px;
$iconWidth: 48px;

.header {
  display: flex;
  margin-bottom: 32px;
  align-items: center;
  padding: 40px 40px 0px 40px;
}

.thumbnail {
  height: 48px;
  width: 48px;
  border-radius: 4px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnailShadow {
  border: 1px solid $strokeTwo;
}

.progressReportThumbnail {
  background-color: $purple93;
}

.assignmentIconContainer {
  border-radius: 50%;
  height: $iconHeight;
  width: $iconWidth;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.qt {
  background-color: $blue40;
}

.le {
  background-color: $violet63;
}

.formative {
  background-color: $yellow50;
}

.summative {
  background-color: $teal42;
}

.preAssessment {
  background-color: $blue29;
}

.performanceTask {
  background-color: $blue29;
}

.supplementary {
  background-color: $blue29;
}

.info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.title {
  display: flex;
  font-size: 2rem;
  @include bold-font;
}

.subTitle {
  display: flex;
  @include medium-font;
  font-size: 1.4rem;
  @include multiLineEllipsis(1.9rem, 1);
  color: $gray13;
}

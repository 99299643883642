@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  margin-bottom: 56px;
  // background-color: $white;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  flex-direction: column;
}

.rightContainer {
  display: flex;
  align-items: center;
}

.titleText {
  display: flex;
  @include bold-font;
  font-size: 2.4rem;
  color: $gray13;
}

.subTitleText {
  display: flex;
  @include regular-font;
  font-size: 1.6rem;
  color: $gray31;
}

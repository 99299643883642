@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  width: 100%;
  box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.08);
}

.innerContainer {
  display: flex;
  width: 100%;
  height: 64px;
  max-width: 1008px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.rightButtonContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;
  align-items: center;
}

.buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.scheduleTextContainer {
  display: flex;
  @include demi-bold-font;
  font-size: 1.6rem;
  cursor: pointer;
  max-width: 244px;
  @include multiLineEllipsis(1.6rem, 1);
}

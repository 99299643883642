@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
}

.inputText {
  width: 100%;
  border: none;
  resize: none;
  outline: none;
  font-size: 1.6rem;
  background-color: inherit;
  @include border-class;
  padding: 16px 24px;

  &:hover {
    border-color: $gray72 !important;
  }
  &:focus-within {
    border-color: $gray13 !important;
  }
}
.disabledInputBox {
  composes: inputText;
  color: $textDefault;
  cursor: not-allowed;
  border: none !important;
  background-color: $surfaceDisabled;
  @include medium-font;

  &:hover {
    border-color: none !important;
  }
}

.error {
  display: flex;
  border-color: $borderCritical;
}

.containerClass {
  position: relative;
}

.errorClass {
  position: absolute;
  margin-top: 4px;
}

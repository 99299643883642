@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
}

.error {
  display: flex;
  flex-grow: 0;
  font-size: 1.3rem;
  color: $error50;
  margin-top: 6px;
  height: 16px;
}

.editor {
  display: flex;
  //margin-top:16px;
}

.loiContainer {
  margin-top: 32px;
  @include medium-font;
  line-height: 3rem;
}

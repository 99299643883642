@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
$borderColor: $strokeOne;

.container {
  display: flex;
  flex-direction: column;
  //   flex-shrink: 0;
  max-width: 100%;
  //max-height: 400px;
  overflow-y: auto;
  margin-top: 64px;
}

.noteContainer {
  display: flex;
  margin-bottom: 16px;
  font-size: 1.6rem;
  @include medium-font;
  color: $gray13;
}

.noteLabel {
  color: $yellow50;
  @include demi-bold-font;
  margin-right: 4px;
}

.tableWrapper {
  border: 1px solid $borderColor;
  border-radius: 7px;
}

.innerContainer {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.cell {
  position: relative;
  padding: 24px 16px 20px 16px;
  vertical-align: top;
  //width: 172px;
  @include medium-font;
}

.bodyRowCell {
  composes: cell;
  border-right: 1px solid $borderColor;
  border-top: 1px solid $borderColor;
  &:last-child {
    border-right: none;
  }
}

.headerRowCell {
  composes: cell;
  position: sticky;
  top: 0;
  z-index: 101;
  background-color: $white;
  text-align: start;
  font-size: 1.4rem;
  background-color: rgba(255, 171, 0, 0.08);
  border-right: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  &:last-child {
    border-right: none;
  }
}

.headerColumnCell {
  composes: cell;
  color: $gray31;
  position: sticky;
  left: 0;
  z-index: 100;
  //width: 300px;
  background-color: rgba(100, 100, 220, 0.05);
  font-size: 1.2rem;
  border-right: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
}

.mergedCell {
  @include demi-bold-font;
  color: $pink39;
}

.headerRowColumnCell {
  composes: headerRowCell;
  composes: headerColumnCell;
  z-index: 102;
  background-color: rgba(100, 100, 220, 0.05);
  border-right: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
}

.clickableCell {
  composes: cell;
  cursor: pointer;
  font-size: 1.2rem;
  &:hover {
    background-color: rgba(100, 100, 220, 0.05);
  }
}

.clickableStudentCell {
  composes: cell;
  font-size: 1.2rem;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 180, 0, 0.08);
  }
}

.selectableCell {
  composes: cell;

  border: 1px solid $violet63 !important;
  color: $blue29 !important;
  background-color: rgba(100, 100, 220, 0.05);
  &:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 1px;
    height: 100%;
    background-color: $violet63;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 1px;
    background-color: $violet63;
  }
}

.selectableStudentCell {
  composes: cell;

  background-color: rgba(255, 180, 0, 0.08);
}

.tickSvgContainer {
  position: absolute;
  top: 8px;
  right: 8px;
  justify-content: flex-end;
  display: flex;
}

.tickSvgComponent {
  display: flex;
}

.ratingContainer {
  width: 100%;
  height: 60px;
  margin-bottom: 40px;
  display: flex;
  margin-top: 16px;
  align-items: center;
}
.ratingTextContainer {
  width: 50%;
}
.ratingCompContainer {
  width: 50%;
  flex-direction: column;
  display: flex;
  align-items: flex-end;
}

.finalRatingHeaderText {
  @include medium-font;
  font-size: 1.4rem;
}

.finalRatingSubText {
  @include medium-font;
  font-size: 1.4rem;
  color: $gray48;
  width: 80%;
}

.alertIconContainer {
  display: inline-block;
  margin-left: 8px;
  position: relative;
  top: 4px;
}

.objectiveName {
  margin: 8px 8px 8px 0px;
}

.objectiveNameSpacing {
  margin-top: 24px;
}

.insight {
  display: flex;
  margin-bottom: 8px;
}

.insightCount {
  @include demi-bold-font;
  font-size: 2.4rem;
  color: $blue29;
}

.insightStudent {
  font-size: 1.4rem;
  color: $gray48;
  margin-left: 4px;
  align-self: flex-end;
  -webkit-font-smoothing: antialiased;
  line-height: 1.86;
}

.insightDescriptor {
  @include medium-font;
  font-size: 1.4rem;
  color: $gray72;
  word-break: break-word;
}

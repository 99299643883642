@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  width: 100%;
  min-height: 106px;
  background-color: $toddleBG1;
  display: flex;
  align-items: center;
  padding: 0px 48px;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  height: 100%;
  cursor: pointer;
}

.filterBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 40px;
}

.filterContainer:nth-child(1) > .filterBox{
  margin-left: 0px;
}

.activeBorder {
  border-bottom: 4px solid $salmon60;
  @include demi-bold-font;
}

.emptyDiv {
  margin: 24px 0px;
  border-left: 2px solid $gray92;
}

.count {
  color: $bar;
  font-size: 2.4rem;
}

.label {
  color: $bar;
  font-size: 1.6rem;
  @include medium-font;
}

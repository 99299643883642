@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 0;
}

.scrollContainer {
  display: flex;
  width: 100%;
  overflow-y: auto;
  justify-content: center;
  flex: 1;
}

.stepContainer {
  display: flex;
  flex-direction: column;
  &::before {
    content: "";
    padding-top: 56px;
  }
  &::after {
    content: "";
    padding-bottom: 56px;
  }
}

.step1Container {
  width: 600px;
}

.step2Container {
  width: 600px;
}

.step3Container {
  width: 800px;
}

.step4Container {
  width: 600px;
}

.nextButton {
  padding: 0 48px;
}

.modalBody {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  box-shadow: 8px 0px 16px rgba($black, 0.05);
}

.footerContent {
  width: 600px;
  display: flex;
  justify-content: space-between;
}

.modalFooter {
  display: flex;
  height: 64px;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 0px 40px;
  //position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background-color: $white;
  justify-content: center;
}

.rightPaneFooter {
  display: flex;
  align-items: center;
  width: 700px;
  justify-content: space-between;
}

.widthFull {
  width: 700px;
}

.widthShorter {
  width: 600px;
}

.discardBtn {
  @include medium-font;
  font-size: 1.6rem;
  color: $gray13;
  cursor: pointer;
}

.progressContainer {
  display: flex;
  height: 4px;
}

.createButton {
  width: $model-footer-button-width;
  flex-shrink: 0;
}

.lastButton {
  margin-left: 24px;
}

.cancelButton {
  width: $model-footer-button-width;
  margin-right: $model-footer-button-margin-right;
}

.backButton {
  width: $model-footer-button-width;
  margin-right: $model-footer-button-margin-right;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.showMoreText {
  @include regular-font;
  cursor: pointer;
  color: $blue29;
}

.showLessText {
  @include regular-font;
  cursor: pointer;
  color: $blue29;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.crossSvg {
  background: black;
  border-radius: 0 0 0 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
}
.closeIcon {
  margin-left: 14px;
  margin-top: 8px;
}

.feedbackButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $salmon60;
  color: $white;
  padding: 0px 12px;
  width: 100%;
  border-radius: 8px;
  fill: $white;
}

.feedbackButtonText {
  margin-left: 8px;
  @include demi-bold-font;
}

.imageViewer ul {
  all: unset;
  margin-bottom: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageViewer li {
  all: unset;
  width: auto !important;
  margin-right: 4px !important;
  padding: 0px 8px;
}

.imageViewer li:hover {
  background-color: unset !important;
}

.downloadButton {
  margin-top: 3px;
}

// .hideCloseBtnCon {
//   display: flex;
//   flex-direction: column;
// }

// .hideCloseBtnCon :global {
//   .react-viewer-close {
//     display: none;
//   }
// }

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: 440px;
  overflow-y: auto;
  margin-top: 16px;
  flex-shrink: 0;
  border: 1px solid $strokeTwo;
  border-radius: 4px;
  padding: 40px;
}

.item {
  display: flex;
  border-bottom: 1px solid $strokeOne;
  padding: 16px 0;
  justify-content: space-between;
  flex-shrink: 0;
  &:hover {
    .previewButton {
      display: flex;
    }
    .deleteButton {
      display: flex;
    }
    .deleteIconButton {
      display: flex;
    }
  }
  &:first-of-type {
    padding-top: 0px;
  }
  &:last-of-type {
    border: 0px;
    padding-bottom: 0px;
  }
}

.details {
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  display: flex;
}

.deleteButton {
  display: none;
  align-items: center;
  margin-right: 16px;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
  @include mediaQuery("touchable") {
    display: flex;
    fill: $gray31;
  }
  cursor: pointer;
}

.deleteIconButton {
  display: none;
  margin-right: 16px;
}

.previewButton {
  display: none;
  cursor: pointer;
  margin-right: 16px;
  @include mediaQuery("touchable") {
    display: flex;
  }
}
.title {
  display: flex;
  @include demi-bold-font();
  color: $gray13;
  font-size: 1.6rem;
}

.userName {
  display: flex;
  font-size: 1.4rem;
  @include medium-font();
  color: $gray48;
  line-height: 1.43;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 16px 24px;
  flex: 1;
}

.groupOptionContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  cursor: pointer;
  flex-shrink: 0;
}

.labelContainer {
  display: flex;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $textDefault;
}

.searchInputContainer {
  display: flex;
  margin-bottom: 4px;
}

.scoreCategoryLabelText {
  margin: 8px 0;
  font-size: 1.4rem;
}

.categoryText {
  @include demi-bold-font;
}

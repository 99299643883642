@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.titleContainerSubmodule {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 24px;
  border-bottom: 1px solid $gray31;
}

.leftContainer {
  display: flex;
  flex-direction: column;
}

.titleSubmodule {
  font-size: 1.8rem;
  @include bold-font();
  color: $bar;
}

.subTitle {
  font-size: 1.6rem;
  @include medium-font();
  color: $textSubtle;
}

.bulletSpan {
  margin: 0 6px;
}

.turnitinHelpCenterLink {
  color: $textLink;
  cursor: pointer;
}

.settingContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 460px;
}

.rightContainer {
  display: flex;
  width: 58px;
  height: 40px;
}

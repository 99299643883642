@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
$borderColor: $strokeOne;

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
  flex-shrink: 0;
}

.header {
  display: flex;
  padding: 48px 0;
  align-items: center;
  border-top: 1px solid $borderColor;
}

.naLabel {
  display: flex;
  @include demi-bold-font();
  font-size: 1.6rem;
  color: $gray31;
  margin-left: 16px;
}

.evaluationContainer {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 64px;
  }
}

.evaluationLabel {
  display: flex;
  @include bold-font();
  font-size: 2rem;
  color: $gray31;
  margin-bottom: 24px;
}

.evaluationBody {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid $borderColor;
  padding: 40px;
  background: $white;
}

.evaluationBodyPreview {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0px;
  overflow-x: auto;
}

.evaluationBodyMultiLevelPreview {
  padding: 0px;
  overflow-x: scroll;
}

.rootContainer {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 56px;
  }
}

.rootContainerPreview {
  display: grid;
  grid-template-columns: 1fr;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.rootLabel {
  display: flex;
  @include medium-font();
  font-size: 1.8rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $gray13;
}

.criteriaSetHeaderLabel {
  font-size: 1.6rem;
  color: $blue29;
  @include bold-font();
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.criteriaSetHeaderLabelPreview {
  @include demi-bold-font();
  color: $pink39;
}

.criteriaSetHeaderRow {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $borderColor;
  padding-bottom: 24px;
}

.criteriaSetHeaderRowPreview {
  padding: 4px;
}

.criteriaSetHeaderSingle {
  border-bottom: 0px;
}

.rightContainer {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.set {
  display: flex;
  flex: 1;
  max-width: 140px;
  align-items: center;
  justify-content: center;
  &:not(:last-child) {
    margin-right: 24px;
  }
}

.setPreview {
  min-width: 120px;
}

.buttonContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.bottomArrow {
  height: 24px;
  width: 16px;
  text-align: right;
}

.leftContainer {
  display: flex;
  margin-right: 24px;
}

.leftContainerPreview {
  min-width: 700px;
  align-items: center;
}

.parentContainer {
  display: flex;
  flex-direction: column;
}

.rowContainer {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  flex-shrink: 0;
  align-items: center;
  word-break: break-word;
  border-bottom: 1px solid $borderColor;
}

.rowContainerPreview {
  padding: 4px;
  width: 100%;
  border-bottom: 0px;
}

.rowContainerLevelPreview {
  border-top: 1px solid $borderColor;
}

.rowLabel {
  width: 100%;
  @include regular-font();
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $gray31;
  &:first-letter {
    text-transform: capitalize;
  }
}

.h1Label {
  composes: rowLabel;
  @include bold-font();
  font-size: 1.8rem;
}

.h2Label {
  composes: rowLabel;
  @include bold-font();
}

.h3Label {
  composes: rowLabel;
  @include demi-bold-font();
}

.h4Label {
  composes: rowLabel;
}

.setValue {
  @include demi-bold-font();
  font-size: 1.6rem;
  color: $gray31;
}

.setValuePreview {
  @include medium-font();
  font-size: 1.4rem;
  line-height: 2rem;
  color: $textDefault;
}

.rowsContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.h1LabelPreview {
  composes: rowLabel;
  @include demi-bold-font();
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: $textDefault;
}

.h2LabelPreview {
  composes: rowLabel;
  @include medium-font();
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: $textDefault;
}

.h3LabelPreview {
  composes: rowLabel;
  @include medium-font();
  font-size: 1.4rem;
  line-height: 2rem;
  color: $textDefault;
}

.h4LabelPreview {
  composes: rowLabel;
  @include medium-font();
  font-size: 1.4rem;
  line-height: 2rem;
  color: $textSubtle;
}

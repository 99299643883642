@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: grid;
  width: 100%;
  flex-shrink: 0;
  flex-direction: row;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.leftContainer {
  display: grid;
  gap: 4px;
}

.firstRow {
  line-height: 2.1rem;
  color: $textSubtle;
}

.secondRow {
  @include bold-font;
  font-size: 2.4rem;
  color: $gray13;
}

.subText {
  @include medium-font;
  font-size: 1.4rem;
  color: $gray48;
  margin-top: 8px;
}

@import "~UIComponents/CommonStyles/colors";

.tooltipContainer {
  display: flex;
  justify-content: space-between;
  color: $white;
  font-size: 1.2rem;
}

.message {
  margin-left: 12px;
}

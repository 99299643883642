@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  min-height: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 36px 20px;
}

.title {
  display: flex;
  @include bold-font;
  font-size: 2.4rem;
  color: $gray13;
}

.cyclesContainer {
  display: flex;
  flex-direction: column;
  @include scrollContainer();
}

.cyclesScrollContainer {
  @include flexGap(40px, 0);
}

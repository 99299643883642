@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $bar;
  padding: 40px 0 0;
  overflow: auto;
  grid-row: 1/3;
  overflow-x: hidden;
}

.addEvidenceLabel {
  @include bold-font;

  font-size: 2.4rem;
  color: $white;
  padding: 0 24px 20px;
}

.item {
  @include demi-bold-font;

  display: grid;
  height: 44px;
  padding: 12px 20px;
  flex-shrink: 0;
  min-width: 192px;
  grid-template-columns: auto min-content;
  font-size: 1.4rem;
  color: $gray72;
  cursor: pointer;
  border-left: 4px solid transparent;
}

.content {
  display: flex;

  .label {
    @include multiLineEllipsis(2.4rem, 1);
    word-break: break-all;
  }
}

.itemCount {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  padding: 0 8px;
}

.activeItem {
  border-color: $salmon60;
  color: $salmon60;
}

.separator {
  border: 0;
  border-top: 1px solid $gray31;
  width: 192px;
  margin: 12px 24px;
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: $yellow70;
  position: relative;
  align-self: center;
}

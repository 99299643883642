@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: grid;
  margin-top: 16px;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  padding: 0 16px;
  grid-gap: 16px;
  flex-shrink: 0;
}

.seeOthers {
  @include regular-font;
  font-size: 1.4rem;
  color: $blue29;
  display: flex;
  padding: 8px 24px;
  cursor: pointer;
  width: max-content;
}

.seeOthersDisable {
  color: $gray48;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.modalContent {
  width: 560px !important;
  height: 600px !important;
}

.smallModalContent {
  height: auto !important;
}

.container {
  display: grid;
  padding: 64px;
  grid-auto-flow: row;
  height: 100%;
  justify-content: space-between;
  position: relative;
}

.containerSmall {
  padding: 40px;
}

.cancelIcon {
  display: flex;
  position: absolute;
  top: 24px;
  right: 24px;
  fill: $gray48;
  cursor: pointer;
  &:hover {
    fill: $gray31;
  }
}

.middleBody {
  display: grid;
  grid-auto-flow: row;
  align-content: flex-start;
}

.premiumContainer {
  display: grid;
  grid-auto-flow: row;
  justify-content: flex-start;
  grid-row-gap: 12px;
  margin-bottom: 32px;
}

.premiumContainerSmall {
  grid-row-gap: 32px;
}

.premiumSubText {
  font-size: 1.6rem;
  color: $gray48;
  @include regular-font;
}
.premiumSubTextMedium {
  @include medium-font;
  font-size: 2rem;
  color: $gray31;
  line-height: 4rem;
}

.heading {
  @include bold-font;
  font-size: 3.2rem;
  line-height: 1.5;
  color: $seaweedTwo;
  margin-bottom: 24px;
}

.subHeading {
  @include medium-font;
  font-size: 1.8rem;
  color: $gray31;
}

.footer {
  display: grid;
  grid-row-gap: 24px;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: flex-end;
}

.upgradeContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 4px;
}

.upgradeText {
  display: grid;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $salmon60;
}

.plansText {
  display: grid;
  @include medium-font;
  text-decoration: underline;
  font-size: 1.6rem;
  color: $blue29;
  cursor: pointer;
}

.teacherText {
  font-size: 1.5rem;
  color: $salmon60;
}

.upgradeButton {
  display: grid;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: grid;
  grid-auto-flow: row;
  width: 100%;
}

.fieldContainer {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 12px;
}
.childrenContainer {
  display: flex;
}

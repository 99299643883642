@import "~UIComponents/CommonStyles/colors";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  margin: 0;
  line-height: 3.2rem;
  color: $textDefault;
}

.schoolDetailsHeader {
  line-height: 2.4rem;
  color: $textDefault;
  margin: 48px 0 0 0;
}

.schoolLogoLabel {
  line-height: 2.4rem;
  color: $textDefault;
  margin: 40px 0 0 0;
}

.schoolDetailsContainer {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 40px;
}

.selectDropdownContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.selectDropdownLabel {
  display: flex;
  line-height: 2.4rem;
  color: $textDefault;
}

.schoolLogoContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 272px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
}
.inputField {
  display: flex;
  margin-bottom: 32px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  padding: 40px;
}
.innerScrollContainer {
  display: flex;
  flex-direction: column;
  height: max-content;
}
.header {
  display: flex;
  margin-bottom: 24px;
  @include bold-font;
  font-size: 2.4rem;
  color: $gray13;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 72px;
  padding: 0 40px;
  box-shadow: 8px 0px 16px rgba(0, 0, 0, 0.05);
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  flex-direction: column;
  padding: 120px 0 120px;
  max-width: 1020px;
  width: 100%;
  margin: auto;
  align-items: center;
  flex-shrink: 0;
}

.title {
  text-align: center;
  @include bold-font;
  font-size: 4rem;
  line-height: 1.6;
  color: $salmon60;
}

.description {
  text-align: center;
  @include medium-font;
  font-size: 1.8rem;
  line-height: 2;
  color: $gray13;
  max-width: 840px;
}

@media (max-width: 991px) {
  .container {
    padding: 72px 64px;
  }
  .title {
    text-align: center;
    font-size: 3.6rem;
    line-height: 1.38;
  }
  .description {
    text-align: center;
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .container {
    padding: 56px 32px;
  }
  .title {
    font-size: 3.2rem;
  }
  .description {
    font-size: 1.6rem;
    line-height: 1.75;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.pdftron {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.webviewer {
  height: 100%;
}

.feedback {
  display: flex;
  justify-content: center;
}

.feedbackButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $salmon60;
  color: $white;
  padding: 0px 12px;
  border-radius: 8px;
  fill: $white;
  bottom: 60px;
  cursor: pointer;
  height: 28px;
}

.feedbackButtonText {
  margin-left: 8px;
  @include demi-bold-font;
}

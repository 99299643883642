@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
.container {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 48px;
  justify-content: space-between;
  flex-direction: column;
}
.filterContainer {
  display: flex;
  width: 100%;
  height: 40px;
  flex-shrink: 0;
  flex-direction: row;
}

.leftContainer {
  display: flex;
  flex-shrink: 0;
  flex: 1;
}

.rightcontainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.filterandlabelcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;
}

.filterLabelText {
  display: flex;
  @include medium-font;
  color: $gray31;

  font-size: 1.4rem;
}

.filterBox {
  display: flex;
  fill: $gray72;
  @include medium-font();
  justify-content: space-between;
  height: 100%;
  align-items: center;
  max-width: 220px;
}

.filterText {
  display: flex;
  @include medium-font;
  font-size: 1.4rem;
  color: $gray31;
  @include multiLineEllipsis(1.4rem, 1);
  margin-right: 8px;
  text-align: right;
}

.bottomArrow {
  height: 24px;
  width: 16px;
  text-align: right;
}

.searchText {
  display: flex;
  @include medium-font;
  color: $gray31;
  font-size: 1.4rem;
  width: 100%;
  margin-top: 16px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
$borderColor: $strokeTwo;

.questionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  // border: 1px solid $borderColor;
  border-radius: 4px;
  height: max-content;
  // padding: 24px 24px 32px 24px;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.questionViewContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}
.questionHeader {
  display: flex;
  justify-content: space-between;
}

.label {
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $gray13;
  line-height: 2.8rem;
  min-height: 2.8rem;
}

.buttonContainer {
  display: flex;
  margin-left: 40px;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-top: 8px;
}

.kcContainer {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.viewKcContainer {
  composes: kcContainer;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.kcLabelAndSubText {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.kcLabelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.deleteIcon {
  display: flex;
  :hover {
    cursor: pointer;
  }
}

.subText {
  color: $gray48;
  @include regular-font;
  font-size: 1.4rem;
}

.feedContainer {
  display: flex;
  flex-direction: column;
  // margin-top: 32px;
}

.viewFeedContainer {
  composes: feedContainer;
  margin-top: 16px;
}

.TextAreaInputDiv {
  display: flex;
  @include regular-font;
  font-size: 1.6rem;
  color: $gray31;

  flex-direction: column;
  white-space: pre-wrap;
}

.viewTextAreaInputDiv {
  composes: TextAreaInputDiv;
  word-break: break-word;
}

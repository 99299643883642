@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

$paddingHorizontal: 32px;

.modalContent {
  width: 640px !important;
  height: 640px !important;
}

.progressBarContainer {
  // cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 100;
}

.modalBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;
  margin-bottom: 72px;
  overflow: hidden;
}

.footerButtonContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 72px;
  padding: 0 $paddingHorizontal;
  flex-shrink: 0;
  box-shadow: 0 -2px 16px 0 rgba($black, 0.08);
  position: absolute;
}

.cancelButton {
  width: $model-footer-button-width;
  flex-shrink: 0;
  border-radius: 0px;
}

.footerLeftContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.footerRightContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.leftFooterButton {
  display: flex;
  margin-right: 16px;
}

.rightFooterButton {
  width: $model-footer-button-width;
  flex-shrink: 0;
  border-radius: 2px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 32px;
}

.cancelIcon {
  cursor: pointer;
  fill: $gray48;
  z-index: 99;
  &:hover {
    fill: $gray31;
  }
}

.list {
  display: flex;
  overflow-y: auto;
  flex: 1;
}

.listContent {
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
}

.backIcon {
  display: flex;
  margin-right: 8px;
}

.backButtonContainer {
  display: flex;
  height: 16px;
  width: 100%;
  margin-bottom: 8px;
}
.backButton {
  width: fit-content;
  font-size: 1.6rem;
  @include demi-bold-font;
  color: $salmon60;
  margin-right: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.titleText {
  display: flex;
  @include bold-font;
  font-size: 24px;
  color: $gray13;
}

.headerLeftContainer {
  display: flex;
  flex-direction: column;
}

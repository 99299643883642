@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "../common.scss";

.containerWrapper {
  background: $toddleBG1;
}

.itemsContainer {
  grid-template-columns: repeat(3, minmax(220px, 1fr));
  grid-column-gap: 26px;
  max-width: 1240px;
}

.imageContainer {
  // border: 1px solid $strokeOne;
  width: 100%;
  max-width: 500px;
  img {
    width: 100%;
  }
}

@include mediaQuery("mobile") {
  .container {
    padding: 48px 32px 0;
  }
}

@include mediaQuery("mobile-and-tablet") {
  .header {
    margin-bottom: 48px;
  }
  .itemsContainer {
    order: 2;
    grid-template-columns: 1fr;
  }
}

@include mediaQuery("tablate") {
  .container {
    padding-bottom: 0;
  }
  .itemsContainer {
    width: 480px;
  }
}

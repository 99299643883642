@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;

  @media (min-width: 1550px) {
    margin-top: 76px !important;
  }
}

.innerContainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 16px;
}

.inputRowContainer {
  display: grid;
  grid-column-gap: 32px;
  grid-auto-flow: column;
  margin-bottom: 16px;
}

.inputContainer {
  display: flex;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.drodpownContainer {
  display: flex;
  position: relative;
  margin-bottom: 16px;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.noWorkEmail {
  display: flex;
  position: absolute;
  right: 0;
  font-size: 1.4rem;
  @include medium-font;
  color: $gray48;
  cursor: pointer;
}

.loadingContainer {
  display: flex;
  align-items: center;
  position: absolute;
  right: 12px;
  top: 36px;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.signInButton {
  @include medium-font;
  color: $blue29;
  text-decoration: underline;
  font-size: 1.2rem;
  margin-left: 4px;
  cursor: pointer;
}

.emailWarnings {
  display: flex;
  padding: 24px;
  color: $black;
  background-color: rgba($yellow50, 0.1);
  @include medium-font;
  border-radius: 4px;
  font-size: 1.6rem;
}

.contactUsButton {
  text-decoration: underline;
  cursor: pointer;
}

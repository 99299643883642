@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px 0;
  padding: 0 40px;
  background-color: $toddleBG1;
  margin-top: 0; //to override margin top of mixin
}

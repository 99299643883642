@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: grid;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  align-content: flex-start;
  // padding: 32px;
}

.header {
  display: grid;
  grid-auto-flow: column;
  align-content: flex-start;
  grid-template-columns: 80px 1fr 12px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid $strokeTwo;
}

.headerV2 {
  display: grid;
  grid-auto-flow: column;
  align-content: flex-start;
  grid-template-columns: 1fr 12px;
  padding: 32px;
  border-bottom: 1px solid $strokeTwo;
}

.image {
  display: grid;
  height: 88px;
  border-radius: 4px;
  background-size: contain;
  background-position-y: center;
}

.headerDetails {
  display: grid;
  margin: 0 16px;
  margin-right: 0;
  grid-row-gap: 4px;
}

.headerDetailsV2 {
  display: grid;
  align-content: center;
  // margin: 0 16px;
  margin-right: 0;
  grid-row-gap: 4px;
}

.heading {
  @include medium-font;
  font-size: 1.4rem;
  color: $teal20;
}

.headingV2 {
  @include bold-font;
  font-size: 1.4rem;
  color: $gray31;
}

.title {
  @include bold-font;
  font-size: 2rem;
  // line-height: 1.5;
  color: $gray13;
}

.subTitle {
  @include medium-font;
  font-size: 1.4rem;
  color: $gray31;
  //line-height: 1.5;
  letter-spacing: 0.01px;
}

.closeIcon {
  display: grid;
  align-content: center;
  height: 12px;
  cursor: pointer;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 0px;
}

.stageContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $strokeTwo;

  .stageHeader {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex: 1;
    height: 82px;
    padding: 24px 32px;
    cursor: pointer;

    .stageLabel {
      display: flex;
      align-items: center;
      margin-right: 16px;
      @include medium-font;
      font-size: 1.6rem;
      color: $gray31;
    }
    .stageLabelExpanded {
      color: $blue29;
      @include bold-font;
    }

    .arrowIcon {
      display: flex;
      align-items: center;
    }
  }

  .stepsContainer {
    display: flex;
    flex-direction: column;
    padding: 0px 32px 20px 32px;

    .stepGroup {
      display: flex;
      flex-direction: column;

      .stepSubLabel {
        display: flex;
        @include bold-font;
        font-size: 1.6rem;
        margin-bottom: 20px;
      }
    }
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;

  @media (min-width: 1550px) {
    margin-top: 76px !important;
  }
}

.innerContainer {
  display: flex;
  flex-direction: column;
  margin-top: 56px;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 28px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.bottomContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.inputLabelContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.label {
  font-size: 1.6rem;
  @include demi-bold-font;
  color: $gray31;
}

.createAccountButton {
  color: $blue29;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: darken($blue29, 10%);
  }
}

.subHeader {
  display: flex;
  margin-top: 4px;
  @include medium-font;
  font-size: 1.6rem;
  color: $gray31;
}

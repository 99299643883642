@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 24px 40px 0 40px;
  overflow-y: auto;
  flex-grow: 1;
}

.feedContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  align-items: center;
}

.noDataContainer {
  display: flex;
  flex: 1;
  min-height: 0;
  color: $gray31;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 64px;
}

.noPostsText {
  margin-top: 40px;
  display: flex;
  @include bold-font;
  font-size: 2.1rem;
  color: $gray13;
}

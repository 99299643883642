@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

$borderColor: $strokeOne;
.modalHeader {
  display: flex;
  min-height: 90px;
  padding: 32px 40px;
  justify-content: space-between;
  align-items: center;
}

.modalBody {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  flex: 1;
  margin-bottom: 72px;
  padding: 0px 40px;
}

.headerTitle {
  display: flex;
  @include bold-font;
  font-size: 2.4rem;
}

.fieldComp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 32px;
}

.focusItemComp {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: white;
  flex-shrink: 0;
  padding: 32px 24px;
  box-shadow: 1px 0 0 0 $borderColor, 0 1px 0 0 $borderColor,
    1px 1px 0 0 $borderColor, 1px 0 0 0 $borderColor inset,
    0 1px 0 0 $borderColor inset;
  //border: 1px solid $borderColor;
  position: relative;
  //justify-content: flex-end;
  border-collapse: collapse;
  justify-content: center;
  cursor: pointer;
}
.focusItemCompActive {
  composes: focusItemComp;
  background-color: $teal20;
  // border: 1px solid $white;
  box-shadow: 1px 0 0 0 $white, 0 1px 0 0 $white, 1px 1px 0 0 $white,
    1px 0 0 0 $white inset, 0 1px 0 0 $white inset;
}

.titleText {
  font-size: 1.4rem;
  @include demi-bold-font;
  color: $gray13;
  text-transform: capitalize;
}

.titleTextSelected {
  composes: titleText;
  color: $white;
}

.subText {
  color: $gray48;
  font-size: 1.4rem;
  @include regular-font;
}

.subTextSelected {
  composes: subText;
  color: $white;
}

.cancelIcon {
  cursor: pointer;
  fill: $gray48;
  &:hover {
    fill: $gray31;
  }
}

.tickAndHelperComp {
  position: absolute;
  top: 8px;
  right: 8px;
}

.modalFooter {
  display: flex;
  padding: 0px 40px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 72px;
  align-items: center;
  justify-content: flex-end;
  // border-top: 1px solid $gray72;
}

.modalFooterShadow {
  box-shadow: 0 -2px 16px 0 rgba($black, 0.08);
}

.cancelButton {
  display: flex;
  margin-right: 16px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
}

.starIcon {
  fill: $yellow50;
}

.nodeContainer {
  display: flex;
  flex-direction: column;
}

.practicesContainer {
  display: flex;
  flex-direction: column;
}

.practice {
  font-size: 1.4rem;
  @include medium-font;
  color: $gray31;
  margin-bottom: 20px;
}

.approvedPractice {
  display: flex;
  margin-left: 8px;
}

.practiceHeader {
  display: flex;
}

.code {
  color: $blue29;
  @include bold-font;
  margin-right: 4px;
}

.type {
  display: flex;
  margin-left: 4px;
  text-transform: lowercase;
  @include medium-italic-font;
}

.highlightText {
  display: inline;
  @include demi-bold-font;
}

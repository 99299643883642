@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px 40px 0px 40px;
}

.header {
  display: flex;
  align-items: center;
  font-size: 2rem;
  @include bold-font();
  margin-bottom: 24px;
}

.contentContainer {
  display: flex;
  padding: 24px;
  overflow-y: auto;
  background-color: $surfaceLight;
  border: 1px solid $borderSubtle;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 24px 0px;
}

@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;
}
.header {
  height: 72px;
  display: flex;
  align-items: center;
}
.toddleIcon {
  width: 40px;
  height: 40px;
  fill: #fd727d;
  margin-left: 16px;
}
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  @include medium-font;
  margin-top: 32px;
  color: #82828c;
}
.tabsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.tabs {
  width: 200px;
  height: 200px;
  background-color: #e6e6e8;
  display: flex;
  color: #5f5f69;
  justify-content: center;
  align-items: center;
  margin: 24px;
  font-size: 36px;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background-color: #d7d7db;
  }
}

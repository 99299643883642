@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  height: 100%;
  width: 100%;
  display: flex;
}

.contentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.headerContainer {
  padding: 40px 0 24px;
  margin: 0 40px;
  border-bottom: 1px solid $strokeTwo;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 0;
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.header {
  flex: 1;
  display: flex;
  color: $gray13;
  @include bold-font;
  font-size: 2.4rem;
  // word-break: break-all;
}

.theme {
  font-size: 1.4rem;
  @include medium-font;
  color: $gray48;
  margin: 8px 0;
}

.leCount {
  font-size: 1.4rem;
  @include medium-font;
  color: $seaweedTwo;
}

.profileContainer {
  width: 120px;
  height: 96px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  flex-shrink: 0;
  margin-left: 12px;
}

.body {
  padding: 0 40px;
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  height: max-content;
  flex-shrink: 0;
}

.block {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 0;
}

.footer {
  min-height: 64px;
  border-top: 1px solid $strokeTwo;
  padding: 8px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.termsAndConditions {
  font-size: 1.2rem;
  color: $gray48;
  line-height: 1.67;
  @include medium-font;
  margin-right: 40px;
  a,
  a:visited {
    color: $blue29;
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.loadingContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
}

.innerContainer {
  display: grid;
  margin-top: 24px;
  width: 100%;
  max-width: 960px;
  grid-auto-flow: column;
  grid-template-columns: 1fr 232px;
  grid-column-gap: 72px;
  align-items: center;
}

.stepsImage {
  width: 100%;
}

.stepsContainer {
  display: flex;
  flex-direction: column;
}

.step {
  display: flex;
  flex-direction: row;

  align-items: center;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.stepCount {
  display: flex;
  align-items: center;
  justify-content: center;
  @include demi-bold-font;
  color: $white;
  font-size: 1.6rem;
  line-height: 1.5;
  width: 76px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 4px;
}

.mobileStepCount {
  composes: stepCount;
  display: none;
}
.stepDotContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;
  margin-left: 24px;
  position: relative;
}
.stepDot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 2.5px $white;
}

.stepDetailsContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}
.stepDetails {
  @include medium-font;
  font-size: 1.6rem;
  color: $gray13;
  line-height: 1.75;
}
.verticalLine {
  display: flex;
  width: 1px;
  height: 48px;
  top: 16px;
  position: absolute;
  background-color: $strokeTwo;
}

.strips {
  margin-top: 64px;
}

.othersText {
  margin-top: 24px;
  @include medium-font;
  font-size: 1.6rem;
  line-height: 1.75;
  color: $gray13;
}

.button {
  margin-top: 24px;
  width: max-content;
}

@media (max-width: 991px) {
  .innerContainer {
    grid-template-columns: 1fr 0;
    grid-column-gap: 0;
    margin-top: 40px;
  }
  .stepsImage {
    display: none;
  }
  .strips {
    text-align: center;
  }

  .othersText {
    text-align: center;
  }
  .button {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .step {
    align-items: flex-start;
  }

  .stepDotContainer {
    margin-left: 0;
    margin-top: 8px;
  }

  .stepCount {
    display: none;
  }

  .mobileStepCount {
    display: flex;
    margin-bottom: 8px;
  }

  .stepDetails {
    line-height: 1.5;
    //position: absolute;
  }
  .verticalLine {
    height: 104px;
  }
  .strips {
    margin-top: 40px;
    text-align: center;
  }

  .othersText {
    text-align: center;
  }
  .button {
    width: 100%;
  }
}

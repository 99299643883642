@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.modalContent {
  width: 86vw !important;
  height: 90vh !important;
  border-radius: 24px !important;
}

.headerContainer {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 24px;
  border-bottom: 1px solid $borderSubtle;
}

.leftContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.headerTitle {
  @include bold-font;
  font-size: 2rem;
  color: $textDefault;
}

.footerContainer {
  width: 100%;
  height: 72px;
  padding: 16px 24px;
  border-top: 1px solid $borderSubtle;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  margin-top: auto;
}

.buttonsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftButtonContainer {
  display: flex;
}

.rightButtonContainer {
  display: flex;
  gap: 12px;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px 28px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

$rightPaneAnimationTime: 0.3s;
$middle_pane_width: 648px;
$left_pane_width: 352px;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $toddleBG1;
}

.bodyContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 0;
  width: 100%;
  position: relative;
}

.leftContainer {
  display: flex;
  width: $left_pane_width;
  overflow-y: auto;
  border-right: 1px solid $strokeTwo;
}

.middlePane {
  display: flex;
  flex-shrink: 0;
  height: 100%;
}

.smallMiddlePane {
  composes: middlePane;
  width: $middle_pane_width;
  transition: width $rightPaneAnimationTime;
}

.fullMiddlePane {
  composes: middlePane;
  width: calc(100% - #{$left_pane_width});
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  width: 600px !important;
  height: 432px !important;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  padding: 56px 40px;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
}

.headerTitle {
  display: flex;
  @include bold-font();
  font-size: 2.8rem;
}

.description {
  @include medium-font();
  font-size: 1.6rem;
  color: $bar;
  margin-top: 40px;
  min-height: 22px;
  text-align: center;
}

.inputField {
  margin-top: 24px;
  width: 472px;
}

.button {
  margin-top: 24px;
}

.cancelAndLogoutButton {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.errorText {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  color: $salmon60;
  @include demi-bold-font;
  font-size: 1.2rem;
  height: 16px;
}

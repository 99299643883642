@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;

  @media (min-width: 1550px) {
    margin-top: 76px !important;
  }
}

.headerSubText {
  display: flex;
  @include medium-font;
  font-size: 2.4rem;
  color: $gray31;
  margin-bottom: 16px;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 24px;
}

.inputContainer {
  display: flex;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.bottomContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.confirmationHeaderText {
  display: flex;
  @include bold-font;
  color: $blue29;
  font-size: 2.8rem;
  margin-bottom: 38px;
  line-height: 4.2rem;
}

.confimationSubTextContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
}

.confirmationSubText {
  display: flex;
  @include regular-font;
  font-size: 1.4rem;
  color: $gray48;
  line-height: 2.4rem;
  margin-bottom: 12px;
}

.confirmationSubText1 {
  display: flex;
  @include regular-font;
  font-size: 1.4rem;
  color: $gray48;
  line-height: 2.4rem;
}

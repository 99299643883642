@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.standard {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    border-bottom: 1px solid $strokeOne;
  }
  flex-shrink: 0;
  padding: 32px 0;
}

.standardLabelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.rightContainer {
  display: flex;
}

.standardLabel {
  @include bold-font;
  font-size: 1.6rem;
  color: $gray13;
}

.practiceList {
  display: flex;
  flex-direction: column;
}

.practice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:not(:last-child) {
    border-bottom: 1px solid $strokeOne;
  }
  padding: 24px 0;
  &:last-child {
    padding-bottom: 0;
  }
  cursor: pointer;
}

.practiceDetails {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}

.practiceCode {
  @include bold-font;
  font-size: 1.4rem;
  color: $blue29;
  line-height: 1.43;
}

.practiceLabel {
  @include demi-bold-font;
  font-size: 1.4rem;
  color: $gray31;
  line-height: 1.43;
}

.checkbox {
  display: flex;
}

.selectedCount {
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: $salmon60;
  color: $white;
  font-size: 1.2rem;
  @include demi-bold-font;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.noDataText {
  display: flex;
  text-align: center;
  width: 100%;
  font-size: 1.4rem;
  color: $gray48;
  flex: 1;
  align-items: center;
  justify-content: center;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 40px 40px 64px 40px;
  overflow-y: auto;
}

.modalContent {
  width: 600px !important;
  height: 640px !important;
}

.modalBody {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 2.2rem;
  padding-bottom: 40px;
  @include bold-font;
}

.modalFooter {
  display: flex;
  height: 64px;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 0px 40px;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background-color: $white;
}

.rightPaneFooter {
  display: flex;
  flex-basis: 50%;
  height: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.saveButton {
  width: $model-footer-button-width;
  flex-shrink: 0;
}

.lastButton {
  margin-left: 24px;
}

.cancelButton {
  width: $model-footer-button-width;
  margin-right: $model-footer-button-margin-right;
}

.settingCard {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  width: 100%;
  padding: 16px 0px;
  align-items: center;
  justify-content: space-between;
}

.settingLeftContainer {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  flex: 1;
}

.settingTitle {
  @include demi-bold-font;
  color: $black;
  font-size: 1.6rem;
  margin-bottom: 4px;
}

.switch {
  display: flex;
}

.colorPalletButtonContainer {
  display: flex;
  align-items: flex-end;
  position: relative;
}

.colorPalletButton {
  display: flex;
  height: 54px;
  width: 54px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.colorDropContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

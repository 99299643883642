@import "~UIComponents/CommonStyles/colors";

.dropdownItemHeader {
  padding: 12px 24px;
  line-height: 2.4rem;
  display: flex;
  color: $textDefault;
  justify-content: space-between;
  align-items: center;
}

.expandedHeader {
  background-color: $surfacePressed;
}

.iconContainer {
  margin-left: 8px;
  display: flex;
}

.labelSiblingContainer {
  display: flex;
  align-items: center;
}

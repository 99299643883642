@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
}

.header {
  display: flex;
  column-gap: 16px;
  align-items: center;
  justify-content: space-between;
}

.headerLabelContainer {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.headerLabel {
  color: $textDefault;
  @include multiLineEllipsis(2rem, 1);
}

.headerSubText {
  color: $textSubtle;
  @include multiLineEllipsis(1.6rem, 1);
}

.rightHeaderContainer {
  display: flex;
  column-gap: 16px;
  align-items: center;
}

.headerMarksBox {
  display: flex;
  padding: 4px 8px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  height: 32px;
  background-color: $decorativeSurfaceTwoL;
  color: $interactiveThreeHover;
}

.unMarkedBox {
  composes: headerMarksBox;
  color: $textDefault;
  background-color: $surfaceDefault;
}

.singleLevelBodyContainer {
  display: flex;
  flex-direction: column;
}

.bodyContainer {
  composes: singleLevelBodyContainer;
  row-gap: 72px;
  margin-top: 48px;
}

//Criteria Classes

.criteriaBox {
  composes: headerMarksBox;
  color: $textDefault;
  background-color: $surfaceDefault;
}

.parentNodeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid $strokeTwo;
}

//Criterion Classes

.leafNodeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  column-gap: 24px;
}

.leafNodeLabel {
  color: $textDefault;
  word-break: break-word;
}

//Descriptor Table Classes

.descriptorTableContainer {
  display: flex;
  flex-direction: column;
  color: $textDefault;

  .descriptorTableTitle {
    margin-bottom: 16px;
  }

  .descriptorTableDescription {
    display: flex;
    margin-bottom: 24px;
    color: $textSubtle;
    p,
    ul {
      margin: 0;
    }
  }
}

//Cannot be Assessed Text class

.assessedTextContainer {
  display: flex;
  column-gap: 8px;
  color: $textSubtle;
  align-items: center;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  width: 100%;
  flex-direction: column;
}

.feedContainer {
  width: 100%;
  flex-direction: column;
}

.emptyText {
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: $gray48;
  @include regular-font();
  letter-spacing: 0;
}

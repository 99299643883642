@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  flex-direction: column;
  width: 264px;
  background-color: $toddleBG1;
  height: 100%;
  flex-shrink: 0;
  overflow-y: auto;
  padding: 40px 32px;
  border-right: 1px solid $strokeOne;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  flex-shrink: 0;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.menuContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
  flex-shrink: 0;
}

.labelText {
  display: flex;
  @include regular-font;
  font-size: 1.6rem;
  color: $gray13;

  word-break: break-word;
  flex: 1;
}

.labelText01 {
  display: flex;
  @include medium-font;
  font-size: 1.6rem;
  color: $gray48;
  &:hover {
    color: $gray13;
  }
}

.labelText02 {
  display: flex;
  @include demi-bold-font;
  font-size: 1.6rem;
  color: $black;
}

.labelText12 {
  display: flex;
  @include regular-font;
  font-size: 1.6rem;
  color: $gray48;
  &:hover {
    color: $gray13;
  }
}

.rightText {
  display: flex;
  color: $gray48;
  font-size: 1.6rem;
  @include regular-font;
  width: 24px;
  justify-content: flex-end;
}

.selectedLabelText {
  display: flex;
  text-decoration: underline;
  color: $blue29;
  &:hover {
    color: $blue29;
  }
}

.noText {
  display: flex;
  color: $gray48;
  @include medium-font;
  font-size: 1.6rem;
}

.rootLevelText {
  display: flex;
  font-size: 1.8rem;
  @include bold-font;
  color: $gray13;
  margin-bottom: 24px;
  flex-shrink: 0;
}

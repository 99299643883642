@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContainer {
  height: auto !important;
  max-height: 640px;
}

.modalHeader {
  display: flex;
  min-height: 90px;
  padding: 32px 40px;
  justify-content: space-between;
  align-items: center;
}

.modalBody {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  flex: 1;
  padding: 4px 40px;
}

.headerLabel {
  display: flex;
  @include bold-font;
  font-size: 2.4rem;
}

.optionsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
}

.modalFooter {
  display: flex;
  padding: 16px 40px;
  width: 100%;
  column-gap: 16px;
  justify-content: flex-end;
}

.modalFooterShadow {
  box-shadow: 0 -2px 16px 0 rgba($black, 0.08);
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  &:hover {
    .middleIconContainer {
      display: none;
    }
    .actionButton {
      display: flex;
    }
    .overlay {
      opacity: 1;
    }
    .videoEmbedOverlay{
      background-color: rgba($black, 0.4);
    }
    .svgContainer {
      display: flex;
    }
    .assessmentOverlay {
      display: none;
    }
    .assessmentPlaceholderOverlay {
      .assessmentOverlayCircle {
        display: none;
      }
    }
  }
}

.imageContainer {
  display: flex;
  width: 100%;
  height: 100%;
  background-size: cover;
  justify-content: center;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba($black, 0.4);
}

$notesWrapperPadding: 16px;

.noteWrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: $notesWrapperPadding;
}
.noteContainer {
  height: 100%;
  border-left: 2px solid $yellow50;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  font-size: 1.4rem;
  @include medium-font;
  overflow: hidden;
  white-space: pre-wrap;
  height: 100%;
  word-break: break-word;
}
.noteContainerWithLimit {
  composes: noteContainer;
  @include multiLineEllipsis(2.8rem, 9);
  max-height: 288px;
  a {
    color: $blue29;
  }
}
.noteLinesContainer {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  position: relative;
  background: white;
  border-radius: 4px;
  background-image: repeating-linear-gradient(
    $yellow96 0px,
    $yellow96 27px,
    $strokeOne 28px
  );
  -webkit-background-size: 100% 2.8rem;
  -moz-background-size: 100% 2.8rem;
  -ms-background-size: 100% 2.8rem;
  background-size: 100% 2.8rem;
}
// to negate padding 16px
.notesOverlayContainer {
  position: absolute;
  top: -$notesWrapperPadding;
  left: -$notesWrapperPadding;
  right: -$notesWrapperPadding;
  bottom: -$notesWrapperPadding;
}
.middleIconContainer {
  display: flex;
  width: 15%;
  max-width: 40px;
  min-width: 32px;
  border-radius: 50%;
  background-color: rgba($color: $black, $alpha: 0.6);
  justify-content: center;
  align-items: center;
  fill: $white;
  &:hover {
    background-color: $gray31;
  }
}

.middleIconContainer:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.linkReference {
  position: absolute;
  bottom: 0;
  height: 48px;
  width: 100%;
  padding: 8px;
  background: rgba($color: $black, $alpha: 0.6);
}
.linkText {
  color: #fff;
  font-size: 1rem;

  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 48px;
  display: -webkit-box;
  &:hover {
    color: $blue29;
  }
  &:visited {
    color: #fff;
  }
}

.svgContainer {
  display: none;
  transition: opacity 0.35s ease;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: row;
  flex-wrap: wrap;
}

.actionSvgIcon {
  display: flex;
  fill: #fff;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.audioSvgIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 10;
  margin-right: 12px;
}

.downloadIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionButton {
  composes: middleIconContainer;
  margin: 0px 12px;
  cursor: pointer;
  fill: $strokeTwo;
}

.carouselContainer :global {
  .slick-slide {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  .slick-slide:focus {
    outline: none !important;
  }
  .slick-slide div {
    outline: none !important;
  }
  img {
    object-fit: contain;
    width: unset !important;
  }
  height: 340px;
}

.carouselContainerV2 :global {
  .slick-slide:focus {
    outline: none !important;
  }
  .slick-slide div {
    outline: none !important;
  }
  img {
    object-fit: contain;
    width: unset !important;
    border-radius: 8px;
  }
  .slick-slider{
    .slick-dots{
      position: unset !important;
    }
  }
  height: 100%;
}

.carouselItemV2 {
  display: flex;
  justify-content: center;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
  margin:0 24px;
}

.smallCarouselContainer :global {
  img {
    object-fit: cover;
    object-position: top;
    border-radius: 8px;
    margin-right: 8px;
  }
  .slick-slide {
    margin-right: 0px !important;
  }
  max-width: 400px;
}

.carouselImageContainer {
  position: relative;
  .overlay {
    opacity: 0 !important;
  }
  .svgContainer {
    display: none !important;
  }
  &:hover {
    .svgContainer {
      display: flex !important;
    }
    .overlay {
      opacity: 1 !important;
    }
  }
}

.smallCarouselImageContainer {
  position: relative;
  border-radius: 0px;
  margin: 0;
  .slick-arrow {
    visibility: hidden !important;
  }

  &:hover {
    .slick-arrow {
      visibility: visible !important;
    }
  }
}

.audioWrapper {
  display: flex;
  height: 100%;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  border: 1px solid $surfaceLight;
  background-color: $surfaceLight;

  &:hover {
    border: 1px solid $borderHover;
  }
}

.audioWrapperUploading {
  &:hover {
    border: 1px solid $surfaceLight;
  }
  cursor: not-allowed;
}

.audioNote {
  display: flex;
  width: 100%;
  //height: 40px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.smallAudioNote {
  display: flex;
  position: absolute;
  bottom: 8px;
  left: 8px;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  fill: $white;
  background-color: rgba(32, 38, 50, 0.9);
}

.audioBGSvg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.1;
}

.smallAudioBGSvg {
  background-size: unset;
}

.counterText {
  display: flex;
  position: absolute;
  left: 8px;
  @include demi-bold-font;
  font-size: 1.2rem;
  color: $white;
  height: 16px;
  padding: 0 8px;
  background-color: rgba($bar, 0.9);
  border-radius: 10px;
}

.audioPlayerWrapper {
  display: flex;
  width: 100%;
}

.lockBackground {
  position: absolute;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  background-color: $gray48;
  transition: 0.4s;
  &:hover {
    background-color: $black;
  }
}

.lockIcon {
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;
}

.lockIcon:hover + .lockBackground {
  position: absolute;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  background-color: $black;
}

.audioContainer {
  margin-bottom: 12px;
}

.assessmentOverlay {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assessmentPlaceholderOverlay {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.assessmentOverlayCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: rgba($surfaceDark, 0.6);
  border-radius: 50%;
}

.assessmentIcon {
  background: $surfaceWhite;
  border-radius: 4px;
}

.downloadBeginOverlay {
  position: absolute;
  bottom: 0px;
  height: 44px;
  background-color: $surfaceDark;
  opacity: 0.6;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.downloadBeginAnimation {
  animation-timing-function: ease;
  animation: slidein 0.5s;
}

@keyframes slidein {
  from {
    bottom: -44px;
  }

  to {
    bottom: 0px;
  }
}

.downloadBeginText {
  @include medium-font;
  font-size: 1.4rem;
  line-height: 2rem;
  color: $textOn;
}

.clickHereText {
  text-decoration: underline;
}

.videoEmbedOverlay{
  background-color: rgba($black, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  transition: 0.5s ease;
}

.embedPlayer{
  display: flex;
}
.embedPlayerHide{
  display: flex;
}

.audioSpeakerIconContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
}

.audioSpeakerIconBackground{
  width: 96px;
  height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 1.6rem;
}

.iconCardContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
}

.iconBackground {
  width: 96px;
  height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.extentionContainer {
  font-size: 1.6rem;
  @include medium-font;
  margin-top: 8px;
}
@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  background-color: $surfaceLight;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  width: max-content;
  min-width: 592px;
  padding: 12px 32px;
  border-radius: 8px;
}

.leftContainer {
  display: flex;
  align-items: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
  min-width: 92px;
  margin-left: 8px;
}

.scoringText {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  line-height: 1.5;
  color: $textDefault;
  font-weight: 600;
  @include demi-bold-font;
  @include multiLineEllipsis(2.6rem, 1);
}

.rightContainer {
  display: flex;
  margin-left: 16px;
  flex-shrink: 0;
}

.categoryContainer {
  display: flex;
  margin-left: 24px;
}

.courseContainer {
  display: flex;
  margin-left: 24px;
}

.dropdown {
  min-width: 224px;

  @include mediaQuery("tablate") {
    width: max-content;
  }
  @include mediaQuery("mobile") {
    width: max-content;
  }
}

.maxScoreInputContainer {
  display: flex;
  width: 172px;

  @include mediaQuery("mobile") {
    width: 100px;
  }
}

//add cursor pointer from the container, if you want

.progressBarContainer {
  display: flex;
  flex-direction: row;
  min-height: 8px;
  width: 100%;
}

.progressBar {
  display: flex;
  flex: 1;
  &:not(:last-child) {
    margin-right: 3px;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: baseline;
  position: relative;
  border: 1px solid $strokeTwo;
  border-radius: 4px;
  padding: 6px 0 6px 12px;
}

.searchIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  position: relative;
  top: 2px;
}

.textInputContainer {
  border: none;
  border-radius: 0;
  outline: none;
  flex: 1;
  margin-left: 4px;
  align-self: center;
  line-height: 28px;
  font-size: 1.6rem;
  background-color: transparent;
}

.focusContainer {
  border-color: $gray72;
}

.arrowContainer {
  display: flex;
  fill: $gray48;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.doneButton {
  display: flex;
  color: $salmon60;
  @include medium-font;
  font-size: 1.6rem;
  height: 100%;
  padding-right: 24px;
  align-items: center;
  cursor: pointer;
}

.errorContainer {
  display: flex;
  background-color: rgba($yellow50, 0.1);
  border-color: $yellow50;
}

.errorText {
  display: flex;
  margin-top: 8px;
  color: $salmon60;
  @include demi-bold-font;
  font-size: 1.2rem;
  height: 14px;
  text-align: left;
  width: 100%;
}

.searchBarContainer {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 7px 12px;
  min-width: 188px;
  border-radius: 4px;
}

.addNewLabel {
  width: 100%;
  background: $white;
  position: absolute;
  top: calc(100% + 2px);
  padding: 10px 12px;
  left: 0;
  font-size: 1.8rem;
  @include medium-font;
  border: 1px solid $strokeOne;
  border-radius: 4px;
  color: $bar;
  display: flex;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: $toddleBG1;
  }
}

.tagListContainer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  cursor: default;
  margin-top: 16px;
  width: 100%;
}

@import "~UIComponents/CommonStyles/colors";

.rowContainer td {
  border-bottom: 1px dashed $strokeTwo;
  border-right: 1px dashed $strokeTwo;
  // border-radius: 4px;
  border-collapse: collapse;
  padding: 12px 16px;
  height: 48px;
  box-sizing: border-box;
  background-color: $white;
}
.rowContainer {
  height: 48px;
}
.rowContainerDisabled td {
  background-color: $strokeOne;
}

.isRowDragging td {
  width: inherit;
  // border-top: none;
  // border-bottom: none;
  border-top: 1px solid $blue29;
  border-bottom: 1px solid $blue29;
}
.isRowDragging {
  border-left: 1px solid $blue29;
  border-right: 1px solid $blue29;
}
.tableCell {
  &:first-child {
    width: 60%;
    position: sticky;
    left: 0;
    border-left: 1px dashed $strokeTwo;
    z-index: 11;
  }
  &:nth-child(2) {
    width: 10%;
    max-width: 250px;
  }
  &:nth-child(3) {
    width: 15%;
    max-width: 300px;
  }
  &:nth-child(4) {
    width: 15%;
    max-width: 300px;
  }
  &:last-child {
    // border-right: 1px solid $strokeTwo;
  }
}

.isRowDragging td:first-child {
  border-left: none;
  width: inherit !important;
}
.isRowDragging td:last-child {
  border-right: none;
}

.isRowError td:first-child {
  border-left: 1px solid $red66;
}
.isRowError td {
  background: $pink98;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.commonPadding {
  padding: 0 40px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.labelContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.labelRightContainer {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.editLabel {
  display: flex;
  column-gap: 8px;
  color: $textLink;
  cursor: pointer;
}

.boxOuterContainer {
  display: flex;
  height: 72px;
  width: 100%;
  border-radius: 8px;
  border: 1px dashed $strokeTwo;
  padding: 8px;
}

.boxInnerContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  color: $textLink;
  background-color: $surfaceLight;
  border-radius: 8px;
  cursor: pointer;
}

.valueLabelText {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $borderDefault;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 40px;
  row-gap: 24px;
  min-height: 0;
}

.headingContainer {
  composes: commonPadding;
}

.searchBox {
  composes: commonPadding;
}

.listContainer {
  @include scrollContainer;
  flex: 1;
}

.innerContainer {
  composes: commonPadding;
}

.listItemContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $strokeOne;
  padding: 20px 0;
}

.footerContainer {
  display: flex;
  justify-content: flex-end;
  padding: 12px 40px;
  border-top: 1px solid $borderDefault;
  column-gap: 16px;
}

.boxDisabled {
  cursor: not-allowed;
  color: $textSubtle;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.postFeedCon {
  display: flex;
  flex-direction: column;
}

.postFeedItem {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";
@import "../common.scss";

.containerWrapper {
  background: $toddleBG1;
}

.container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.header {
  margin-bottom: 40px;
}

.subHeader {
  display: flex;
  font-size: 2.4rem;
  line-height: 4rem;
  @include medium-font;
  margin-bottom: 40px;
  color: $gray31;
  max-width: 960px;
  text-align: center;
}

.imageContainer {
  width: 100%;
  display: flex;
  margin: 0 auto 80px auto;
  max-width: 1240px;
  img {
    width: 100%;
  }
}

.carouselContainer {
  display: none;
}

.carouselItem {
  display: flex;
  background-color: transparent;
  position: relative;
}

@include mediaQuery("mobile-and-tablet") {
  .container {
    padding: 48px 32px 0;
  }
  .header {
    margin-bottom: 48px;
  }
  .imageContainer {
    margin-bottom: 32px;
  }

  .carouselData {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .carouselContainer {
    display: flex;
    width: 100%;
    padding-bottom: 64px;
  }
  .divider {
    width: 100%;
    height: 1px;
    background: $strokeTwo;
    order: 2;
    margin-bottom: 40px;
  }
  .subHeader,
  .imageContainer,
  .carouselContainer {
    order: 2;
  }
}

@include mediaQuery("mobile") {
  .subHeader {
    font-size: 1.6rem;
    line-height: 2.8rem;
    margin-bottom: 32px;
    color: $bar;
  }
  .title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $salmon60;
    @include bold-font;
    margin-bottom: 8px;
    text-align: center;
  }

  .content {
    @include medium-font;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $gray48;
    text-align: center;
    margin-bottom: 32px;
  }
}

@include mediaQuery("tablate") {
  .subHeader {
    font-size: 1.8rem;
    line-height: 32px;
    max-width: 600px;
  }
  .imageContainer {
    max-width: 600px;
  }
  .divider {
    max-width: 600px;
  }
  .title {
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: $salmon60;
    @include bold-font;
    margin-bottom: 16px;
    text-align: center;
  }

  .content {
    @include medium-font;
    font-size: 1.8rem;
    line-height: 32px;
    color: $gray48;
    text-align: center;
    margin-bottom: 32px;
  }
}

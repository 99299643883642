@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.innerContainer {
}

.text {
  text-align: center;
  font-size: 2.4rem;
  @include demi-bold-font;
  margin-bottom: 16px;
  color: $black;
}

.subText {
  text-align: center;
  font-size: 1.6rem;
  color: $gray31;
  @include medium-font;
  margin-bottom: 24px;
}

.lockImage {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
}

.lockImage img {
  width: 300px;
}

.redirectButton {
  display: flex;
  justify-content: center;
}

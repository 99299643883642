@import "~UIComponents/CommonStyles/colors";

.container {
  display: flex;
  width: 100%;
  border: 1px solid $borderDefault;
  padding: 8px;
  border-radius: 4px;
}

.searchContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex: 1;
}

.searchIcon {
  display: flex;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  padding: 32px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.backButton {
  font-size: 1.4rem;
  margin-bottom: 32px;
  color: $gray48;

  span {
    margin-left: 8px;
  }
}

.title {
  font-size: 2.4rem;
  @include bold-font;
  color: $bar;
  margin-bottom: 8px;
}

.description {
  font-size: 1.4rem;
  color: $gray48;
  margin-bottom: 32px;
}

.formItem {
  margin-bottom: auto;
}

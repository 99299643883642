.container {
  padding-bottom: 8px;
  width: 420px;
  border-radius: 8px;
  max-height: 400px;
  overflow-y: auto;
}

.searchContainer {
  padding: 16px 24px 0;
  margin-bottom: 8px;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;

  @media (min-width: 1550px) {
    margin-top: 12px !important;
  }
  @include mediaQuery("mobile") {
    padding-top: 64px !important;
    height: calc(100% + 300px);
  }
}

.subTextStyle {
  font-size: 1.4rem;
  line-height: 1.36;
  color: $gray13;
  @include medium-font;
  margin-top: 8px;
  @include mediaQuery("mobile") {
    margin-top: 16px !important;
  }
}

.infoTextBox {
  position: relative;
  padding: 16px 16px 16px 46px;
  margin-top: 16px;
  font-size: 1.4rem;
  line-height: 1.36;
  @include medium-font;
  color: $gray13;
  background-color: $yellow96;
  border-left: 2px solid $yellow50;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.yellowDot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: $yellow50;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  left: 16px;
}

.underLineButton {
  text-decoration: underline;
  cursor: pointer;
  color: $blue29;
}
.headerButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: baseline;
  width: 100%;
  @include mediaQuery("mobile") {
    margin-bottom: 32px;
  }
}
.createSchoolDiv {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.createSchoolText {
  display: flex;
  font-size: 1.6rem;
  color: $black;
  margin-right: 16px;
  @include medium-font;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 12px;
}

.inputContainer {
  display: flex;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.haveAccountText {
  font-size: 1.6rem;
  color: $gray31;
  @include medium-font;
}

.bottomContainer {
  display: flex;
}

.signInButton {
  @include medium-font;
  color: $blue29;
  text-decoration: underline;
  font-size: 1.6rem;
  margin-left: 4px;
  cursor: pointer;
}

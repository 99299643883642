@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  box-shadow: 8px 0 16px 0 rgba(0, 0, 0, 0.05);
}

.innerContainer {
  display: flex;
  width: 100%;
  padding: 40px 128px;
  max-width: 984px;
  height: max-content;
  min-height: 100%;
  flex-direction: column;
  margin: 0 auto;
}

.subInnerCon {
  display: flex;
  align-items: center;
  height: 100%;
}

.stepPane {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  overflow-y: auto;
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.heading {
  @include bold-font;
  font-size: 2.4rem;
  color: $gray13;
}

.subHeading {
  @include medium-font;
  font-size: 1.6rem;
  color: $gray48;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.headerContainer {
  display: flex;
  width: 100%;

  flex-direction: row;
  align-items: center;
}

.contentContainer {
  display: block;
  padding-left: 44px;
}

.masonryContainer {
  width: 100%;
}

.nameText {
  margin-left: 12px;
  display: flex;
  @include bold-font;
  color: $gray31;
  font-size: 1.6rem;
}

.responseText {
  display: flex;
  @include regular-font;
  color: $gray31;
  font-size: 1.6rem;
  margin-bottom: 8px;
  white-space: pre-wrap;
  word-break: break-word;
}

.evidenceCount {
  @include regular-font;
  color: $gray48;
  font-size: 1.4rem;
}

.modalContent {
  height: 329px;
  width: 680px;
  border-radius: 16px;
}
.recurringOptionsContainer {
  padding: 48px 40px;
  position: relative;
  height: 100%;
  width: 100%;
}
.recurringButtonsContainer {
  position: absolute;
  bottom: 16px;
  display: flex;
  width: 90%;
  justify-content: flex-end;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    .titleContainer {
      color: $textDefault;
    }
  }
  flex-shrink: 0;
}

.innerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.optionContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}

.iconContainer {
  display: flex;
  flex-shrink: 0;
  margin-right: 8px;
}

.titleContainer {
  display: flex;
  color: $textSubtle;
  @include medium-font;
  font-size: 1.6rem;
  @include multiLineEllipsis(2.4rem, 1, 40);
  word-break: break-all;
}

.leftContainer {
  display: flex;
}

.rightContainer {
  display: flex;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
@import "~UIComponents/CommonStyles/mixins";

.container {
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    .moreText {
      text-decoration: underline;
    }
  }
}

.innerContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.headerContainer {
  display: flex;
  height: 44px;
  margin-bottom: 8px;
}

.imagePlaceHolderContainer {
  display: flex;
  width: 60px;
  height: 44px;
  flex-shrink: 0;
}

.moreText {
  display: flex;
  color: $gray13;
  width: 100%;
  align-items: center;
  padding: 0 12px;
  font-size: 1.4rem;
  @include medium-font;
}

.moreTextIcon {
  display: flex;
  color: $teal20;
  font-size: 1.6rem;
  align-items: center;
  justify-content: center;
  @include medium-font;
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.container {
  //padding-top: 260px;
  //border-bottom: 1px solid $strokeTwo;
  //border-top: 1px solid $strokeOne;
}

.stepsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 80px;
}

.desktopStepsImage {
  width: 100%;
  max-width: 926px;
}
.mobileStepsImage {
  width: 100%;
  display: none;
}

.step {
  display: flex;
  width: 216px;
  flex-direction: column;
}

.stepImage {
  display: flex;
  justify-content: center;
  height: 124px;
}

.icon {
  width: 32px;
}

.stepTitle {
  margin-top: 40px;
  @include demi-bold-font;
  font-size: 1.8rem;
  line-height: 1.78;
  color: $gray13;
  text-align: center;
}

@media (max-width: 991px) {
  .container {
    //padding-top: 56px;
  }
  .stepsContainer {
    margin-top: 64px;
  }

  .icon {
    width: 24px;
  }
  .step {
    width: 176px;
  }
  .stepImage {
    height: 104px;
  }

  .stepTitle {
    margin-top: 32px;
    font-size: 1.6rem;
  }
}

@media (max-width: 767px) {
  .stepsContainer {
    margin-top: 40px;
  }
  .desktopStepsImage {
    display: none;
  }
  .mobileStepsImage {
    display: unset;
  }
  .step {
    width: 80px;
  }
  .stepImage {
    height: 60px;
  }
  .icon {
    width: 12px;
  }
  .stepTitle {
    margin-top: 20px;
    font-size: 1rem;
    line-height: 1.5;
  }
}

@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.listContainer {
  display: flex;
  width: 100%;
  padding: 0 24px;
  overflow-y: scroll;
  flex-direction: column;
  height: 100%;
  max-height: 368px;
  margin-top: 8px;
  border-radius: 4px;
  z-index: 11;
  background-color: $white;
}

.sectionContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
}

.sectionTitleText {
  display: flex;
  @include demi-bold-font;
  color: $gray31;
  flex-shrink: 0;
  padding: 20px 0px 16px 0px;
  font-size: 1.8rem;
}

.noDataContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.nodatatext {
  display: flex;
  @include medium-font;
  color: $gray13;
  padding: 32px;
}

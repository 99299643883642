@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";

.modalContent {
  height: 88vh;
  width: 728px !important;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-shrink: 0;
}
.containerWrapper {
  flex-shrink: 0;
  padding: 56px 64px;
  display: flex;
  flex-direction: column;
  height: max-content;
}

.headerContainer {
  border-bottom: 1px solid $strokeTwo;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
  flex-shrink: 0;
}

.title {
  flex: 1;
  flex-shrink: 0;
  display: flex;
  color: $gray13;
  @include bold-font;
  font-size: 3.2rem;
}

.cancelIcon {
  display: flex;
  cursor: pointer;
  fill: $gray48;
  margin-left: 12px;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  flex-shrink: 0;
}

.descriptionTitle {
  color: $teal20;
  @include bold-font;
  font-size: 1.6rem;
  line-height: 1.6;
}

.description {
  font-size: 1.6rem;
  line-height: 1.6;
  color: $gray48;
  @include medium-font;
  margin-top: 4px;
}

.body {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
}

.attachmentTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.attachmentCount {
  font-size: 1.6rem;
  @include bold-font;
  color: $teal20;
}

.downloadAttachments {
  font-size: 1.6rem;
  @include medium-font;
  color: $blue29;
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    margin-left: 8px;
  }
}

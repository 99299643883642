@import "~UIComponents/CommonStyles/colors";
@import "~UIComponents/CommonStyles/typography";
.container {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;

  @media (min-width: 1550px) {
    margin-top: 76px !important;
  }
}

.innerContainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 48px;
}

.inputContainer {
  display: flex;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.phoneText {
  @include demi-bold-font;
  color: $gray13;
}

.resendButton {
  @include medium-font;
  color: $salmon60;
  cursor: pointer;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}

.confirmButton {
  margin-top: 24px;
  width: 100%;
}
